import React from 'react';
import {
  Row,
  Col,
  Button,
} from "antd";
import PagesHeader from '../../components/PageHeader';
import InvoiceData from '../../datatables/invoiceDatatable';
import ButtonBar from '../../components/ButtonNavigation';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from "@ant-design/icons";

const AllInvoices = () => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/create-invoice")
  }

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader
          title='All Invoices'
        />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            <Col xs={24} md={6}>
              <Button
                type='success'
                className="btn inspectionAdd" 
                onClick={() => handleClick()}
                icon={<PlusOutlined />}
              >
                Create New Invoice
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
            <InvoiceData />
        </div>
      </div>
    </div>
  );
};

export default AllInvoices;
