import React from 'react';
import { Button, Tooltip } from 'antd';

export const PrimaryButton = ({ type, handleClick, text, className }) => {
  return (
    <Button type={type} className={'btn btnPrimary' + className} onClick={handleClick}>
      {text}
    </Button>
  );
};

export const SecondaryButton = ({ type, handleClick, text, className, id }) => {
  return (
    <Button type={type} className={'btn btnSecondary ' + className} onClick={handleClick} key={id}>
      {text}
    </Button>
  );
};

export const DefaultButton = ({ type, handleClick, text }) => {
  return (
    <Button type={type} className="btn btnDefault" onClick={handleClick}>
      {text}
    </Button>
  );
};

export const AddButton = ({ type, handleClick, text }) => {
  return (
    <Button type={type} className="btn btnAdd" onClick={handleClick}>
      {text}
    </Button>
  );
};

export const IconButton = ({ type, handleClick, icon, tooltip, className, text }) => {
  return (
    <Tooltip title={tooltip} color={'#000'}>
      <Button
        type={type}
        className={'btn btnIcon ' +className}
        onClick={handleClick}
        icon={icon}
      >{text}</Button>
    </Tooltip>
  );
};
