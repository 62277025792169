import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Collapse,
  Input,
  Card,
  Form,
  DatePicker,
  Cascader,
  Select,
  Checkbox,
  Space,
  Button,
  Divider,
  Empty,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import * as getClientbyIdService from '../../../services/getClientbyID';
import { useDispatch, useSelector } from 'react-redux';
import TripleToggle from "../../../components/trippleToggle/trippleToggle";

const { TextArea } = Input;
const { Panel } = Collapse;

const FirstStep = (props) => {
  const [form] = Form.useForm();
  var dispatch = useDispatch();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenBtn, setIsOpenBtn] = useState(true);
  const [commissioningData, setCommissioningData] = useState({});

  const getCommissioningData = (e) => {
    let value = props.values;
    let newval = {
      name: value.company_name_qr,
      end_email: value.comm_email,
      Home_street: value.street_name_number,
      Home_city: value.city,
      Home_stateProvince: value.state,
      Home_country: value.country,
      end_address: value.city,
      //end_address: value.city,
      h_eu_zip: value.zip_code,
      h_eu_contact: value.agent_claim_name,
      eu_phone1: value.phone_no_party,
    };
    if(e.target.checked){
      setCommissioningData(newval);
      props.values.h_eu_name = newval.name;
      props.values.end_email = newval.end_email;
      props.values.h_eu_street = newval.Home_street;
      props.values.h_eu_city = newval.Home_city;
      props.values.h_eu_stateProvince = newval.Home_stateProvince;
      props.values.h_eu_country = newval.Home_country;
      //props.values.end_address = newval.end_address;
      props.values.h_eu_zip = newval.h_eu_zip;
      props.values.h_eu_contact = newval.h_eu_contact;
      props.values.eu_phone1 = newval.eu_phone1;
      props.values.commishomeowner = true;
    }else{
      setCommissioningData({});
      props.values.h_eu_name = "";
      props.values.end_email = "";
      props.values.h_eu_street = "";
      props.values.h_eu_city = "";
      props.values.h_eu_stateProvince = "";
      props.values.h_eu_country = "";
      props.values.h_eu_zip = "";
      props.values.h_eu_contact = "";
      props.values.eu_phone1 = "";
      props.values.commishomeowner = false;
    }
  
  };


  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const timeampm = [
    {
      value: 'AM',
      label: 'AM',
    },
    {
      value: 'PM',
      label: 'PM',
    },
  ];

  const options = [
    {
      value: 'sq/ft',
      label: 'sq/ft',
    },
    {
      value: 'Sq/yds',
      label: 'Sq/yds',
    },
    {
      value: 'boxes',
      label: 'boxes',
    },
  ];

  const { Option } = Select;

  const DateFormat = 'MM-DD-YYYY';

  const getClientbyId = (key) => {
    setIsOpenBtn(false);
    props.values.client_select_drop = key;
    dispatch(getClientbyIdService.getClientbyId(key))
      .then((res) => {
        form.setFieldsValue({
          company_name_qr: res.company_name,
          agent_claim_name: res.name,
          comm_email: res.email,
          street_name_number: res.street,
          city: res.city,
          state: res.states,
          country: res.country,
          zip_code: res.zip_code,
          phone_no_party: res.phone_personal,
        });
        props.handleFill({
          company_name_qr: res.company_name,
          agent_claim_name: res.name,
          comm_email: res.email,
          street_name_number: res.street,
          city: res.city,
          state: res.states,
          country: res.country,
          zip_code: res.zip_code,
          phone_no_party: res.phone_personal,
        });
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const addClient = () => {
    setIsOpen(false);
    setIsOpenBtn(true);
    form.setFieldsValue({
      company_name_qr: '',
      client_select_drop: '',
      comm_party_drop: '',
      agent_claim_name: '',
      comm_email: '',
      street_name_number: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      phone_no_party: '',
    });
  };

  const hideClient = () => {
    setIsOpenBtn(false);
  };

  useEffect(() => {
    console.warn('data', Data);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form
        name="report"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Card className="reportCard">
          <div className="reportCardBody">
            <div className="formSection">
              <div className="inspectionSection">
                <div className="inspecHead">
                  <h4 className="formGroupTitle">Inspection Details</h4>
                </div>
                <div className="inspecContent">
                  <Row gutter={16}>
                    <Col xs={24} md={6} xl={6}>
                      <div className="formGroup">
                        <Form.Item label="Claim Number" name="claim_num">
                          <Input
                            placeholder="Claim Number"
                            className="formControl"
                            onChange={props.handleChange('claim_num')}
                            defaultValue={props.values.claim_num}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={6} xl={6}>
                      <div className="formGroup">
                        <Form.Item label="Inspection Date" name="h_bi_ins_date">
                          <Input.Group compact className="mt-0">
                            <Input
                              placeholder="Inspection Date"
                              className="formControl"
                              onChange={(e) =>
                                props.handleDateformate(
                                  e,
                                  'h_bi_ins_date',
                                  'text'
                                )
                              }
                              value={props.values.h_bi_ins_date}
                              onFocus={() => props.setCalOpen(!props.calOpen)}
                              onKeyUp={() => props.setCalOpen(false)}
                              onBlur={() => props.setCalOpen(false)}
                            />

                            <Button
                              className="btnAddOn"
                              icon={<CalendarOutlined />}
                              onClick={() => props.setCalOpen(!props.calOpen)}
                            />
                          </Input.Group>
                          <DatePicker
                            className="formControl dateHide"
                            format={DateFormat}
                            open={props.calOpen}
                            style={{ visibility: 'hidden', width: 0 }}
                            onChange={(e) =>
                              props.handleDateformate(
                                e,
                                'h_bi_ins_date',
                                'date'
                              )
                            }
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={6} xl={6}>
                      <div className="formGroup custom-form-group">
                        <Form.Item label="Inspection Time" name="ins_time">
                          <Input
                            className="formGroup customPadding"
                            addonAfter={
                              <Cascader
                                placeholder="AM"
                                onChange={props.handleChange('time_type')}
                                options={timeampm}
                                className="custom-cascadar"
                                defaultValue={props.values.time_type}
                              />
                            }
                            placeholder="12:00"
                            onChange={props.handleChange('ins_time')}
                            defaultValue={props.values.ins_time}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={6} xl={6}>
                      <div className="formGroup">
                        <Form.Item
                          label="Inspection Type"
                          name="inspection_type"
                        >
                          <Select
                            className="formGroup"
                            defaultValue={
                              props.values.inspection_type
                                ? props.values.inspection_type
                                : 'Select Inspection Type'
                            }
                            onChange={props.handleChange('inspection_type')}
                          >
                            <Option value="Residential Inspection">
                              Residential Inspection
                            </Option>
                            <Option value="Commercial Inspection">
                              Commercial Inspection
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="CollapseSection">
                <Collapse
                  defaultActiveKey={
                    props.values.stepOneTogg
                      ? props.values.stepOneTogg
                      : ['1', '2', '3', '4', '6', '5']
                  }
                  onChange={props.handleChange('stepOneTogg')}
                  className="reportCollapse"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="custom-icon" />
                    ) : (
                      <PlusOutlined className="custom-icon" />
                    )
                  }
                  ghost
                >
                  <Panel header="Commissioning Party Information" key="1">
                    <Row gutter={16}>
                      {props.values?.client_type === 'Flooring Manufacturer' ? (
                        ''
                      ) : (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Select Client"
                              name="client_select_drop"
                            >
                              <Select
                                showSearch
                                defaultValue="Select Client"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={getClientbyId}
                                open={isOpen}
                                onDropdownVisibleChange={(visible) =>
                                  setIsOpen(visible)
                                }
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: '0',
                                      }}
                                    />
                                    <Space className="renderBtn">
                                      <Button
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={() => addClient()}
                                        block
                                      >
                                        Add New Client
                                      </Button>
                                    </Space>
                                  </>
                                )}
                              >
                                {props.clientDropdown ? (
                                  props.clientDropdown.map((option, id) => {
                                    return (
                                      <Option value={option.id} key={id}>
                                        {option.name}
                                      </Option>
                                    );
                                  })
                                ) : (
                                  <Option value="No data">
                                    <Empty />
                                  </Option>
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Commissioning Party"
                            name="comm_party_drop"
                          >
                            <Select
                              defaultValue={
                                props.values.comm_party_drop
                                  ? props.values.comm_party_drop
                                  : 'Commissioning Party'
                              }
                              onChange={props.handleChange('comm_party_drop')}
                            >
                              <Option value="Manufacturer">Manufacturer</Option>
                              <Option value="Dealer">Dealer</Option>
                              <Option value="Distributor">Distributor</Option>
                              <Option value="Homeowner">Homeowner</Option>
                              <Option value="Attorney">Attorney</Option>
                              <Option value="Builder">Builder</Option>
                              <Option value="Insurance Company">
                                Insurance Company
                              </Option>
                              <Option value="Installer">Installer</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.commPartyopen === true ||
                      props.values.comm_party_drop === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="comm_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange('comm_other')}
                                defaultValue={props.values.comm_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Company Name"
                            name="company_name_qr"
                          >
                            <Input
                              placeholder="Company Name"
                              className="formControl"
                              onChange={props.handleChange('company_name_qr')}
                              defaultValue={props.values.company_name_qr}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Full Name" name="agent_claim_name">
                            <Input
                              placeholder="John"
                              className="formControl"
                              onChange={props.handleChange('agent_claim_name')}
                              defaultValue={props.values.agent_claim_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Email Address" name="comm_email">
                            <Input
                              placeholder="example@mail.com"
                              className="formControl"
                              onChange={props.handleChange('comm_email')}
                              defaultValue={props.values.comm_email}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Street Name/Number"
                            name="street_name_number"
                          >
                            <Input
                              placeholder="Street Name/Number"
                              className="formControl"
                              onChange={props.handleChange(
                                'street_name_number'
                              )}
                              defaultValue={props.values.street_name_number}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="City" name="city">
                            <Input
                              placeholder="City"
                              className="formControl"
                              onChange={props.handleChange('city')}
                              defaultValue={props.values.city}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="State/Province" name="state">
                            <Input
                              placeholder="State/Province"
                              className="formControl"
                              onChange={props.handleChange('state')}
                              defaultValue={props.values.state}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Country" name="country">
                            <Input
                              placeholder="Country"
                              className="formControl"
                              onChange={props.handleChange('country')}
                              defaultValue={props.values.country}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Zip/Postal Code" name="zip_code">
                            <Input
                              placeholder="325008"
                              className="formControl"
                              onChange={props.handleChange('zip_code')}
                              defaultValue={props.values.zip_code}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Phone Number" name="phone_no_party">
                            <Input
                              placeholder="(000)00000"
                              className="formControl"
                              onChange={props.handleChange('phone_no_party')}
                              defaultValue={props.values.phone_no_party}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      {isOpenBtn ? (
                        <Col
                          xs={24}
                          md={6}
                          xl={6}
                          className="d-flex justify-content-start align-items-center"
                        >
                          <div className="formGroup">
                            <Button
                              className="btnSecondary me-2"
                              onClick={props.addClient}
                            >
                              Add Client
                            </Button>
                            <Button
                              className="btnSecondary me-2"
                              onClick={() => hideClient()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Panel>
                  <Panel header="Homeowner/End-User Information" key="2">
                    <Row gutter={16}>
                      <div className="m-2">
                        <Form.Item name="commishomeowner">
                          <Checkbox
                            onChange={getCommissioningData}
                            defaultChecked={props.values.commishomeowner}
                          >
                            Check this box if Homeowner is Commissioning Party
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Name"
                            name="h_eu_name"
                            valuePropName={commissioningData?.name}
                          >
                            <Input
                              placeholder="Homeowner Name"
                              className="formControl"
                              onChange={props.handleChange('h_eu_name')}
                              value={commissioningData?.name}
                              defaultValue={props.values.h_eu_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Street Name/Number "
                            name="h_eu_street"
                            valuePropName={commissioningData?.Home_street}
                          >
                            <Input
                              placeholder="Street Name/Number"
                              className="formControl"
                              onChange={props.handleChange('h_eu_street')}
                              value={commissioningData?.Home_street}
                              defaultValue={props.values.h_eu_street}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="City "
                            name="h_eu_city"
                            valuePropName={commissioningData?.Home_city}
                          >
                            <Input
                              placeholder="City"
                              className="formControl"
                              onChange={props.handleChange('h_eu_city')}
                              value={commissioningData?.Home_city}
                              defaultValue={props.values.h_eu_city}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="State/Province "
                            name="h_eu_stateProvince"
                            valuePropName={
                              commissioningData?.Home_stateProvince
                            }
                          >
                            <Input
                              placeholder="State/Province"
                              className="formControl"
                              onChange={props.handleChange(
                                'h_eu_stateProvince'
                              )}
                              value={commissioningData?.Home_stateProvince}
                              defaultValue={props.values.h_eu_stateProvince}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Country"
                            name="h_eu_country"
                            valuePropName={commissioningData?.Home_country}
                          >
                            <Input
                              placeholder="Country"
                              className="formControl"
                              onChange={props.handleChange('h_eu_country')}
                              defaultValue={props.values.h_eu_country}
                              value={commissioningData?.Home_country}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      {/* <Col xs={24} md={12} xl={12}>
                          <div className="formGroup">
                            <Form.Item label="Address" name="end_address" valuePropName={commissioningData?.end_address}> */}
                      {/* {console.log()} */}
                      {/* <Input
                                placeholder="Address"
                                className="formControl"
                                onChange={props.handleChange('end_address')}
                                value={commissioningData?.end_address}
                                defaultValue={props.values.end_address}
                              />
                            </Form.Item>
                          </div>
                        </Col> */}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Zip/Postal Code"
                            name="h_eu_zip"
                            valuePropName={commissioningData?.h_eu_zip}
                          >
                            <Input
                              placeholder="324004"
                              className="formControl"
                              onChange={props.handleChange('h_eu_zip')}
                              value={commissioningData?.h_eu_zip}
                              defaultValue={props.values.h_eu_zip}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Contact"
                            name="h_eu_contact"
                            valuePropName={commissioningData?.h_eu_contact}
                          >
                            <Input
                              placeholder="Contact"
                              className="formControl"
                              onChange={props.handleChange('h_eu_contact')}
                              value={commissioningData?.h_eu_contact}
                              defaultValue={props.values.h_eu_contact}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Phone Number"
                            name="eu_phone1"
                            valuePropName={commissioningData?.eu_phone1}
                          >
                            <Input
                              placeholder="Phone Number"
                              className="formControl"
                              onChange={props.handleChange('eu_phone1')}
                              value={commissioningData?.eu_phone1}
                              defaultValue={props.values.eu_phone1}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Mobile Number"
                            name="end_mobile"
                            valuePropName={commissioningData?.end_mobile}
                          >
                            <Input
                              placeholder="Mobile Number"
                              className="formControl"
                              onChange={props.handleChange('end_mobile')}
                              value={commissioningData?.end_mobile}
                              defaultValue={props.values.end_mobile}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Email Address"
                            name="end_email"
                            valuePropName={commissioningData?.end_email}
                          >
                            <Input
                              placeholder="email@mail.com"
                              className="formControl"
                              onChange={props.handleChange('end_email')}
                              value={commissioningData?.end_email}
                              defaultValue={props.values.end_email}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Manufacturers Contact Information" key="3">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Manufacturer Name" name="manf_name">
                            <Input
                              placeholder="Manufacturer Name"
                              className="formControl"
                              onChange={props.handleChange('manf_name')}
                              defaultValue={props.values.manf_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Dealer Contact Information" key="4">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Dealer Name" name="dealer_name">
                            <Input
                              placeholder="Dealer Name"
                              className="formControl"
                              onChange={props.handleChange('dealer_name')}
                              defaultValue={props.values.dealer_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Contact Name" name="dealer_contact">
                            <Input
                              placeholder="Contact Name"
                              className="formControl"
                              onChange={props.handleChange('dealer_contact')}
                              defaultValue={props.values.dealer_contact}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>

                  <Panel header="Product and Claim Information" key="5">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Date of Installation"
                            name="product_date_of_ins"
                          >
                            <Input.Group compact className="mt-0">
                              <Input
                                placeholder="Date of Installation"
                                className="formControl"
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'product_date_of_ins',
                                    'text'
                                  )
                                }
                                value={props.values.product_date_of_ins}
                                onFocus={() =>
                                  props.setCalOpen1(!props.calOpen1)
                                }
                                onKeyUp={() => props.setCalOpen1(false)}
                                onBlur={() => props.setCalOpen1(false)}
                              />

                              <Button
                                className="btnAddOn"
                                icon={<CalendarOutlined />}
                                onClick={() =>
                                  props.setCalOpen1(!props.calOpen1)
                                }
                              />
                            </Input.Group>
                            <DatePicker
                              className="formControl dateHide"
                              format={DateFormat}
                              open={props.calOpen1}
                              style={{ visibility: 'hidden', width: 0 }}
                              onChange={(e) =>
                                props.handleDateformate(
                                  e,
                                  'product_date_of_ins',
                                  'date'
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Date Problem Noticed"
                            name="date_problem_noticed"
                          >
                            <Input.Group compact className="mt-0">
                              <Input
                                placeholder="Date Problem Noticed"
                                className="formControl"
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'date_problem_noticed',
                                    'text'
                                  )
                                }
                                value={props.values.date_problem_noticed}
                                onFocus={() =>
                                  props.setCalOpen2(!props.calOpen2)
                                }
                                onKeyUp={() => props.setCalOpen2(false)}
                                onBlur={() => props.setCalOpen2(false)}
                              />

                              <Button
                                className="btnAddOn"
                                icon={<CalendarOutlined />}
                                onClick={() =>
                                  props.setCalOpen2(!props.calOpen2)
                                }
                              />
                            </Input.Group>
                            <DatePicker
                              className="formControl dateHide"
                              format={DateFormat}
                              open={props.calOpen2}
                              style={{ visibility: 'hidden', width: 0 }}
                              onChange={(e) =>
                                props.handleDateformate(
                                  e,
                                  'date_problem_noticed',
                                  'date'
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Reported To" name="reported_to">
                            <Input
                              placeholder="Reported To"
                              className="formControl"
                              onChange={props.handleChange('reported_to')}
                              defaultValue={props.values.reported_to}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Flooring Type" name="flooring_type">
                            <Select
                              defaultValue={
                                props.values.flooring_type
                                  ? props.values.flooring_type
                                  : 'Select Flooring Type'
                              }
                              onChange={props.handleChange('flooring_type')}
                            >
                              <Option value="Solid Hardwood">
                                Solid Hardwood
                              </Option>
                              <Option value="Engineered Hardwood">
                                Engineered Hardwood
                              </Option>
                              <Option value="LV-Multilayer">
                                LV-Multilayer
                              </Option>
                              <Option value="Resillient Sheet Goods">
                                Resillient Sheet Goods
                              </Option>
                              <Option value="Laminate">Laminate</Option>
                              <Option value="Tile">Tile</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.floorOtherField === true ||
                      props.values.flooring_type === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="hard_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange('hard_other')}
                                defaultValue={props.values.hard_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Installation Type"
                            name="car_i2_inst_type"
                          >
                            <Select
                              defaultValue={
                                props.values.car_i2_inst_type
                                  ? props.values.car_i2_inst_type
                                  : 'Select Installation Type'
                              }
                              onChange={props.handleChange('car_i2_inst_type')}
                            >
                              <Option value="Floating">Floating</Option>
                              <Option value="Glue down">Glue Down</Option>
                              <Option value="Nail/staple">Nail/staple</Option>
                              <Option value="Loose lay">Loose Lay</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.installationOther === true ||
                      props.values.car_i2_inst_type === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other"
                              name="car_i2_other_inst_mthd"
                            >
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  'car_i2_other_inst_mthd'
                                )}
                                defaultValue={
                                  props.values.car_i2_other_inst_mthd
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}

                      {props.isOpen.stapleField === true ||
                      props.values.car_i2_inst_type === 'Nail/staple' ? (
                        <>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Nail/Staple size"
                                name="nail_staple_size"
                              >
                                <Input
                                  placeholder="Nail/Staple Size"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'nail_staple_size'
                                  )}
                                  defaultValue={props.values.nail_staple_size}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Nail/Staple Pattern or Spacing"
                                name="nail_staple_pattern_spacing"
                              >
                                <Input
                                  placeholder="Nail/Staple Pattern or Spacing"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'nail_staple_pattern_spacing'
                                  )}
                                  defaultValue={
                                    props.values.nail_staple_pattern_spacing
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      ) : null}

                      {props.isOpen.installationGlue === true ||
                      props.values.car_i2_inst_type === 'Glue down' ? (
                        <>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                              label="Was the Floor Rolled ?"
                              name="floor_rolled_toggle"
                            >
                              {console.warn('here')}
                              
                              <TripleToggle
                              value={props.values.floor_rolled_toggle}
                              onToggle={props.handleChange('floor_rolled_toggle')}
                            />
                            </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Adhesive Name/Number"
                                name="adhesive_name_number"
                              >
                                <Input
                                  placeholder="Adhesive Name/Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'adhesive_name_number'
                                  )}
                                  defaultValue={
                                    props.values.adhesive_name_number
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Trowel Used-Size/Dimension"
                                name="trowel_used_size_dimension"
                              >
                                <Input
                                  placeholder="Trowel Used-Size/Dimension"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'trowel_used_size_dimension'
                                  )}
                                  defaultValue={
                                    props.values.trowel_used_size_dimension
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Roller Weight LBS"
                                name="roller_weight_lbs"
                              >
                                <Input
                                  placeholder="Roller Weight LBS"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'roller_weight_lbs'
                                  )}
                                  defaultValue={props.values.roller_weight_lbs}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Manufacturer Name"
                            name="product_manf"
                          >
                            <Input
                              placeholder="Manufacturer Name"
                              className="formControl"
                              onChange={props.handleChange('product_manf')}
                              defaultValue={props.values.product_manf}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Style Name/Number"
                            name="style_name"
                          >
                            <Input
                              placeholder="Style Name/Number"
                              className="formControl"
                              onChange={props.handleChange('style_name')}
                              defaultValue={props.values.style_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Color Name/Number"
                            name="product_color"
                          >
                            <Input
                              placeholder="Brown,Wooden"
                              className="formControl"
                              onChange={props.handleChange('product_color')}
                              defaultValue={props.values.product_color}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Species" name="hardwood_species">
                            <Input
                              placeholder="Species"
                              className="formControl"
                              onChange={props.handleChange('hardwood_species')}
                              defaultValue={props.values.hardwood_species}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Area/Rooms Installed"
                            name="area_room_ins"
                          >
                            <Input
                              placeholder="Area/Rooms Installed"
                              className="formControl"
                              onChange={props.handleChange('area_room_ins')}
                              defaultValue={props.values.area_room_ins}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup custom-form-group">
                          <Form.Item
                            label="Total Claim Quantity"
                            name="total_claim_qt"
                          >
                            <Input
                              className="formGroup customPadding"
                              addonAfter={
                                <Cascader
                                  placeholder="sq/ft"
                                  onChange={props.handleChange('measure_type')}
                                  options={options}
                                  className="custom-cascadar"
                                  defaultValue={props.values.measure_type}
                                />
                              }
                              placeholder="10 sq/ft"
                              onChange={props.handleChange('total_claim_qt')}
                              defaultValue={props.values.total_claim_qt}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Invoice Number/PO Number"
                            name="product_invoice"
                          >
                            <Input
                              placeholder="#01234567"
                              className="formControl"
                              onChange={props.handleChange('product_invoice')}
                              defaultValue={props.values.product_invoice}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={12} xl={24}>
                        <div className="formGroup">
                          <Form.Item
                            label="Statement of Concern"
                            name="concerns"
                          >
                            <TextArea
                              placeholder="Statement of Concern"
                              className="customTextarea"
                              onChange={props.handleChange('concerns')}
                              defaultValue={props.values.concerns}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel
                    header="Site Description and Occupancy Information"
                    key="6"
                  >
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Property Type"
                            name="property_dropdown"
                          >
                            <Select
                              defaultValue={
                                props.values.property_dropdown
                                  ? props.values.property_dropdown
                                  : 'Select Property Type'
                              }
                              onChange={props.handleChange('property_dropdown')}
                            >
                              <Option value="Residential">Residential</Option>
                              <Option value="Commercial">Commercial</Option>
                              <Option value="Rental Unit">Rental Unit</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Building Type" name="building_type">
                            <Select
                              defaultValue={
                                props.values.building_type
                                  ? props.values.building_type
                                  : 'Select Building Type'
                              }
                              onChange={props.handleChange('building_type')}
                            >
                              <Option value="Single Story">Single Story</Option>
                              <Option value="Multi-Level">Multi-Level</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.buildingOther === true ||
                      props.values.building_type === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="build_type_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  'build_type_other'
                                )}
                                defaultValue={props.values.build_type_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Building Style"
                            name="building_style"
                          >
                            <Select
                              defaultValue={
                                props.values.building_style
                                  ? props.values.building_style
                                  : 'Select Building Style'
                              }
                              onChange={props.handleChange('building_style')}
                            >
                              <Option value="Single Family">
                                Single Family
                              </Option>
                              <Option value="Apartment">Apartment</Option>
                              <Option value="Condominium">Condominium</Option>
                              <Option value="Church">Church</Option>
                              <Option value="Common Areas">Common Areas</Option>
                              <Option value="Dental">Dental</Option>
                              <Option value="Exterior">Exterior</Option>
                              <Option value="Educational Facility">
                                Educational Facility
                              </Option>
                              <Option value="Golf Course">Golf Course</Option>
                              <Option value="Group Home">Group Home</Option>
                              <Option value="Hospital">Hospital</Option>
                              <Option value="Hospitality">Hospitality</Option>
                              <Option value="Manufactured Home">
                                Manufactured Home
                              </Option>
                              <Option value="Medical">Medical </Option>
                              <Option value="Offices">Offices</Option>
                              <Option value="Office Building">
                                Office Building
                              </Option>
                              <Option value="Retail Store">Retail Store</Option>
                              <Option value="Veterinary">Veterinary</Option>
                              <Option value="Watercraft">Watercraft</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.buildingStyleOther === true ||
                      props.values.building_style === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other"
                              name="other_building_style"
                            >
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  'other_building_style'
                                )}
                                defaultValue={props.values.other_building_style}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Traffic Type" name="traffic_type">
                            <Select
                              defaultValue={
                                props.values.traffic_type
                                  ? props.values.traffic_type
                                  : 'Select Traffic Type'
                              }
                              onChange={props.handleChange('traffic_type')}
                            >
                              <Option value="Owner Occupied">
                                Owner Occupied
                              </Option>
                              <Option value="Rental">Rental</Option>
                              <Option value="Seasonal Residents">
                                Seasonal Residents
                              </Option>
                              <Option value="Unoccupied">Unoccupied</Option>
                              <Option value="Light Commercial Traffic">
                                Light Commercial Traffic
                              </Option>
                              <Option value="Moderate Commercial Traffic">
                                Moderate Commercial Traffic
                              </Option>
                              <Option value="Heavy Commercial Traffic">
                                Heavy Commercial Traffic
                              </Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.trafficTypeOther === true ||
                      props.values.traffic_type === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="other_trafic">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange('other_trafic')}
                                defaultValue={props.values.other_trafic}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Building Age" name="building_age">
                            <Input
                              placeholder="10 yrs"
                              className="formControl"
                              onChange={props.handleChange('building_age')}
                              defaultValue={props.values.building_age}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                        <Form.Item
                        label="New Construction?"
                        name="h_bui_cons"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.h_bui_cons}
                              onToggle={props.handleChange('h_bui_cons')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.constructionField === true ||
                          props.values.h_bui_cons === 'Yes' ? (
                          <div>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Closing Date"
                                    name="closing_date"
                                  >
                                    <Input.Group compact className="mt-0">
                                      <Input
                                        placeholder="Closing Date"
                                        className="formControl"
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'closing_date',
                                            'text'
                                          )
                                        }
                                        value={props.values.closing_date}
                                        onFocus={() =>
                                          props.setCalOpen3(!props.calOpen3)
                                        }
                                        onKeyUp={() => props.setCalOpen3(false)}
                                        onBlur={() => props.setCalOpen3(false)}
                                      />

                                      <Button
                                        className="btnAddOn"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                          props.setCalOpen3(!props.calOpen3)
                                        }
                                      />
                                    </Input.Group>
                                    <DatePicker
                                      className="formControl dateHide"
                                      format={DateFormat}
                                      open={props.calOpen3}
                                      style={{ visibility: 'hidden', width: 0 }}
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          'closing_date',
                                          'date'
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Move in Date"
                                    name="moving_date"
                                  >
                                    <Input.Group compact className="mt-0">
                                      <Input
                                        placeholder="Move in Date"
                                        className="formControl"
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'moving_date',
                                            'text'
                                          )
                                        }
                                        value={props.values.moving_date}
                                        onFocus={() =>
                                          props.setCalOpen4(!props.calOpen4)
                                        }
                                        onKeyUp={() => props.setCalOpen4(false)}
                                        onBlur={() => props.setCalOpen4(false)}
                                      />

                                      <Button
                                        className="btnAddOn"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                          props.setCalOpen4(!props.calOpen4)
                                        }
                                      />
                                    </Input.Group>
                                    <DatePicker
                                      className="formControl dateHide"
                                      format={DateFormat}
                                      open={props.calOpen4}
                                      style={{ visibility: 'hidden', width: 0 }}
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          'moving_date',
                                          'date'
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                                <div className="formGroup">
                                  <Form.Item
                                    label="HVAC Operational Date"
                                    name="operational_date"
                                  >
                                    <Input.Group compact className="mt-0">
                                      <Input
                                        placeholder="HVAC Operational Date"
                                        className="formControl"
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'operational_date',
                                            'text'
                                          )
                                        }
                                        value={props.values.operational_date}
                                        onFocus={() =>
                                          props.setCalOpen5(!props.calOpen5)
                                        }
                                        onKeyUp={() => props.setCalOpen5(false)}
                                        onBlur={() => props.setCalOpen5(false)}
                                      />

                                      <Button
                                        className="btnAddOn"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                          props.setCalOpen5(!props.calOpen5)
                                        }
                                      />
                                    </Input.Group>
                                    <DatePicker
                                      className="formControl dateHide"
                                      format={DateFormat}
                                      open={props.calOpen5}
                                      style={{ visibility: 'hidden', width: 0 }}
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          'operational_date',
                                          'date'
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                         <Form.Item
                              label="Walk off Mats"
                              name="h_bui_walk_off"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.h_bui_walk_off}
                              onToggle={props.handleChange('h_bui_walk_off')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.walkField === true ||
                          props.values.h_bui_walk_off === 'Yes' ? (
                          <div>
                            <Row>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="All Exterior Building Entrance"
                                    name="all_exterior"
                                  >
                                    <Select
                                      defaultValue={
                                        props.values.all_exterior
                                          ? props.values.all_exterior
                                          : 'Select Exterior'
                                      }
                                      onChange={props.handleChange(
                                        'all_exterior'
                                      )}
                                    >
                                      <Option value="Interior Entrances">
                                        Interior Entrances
                                      </Option>
                                      <Option value="High Traffic Areas">
                                        High Traffic Areas
                                      </Option>
                                      <Option value="Other">Other</Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                              {props.isOpen.exteriorOther === true ||
                              props.values.all_exterior === 'Other' ? (
                                <Col xs={24} md={24} xl={24}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Other"
                                      name="other_exterior"
                                    >
                                      <Input
                                        placeholder="Other"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          'other_exterior'
                                        )}
                                        defaultValue={
                                          props.values.other_exterior
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              ) : null}
                            </Row>
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                              label="Building Occupied?"
                              name="h_bui_occupied"
                            >
                              {console.warn('here')}

                              <TripleToggle
                              value={props.values.h_bui_occupied}
                              onToggle={props.handleChange('h_bui_occupied')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.buildField === true ||
                          props.values.h_bui_occupied === 'Yes' ? (
                          <div>
                            <Row>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <Form.Item label="Adults" name="adults">
                                    <Input
                                      placeholder="Adults"
                                      className="formControl"
                                      onChange={props.handleChange('adults')}
                                      defaultValue={props.values.adults}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="formGroup">
                                  <Form.Item label="Children" name="childrens">
                                    <Input
                                      placeholder="Children"
                                      className="formControl"
                                      onChange={props.handleChange('childrens')}
                                      defaultValue={props.values.childrens}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="formGroup">
                                  <Form.Item label="Pets" name="pets">
                                    <Input
                                      placeholder="Pets"
                                      className="formControl"
                                      onChange={props.handleChange('pets')}
                                      defaultValue={props.values.pets}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </Card>
      </Form>
    </>
  );
};

export default FirstStep;
