import React, { useCallback, useRef, useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import {
  Tooltip,
  Upload,
  Modal,
  Input,
  Radio,
  Typography,
  Progress,
  Form,
  Spin
} from "antd";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import axios from "axios";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MEDIA_UPLOAD_URL } from "../../Helper";
import Fancybox from "./fancybox";
import { EyeOutlined } from "@ant-design/icons";

const type = "DragableUploadList";
const { Title } = Typography;

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef(null);
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ""
        }`}
      style={{
        cursor: "move",
      }}
    >
      {file.status === "error" ? errorNode : originNode}
    </div>
  );
};

const App = (props) => {
  const takefileList = props.takefileList;
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancel = () => setPreviewVisible(false);
  const normFile = (e) => {
    // console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e && e.fileList;
    }

    return e && e.fileList;
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );
  useEffect(() => {
    setFileList(props.images ? props.images : []);
  }, [props.images]);

  const uploadImage = async (options, values) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }

        onProgress({ percent: (event.loaded / event.total) * 100 });

      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess(res);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };

  const onChange = (info) => {
    console.warn({ info: info })
    if (info.file.status === "uploading") {
      setLoading(true);
      setFileList(info.fileList);
    }
    if (info.file.status === "removed") {
      setFileList(info.fileList);
    }
    if (info.file.status === "done") {
      let newFileList = [...fileList];
      let index = newFileList.findIndex((ele) => ele.uid === info.file.uid)
      newFileList[index]['status'] = 'done';
      newFileList[index]['media_id'] = info.file.response.data.id;
      newFileList[index]['url'] = info.file.response.data.source_url;
      setFileList(newFileList);
      setLoading(false);
    }

  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    console.warn('handlePreview', file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <>
      <Spin size="large" spinning={loading}>
        <DndProvider backend={HTML5Backend}>
          <Form.Item
            name="dragger"
            valuePropName={fileList}
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload
              name="files"
              multiple={true}
              customRequest={uploadImage}
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={handlePreview}
              showUploadList={{
                showPreviewIcon: false,
              }}
              
               accept="image/*"
              type="file"
              // accept="image/png, image/gif, image/jpeg"
              itemRender={(originNode, file, currFileList) => (
                <>
                  <div className="uploaded-img-wrapper">
                    <div className="img-section">
                      <Fancybox>
                        <a data-fancybox="gallery" href={file.url} className="zIndex">
                          <EyeOutlined />
                        </a>
                      </Fancybox>
                      <DragableUploadListItem
                        originNode={originNode}
                        file={file}
                        fileList={currFileList}
                        moveRow={moveRow}
                        onChange={takefileList(currFileList)}
                      />
                    </div>
                    <div className="img-text-wrapper">
                      <div className="input-header">
                        <Title level={5}>Description</Title>
                      </div>
                      <Form.Item
                        name={[
                          "Allimagedescription",
                          file.uid.toString(),
                          "caption",
                        ]}
                        initialValue={file.desc}
                      >
                        <Input.TextArea
                          className="input-img"
                        //defaultValue={file.desc}
                        //value={file.desc}
                        />
                      </Form.Item>
                    </div>
                    <div className="img-footer">
                      <Form.Item name="cover_image">
                        <Radio.Group
                          defaultValue={
                            parseInt(file.cover) === file.uid ? file.uid : ""
                          }
                        >
                          <Radio
                            name="cover_image"
                            value={
                              file?.media_id
                                ? file?.media_id
                                : file?.uid
                            }
                          >
                            <p>Please Select Cover Photo</p>
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </>
              )}
            >
              <p>Drop files here to upload</p>
            </Upload>
          </Form.Item>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </DndProvider>
      </Spin>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default App;
