import React, { useState, useEffect } from "react";
import { Row, Card, Col, Image, Divider, Button, Skeleton,Modal,Form,Radio,List } from "antd";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as ReportServices from "../../services/SingleReport";
import {
  FormOutlined
} from "@ant-design/icons";
import PlaceHolderImg from '../../assets/images/logoPlaceholder.png';
import Swal from 'sweetalert2';
import * as setPdfSettingsService from "../../services/PdfSettingsService";

function SingleLaminate() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);
  const [savePdfLoading, setSavePdfLoading] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [reportLogos, setReportLogos] = useState(null);
  // const [defaultPdfTemplate, setDefaultPdfTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
    getPdfTemplateData();
    setAttachments();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadio = (e) => {
    
    setSelectedTemplate(e.target.value);
  }
  const pdfTemplates = {
    1: {
      index: 1,
      template_id: 1,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040610/template_pdf_img_1.png",
    },
    2: {
      index: 2,
      template_id: 2,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040629/template_pdf_img_2.png",
    },
    3: {
      index: 3,
      template_id: 3,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/22093235/template_3_img.png",
    },
    4: {
      index: 4,
      template_id: 4,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040619/template_pdf_img_4.png",
    },
    5: {
      index: 5,
      template_id: 5,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040615/template_pdf_img_5.png",
    },
    6: {
      index: 6,
      template_id: 6,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/08/28053646/template_pdf_img_6-1.png",
    },
  };
  const getPdfTemplateData = () => {
    setLoading(true);
    dispatch(setPdfSettingsService.getpdfTemplate(selectedTemplate))
    .then((res) => {
      setSelectedTemplate(res?.pdf_template_id);

    })
    .catch((err) => {
      console.info("Get Error Edit State...");
      setLoading(false);
    });
  }
  const setPdfSettings = (value) => {
    dispatch(setPdfSettingsService.PdfSettingsService(selectedTemplate))
      .then((res) => {
        console.warn({ res });
        Swal.fire({
          icon: 'success',
          title: 'PDF Template selected successfully',
        })
        handleCancel();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
 
  };
  const showModal = () => {
    setIsModalOpen(true);
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };   

    const getPdf = () => {
    setSavePdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setSavePdfLoading(false);
        
        // After generating the PDF, show the popup to choose the next action
        Swal.fire({
          title: 'PDF Generated Successfully',
          text: 'Please choose your next action:',
          icon: 'success',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Go to Invoice',
          denyButtonText: 'Download PDF',
          cancelButtonText: 'Skip to Cover Letter',
          reverseButtons: true,
          confirmButtonColor: '#0A497B',
          denyButtonColor: '#0A497B',
          cancelButtonColor: '#0A497B',
        }).then((result) => {
          if (result.isConfirmed) {
            if (reportData.invoice_generated === "NO") {
              navigate("/create-invoice/?req_id=" + reportData.req_id);
            } else {
              navigate("/report-invoice/?invoice_id=" + reportData.invoice_id);
            }
          } else if (result.isDenied) {
            // Logic to download the generated PDF
            const link = document.createElement('a');
            link.href = res.data; // Assuming res.data.pdf_url contains the URL of the generated PDF
            link.download = 'GeneratedReport.pdf'; // Specify the file name
            link.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            if (reportData.cover_generated === "NO") {
              navigate("/create-cover-letter/?req_id=" + reportData.req_id);
            } else {
              navigate("/cover-letter/?cover_id=" + reportData.cover_id);
            }
          }
        });
  
      })
      .catch((err) => {
        let errorMessage;
  
        if (err.response) {
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }
  
        alert(errorMessage);
        setSavePdfLoading(false);
      });
  };
  const downloadPdf = () => {
    setDownloadPdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setDownloadPdfLoading(false);
        // window.open(res.data, 'download');
        window.open(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const fetchData = (params = {}) => {
    const queryParams = new URLSearchParams(window.location.search);

    const report_id = queryParams.get("report_id");
    setLoading(true);
    dispatch(ReportServices.getSingleReport(report_id))
      .then((res) => {
        console.info(res);
        setReportData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  useEffect(() => {
    setAttachments(reportData?.attachments);
    setReportLogos(reportData?.selectededlogos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.attachments, reportData?.selectededlogos]);

  const handleNavigate = () => {
    navigate("/laminate-update/?report_id=" + reportData.report_id)
  }

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader
          title="Inspection Request | Professional Report"
        />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              {reportData?.current_user_role === "um_inspector" ? (
                <Button
                  type='success'
                  className="btn inspectionAdd"
                  onClick={() => handleNavigate()}
                  icon={<FormOutlined />}
                >
                  Edit Report
                </Button>
              ) : (
                ''
              )}

            </Col>
          </Row>
        </div>
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
          {loading ? (
            <div className="spinDiv fullHeight">
              {/* <Spin size="large" /> */}
              <Skeleton loading={loading} active avatar>
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
          
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
            </div>
          ) : (
            <Card className="reportCard" id="quickReportContent">
              <div className="reportCardBody">
                <div className="inspectorInfo">
                  <Row gutter={30}>
                    <Col xs={24} md={4}>
                      <div className="inspectorLogo">
                        <Image
                          src={
                            reportData?.logo_url ? reportData.logo_url : PlaceHolderImg
                          }
                          className="ing-fluid"
                          alt="InspectorLogo"
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={20}>
                      <div className="inspectorDetails">
                        <div className="inspecDetailHead">
                          <h5 className="inspcTitle">
                            Inspection Request | Professional Report
                          </h5>
                        </div>
                        <div className="inspcInfo">
                          <p className="orgName">
                            {reportData?.organization_name_ins}
                          </p>
                          <p className="inspcData">
                            <span className="inspcSpan">
                              {reportData?.insp_fullname_ins}
                            </span>

                            <span className="inspcSpan">
                              {reportData?.address_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Office- {reportData?.phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Cell- {reportData?.company_phone_number_ins}{" "}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.websiteins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.email_ins}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Inspection Details */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Inspection Details
                  </Divider>
                  <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Claim Number</label>
                            <p>{reportData?.claim_num}</p>
                          </div>
                        </Col>

                        {reportData?.h_bi_ins_date ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Date</label>
                              <p>{reportData?.h_bi_ins_date}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.ins_time ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Time</label>
                              <p>{reportData?.ins_time} {reportData?.time_type}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.inspection_type ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Type</label>
                              <p>{reportData?.inspection_type}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>
                {/* Commissioning Party Information */}
                {reportData.party_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.comm_party_drop ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Commissioning Party</label>
                                {reportData?.comm_party_drop === "Other" ? (
                                  <p>{reportData?.comm_other}</p>
                                ) : (
                                  <p>{reportData?.comm_party_drop}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.company_name_qr ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Company Name</label>
                                <p>{reportData?.company_name_qr}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.agent_claim_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Full Name</label>
                                <p>{reportData?.agent_claim_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.comm_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.comm_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.street_name_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.street_name_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.zip_code ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip Code/Postal Code</label>
                                <p>{reportData?.zip_code}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.phone_no_party ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.phone_no_party}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/*  Homeowner/End-User Information*/}
                {reportData.enduser_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Homeowner/End-User Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        {/* {reportData?.commishomeowner ? (
                          <Col xs={24} md={8}>
                            <div className="formGroup">
                              <label>
                                Check This Box if Homeowner is Commissioning Party
                              </label>
                              <p>{reportData?.commishomeowner}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )} */}
                        <Row gutter={16}>
                          {reportData?.h_eu_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.h_eu_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.h_eu_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.h_eu_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.h_eu_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_stateProvince ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.h_eu_stateProvince}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.h_eu_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.end_address ? (
                            <Col xs={24} md={12}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.end_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.h_eu_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip Code/Postal Code</label>
                                <p>{reportData?.h_eu_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.h_eu_contact ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact</label>
                                <p>{reportData?.h_eu_contact}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.eu_phone1 ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.eu_phone1}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.end_mobile ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Mobile Number</label>
                                <p>{reportData?.end_mobile}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.end_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.end_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Manufacturers Contact Informationn*/}
                {reportData.mc_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Manufacturers Contact Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        {/* {reportData?.manufiscomm ? (
                          <Col xs={24} md={8}>
                            <div className="formGroup">
                              <label>
                                Check This Box if Manufacturer is Commissioning
                                Party
                              </label>
                              <p>{reportData?.manufiscomm}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )} */}
                        <Row gutter={16}>
                          {/* <Col xs={24} md={6}>
                      <div className="formGroup">
                        <label>Select Manufacturer</label>
                        <p>-</p>
                      </div>
                    </Col> */}

                          {reportData?.manf_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Manufacturers Name</label>
                                <p>{reportData?.manf_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_contact ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Name</label>
                                <p>{reportData?.manf_contact}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_street_name_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.manf_street_name_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.manf_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.manf_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.manf_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip Code/Postal Code</label>
                                <p>{reportData?.manf_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_phone ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.manf_phone}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.manf_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.manf_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Dealer Contact Information*/}
                {reportData.dealer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Dealer Contact Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        {/* {reportData?.commisdealer ? (
                          <Col xs={24} md={8}>
                            <div className="formGroup">
                              <label>
                                Check This Box if Dealer is Commissioning Party
                              </label>
                              <p>{reportData?.commisdealer}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )} */}
                        <Row gutter={16}>
                          {/* <Col xs={24} md={6}>
                        <div className="formGroup">
                          <label>Select Dealer</label>
                          <p>-</p>
                        </div>
                      </Col> */}

                          {reportData?.dealer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Dealer Name</label>
                                <p>{reportData?.dealer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_contact ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Name</label>
                                <p>{reportData?.dealer_contact}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_street_name_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.dealer_street_name_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.dealer_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.dealer_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip Code/Postal Code</label>
                                <p>{reportData?.dealer_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_phone ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.dealer_phone}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.dealer_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.dealer_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Installer Information */}
                {reportData.installer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                          Installer Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                      <Row gutter={16}>
                          {reportData?.installer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Installer Name</label>
                                <p>{reportData?.installer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.installer_contact ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Name</label>
                                <p>{reportData?.installer_contact}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                             {reportData?.installer_street_name_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.installer_street_name_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}


                          {reportData?.installer_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.installer_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.installer_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.installer_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.installer_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.installer_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                       

                          {reportData?.installer_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip Code/Postal Code</label>
                                <p>{reportData?.installer_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.installer_phone ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.installer_phone}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.installer_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.installer_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                      
                        {reportData?.installer_present === "HideThisField" || reportData?.installer_present === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Was The Installer Present</label>
                                  <p>{reportData?.installer_present}</p>
                                </div>
                              </Col>
                          )}
                          </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* GENERAL CONTRACTOR/BUILDER */}
                {reportData.gc_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      General Contractor/Builder
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.gc_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>General Contractor Name</label>
                                <p>{reportData?.gc_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.gc_contact_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Name</label>
                                <p>{reportData?.gc_contact_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_contac_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.gc_contac_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_contac_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.gc_contac_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_contac_state_province ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.gc_contac_state_province}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_contac_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.gc_contac_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.gc_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.gc_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip Code/Postal Code </label>
                                <p>{reportData?.gc_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.gc_phone ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.gc_phone}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.gc_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.gc_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_subdivision[0] && reportData?.gc_subdivision[0][0] !== "" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Sub Division </label>
                                <p>{reportData?.gc_subdivision.join(", ")}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.gc_lotnumber[0] && reportData?.gc_lotnumber[0][0] !== "" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Lot Number</label>
                                <p>{reportData?.gc_lotnumber.join(", ")}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Product and Claim Information*/}
                {reportData.basic_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Product and Claim Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.product_date_of_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date of Installation</label>
                                <p>{reportData?.product_date_of_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.date_problem_noticed ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Problem Noticed</label>
                                <p>{reportData?.date_problem_noticed}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.reported_to ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Reported To</label>
                                <p>{reportData?.reported_to}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_manf ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Manufacturer Name</label>
                                <p>{reportData?.product_manf}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.previous_floor_covering ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Previous Floor Covering </label>
                                <p>{reportData?.previous_floor_covering}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.car_i2_inst_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Installation Type</label>
                                {reportData?.car_i2_inst_type === "Other" ? (
                                  <p>{reportData?.car_i2_other_inst_mthd}</p>
                                ) : (
                                  <p>{reportData?.car_i2_inst_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.car_i2_inst_type === 'Glue down' ? (
                            <>
                            
                              {reportData?.floor_rolled_toggle === "HideThisField" || reportData?.floor_rolled_toggle === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={8}>
                                    <div className="formGroup">
                                    <label>Was the Floor Rolled ?</label>
                                      <p>{reportData?.floor_rolled_toggle}</p>
                                    </div>
                                  </Col>
                              )}

                              {reportData?.adhesive_name_number ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Adhesive Name/Number</label>
                                    <p>{reportData?.adhesive_name_number}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.trowel_used_size_dimension ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Trowel Used-Size/Dimension</label>
                                    <p>{reportData?.trowel_used_size_dimension}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.roller_weight_lbs ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Roller Weight LBS</label>
                                    <p>{reportData?.roller_weight_lbs}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {reportData?.style_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Style Name/Number</label>
                                <p>{reportData?.style_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_color ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Color Name/Number</label>
                                <p>{reportData?.product_color}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.area_room_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Areas/Rooms Installed</label>
                                <p>{reportData?.area_room_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.total_claim_qt ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Total Claim Quantity</label>
                                <p>{reportData?.total_claim_qt} {reportData?.total_claim_qt_unit}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.total_affected ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Total Affected</label>
                                <p>{reportData?.total_affected} {reportData?.total_affected_unit}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.balance_toggle === "HideThisField" || reportData?.balance_toggle === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Affected Area Connected To Balance</label>
                                  <p>{reportData?.balance_toggle}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.curling_doming_measurement === "yes" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Cupping/Curling or Doming?</label>
                                <p>
                                  {reportData?.curling_doming_measurement ===
                                    "yes"
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.curling_doming_measure_peak ? (
                            <Col xs={24} md={12}>
                              <div className="formGroup">
                                <label>
                                Cupping /Curling or Doming Measurement Peak to Peak
                                </label>
                                <p>{reportData?.curling_doming_measure_peak}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_invoice ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Invoice Number/PO Number</label>
                                <p>{reportData?.product_invoice}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.flooring_installed_toggle === "HideThisField" || reportData?.flooring_installed_toggle === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Flooring Installed</label>
                                  <p>{reportData?.flooring_installed_toggle}</p>
                                </div>
                              </Col>
                          )}
                        </Row>
                        <Row gutter={16}>
                          {reportData?.concerns ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>Statement of Concern</label>
                                <p>{reportData?.concerns}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Site Description and Occupancy Information*/}
                {reportData.site_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Site Description and Occupancy Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.property_dropdown ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Property Type</label>
                                <p>{reportData?.property_dropdown}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.building_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Type</label>
                                {reportData?.building_type === "Other" ? (
                                  <p>{reportData?.build_type_other}</p>
                                ) : (
                                  <p>{reportData?.building_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.building_style ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Style</label>
                                {reportData?.building_style === "Other" ? (
                                  <p>{reportData?.other_building_style}</p>
                                ) : (
                                  <p>{reportData?.building_style}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.traffic_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Traffic Type</label>
                                {reportData?.traffic_type === "Other" ? (
                                  <p>{reportData?.other_trafic}</p>
                                ) : (
                                  <p>{reportData?.traffic_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.date_home ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Date Home/Building Fully Constructed
                                </label>
                                <p>{reportData?.date_home}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.date_hvac ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>HVAC Fully Operational/Functional</label>
                                <p>{reportData?.date_hvac}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.site_occupied_date ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Occupied Date</label>
                                <p>{reportData?.site_occupied_date}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.building_age ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Age</label>
                                <p>{reportData?.building_age}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                      <div className="inspectionDetails">
                        <div className="reportData">
                          <Row gutter={16}>
                          {reportData?.h_bui_cons === "HideThisField" || reportData?.h_bui_cons === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>New Construction?</label>
                                  <p>
                                  {reportData?.h_bui_cons === "Yes" || reportData?.h_bui_cons === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_cons === "No"  || reportData?.h_bui_cons === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.closing_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Closing Date</label>
                                    <p>{reportData?.closing_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}


                              {reportData?.moving_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Move in Date</label>
                                    <p>{reportData?.moving_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.operational_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>HVAC Operational Date</label>
                                    <p>{reportData?.operational_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                                  </>
                                  )}
                            </>
                          )
                        }

                          </Row>
                        </div>
                      </div>
                      <div className="inspectionDetails">
                        <div className="reportData">
                          <Row gutter={16}>
                          {reportData?.h_bui_walk_off === "HideThisField" || reportData?.h_bui_walk_off === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Walk off Mats</label>
                                  <p>
                                  {reportData?.h_bui_walk_off === "Yes"  || reportData?.h_bui_walk_off === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_walk_off === "No" || reportData?.h_bui_walk_off === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.all_exterior ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>All Exterior Building Entrances</label>
                                        {reportData?.all_exterior === "Other" ? (
                                          <p>{reportData?.other_exterior}</p>
                                        ) : (
                                          <p>{reportData?.all_exterior}</p>
                                        )}
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}

                          </Row>
                        </div>
                      </div>
                      <div className="inspectionDetails">
                        <div className="reportData">
                          <Row gutter={16}>
                          {reportData?.h_bui_occupied === "HideThisField" || reportData?.h_bui_occupied === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Building Occupied</label>
                                  <p>
                                    {reportData?.h_bui_occupied === "Yes"  || reportData?.h_bui_occupied === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_occupied === "No" || reportData?.h_bui_occupied === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.adults ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Adults</label>
                                        <p>{reportData?.adults}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.childrens ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Children</label>
                                        <p>{reportData?.childrens}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.pets ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Pets</label>
                                        <p>{reportData?.pets}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}

                          </Row>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Installation Information*/}
                {/* {reportData.i2_product_ins === "yes" ? ( */}
                {reportData?.i2_product_ins !== "HideThisField" ? (
                    
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Installation Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          
                          {reportData?.i2_product_ins === "HideThisField" || reportData?.i2_product_ins === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Product Installed?</label>
                                  <p>
                                    {reportData?.i2_product_ins === "Yes"  || reportData?.i2_product_ins === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.i2_product_ins === "No" || reportData?.i2_product_ins === "no"  ? (
                                                      ""
                                                    ) : (
                                                      <>
                          
                          {reportData?.items_obstruct === "HideThisField" || reportData?.items_obstruct === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>
                                  Items Obstructing Expansion of Flooring
                                </label>
                                  <p>{reportData?.items_obstruct}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.fasteners_applied === "HideThisField" || reportData?.fasteners_applied === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Fasteners Applied Through Laminate</label>
                                  <p>{reportData?.fasteners_applied}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.fast_floor === "HideThisField" || reportData?.fast_floor === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Fasteners Obstruct Floor Expansion</label>
                                  <p>{reportData?.fast_floor}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.evident_floor === "HideThisField" || reportData?.evident_floor === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Evident Floor Unlevelness</label>
                                  <p>{reportData?.evident_floor}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.vertical_delection === "HideThisField" || reportData?.vertical_delection === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Vertical Deflection Evident</label>
                                  <p>{reportData?.vertical_delection}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.laminate_box_label ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Box Label Information</label>
                                <p>{reportData?.laminate_box_label}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.lam_plank_backstamp ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Plank Backstamp Information</label>
                                  <p>{reportData?.lam_plank_backstamp}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.shortest_seam ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Shortest Seam Stagger in Floor</label>
                                <p>{reportData?.shortest_seam}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.unlevelness_new ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Flatness Measurements</label>
                                <p>{reportData?.unlevelness_new}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.lam_span ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Largest Span of Flooring Width</label>
                                <p>{reportData?.lam_span}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.lam_largest ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Largest Span of Flooring Length</label>
                                <p>{reportData?.lam_largest}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.vertical_def ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Vertical Deflection Measurement</label>
                                <p>{reportData?.vertical_def}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.measure_unit ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Vertical Deflection Measurement Unit
                                </label>
                                <p>{reportData?.measure_unit}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.room_peri ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Expansion Space - Room Perimeter</label>
                                <p>{reportData?.room_peri}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_area_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Rooms/Areas Installed</label>
                                <p>{reportData?.rooms_area_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.ver_obs ? (
                            <Col xs={24} md={12}>
                              <div className="formGroup">
                                <label>
                                  Expansion Space - Other Vertical Obstructions
                                </label>
                                <p>{reportData?.ver_obs}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.adjoining_rooms === "HideThisField" || reportData?.adjoining_rooms === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>
                                  Transitions Used Between All Adjoining Rooms?
                                </label>
                                  <p>{reportData?.adjoining_rooms}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.doorways_togg === "HideThisField" || reportData?.doorways_togg === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>
                                  Doorways and Cased Openings Measuring Less Than
                                  or Equal to 72" have Transition Moldings
                                </label>
                                  <p>{reportData?.doorways_togg}</p>
                                </div>
                              </Col>
                          )}
                                  </>
                                  )}
                            </>
                         )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Cleaning Information*/}
                {reportData.cleaned !== "HideThisField" ?(
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Cleaning Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                        {reportData?.cleaned === "HideThisField" || reportData?.cleaned === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Cleaned?</label>
                                  <p>
                                    {reportData?.cleaned === "Yes"  || reportData?.cleaned === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.cleaned === "No" || reportData?.cleaned === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                           {reportData?.professinally_cleaned === "HideThisField" || reportData?.professinally_cleaned === "" ? (
                                                          ""
                                                        ) : (
                                                          <>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Professionally Cleaned?</label>
                                      <p>
                                        {reportData?.professinally_cleaned === "Yes"  || reportData?.professinally_cleaned === "yes"
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  {reportData?.professinally_cleaned === "No" || reportData?.professinally_cleaned === "no"  ? (
                                                          ""
                                                        ) : (
                                                          <>
                                      {reportData?.cleaning_company_name ? (
                                        <Col xs={24} md={6}>
                                          <div className="formGroup">
                                            <label>Company Name</label>
                                            <p>{reportData?.cleaning_company_name}</p>
                                          </div>
                                        </Col>
                                      ) : (
                                        ""
                                      )}
                                      </>
                                      )}
                                </>
                            )}
                          {reportData?.do_it_yourself === "HideThisField" || reportData?.do_it_yourself === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Do It Yourself Cleaning</label>
                                  <p>{reportData?.do_it_yourself}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.clean_product ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>What Cleaning Products Used ?</label>
                                <p>{reportData?.clean_product}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.cleaning_method ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Cleaning Method</label>
                                <p>{reportData?.cleaning_method}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.often_cleaned ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>How Often Cleaned?</label>
                                <p>{reportData?.often_cleaned}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.cleaning_history ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Cleaning History and Details</label>
                                <p>{reportData?.cleaning_history}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                              </>
                                  )}
                            </>
                         )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* INTERIOR AT THE TIME OF INSPECTION */}
                {reportData.interior_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Interior at the Time of Inspection
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Divider orientation="left" plain>
                            Relative Humidity in the Air Space
                          </Divider>

                          {reportData?.floor1_relative_humidity ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>1st Floor</label>
                                <p>{reportData?.floor1_relative_humidity}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.floor2_relative_humidity ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>2nd Floor</label>
                                <p>{reportData?.floor2_relative_humidity}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.basement_relative_humidity ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Basement</label>
                                <p>{reportData?.basement_relative_humidity}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.floor_near_problem_relative_temperature ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Humidity of Floor(Near Problem)</label>
                                <p>
                                  {
                                    reportData?.floor_near_problem_relative_temperature
                                  }
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.other_relative_humidity ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Other</label>
                                <p>{reportData?.other_relative_humidity}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Divider orientation="left" plain>
                            Temperature
                          </Divider>

                          {reportData?.floor1_relative_temperature ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>1st Floor</label>
                                <p>{reportData?.floor1_relative_temperature}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.floor2_relative_temperature ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>2nd Floor</label>
                                <p>{reportData?.floor2_relative_temperature}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.basement_relative_temperature ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Basement</label>
                                <p>{reportData?.basement_relative_temperature}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.floor_near_problem_relative_humidity ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Temperature of Floor(Near Problem)</label>
                                <p>
                                  {
                                    reportData?.floor_near_problem_relative_humidity
                                  }
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.other_relative_temperature ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Other</label>
                                <p>{reportData?.other_relative_temperature}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Divider orientation="left" plain>
                            Floor Temperature
                          </Divider>

                          {reportData?.floor_temp_shaded ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Floor Temperature Shaded Area</label>
                                <p>{reportData?.floor_temp_shaded}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.floor_temp_shaded ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Floor Temperature Sunlight Area</label>
                                <p>{reportData?.floor_temp_sunlight}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.heating_system ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Heating System</label>
                                {reportData?.heating_system === "Other" ? (
                                  <p>{reportData?.heating_system_other}</p>
                                ) : (
                                  <p>{reportData?.heating_system}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.air_conditioning ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Air Conditioning System</label>
                                {reportData?.air_conditioning === "Other" ? (
                                  <p>{reportData?.air_on_other}</p>
                                ) : (
                                  <p>{reportData?.air_conditioning}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.heat === "HideThisField" || reportData?.heat === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Heat On?</label>
                                  <p>{reportData?.heat}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.airon === "HideThisField" || reportData?.airon === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Air On?</label>
                                  <p>{reportData?.airon}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.humidifier === "HideThisField" || reportData?.humidifier === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Humidifier On?</label>
                                  <p>
                                    {reportData?.humidifier === "Yes"  || reportData?.humidifier === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.humidifier === "No" || reportData?.humidifier === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.humid_settings ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Humidifier Settings</label>
                                    <p>{reportData?.humid_settings}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                                  </>
                                  )}
                            </>
                          )}



                          {reportData?.dehumidifier === "HideThisField" || reportData?.dehumidifier === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Dehumidifier On?</label>
                                  <p>
                                    {reportData?.dehumidifier === "Yes" || reportData?.dehumidifier === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.dehumidifier === "No" || reportData?.dehumidifier === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.dehumid_settings ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Dehumidifier Settings</label>
                                        <p>{reportData?.dehumid_settings}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}

                          {reportData?.acc_normal_liv === "HideThisField" || reportData?.acc_normal_liv === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>
                                  Accimilated to Expected Normal Living
                                  Conditions?
                                </label>
                                  <p>{reportData?.acc_normal_liv}</p>
                                </div>
                              </Col>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* MOISTURE INFORMATION PROVIDED BY DEALER OR INSTALLER AT INSTALLATION */}
                {/* {reportData.moisture_info === "yes" ? ( */}
                {reportData.moisture_info === "yes"  &&( reportData?.lam_mois_info || reportData?.subfloor_mois || reportData?.test_method_used_mois || reportData?.concrete_ph) ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Moisture Information Provided By Dealer or Installer at
                      Installation
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.lam_mois_info ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Moisture Information at Installation
                                </label>
                                <p>{reportData?.lam_mois_info}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.subfloor_mois ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Sub Floor</label>
                                <p>{reportData?.subfloor_mois}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.test_method_used_mois ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Test Method Used</label>
                                <p>{reportData?.test_method_used_mois}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.concrete_ph ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Concrete pH Level</label>
                                <p>-</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* FLOORING MOISTURE CONTENT IN COMPLAINT AREA DURING INSPECTION */}
                {reportData.floor_mois_info === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Moisture Content During Inspection
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.lam_plank ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Plank</label>
                                <p>{reportData?.lam_plank}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.lam_baseboard ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Baseboard</label>
                                <p>{reportData?.lam_baseboard}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.subfloor_moisture ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Subfloor Moisture</label>
                                <p>{reportData?.subfloor_moisture}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.joist_new ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Joist</label>
                                <p>{reportData?.joist_new}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Substrate Information*/}
                {reportData.subtrace_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Substrate Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.foundation ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Foundation</label>
                                <p>{reportData?.foundation}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.grade ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Grade/Gradient</label>
                                <p>{reportData?.grade}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.footprint ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Home/Building Footprint</label>
                                <p>{reportData?.footprint}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.building_landscape ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Landscape</label>
                                <p>{reportData?.building_landscape}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.subfloor_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Sub Floor Type</label>
                                {reportData?.subfloor_type === "Other" ? (
                                  <p>{reportData?.other_sub_floor_type}</p>
                                ) : (
                                  <p>{reportData?.subfloor_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.joist_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Joist Type</label>
                                <p>{reportData?.joist_type}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                      {reportData?.foundation === "Foundation" || reportData?.foundation === "Basement" || reportData?.foundation === "Basement/Crawl Space" || reportData?.foundation === "Crawlspace" ? (
                                 
                        <>
                          {reportData?.reading_basement ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Relative Humidity Reading Basement</label>
                                <p>{reportData?.reading_basement}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.soil_subfloor ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Soil Subfloor Distance</label>
                                <p>{reportData?.soil_subfloor}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.relate_slab ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Relative Slab Soil Elevation</label>
                                <p>{reportData?.relate_slab}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.temperature_crawl ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Temperature Crawl Space</label>
                                <p>{reportData?.temperature_crawl}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.ground_cover_per ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Ground Covered %</label>
                                <p>{reportData?.ground_cover_per}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.relative_humidity ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Relative Humidity Reading Crawl Space
                                </label>
                                <p>{reportData?.relative_humidity}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.elevation_of_slab ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Relative Elevation of Slab</label>
                                <p>{reportData?.elevation_of_slab}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.sub_floor_flatness ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Sub Floor Flatness</label>
                                <p>{reportData?.sub_floor_flatness}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.perimeter_expansion_space ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Expansion Space</label>
                                <p>{reportData?.perimeter_expansion_space}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.sub_floor_levelness ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Sub Floor Levelness</label>
                                <p>{reportData?.sub_floor_levelness}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.measure_the_longest_length ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Measurement of the Longest Run-Length
                                </label>
                                <p>{reportData?.measure_the_longest_length}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.measure_the_longest_width ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Measurement of the Longest Run-Width
                                </label>
                                <p>{reportData?.measure_the_longest_width}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.moisture_test_method ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Moisture Test Method</label>
                                <p>{reportData?.moisture_test_method}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.car_sub_reslt ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Moisture Test Results</label>
                                <p>{reportData?.car_sub_reslt}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.car_tog_grade === "HideThisField" || reportData?.car_tog_grade === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Above Grade</label>
                                  <p>{reportData?.car_tog_grade}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.car_tog_alkali === "HideThisField" || reportData?.car_tog_alkali === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Foundation Alkali</label>
                                  <p>{reportData?.car_tog_alkali}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.car_tog_plastic === "HideThisField" || reportData?.car_tog_plastic === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Plastic Cover</label>
                                  <p>{reportData?.car_tog_plastic}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.car_tog_cross === "HideThisField" || reportData?.car_tog_cross === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Cross Flow</label>
                                  <p>{reportData?.car_tog_cross}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.car_tog_joist === "HideThisField" || reportData?.car_tog_joist === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Joist Discoloration</label>
                                  <p>{reportData?.car_tog_joist}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.car_tog_mold === "HideThisField" || reportData?.car_tog_mold === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Mold Present</label>
                                  <p>{reportData?.car_tog_mold}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.car_tog_water === "HideThisField" || reportData?.car_tog_water === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Standing Water Present</label>
                                  <p>{reportData?.car_tog_water}</p>
                                </div>
                              </Col>
                          )}
                        </>  
                               ) : (
                                 ""
                      )}
                          {reportData?.foundation === "Basement" || reportData?.foundation === "Basement/Crawl Space" ? (
                                 
                                 <>  
                               {reportData?.car_tog_wall === "HideThisField" || reportData?.car_tog_wall === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Walls Cracked</label>
                                       <p>{reportData?.car_tog_wall}</p>
                                     </div>
                                   </Col>
                               )}
                               {reportData?.car_tog_paint === "HideThisField" || reportData?.car_tog_paint === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Paint Peeling</label>
                                       <p>{reportData?.car_tog_paint}</p>
                                     </div>
                                   </Col>
                               )}
     
                               {reportData?.car_tog_floor === "HideThisField" || reportData?.car_tog_floor === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Floor Stained</label>
                                       <p>{reportData?.car_tog_floor}</p>
                                     </div>
                                   </Col>
                               )}
                               {reportData?.car_tog_vented === "HideThisField" || reportData?.car_tog_vented === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Vented</label>
                                       <p>{reportData?.car_tog_vented}</p>
                                     </div>
                                   </Col>
                               )}
                               {reportData?.car_tog_rusty === "HideThisField" || reportData?.car_tog_rusty === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Rusty Nails</label>
                                       <p>{reportData?.car_tog_rusty}</p>
                                     </div>
                                   </Col>
                               )}
                               {reportData?.car_tog_finish === "HideThisField" || reportData?.car_tog_finish === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Finished</label>
                                       <p>{reportData?.car_tog_finish}</p>
                                     </div>
                                   </Col>
                               )}
                               {reportData?.car_tog_insulated === "HideThisField" || reportData?.car_tog_insulated === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>Insulated Ceiling</label>
                                       <p>{reportData?.car_tog_insulated}</p>
                                     </div>
                                   </Col>
                               )}
                               {reportData?.mois_test === "HideThisField" || reportData?.mois_test === "" ? (
                                   ""
                                 ) : (
                                   <Col xs={24} md={8}>
                                     <div className="formGroup">
                                     <label>
                                       Moisture Tested Before Installation
                                     </label>
                                       <p>{reportData?.mois_test}</p>
                                     </div>
                                   </Col>
                               )}
                               </>  
                               ) : (
                                 ""
                            )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Underlayment Information*/}
                {reportData.underlay_user !== "HideThisField" || reportData.no_underlayment_install !== 'HideThisField'  ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Underlayment Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                      {/* {reportData.underlay_user === "yes" ? ( */}
                        <Row gutter={16}>
                        {reportData?.underlay_user === "HideThisField" || reportData?.underlay_user === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Underlayment Installed</label>
                                <p>
                                    {reportData?.underlay_user === "Yes" || reportData?.underlay_user === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.underlay_user === "No" || reportData?.underlay_user === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.underlay_manf ? (
                                      <Col xs={24} md={6}>
                                        <div className="formGroup">
                                          <label>Manufacturer</label>
                                          <p>{reportData?.underlay_manf}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    {reportData?.underlay_style ? (
                                      <Col xs={24} md={6}>
                                        <div className="formGroup">
                                          <label>Style/Name/Number</label>
                                          <p>{reportData?.underlay_style}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    {reportData?.underlay_thickness ? (
                                      <Col xs={24} md={6}>
                                        <div className="formGroup">
                                          <label>Underlayment Thickness</label>
                                          <p>{reportData?.underlay_thickness}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                  )}
                            </>
                         )}
                        </Row>
                        {/* ) : (
                          ""
                        )} */}
                        <Row gutter={16}>
                          {reportData?.no_underlayment_install === "HideThisField" || reportData?.no_underlayment_install === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>6 Mil Poly Or Equivalent Installed</label>
                                  <p>{reportData?.no_underlayment_install}</p>
                                </div>
                              </Col>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Observations Information */}
                {reportData.obser_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Observations Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="inspectionDetails">
                        {/* <Divider orientation="left" plain>
                        Sending Samples?
                      </Divider> */}
                        <div className="reportData">
                          <Row gutter={16}>
                              {reportData?.sending_samples === "HideThisField" || reportData?.sending_samples === "" ? (
                                                          ""
                                                        ) : (
                                                          <>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Sending Samples?</label>
                                      <p>
                                        {reportData?.sending_samples === "Yes" || reportData?.sending_samples === "yes"
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  {reportData?.sending_samples === "No" || reportData?.sending_samples === "no" ? (
                                                          ""
                                                        ) : (
                                                          <>
                                      {reportData?.shipping_company ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Shipping Company</label>
                                              <p>{reportData?.shipping_company}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {reportData?.tracking_number ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Traking Number</label>
                                              <p>{reportData?.tracking_number}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {reportData?.date_shipped ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Date Shipped</label>
                                              <p>{reportData?.date_shipped}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                      )}
                                </>
                            )}
                          </Row>
                        </div>
                      </div>

                      {/* Claim History */}
                      {reportData.claim_his_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Claim History and Comments of Involved Parties
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        reportData?.claim_history_comments,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Observations or physical description and comments */}
                      {reportData.observe_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Observations or Physical Description and Comments
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.observation_comments,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Applicable Field Tests */}
                      {reportData.app_field_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Applicable Field Tests
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.applicable_field_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Industry Standards/Manufacturers Standards/Citations */}
                      {reportData.indus_stand_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Industry Standards/Manufacturers Standards/Citations
                            and Resources Cited
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        reportData?.industry_standards_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Manufacturing issues, Site related issues, Performance related issues and Installation related issues */}
                {/* {reportData.issues_info_toggle === "yes" ? ( */}
                {reportData.issues_info_toggle === "yes" &&(reportData.manufacturing_issue_togg === "yes" || reportData.site_rel_issue_togg === "yes" || reportData.perfo_rel_issue_togg === "yes" || reportData.insta_rel_issue_togg === "yes")  ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                    Manufacturing issues, Site related issues, Performance related issues and Installation related issues
                    </Divider>
                    <Card className="innerCard">
                      {/* Manufacturing issues */}
                      {reportData.manufacturing_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Manufacturing issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.manufacturing_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    
                      {/* Site related issues */}
                      {reportData.site_rel_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Site related issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.site_rel_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    
                      {/* Performance related issues */}
                      {reportData.perfo_rel_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Performance related issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.perfo_rel_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    
                      {/* Installation related issues */}
                      {reportData.insta_rel_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Installation related issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.insta_rel_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                      {/* Conclusion */}
                      {reportData.conc_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Conclusion
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.conclusion_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Cause */}
                      {reportData.cause_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Cause
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.cause_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                
                {/* Confidential Information */}
                {reportData.confi === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Confidential Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="inspectionDetails">
                        <Divider orientation="left" plain>
                          Has Confidential Comments
                        </Divider>
                        <div className="reportData">
                          <Row gutter={16}>
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.conf_comment,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Footer */}
                {reportData.footer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Footer
                    </Divider>
                    <Card className="innerCard">
                      <div className="inspectionDetails">
                        {reportData?.signurl ? (
                          <>
                            <Divider orientation="left" plain>
                              Upload Signature
                            </Divider>
                            <div className="reportData">
                              <Row gutter={16}>
                                <Col xs={24} md={24}>
                                  <div className="formGroup customSignatureImage">
                                    <Image
                                      src={reportData?.signurl}
                                      alt="image1"
                                      preview={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* Footer text */}
                      {reportData.footer_text_info === "yes" ? (
                      <div className="inspectionDetails">
                        <Divider orientation="left" plain>
                          Footer Text
                        </Divider>
                        <div className="reportData">
                          <Row gutter={16}>
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.footer_text,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div> ) : "" }
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                {/* Add Attachements */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Add Attachments
                  </Divider>
                  <div className="reportData">
                    <Row gutter={16}>
                        <Image.PreviewGroup>
                      {attachments &&
                        Object.keys(attachments).map((keyName, index) => (
                          <Col xs={6} md={6} key={index}>
                            <div className="formGroup customFormGroup">
                              <Image
                                src={attachments[keyName].url}
                                alt="image1"
                                className="img-fluid"
                                preview={true}
                              />
                              <p className="count">{attachments[keyName].id}</p>
                              <p className="descriptions">
                                {attachments[keyName].desc}
                              </p>
                            </div>
                          </Col>
                        ))}
                        </Image.PreviewGroup>
                    </Row>
                  </div>
                </div>
                {/* CERTIFICATION */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    CERTIFICATION
                  </Divider>
                  <div className="reportData footerReportData">
                    <Row justify="center">
                      {reportLogos &&
                        Object.keys(reportLogos).map((keyName, index) => (
                          <Col xs={2} md={2} key={index} className="logoCol">
                            <div className="logoDiv">
                              <div className="innerDiv">
                                <img
                                  src={reportLogos[keyName].logo_url}
                                  alt="not found"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
                {/* Private Note */}
                {reportData.private_notes_togg === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Private Notes
                    </Divider>
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <div className="formGroup sunEditorEditable sun-editor-editable">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: reportData?.private_notes,
                              }}
                            ></p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Row gutter={16} className="pt-3">
                <Col xs={24} md={24} className="text-center">
                <Button
                        className="btnPrimary"
                        onClick={showModal}
                      >
                        Select Your PDF Layout
                      </Button>
                    {reportData?.current_user_role === "um_manufacturer" ||
                      reportData?.current_user_role === "um_claim-analyst" ||
                      reportData?.current_user_role === "um_dealer" ? (
                      <Button
                        className="btnPrimary"
                        onClick={() => downloadPdf()}
                        loading={downloadPdfLoading}
                      >
                        Download PDF
                      </Button>
                    ) : reportData?.current_user_role === "um_inspector" ? (
                      <>
                        <Button
                          className="btnPrimary"
                          onClick={() => downloadPdf()}
                          loading={downloadPdfLoading}
                        >
                          Download PDF
                        </Button>
                        <Button
                          className="btnPrimary"
                          onClick={() => getPdf()}
                          loading={savePdfLoading}
                        >
                          Save and Continue
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </div>
      </div>
      <Modal
        title={"Choose PDF Template."}
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="pdfTemplateform"
          layout="vertical"
          onFinish={setPdfSettings}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          
          <div className="modalContent pdfTemp">
            <div className="formGroup">
              <Radio.Group
                name="radiogroup"
                onChange={handleRadio}
                defaultValue={parseInt(selectedTemplate)}>
                  {Object.keys(pdfTemplates).map((keyName, index) => (
                    <Radio.Button
                      value={pdfTemplates[keyName].template_id}
                      key={index}
                      >
                      <Image
                        src={pdfTemplates[keyName].template_url}
                        className="img-fluid"
                        alt="logos"
                        preview={false}
                      />
                  </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
          </div>
          <Button className="btnPrimary" htmlType="submit">
            Save and Exit
          </Button>
        </Form>
      </Modal>
    </div>
  );
}


export default SingleLaminate;
