import React from "react";
import {
  Row,
  Col,
  Collapse,
  Input,
  Card,
  Form,
  Select,
  Button,
  DatePicker,
  Switch,
  Cascader
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
const optionnss = [
  {
    value: "North",
    label: "North",
  },
  {
    value: "South",
    label: "South",
  },
  {
    value: "East",
    label: "East",
  },
  {
    value: "West",
    label: "West",
  }
]; 
const { TextArea } = Input;
const options = [
  {
    value: "sq/ft",
    label: "sq/ft",
  },
  {
    value: "sq/yds",
    label: "sq/yds",
  },
  {
    value: "boxes",
    label: "boxes",
  },
];
const { Option } = Select;
const { Panel } = Collapse;
const DateFormat = "MM-DD-YYYY";

const ThirdStep = (props) => {
  console.warn("ThirdStep", props);
  const [form] = Form.useForm();

  return (
    <Form name="report" autoComplete="off" layout="vertical" form={form}>
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="CollapseSection">
                <Collapse
                  defaultActiveKey={
                    props.values.stepThreeTogg
                      ? props.values.stepThreeTogg
                      : ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
                  }
                  onChange={props.handleChange("stepThreeTogg")}
                  className="reportCollapse"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="custom-icon" />
                    ) : (
                      <PlusOutlined className="custom-icon" />
                    )
                  }
                  ghost
                >
                  <Panel header="Concerns" key="1">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Condition of Existing Floor"
                              name="concern_con_exi_flr"
                            >
                              <Select
                                defaultValue={
                                  props.values.concern_con_exi_flr
                                    ? props.values.concern_con_exi_flr
                                    : "Condition of Existing Floor"
                                }
                                onChange={props.handleChange(
                                  "concern_con_exi_flr"
                                )}
                              >
                                <Option value="General Throughout">
                                  General Throughout
                                </Option>
                                <Option value="Localized ">Localized </Option>
                                <Option value="Improving">Improving</Option>
                                <Option value="Stabilized">Stabilized</Option>
                                <Option value="Progressing">Progressing</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.concern_con_exi_flrField === true ||
                        props.values.concern_con_exi_flr === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="concern_con_exi_flr_other"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "concern_con_exi_flr_other"
                                  )}
                                  defaultValue={
                                    props.values.concern_con_exi_flr_other
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Flooring" key="2">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Manufacturer"
                              name="concern_manuf"
                            >
                              <Input
                                placeholder="Manufacturer"
                                className="formControl"
                                onChange={props.handleChange("concern_manuf")}
                                defaultValue={props.values.concern_manuf}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Species" name="concern_spec_drop">
                              <Input
                                  placeholder="Species"
                                  className="formControl"
                                  onChange={props.handleChange("concern_spec_drop")}
                                  defaultValue={props.values.concern_spec_drop}
                                />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Flooring Type" name="flooringtype">
                              <Select
                                defaultValue={
                                  props.values.flooringtype
                                    ? props.values.flooringtype
                                    : "Flooring Type"
                                }
                                onChange={props.handleChange(
                                  "flooringtype"
                                )}
                              >
                                <Option value="Factory Finished">
                                  Factory Finished
                                </Option>
                                <Option value="Unfinished ">Unfinished </Option>
                                <Option value="Solid">Solid</Option>
                                <Option value="Engineered">Engineered</Option>
                                <Option value="Strip">Strip</Option>
                                <Option value="Plank">Plank</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                              
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.concern_spec_drop_otherField === true || props.values.flooringtype === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="concern_spec_drop_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange("concern_spec_drop_other")}
                                  defaultValue={props.values.concern_spec_drop_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        {props.isOpen.concern_spec_dropField === true || props.values.flooringtype === "Plank" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Width of Plank" name="concern_spec_drop_width">
                                <Input
                                  placeholder="Width of Plank"
                                  className="formControl"
                                  onChange={props.handleChange("concern_spec_drop_width")}
                                  defaultValue={props.values.concern_spec_drop_width}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Flooring Thickness"
                              name="concern_floor_thick"
                            >
                              <Input
                                placeholder="Flooring Thickness"
                                className="formControl"
                                onChange={props.handleChange(
                                  "concern_floor_thick"
                                )}
                                defaultValue={props.values.concern_floor_thick}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Additional Info on Flooring Container"
                              name="concern_add_inf_flr_con"
                            >
                              <Input
                                placeholder="Additional Info on Flooring Container"
                                className="formControl"
                                onChange={props.handleChange(
                                  "concern_add_inf_flr_con"
                                )}
                                defaultValue={
                                  props.values.concern_add_inf_flr_con
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Production Date"
                              name="concern_prod_date"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Production Date"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "concern_prod_date",
                                      "text"
                                    )
                                  }
                                  value={props.values.concern_prod_date}
                                  onFocus={() =>
                                    props.setCalOpen17(!props.calOpen17)
                                  }
                                  onKeyUp={() => props.setCalOpen17(false)}
                                  onBlur={() => props.setCalOpen17(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen17(!props.calOpen17)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen17}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "concern_prod_date",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Production Lot Number"
                              name="produc_lot_number"
                            >
                              <Input
                                placeholder="Production Lot Number"
                                className="formControl"
                                onChange={props.handleChange(
                                  "produc_lot_number"
                                )}
                                defaultValue={props.values.produc_lot_number}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Amount per Container"
                              name="sqft_per_cont"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type15"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type15}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("sqft_per_cont")}
                                defaultValue={props.values.sqft_per_cont}
                                placeholder="Amount per Container"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Total Material Installed"
                              name="total_sqft"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type16"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type16}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("total_sqft")}
                                defaultValue={props.values.total_sqft}
                                placeholder="Total Material Installed"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Square Footage Delivered"
                              name="square_foot_deli"
                            >
                              <Input
                                placeholder="Square Footage Delivered"
                                className="formControl"
                                onChange={props.handleChange(
                                  "square_foot_deli"
                                )}
                                defaultValue={props.values.square_foot_deli}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="% of Floor Affected"
                              name="per_flo_aff"
                            >
                              <Input
                                placeholder="% of Floor Affected"
                                className="formControl"
                                onChange={props.handleChange("per_flo_aff")}
                                defaultValue={props.values.per_flo_aff}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                    label="Approved Over Radiant Heat"
                                    name="app_over_radiant_heat"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.app_over_radiant_heat}
                                    onToggle={props.handleChange('app_over_radiant_heat')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                    label="Approved Below Grade"
                                    name="app_below_grade"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.app_below_grade}
                                    onToggle={props.handleChange('app_below_grade')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Rooms Being Inspected" key="3">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Entry" name="rooms_entry">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type1"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type1}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_entry")}
                                defaultValue={props.values.rooms_entry}
                                placeholder="Entry"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Formal" name="rooms_formal">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "formal_temp"
                                    )}
                                    options={options}
                                    defaultValue={props.values.formal_temp}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_formal")}
                                defaultValue={props.values.rooms_formal}
                                placeholder="Formal"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Kitchen" name="rooms_kit">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type3"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type3}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_kit")}
                                defaultValue={props.values.rooms_kit}
                                placeholder="Kitchen"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Study" name="rooms_study">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type4"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type4}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_study")}
                                defaultValue={props.values.rooms_study}
                                placeholder="Study"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Hall(s)" name="rooms_hall">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type5"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type5}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_hall")}
                                defaultValue={props.values.rooms_hall}
                                placeholder="Hall(s)"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Library" name="rooms_library">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type6"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type6}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_library")}
                                defaultValue={props.values.rooms_library}
                                placeholder="Library"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Great Room"
                              name="rooms_great_room"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type7"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type7}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange(
                                  "rooms_great_room"
                                )}
                                defaultValue={props.values.rooms_great_room}
                                placeholder="Great Room"
                              />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Living Room"
                              name="rooms_living_room"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type9"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type9}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange(
                                  "rooms_living_room"
                                )}
                                defaultValue={props.values.rooms_living_room}
                                placeholder="Living Room"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Dining Room"
                              name="rooms_dining_roo"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type10"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type10}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange(
                                  "rooms_dining_roo"
                                )}
                                defaultValue={props.values.rooms_dining_roo}
                                placeholder="Dining Room"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Nook" name="rooms_nook">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type11"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type11}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_nook")}
                                defaultValue={props.values.rooms_nook}
                                placeholder="Nook"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Pantry" name="rooms_part">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type12"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type12}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_part")}
                                defaultValue={props.values.rooms_part}
                                placeholder="Pantry"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Powder Bath" name="powderBath">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "powder_bath"
                                    )}
                                    options={options}
                                    defaultValue={props.values.powder_bath}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("powderBath")}
                                defaultValue={props.values.powderBath}
                                placeholder="Powder Bath"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6} className="appendDiv formGroup">
                          <label htmlFor="Bath" className="pb-2">
                           Bath
                          </label>
                          {props.bathArea.map((singleBatharea, index) => (
                            <div key={index} className="services mb-2">
                              <div className="first-division">
                                <Input.Group compact>
                                  <div className="input-group customRepeatorDivHide">
                                    <Input
                                      name="bath_area"
                                      type="text"
                                      id="bath_area"
                                      value={singleBatharea.bath_area}
                                      onChange={(e) =>
                                        props.handleBathChange(e, index,'bath_area')
                                      }
                                      placeholder="Bath"
                                      className="formControl customPaddingg"
                                    />
                                    <Select
                                      name="bath_area_unit"
                                      defaultValue={singleBatharea.bath_area_unit}
                                      options={options}
                                      onChange={(e) =>
                                        props.handleBathChange(e, index,'bath_area_unit')
                                      }
                                      className="customselect"
                                      placeholder="sq/ft"
                                    />
                                  </div>
                                  {props.bathArea.length - 1 === index &&
                                    props.bathArea.length < 5 && (
                                      <Button
                                        type="button"
                                        onClick={props.handleBathAdd}
                                        className="formControl repeatorDivHideBtn"
                                        icon={<PlusOutlined />}
                                      />
                                    )}
                                  {props.bathArea.length !== 1 && (
                                    <Button
                                      type="button"
                                      icon={<MinusOutlined />}
                                      onClick={() =>
                                        props.handleBathRemove(index)
                                      }
                                      className="formControl repeatorDivHideBtn"
                                    />
                                  )}
                                </Input.Group>
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Master Bedroom"
                              name="masterbedroom"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type14"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type14}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("masterbedroom")}
                                defaultValue={props.values.masterbedroom}
                                placeholder="Master Bedroom"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6} className="appendDiv formGroup">
                          <label htmlFor="gc_subdivision" className="pb-2 formGroup">
                            Bed
                          </label>
                             {props.masterBedroomArea.map((singleMasterarea, index) => (
                            <div key={index} className="services mb-2">
                              <div className="first-division">
                                <Input.Group compact>
                                  <div className="input-group customRepeatorDivHide">
                                    <Input
                                      name="master_bedroom_area"
                                      type="text"
                                      id="master_bedroom_area"
                                      value={singleMasterarea.master_bedroom_area}
                                      onChange={(e) =>
                                        props.handleMasterChange(e, index,'master_bedroom_area')
                                      }
                                      placeholder="Bath"
                                      className="formControl customPaddingg"
                                    />
                                    <Select
                                      name="master_bedroom_area_unit"
                                      defaultValue={singleMasterarea.master_bedroom_area_unit}
                                      options={options}
                                      onChange={(e) =>
                                        props.handleMasterChange(e, index,'master_bedroom_area_unit')
                                      }
                                      className="customselect"
                                      placeholder="sq/ft"
                                    />
                                  </div>
                                  {props.masterBedroomArea.length - 1 === index &&
                                    props.masterBedroomArea.length < 5 && (
                                      <Button
                                        type="button"
                                        onClick={props.handleMasterAdd}
                                        className="formControl repeatorDivHideBtn"
                                        icon={<PlusOutlined />}
                                      />
                                    )}
                                  {props.masterBedroomArea.length !== 1 && (
                                    <Button
                                      type="button"
                                      icon={<MinusOutlined />}
                                      onClick={() =>
                                        props.handleMasterRemove(index)
                                      }
                                      className="formControl repeatorDivHideBtn"
                                    />
                                  )}
                                </Input.Group>
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other Rooms" name="other_rooms">
                              <Input
                                placeholder="Other Rooms"
                                className="formControl"
                                onChange={props.handleChange("other_rooms")}
                                defaultValue={props.values.other_rooms}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="New Construction Info" key="4">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Home Completed"
                              name="date_home_completed"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Home Completed"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_home_completed",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_home_completed}
                                  onFocus={() =>
                                    props.setCalOpen13(!props.calOpen13)
                                  }
                                  onKeyUp={() => props.setCalOpen13(false)}
                                  onBlur={() => props.setCalOpen13(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen13(!props.calOpen13)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen13}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_home_completed",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date HVAC Fully Operational"
                              name="date_hvac_fully_oper"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date HVAC Fully Operational"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_hvac_fully_oper",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_hvac_fully_oper}
                                  onFocus={() =>
                                    props.setCalOpen14(!props.calOpen14)
                                  }
                                  onKeyUp={() => props.setCalOpen14(false)}
                                  onBlur={() => props.setCalOpen14(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen14(!props.calOpen14)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen14}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_hvac_fully_oper",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Exterior Evaluation" key="5">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Home Faces"
                              name="exter_home_face"
                            >
                              <Select
                                defaultValue={
                                  props.values.exter_home_face
                                    ? props.values.exter_home_face
                                    : "Home Faces"
                                }
                                onChange={props.handleChange("exter_home_face")}
                              >
                                <Option value="North">North</Option>
                                <Option value="South">South</Option>
                                <Option value="East">East</Option>
                                <Option value="West">West</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Relation of Lot to Street"
                              name="rele_lot_street"
                            >
                              <Input
                                placeholder="Relation of Lot to Street"
                                className="formControl"
                                onChange={props.handleChange("rele_lot_street")}
                                defaultValue={props.values.rele_lot_street}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Relation of Lot to Neighbors"
                              name="rele_lot_neigh"
                            >
                              <Input
                                placeholder="Relation of Lot to Neighbors"
                                className="formControl"
                                onChange={props.handleChange("rele_lot_neigh")}
                                defaultValue={props.values.rele_lot_neigh}
                              />
                            </Form.Item>
                          </div>
                        </Col><Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                    label="Lot Drainage Away from Foundation"
                                    name="lot_drainage_away_foun"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.lot_drainage_away_foun}
                                    onToggle={props.handleChange('lot_drainage_away_foun')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                         <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Slope Angle Measurement"
                              name="slope_al_meas"
                            >
                              <Input
                                placeholder="Slope Angle Measurement"
                                className="formControl"
                                onChange={props.handleChange("slope_al_meas")}
                                defaultValue={props.values.slope_al_meas}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Building is Over"
                              name="buil_over"
                            >
                              <Select
                                defaultValue={
                                  props.values.buil_over
                                    ? props.values.buil_over
                                    : "Building is Over"
                                }
                                onChange={props.handleChange("buil_over")}
                              >
                                <Option value="Basement">Basement</Option>
                                <Option value="Crawlspace">Crawlspace</Option>
                                <Option value="Slab">Slab</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                    label="Water Source/Swimming Pool Nearby"
                                    name="water_sour_swimm"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.water_sour_swimm}
                                    onToggle={props.handleChange('water_sour_swimm')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                       
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Proximity to Structure"
                              name="prox_to_stru"
                            >
                              <Input
                                placeholder="Proximity to Structure"
                                className="formControl"
                                onChange={props.handleChange("prox_to_stru")}
                                defaultValue={props.values.prox_to_stru}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Gutters/Downspouts"
                                   name="gutters_down"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.gutters_down}
                                    onToggle={props.handleChange('gutters_down')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                   label="Roof Overhang"
                                   name="roof_overhang"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.roof_overhang}
                                    onToggle={props.handleChange('roof_overhang')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Soil Damp at Foundation"
                                   name="soil_damp"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.soil_damp}
                                    onToggle={props.handleChange('soil_damp')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Window-Wells" name="wind_wells">
                                <Select
                                defaultValue={
                                  props.values.wind_wells
                                    ? props.values.wind_wells
                                    : "Window-Wells"
                                }
                                onChange={props.handleChange("wind_wells")}
                              >
                                <Option value="Wet">Wet</Option>
                                <Option value="Dry">Dry</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Signs of Moisture"
                                   name="signs_mois"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.signs_mois}
                                    onToggle={props.handleChange('signs_mois')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Sprinklers/Irrigation"
                                   name="sprinklers_irri"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.sprinklers_irri}
                                    onToggle={props.handleChange('sprinklers_irri')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Established Landscape"
                                   name="Esta_lands"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.Esta_lands}
                                    onToggle={props.handleChange('Esta_lands')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                       
                      <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Visible Cracks in Foundation"
                                   name="visible_cracks"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.visible_cracks}
                                    onToggle={props.handleChange('visible_cracks')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                    label="Visible Exterior Damage"
                                    name="visible_exter"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.visible_exter}
                                    onToggle={props.handleChange('visible_exter')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                       <Col xs={24} md={12} xl={12}>
                          <div className="formGroup">
                           
                            <Form.Item
                                    label="Shrubs/Flowers/Planter Boxes at Foundation"
                                    name="shrubs_flowers"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.shrubs_flowers}
                                    onToggle={props.handleChange('shrubs_flowers')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="HVAC Evaluation" key="6">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Type of Heating System"
                              name="type_heating_sys"
                            >
                              <Select
                                defaultValue={
                                  props.values.type_heating_sys
                                    ? props.values.type_heating_sys
                                    : "Type of Heating System"
                                }
                                onChange={props.handleChange(
                                  "type_heating_sys"
                                )}
                              >
                                <Option value="Forced Air">Forced Air</Option>
                                <Option value="Wood Burning Stove">
                                  Wood Burning Stove
                                </Option>
                                <Option value="Electric">Electric</Option>
                                <Option value="Radiator">Radiator</Option>
                                <Option value="Radiant">Radiant</Option>
                                <Option value="Baseboard">Baseboard</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.type_heating_sysField === true ||
                        props.values.type_heating_sys === "Other" ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Other(Type)"
                                  name="type_heating_sys_oth"
                                >
                                  <Input
                                    placeholder="Other(Type)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "type_heating_sys_oth"
                                    )}
                                    defaultValue={
                                      props.values.type_heating_sys_oth
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col></>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Location of Heating"
                              name="loc_heating"
                            >
                              <Select
                                defaultValue={
                                  props.values.loc_heating
                                    ? props.values.loc_heating
                                    : "Location of Heating"
                                }
                                onChange={props.handleChange("loc_heating")}
                              >
                                <Option value="Above">Above</Option>
                                <Option value="Below Floor">Below Floor</Option>
                                <Option value="Baseboard">Baseboard</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Type Of Cooling System"
                              name="type_cooling_sys"
                            >
                              <Select
                                defaultValue={
                                  props.values.type_cooling_sys
                                    ? props.values.type_cooling_sys
                                    : "Type Of Cooling System"
                                }
                                onChange={props.handleChange(
                                  "type_cooling_sys"
                                )}
                              >
                                <Option value="Central Air Conditioning">
                                  Central Air Conditioning
                                </Option>
                                <Option value="Underfloor">Underfloor</Option>
                                <Option value="Portable Air Conditioning">
                                  Portable Air Conditioning
                                </Option>
                                <Option value="Exhaust Fan">Exhaust Fan</Option>
                                <Option value="Swamp Cooler">
                                  Swamp Cooler
                                </Option>
                                <Option value="Window">Window</Option>
                                <Option value="Evaporate">Evaporate</Option>
                                <Option value="None">None</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Unknown">Unknown</Option>
                                <Option value="See Report">See Report</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.acField === true ||
                        props.values.type_cooling_sys === "Other" ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Other(Type)" name="air_on_other">
                                  <Input
                                    placeholder="Other(Type)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "air_on_other"
                                    )}
                                    defaultValue={props.values.air_on_other}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                     label="HVAC System Operating"
                                     name="hvac_sys_oper"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.hvac_sys_oper}
                                    onToggle={props.handleChange('hvac_sys_oper')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                          
                            <Form.Item
                                     label="Humidification/Dehumidification System"
                                     name="h_dehu_syst"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.h_dehu_syst}
                                    onToggle={props.handleChange('h_dehu_syst')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.humi_dehum_sysField === true ||
                        props.values.h_dehu_syst === 'Yes' ? (
                          <>
                        <Col xs={24} md={12}>
                          <div className="formGroup">
                            
                            <Form.Item
                                     label="Is Humidification/Dehumidification System Operating?"
                                     name="humidification_dehum"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.humidification_dehum}
                                    onToggle={props.handleChange('humidification_dehum')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.humidification_dehumField === true ||
                        props.values.humidification_dehum === 'Yes' ? (
                          <>
                           <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                      label="Data Logger"
                                      name="data_logger"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.data_logger}
                                    onToggle={props.handleChange('data_logger')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Thermostat Control Settings"
                                  name="ther_control_sett"
                                >
                                  <Input
                                    placeholder="Thermostat Control Settings"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "ther_control_sett"
                                    )}
                                    defaultValue={
                                      props.values.ther_control_sett
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={12} xl={12}>
                              <div className="formGroup">
                                

                                <Form.Item
                                      label="Is Humidification/Dehumidification System Programmable?"
                                      name="programma"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.programma}
                                    onToggle={props.handleChange('programma')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date of Last Service (if known)"
                              name="date_last_serv"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date of Last Service (if known)"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_last_serv",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_last_serv}
                                  onFocus={() =>
                                    props.setCalOpen15(!props.calOpen15)
                                  }
                                  onKeyUp={() => props.setCalOpen15(false)}
                                  onBlur={() => props.setCalOpen15(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen15(!props.calOpen15)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen15}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_last_serv",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                     label="Has Floor Been Through Full Cycle of Seasons?"
                                     name="has_floor_full_cyc"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.has_floor_full_cyc}
                                    onToggle={props.handleChange('has_floor_full_cyc')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        </> ) : "" }
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Special Considerations" key="7">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Traffic Use" name="traff_use">
                              <Select
                                defaultValue={
                                  props.values.traff_use
                                    ? props.values.traff_use
                                    : "Traffic Use"
                                }
                                onChange={props.handleChange("traff_use")}
                              >
                                <Option value="High">High</Option>
                                <Option value="Average">Average</Option>
                                <Option value="Low">Low</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other Trades | Schedules prior to, during or after installation"
                              name="other_trades"
                            >
                              <TextArea
                                placeholder="Other Trades | Schedules prior to, during or after installation"
                                className="formControl"
                                onChange={props.handleChange("other_trades")}
                                defaultValue={props.values.other_trades}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Any Special or Unique Use"
                              name="any_special_or_unique_use"
                            >
                              <TextArea
                                placeholder="Any Special or Unique Use"
                                className="formControl"
                                onChange={props.handleChange("any_special_or_unique_use")}
                                defaultValue={props.values.any_special_or_unique_use}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Audible Noises-Locations"
                              name="audible_noises"
                            >
                              <TextArea
                                placeholder="Audible Noises-Locations"
                                className="formControl"
                                onChange={props.handleChange("audible_noises")}
                                defaultValue={props.values.audible_noises}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Description of Sound"
                              name="desc_sound"
                            >
                              
                                <div className="m-2"><i><b>
                                 Note: Please include reference to supporting audio/video.
                                  </b> </i>
                                </div>
                             
                              <TextArea
                                placeholder="Description of Sound"
                                className="formControl"
                                onChange={props.handleChange("desc_sound")}
                                defaultValue={props.values.desc_sound}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Factory Finish Issues"
                              name="fac_finish_issue"
                            >
                              <TextArea
                                placeholder="Factory Finish Issues"
                                className="formControl"
                                onChange={props.handleChange(
                                  "fac_finish_issue"
                                )}
                                defaultValue={props.values.fac_finish_issue}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Subfloor Evaluation" key="8">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                    label="Concrete Slab Evaluation"
                                    name="create_slab_evaluation"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.create_slab_evaluation}
                                    onToggle={props.handleChange('create_slab_evaluation')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.create_slabField === true ||
                        props.values.create_slab_evaluation === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Normal Weight Concrete (min 3000 psi)"
                                  name="Normal_weight_con"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.Normal_weight_con
                                        ? props.values.Normal_weight_con
                                        : "Normal Weight Concrete (min 3000 psi)"
                                    }
                                    onChange={props.handleChange(
                                      "Normal_weight_con"
                                    )}
                                  >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                    <Option value="Unknown">Unknown</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Light Weight Gypsum Concrete"
                                  name="light_weight_gypsum"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.light_weight_gypsum
                                        ? props.values.light_weight_gypsum
                                        : "Light Weight Gypsum Concrete"
                                    }
                                    onChange={props.handleChange(
                                      "light_weight_gypsum"
                                    )}
                                  >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                    <Option value="Unknown">Unknown</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Pre-Tension/Post-Tension Slab"
                                  name="pre_tension"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.pre_tension
                                        ? props.values.pre_tension
                                        : "Pre-Tension/Post-Tension Slab"
                                    }
                                    onChange={props.handleChange("pre_tension")}
                                  >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                    <Option value="Unknown">Unknown</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Retarder used Below Slab"
                                  name="Moist_ret_use_slab"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.Moist_ret_use_slab
                                        ? props.values.Moist_ret_use_slab
                                        : "Moisture Retarder used Below Slab"
                                    }
                                    onChange={props.handleChange(
                                      "Moist_ret_use_slab"
                                    )}
                                  >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                    <Option value="Unknown">Unknown</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={12} xl={12}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Relation of Slab Surface to Exterior Soil Line(inches)"
                                  name="subfloor_rela_slab_surface"
                                >
                                  <Input
                                    placeholder="Relation of Slab Surface to Exterior Soil Line"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "subfloor_rela_slab_surface"
                                    )}
                                    defaultValue={
                                      props.values.subfloor_rela_slab_surface
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                                   label="Wood Subfloor Evaluation"
                                   name="wood_subfloor_evaluations"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.wood_subfloor_evaluations}
                                    onToggle={props.handleChange('wood_subfloor_evaluations')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.wood_subfloorField === true ||
                        props.values.wood_subfloor_evaluations === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Type of Wood Subfloor"
                                  name="type_wood_subfloor"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.type_wood_subfloor
                                        ? props.values.type_wood_subfloor
                                        : "Type of Wood Subfloor"
                                    }
                                    onChange={props.handleChange(
                                      "type_wood_subfloor"
                                    )}
                                  >
                                    <Option value="19/3” PS-1 CDX Plywood">19/32" PS-1 CDX Plywood</Option>
                                    <Option value="19/32” PS-2 CDX OSB">19/32” PS-2 CDX OSB</Option>
                                    <Option value="23/32” PS-1 CDX Plywood">23/32" PS-1 CDX Plywood</Option>
                                    <Option value="23/32” PS-2 CDX OSB">23/32” PS-2 CDX OSB</Option>
                                    <Option value="7/8” Plywood or OSB">7/8” Plywood or OSB</Option>
                                    <Option value="1” x 6” Solid Board">1" x 6" Solid Board</Option>
                                   <Option value="Particle Board">
                                      Particle Board
                                    </Option>
                                    <Option value="Other">Other</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            {props.isOpen.type_wood_open === true ||
                            props.values.type_wood_subfloor === "Other" ? (
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Other"
                                    name="type_wood_subfloor_other"
                                  >
                                    <Input
                                      placeholder="Other"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "type_wood_subfloor_other"
                                      )}
                                      defaultValue={
                                        props.values.type_wood_subfloor_other
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            ) : null}
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Joist Spacing"
                                  name="joist_spa"
                                >
                                  <Input
                                    placeholder="Joist Spacing"
                                    className="formControl"
                                    onChange={props.handleChange("joist_spa")}
                                    defaultValue={props.values.joist_spa}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Joist Type" name="jois_ty">
                                  <Input
                                    placeholder="Joist Type"
                                    className="formControl"
                                    onChange={props.handleChange("jois_ty")}
                                    defaultValue={props.values.jois_ty}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Visible Rot"
                                    name="visible_rot"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.visible_rot}
                                    onToggle={props.handleChange('visible_rot')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Below Flooring System Evaluation" key="9">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Crawl Space Evaluation"
                              name="crawl_space_evelu"
                            >
                              <Select
                                defaultValue={
                                  props.values.crawl_space_evelu
                                    ? props.values.crawl_space_evelu
                                    : "Crawl Space Evaluation"
                                }
                                onChange={props.handleChange(
                                  "crawl_space_evelu"
                                )}
                              >
                                <Option value="Open-Air Crawlspace">
                                  Open-Air Crawlspace
                                </Option>
                                <Option value="Enclosed Crawlspace">
                                  Enclosed Crawlspace
                                </Option>
                                <Option value="Wall-Vented Crawlspace">
                                  Wall-Vented Crawlspace
                                </Option>
                                <Option value="Ground is Dirt">
                                  Ground is Dirt
                                </Option>
                                <Option value="Concrete">Concrete</Option>
                                <Option value="Gravel">Gravel</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.crawl_space_evelu_Field === true ||
                        props.values.crawl_space_evelu === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="crawl_space_evelu_other"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "crawl_space_evelu_other"
                                  )}
                                  defaultValue={
                                    props.values.crawl_space_evelu_other
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                         <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Seams Overlapped (inches)"
                              name="seam_overlapped"
                            >
                              <Input
                                placeholder="Seams Overlapped (inches)"
                                className="formControl"
                                onChange={props.handleChange("seam_overlapped")}
                                defaultValue={props.values.seam_overlapped}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                  label="Seams Sealed" 
                                  name="seams_sealed"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.seams_sealed}
                                    onToggle={props.handleChange('seams_sealed')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        
                         <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Method of Sealing"
                              name="meth_sealing"
                            >
                              <Input
                                placeholder="Method of Sealing"
                                className="formControl"
                                onChange={props.handleChange("meth_sealing")}
                                defaultValue={props.values.meth_sealing}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                       <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Vents Present" 
                                   name="vents_prese"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.vents_prese}
                                    onToggle={props.handleChange('vents_prese')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Number of Vents" name="num_vents">
                              <Input
                                placeholder="Number of Vents"
                                className="formControl"
                                onChange={props.handleChange("num_vents")}
                                defaultValue={props.values.num_vents}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Vents Open/Closed" name="opens_closed">
                              <Input
                                placeholder="Vents Open/Closed"
                                className="formControl"
                                onChange={props.handleChange("opens_closed")}
                                defaultValue={props.values.opens_closed}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Condensation Present"
                                   name="cond_present"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.cond_present}
                                    onToggle={props.handleChange('cond_present')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            

                            <Form.Item
                                   label="Standing Water Present"
                                   name="stand_water_present"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.stand_water_present}
                                    onToggle={props.handleChange('stand_water_present')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Alkali/Discoloration on Foundation Walls"
                                   name="Alkali_dis"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.Alkali_dis}
                                    onToggle={props.handleChange('Alkali_dis')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                      <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                  label="Vapor Retarder Present"
                                  name="vapor_retarder_present"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.vapor_retarder_present}
                                    onToggle={props.handleChange('vapor_retarder_present')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Percent of Ground Covered(%)"
                              name="per_ground_cover"
                            >
                              <Input
                                placeholder="Percent of Ground Covered"
                                className="formControl"
                                onChange={props.handleChange(
                                  "per_ground_cover"
                                )}
                                defaultValue={props.values.per_ground_cover}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        
                       <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Plastic Taped up Foundation Walls"
                                 name="plast_tap"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.plast_tap}
                                    onToggle={props.handleChange('plast_tap')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Insulation between Joists"
                                 name="ins_bw_joists"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.ins_bw_joists}
                                    onToggle={props.handleChange('ins_bw_joists')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Insulation on Foundation Walls"
                                 name="ins_found_wall"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.ins_found_wall}
                                    onToggle={props.handleChange('ins_found_wall')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Humidistat Installed"
                                 name="humidistat_ins"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.humidistat_ins}
                                    onToggle={props.handleChange('humidistat_ins')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                 label="Basement Evaluation"
                                 name="basement_evaluation"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.basement_evaluation}
                                    onToggle={props.handleChange('basement_evaluation')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.cleanedField === true ||
                        props.values.basement_evaluation === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Basement Status"
                                  name="base_finish"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.base_finish
                                        ? props.values.base_finish
                                        : "Basement Status"
                                    }
                                    onChange={props.handleChange("base_finish")}
                                  >
                                    <Option value="Finished">Finished</Option>
                                    <Option value="Unfinished">
                                      Unfinished
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            {props.isOpen.base_finishField === true ||
                            props.values.base_finish === "Finished" ? (
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Date Completed"
                                    name="date_completed"
                                  >
                                    <Input.Group compact className="mt-0">
                                      <Input
                                        placeholder="Date Completed"
                                        className="formControl"
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            "date_completed",
                                            "text"
                                          )
                                        }
                                        value={props.values.date_completed}
                                        onFocus={() =>
                                          props.setCalOpen16(!props.calOpen16)
                                        }
                                        onKeyUp={() =>
                                          props.setCalOpen16(false)
                                        }
                                        onBlur={() => props.setCalOpen16(false)}
                                      />

                                      <Button
                                        className="btnAddOn"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                          props.setCalOpen16(!props.calOpen16)
                                        }
                                      />
                                    </Input.Group>
                                    <DatePicker
                                      className="formControl dateHide"
                                      format={DateFormat}
                                      open={props.calOpen16}
                                      style={{ visibility: "hidden", width: 0 }}
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "date_completed",
                                          "date"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Specify Side(s) below ground level"
                                  name="specify_side_below"
                                >
                                  <Select
                                  mode="multiple"
                                  allowClear
                                  style={{
                                    width: '100%',
                                  }}
                                  placeholder="Specify Side(s) below ground level"
                                    defaultValue={
                                      props.values.specify_side_below
                                       
                                    }
                                    onChange={props.handleChange(
                                      "specify_side_below"
                                    )}
                                    options={optionnss}
                                  >
                              
                                  </Select>
                              
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                 label="Wall Cracks Present"
                                 name="wall_cracks_present"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.wall_cracks_present}
                                    onToggle={props.handleChange('wall_cracks_present')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Location of Wall Cracks " name="locat">
                                  <Input
                                    placeholder="Location of Wall Cracks"
                                    className="formControl"
                                    onChange={props.handleChange("locat")}
                                    defaultValue={props.values.locat}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Signs of Moisture Damage"
                                  name="sign_mois_dam"
                                >
                                  <Input
                                    placeholder="Signs of Moisture Damage"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "sign_mois_dam"
                                    )}
                                    defaultValue={props.values.sign_mois_dam}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                  label="Walk Out" 
                                  name="walk_out"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.walk_out}
                                    onToggle={props.handleChange('walk_out')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                   label="Sump Pump" 
                                   name="sump_pump"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.sump_pump}
                                    onToggle={props.handleChange('sump_pump')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Is Sump Pump Operating?" 
                                    name="opera"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.opera}
                                    onToggle={props.handleChange('opera')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                     label="HVAC Vents Open to Basement"
                                     name="hvac_vents_open_basement"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.hvac_vents_open_basement}
                                    onToggle={props.handleChange('hvac_vents_open_basement')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                           <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                     label="Peeling Paint"
                                     name="peeling_paint"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.peeling_paint}
                                    onToggle={props.handleChange('peeling_paint')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Floor Stains"
                                    name="floor_stains"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.floor_stains}
                                    onToggle={props.handleChange('floor_stains')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Rusty Nails"
                                    name="rusty_nails"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.rusty_nails}
                                    onToggle={props.handleChange('rusty_nails')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Gapped Trim or Moldings"
                                    name="gapped_trim"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.gapped_trim}
                                    onToggle={props.handleChange('gapped_trim')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Summary of Physical Description"
                                  name="summ_phy_des"
                                >
                                  <TextArea
                                    placeholder="Summary of Physical Description"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "summ_phy_des"
                                    )}
                                    defaultValue={props.values.summ_phy_des}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default ThirdStep;
