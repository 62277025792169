import React, { useState, useEffect } from 'react';
import QuickReportForm from './QuickReportForm';
import { notification, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import * as quickReportService from '../../../services/createQuickReport';
import * as getQuickReportService from '../../../services/SingleReport';
import * as createClientService from '../../../services/AllClients';
import * as getClientdropService from '../../../services/getClientdrop';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined } from '@ant-design/icons';
import ReportSpin from '../../../components/ReportSpinner';

function QuickReportUpdate() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [loading, setLoading] = useState(true);
  const [clientDropdown, setclientDropdown] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [SpinnerReport, setSpinnerReport] = useState(false);
  const [footerLogo, setFooterLogo] = useState([]);
  const [calOpen, setCalOpen] = useState(false);
  const [calOpen1, setCalOpen1] = useState(false);
  const [calOpen2, setCalOpen2] = useState(false);
  const [calOpen3, setCalOpen3] = useState(false);
  const [isOpen, setIsOpen] = useState({
    commPartyopen: false,
    buildField: false,
    subFloorOther: false,
    sendingSampleField: true,
    claimHistoryField: true,
    observationField: true,
    applicableField: true,
    industryField: true,
    conclusionField: true,
    causeField: false,
    confidentialField: true,
    signatureField: false,
    footerField: true,
    notesField: true,
    manuissuesField: true,
    siteissuesField: true,
    perfoissuesField: true,
    instaissuesField: true,
  });
  const [values, setValues] = useState({
    claim_num: '',
    ins_time: '',
    inspection_type: '',
    comm_party_drop: '',
    company_name_qr: '',
    agent_claim_name: '',
    comm_email: '',
    street_name_number: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    phone_no_party: '',
    dealer_name: '',
    dealer_phone: '',
    h_eu_name: '',
    h_eu_street: '',
    h_eu_city: '',
    h_eu_stateProvince: '',
    h_eu_country: '',
    //h_eu_address: "",
    comm_other: '',
    h_eu_zip: '',
    h_eu_contact: '',
    h_eu_phone_number: '',
    h_eu_alt_phone_number: '',
    h_eu_email: '',
    h_bui_occupied: '',
    reported_to: '',
    h_bi_manu_name: '',
    style_name: '',
    h_bi_color_name: '',
    area_room_ins: '',
    total_claim_qt: '',
    total_affected: '',
    flooring_installed_toggle: true,
    concerns: '',
    subfloor_type: '',
    qr_subfloor_type: '',
    building_landscape: '',
    subs_grade: '',
    footprint: '',
    total_claim_qt_unit: '',
    claim_his_togg: '',
    observe_togg: '',
    app_field_togg: '',
    indus_stand_togg: '',
    conc_togg: '',
    cause_togg: '',
    manufacturing_issue_togg: '',
    manufacturing_issue: '',
    site_rel_issue_togg: '',
    site_rel_issue: '',
    perfo_rel_issue_togg: '',
    perfo_rel_issue: '',
    insta_rel_issue_togg: '',
    insta_rel_issue: '',
    confi: '',
    footer_text_info: '',
    private_notes_togg: '',
    footerLogo: [],
    adults: '',
    childrens: '',
    pets: '',
    shipping_company: '',
    tracking_number: '',
  });
  const [open, setOpen] = useState(['']);

  useEffect(() => {
    getReportData();
    fetchclientData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const report_id = queryParams.get('report_id');

  const getReportData = () => {
    dispatch(getQuickReportService.getSingleReport(report_id))
      .then((res) => {
        setLoading(true);
        let reportRes = res;
        //toggles
        reportRes.flooring_installed_toggle =
        reportRes?.flooring_installed_toggle === 'yes' || reportRes?.flooring_installed_toggle === 'Yes' ? 'Yes' : reportRes?.flooring_installed_toggle;
        reportRes.sending_samples =
        reportRes?.sending_samples === 'yes' || reportRes?.sending_samples === 'Yes' ? 'Yes' : reportRes?.sending_samples;
        reportRes.i2_product_ins =
          reportRes?.i2_product_ins === 'yes' ? true : false;
        reportRes.claim_his_togg =
          reportRes?.claim_his_togg === 'yes' ? true : false;
        reportRes.observe_togg =
          reportRes?.observe_togg === 'yes' ? true : false;
        reportRes.app_field_togg =
          reportRes?.app_field_togg === 'yes' ? true : false;
        reportRes.indus_stand_togg =
          reportRes?.indus_stand_togg === 'yes' ? true : false;
        reportRes.conc_togg = reportRes?.conc_togg === 'yes' ? true : false;
        reportRes.cause_togg = reportRes?.cause_togg === 'yes' ? true : false;
        reportRes.manufacturing_issue_togg = reportRes?.manufacturing_issue_togg === 'yes' ? true : false;
        reportRes.site_rel_issue_togg = reportRes?.site_rel_issue_togg === 'yes' ? true : false;
        reportRes.insta_rel_issue_togg = reportRes?.insta_rel_issue_togg === 'yes' ? true : false;
        reportRes.perfo_rel_issue_togg = reportRes?.perfo_rel_issue_togg === 'yes' ? true : false;
        reportRes.issues_info_toggle = reportRes?.issues_info_toggle === 'yes' ? true : false;
        reportRes.confi = reportRes?.confi === 'yes' ? true : false;
        reportRes.upload_sign_toggle =
          reportRes?.upload_sign_toggle === 'yes' ? true : false;
        reportRes.footer_text_info =
          reportRes?.footer_text_info === 'yes' ? true : false;
        reportRes.private_notes_togg =
          reportRes?.private_notes_togg === 'yes' ? true : false;
        reportRes.floor_rolled_toggle =
          reportRes?.floor_rolled_toggle === 'yes' ? true : false;
        setOpen(res.open[0]);

        reportRes.measure_type1 = reportRes?.total_claim_qt_unit;
        reportRes.measure_type2 = reportRes?.total_affected_unit;
        //footerlogos
        let data1 = [];
        if (reportRes.attachments) {
          Object.keys(reportRes.attachments).forEach(function (key, index) {
            data1.push(reportRes.attachments[key]);
          });
        }
        reportRes.attachments = data1;
        let Allimagedescription = {};
        reportRes.attachments.forEach((attachment) => {
          let indexVal = String(attachment['uid']);
          Allimagedescription[indexVal] = {};
          Allimagedescription[indexVal]['caption'] = attachment['desc'];
        });
        reportRes.Allimagedescription = Allimagedescription;
        reportRes.Quickreporttogg1 = reportRes.Open
          ? reportRes.Open
          : ['1', '2', '3', '4', '5'];
        reportRes.Quickreporttogg1 = reportRes.Open
          ? reportRes.Open
          : ['6', '5', '7', '8', '10'];
        let newlogos = [];
        // console.warn('response quick reports', typeof(res.selectededlogos))

        if (reportRes.selectededlogos) {
          Object.keys(reportRes.selectededlogos) &&
            Object.keys(reportRes.selectededlogos).forEach(function (
              key,
              index
            ) {
              newlogos.push(reportRes.selectededlogos[key].id?.toString());
            });
          //setselectedLogos(newlogos)
          reportRes.footerLogo = newlogos;
        }
        setFooterLogo(newlogos);
        setValues(reportRes);
        setLoading(false);
        console.warn({ reportRes });
      })
      .catch((err) => {
        // alert("error",err);
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };
  const onChangeCollapse = (key) => {
    setOpen(key);
    console.log('togglessss', key);
  };
  const handleChange = (input) => (e) => {
    if (
      input === 'comm_party_drop' ||
      input === 'subfloor_type' ||
      input === 'sending_samples' ||
      input === 'claim_his_togg' ||
      input === 'observe_togg' ||
      input === 'app_field_togg' ||
      input === 'indus_stand_togg' ||
      input === 'conc_togg' ||
      input === 'cause_togg' ||
      input === 'manufacturing_issue_togg' ||
      input === 'site_rel_issue_togg' ||
      input === 'insta_rel_issue_togg' ||
      input === 'perfo_rel_issue_togg' ||
      input === 'confi' ||
      input === 'upload_sign_toggle' ||
      input === 'footer_text_info' ||
      input === 'private_notes_togg' ||
      input === 'flooring_installed_toggle' ||
      input === 'h_bui_occupied'
    ) {
      if (input === 'flooring_installed_toggle') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'comm_party_drop' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          commPartyopen: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'comm_party_drop' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          commPartyopen: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'h_bui_occupied') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            buildField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            buildField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'sending_samples') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            sendingSampleField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            sendingSampleField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'subfloor_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          subFloorOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'subfloor_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          subFloorOther: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'claim_his_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          claimHistoryField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'claim_his_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          claimHistoryField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'observe_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          observationField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'observe_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          observationField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'app_field_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          applicableField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'app_field_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          applicableField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'indus_stand_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          industryField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'indus_stand_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          industryField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'conc_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          conclusionField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'conc_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          conclusionField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'cause_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          causeField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'cause_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          causeField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'manufacturing_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          manuissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'manufacturing_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          manuissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'site_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          siteissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'site_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          siteissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'perfo_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          perfoissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'perfo_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          perfoissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'insta_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          instaissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'insta_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          instaissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'confi' && e === true) {
        setIsOpen({
          ...isOpen,
          confidentialField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'confi' && e === false) {
        setIsOpen({
          ...isOpen,
          confidentialField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'upload_sign_toggle' && e === true) {
        setIsOpen({
          ...isOpen,
          signatureField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'upload_sign_toggle' && e === false) {
        setIsOpen({
          ...isOpen,
          signatureField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'footer_text_info' && e === true) {
        setIsOpen({
          ...isOpen,
          footerField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'footer_text_info' && e === false) {
        setIsOpen({
          ...isOpen,
          footerField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'private_notes_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          notesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'private_notes_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          notesField: false,
        });
        setValues({ ...values, [input]: e });
      } else {
        setValues({ ...values, [input]: e });
      }
    } else if (
      input === 'time_type' ||
      input === 'inspection_type' ||
      input === 'subs_grade' ||
      input === 'footprint' ||
      input === 'building_landscape' ||
      input === 'measure_type1' ||
      input === 'measure_type2'
    ) {
      input === 'time_type' ||
      input === 'measure_type1' ||
      input === 'measure_type2'
        ? setValues({ ...values, [input]: e[0] })
        : setValues({ ...values, [input]: e });
    } else if (input === 'footerLogo') {
      //start
      let footerlogo1 = [];
      let id = e.target.value;
      footerlogo1 = [...footerLogo];
      if (footerlogo1?.includes(id)) {
        let footer_idex = footerlogo1.indexOf(id);
        delete footerlogo1[footer_idex];
      } else {
        footerlogo1.push(id);
      }
      footerlogo1 = footerlogo1?.filter((e) => e);
      setFooterLogo(footerlogo1);
      values.footerLogo = footerlogo1;
      console.warn({ footerLogo });
    } else {
      console.warn({ isOpen });
      setValues({ ...values, [input]: e.target.value });
    }
  };
  const handleEditor = (valEditor, label) => {
    setValues((l) => ({ ...l, [label]: valEditor }));
  };

  const handleFill = (val) => {
    setValues({ ...values, ...val });
  };
  const buttonSubmit = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    console.warn('sent values to api ', values);
    //return;
    dispatch(quickReportService.updateQuickReport(values))
      .then((res) => {
        console.warn('Submit', res);
        notification.open({
          message: 'Report successfully updated!',
          description: 'You have successfully updated a report.',
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#108ee9',
              }}
            />
          ),
        });
        //return;
        setSpinnerReport(false);
        navigate('/single-quick-report/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const saveDraft = () => {
    setSpinnerReport(true);
    onFinish();
    values.status = 'Draft';
    dispatch(quickReportService.updateQuickReport(values))
      .then((res) => {
        console.warn('Submit', res);
        setSpinnerReport(false);
        window.location.reload(false);
        navigate('/quick-report-update/?report_id=' + res.data.report_id);
        message.success('Saved as draft!');
      })
      .catch((err) => {
        console.error({ err });
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const addClient = () => {
    let clientData = {
      company_name_qr: values.company_name_qr,
      comm_party_drop: values.comm_party_drop,
      agent_claim_name: values.agent_claim_name,
      comm_email: values.comm_email,
      street_name_number: values.street_name_number,
      state: values.state,
      city: values.city,
      country: values.country,
      zip_code: values.zip_code,
      phone_no_party: values.phone_no_party,
    };
    if (clientData.company_name_qr !== '' && clientData.comm_email !== '') {
      dispatch(createClientService.create_client(clientData))
        .then((res) => {
          console.warn('createClientService', res);
          if (res.status === 'client added') {
            fetchclientData();
            notification.open({
              message: 'Client successfully created!',
              description: 'You have successfully created a client.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            });
          } else {
            fetchclientData();
            notification.error({
              message: `${res.status}`,
              description:
                'The client that you are trying to create already exists.”',
              className: 'custom-class',
            });
          }
        })
        .catch((err) => {
          console.info('Get Error Edit State...', loading);
          notification.error('This is an error message');
          setLoading(false);
        });
    } else {
      notification.error('Client data is not valid');
    }
  };
  const handleDateformate = (e, name, type) => {
    if (name === 'h_bi_ins_date') {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'product_date_of_ins') {
      if (type === 'date') {
        setCalOpen1(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_problem_noticed') {
      if (type === 'date') {
        setCalOpen2(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }

    if (name === 'date_shipped') {
      if (type === 'date') {
        setCalOpen3(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
  };
  const onFinish = () => {
    setLoadings(true);

    values.req_id = Data.req_id;
    values.category = Data.floor_category;
    values.total_claim_qt_unit = values?.measure_type1;
    values.total_affected_unit = values?.measure_type2;
    values.claim_his_togg = values.claim_his_togg === true ? 'yes' : 'no';
    values.observe_togg = values.observe_togg === true ? 'yes' : 'no';
    values.app_field_togg = values.app_field_togg === true ? 'yes' : 'no';
    values.indus_stand_togg = values.indus_stand_togg === true ? 'yes' : 'no';
    values.conc_togg = values.conc_togg === true ? 'yes' : 'no';
    values.cause_togg = values.cause_togg === true ? 'yes' : 'no';
    values.manufacturing_issue_togg = values.manufacturing_issue_togg === true ? 'yes' : 'no';
    values.site_rel_issue_togg = values.site_rel_issue_togg === true ? 'yes' : 'no';
    values.perfo_rel_issue_togg = values.perfo_rel_issue_togg === true ? 'yes' : 'no';
    values.insta_rel_issue_togg = values.insta_rel_issue_togg === true ? 'yes' : 'no';
    values.issues_info_toggle = open.includes('10') ? 'yes' : 'no';
    values.confi = values.confi === true ? 'yes' : 'no';
    values.upload_sign_toggle =
      values.upload_sign_toggle === true ? 'yes' : 'no';
    values.footer_text_info = values.footer_text_info === true ? 'yes' : 'no';
    values.private_notes_togg =
      values.private_notes_togg === true ? 'yes' : 'no';
    values.floor_rolled_toggle =
      values.floor_rolled_toggle === true ? 'yes' : 'no';
    values.party_toggle = open.includes('1') ? 'yes' : 'no';
    values.dealer_info_toggle = open.includes('2') ? 'yes' : 'no';
    values.enduser_toggle = open.includes('3') ? 'yes' : 'no';
    values.basic_info_toggle = open.includes('4') ? 'yes' : 'no';
    values.subtrace_toggle = open.includes('5') ? 'yes' : 'no';
    values.obser_info_toggle = open.includes('6') ? 'yes' : 'no';
    //values.confi = open.includes('7') ? 'yes' : 'no';
    values.footer_info_toggle = open.includes('8') ? 'yes' : 'no';
    values.open = open;

    console.log('Success:', values);
  };
  return (
    <>
      {SpinnerReport ? (
        <ReportSpin />
      ) : (
        <>
          <QuickReportForm
            handleChange={handleChange}
            handleFill={handleFill}
            values={values}
            loading={loading}
            isOpen={isOpen}
            open={open}
            onChangeCollapse={onChangeCollapse}
            addClient={addClient}
            clientDropdown={clientDropdown}
            handleDateformate={handleDateformate}
            calOpen={calOpen}
            setCalOpen={setCalOpen}
            calOpen1={calOpen1}
            setCalOpen1={setCalOpen1}
            calOpen2={calOpen2}
            setCalOpen2={setCalOpen2}
            calOpen3={calOpen3}
            setCalOpen3={setCalOpen3}
            handleEditor={handleEditor}
            buttonSubmit={buttonSubmit}
            saveDraft={saveDraft}
            loadings={loadings}
            setLoadings={setLoadings}

          />
        </>
      )}
    </>
  );
}

export default QuickReportUpdate;
