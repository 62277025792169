import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getInspectionDealerListingData() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'getdealerlisting' )
          .then((res) => {
            //console.warn('InspectorData', res.data)
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function dealerRequest() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'dealer_req_btn' )
          .then((res) => {
            console.warn('dealerRequest', res.data)
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  