import React, { useCallback, useRef, useState, useEffect } from 'react';
import 'antd/dist/antd.min.css';
import './upload.css';
import {
  Tooltip,
  Upload,
  Modal,
  Input,
  Radio,
  Typography,
  Form,
  message,
  Image,
  Spin,
  Row,
  Col,
  Button,
  Result,
  Checkbox,
} from 'antd';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import axios from 'axios';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ImageEditor from './ImageEditor';
import { MEDIA_UPLOAD_URL, INSPECTION_URL } from '../../Helper';
import Swal from 'sweetalert2';
import Fancybox from './fancybox';
import { EyeOutlined, SmileOutlined } from '@ant-design/icons';
import * as getCompanycamPhotosService from '../../services/CompanyCam';
import * as getCompanycamProPhotosService from '../../services/CompnyCamImgs';
import * as companyCamtokenSave from '../../services/companyCamtokenService';
import CustomSpinner from '../../components/spinner';
import { useDispatch } from 'react-redux';

const { Item } = Form;
const type = 'DragableUploadList';
const { Title } = Typography;

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef(null);

  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${
        isOver ? dropClassName : ''
      }`}
      style={{
        cursor: 'move',
      }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};
const progress = {
  strokeWidth: 15,
  format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
};

const App = (props) => {
  const takefileList = props.takefileList;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCancel = () => setPreviewVisible(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyCam, setCompanycam] = useState([]);
  const [companyCamProImg, setCompanycamProImg] = useState([]);
  const [companyCamHeading, setCompanycamHeading] = useState('');
  const [CompanycamAddressCity, setCompanycamAddressCity] = useState('');
  const [CompanycamAddressPostal, setCompanycamAddressPostal] = useState('');
  const [CompanycamAddressState, setCompanycamAddressState] = useState('');
  const [CompanycamAddressStreet, setCompanycamAddressStreet] = useState('');
  const [selectedImages, setselectedImages] = useState([]);
  const [active, setActive] = useState(null);
  const [camLoader, setCamLoader] = useState(false);
  const [uploading, setuploading] = useState(false);
  const [cCprogress, setCcprogress] = useState(0);

  const [companyCamToken, setcompanyCamToken] = useState('');

  // Token Modal
  const [isModalToken, setIsModalToken] = useState(false);
  const [isModalHelp, setIsModalHelp] = useState(false);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  var dispatch = useDispatch();
  console.warn([fileList]);
  const handleChange = (input) => (e) => {
    props.values.Allimagedescription = {
      ...props.values.Allimagedescription,
      [input]: {
        caption: e.target.value,
      },
    };
  };
  const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e && e.fileList;
    }

    return e && e.fileList;
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );
  const fetchCompanycam = (params = {}) => {
    dispatch(getCompanycamPhotosService.getCompanycamPhotos())
      .then((res) => {
        setCompanycam(res);
      })
      .catch((err) => {
        setIsModalToken(true);
        setIsModalOpen(false);
      });
  };
  // Inside the ImageGallery component

  console.info('companyCamProImg', companyCamProImg);

  const setCompanycamToken = (value) => {
    dispatch(companyCamtokenSave.companyCamtokenService(value.companycam_token))
      .then((res) => {
        if (res.data.companycam_token === 'invalid') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You have entered invalid token!',
            confirmButtonText: 'Help',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              showHelpModal();
            }
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Your token have been successfully saved!',
          });
          handleCancel();

          let apiResponseCCToken = res.data.companycam_token;
          setcompanyCamToken(apiResponseCCToken);
          fetchCompanycam();
          localStorage.setItem('companycam_token', apiResponseCCToken);
        }
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allCompImgs = companyCamProImg.map((c) => c?.uris[3]?.uri);
      setselectedImages(allCompImgs);
    } else {
      setselectedImages([]);
    }
  };

  async function saveCompanyCamMedia() {
    setuploading(true);
    const values = [];
    const keys = selectedImages;
    const key1 = 'url';
    const key2 = 'media_id';
    const key3 = 'status';
    const numOfimg = selectedImages.length;
    for (let i = 0; i < selectedImages.length; i++) {
      const url = selectedImages[i];

      try {
        const response = await axios.post(
          INSPECTION_URL + 'uploadMediafromCompanycam/',
          { url }
        );
        values[i] = response.data;
        if (numOfimg > 1) {
          let remainingImgs = numOfimg - (i + 1);
          let progressPercent = ((numOfimg - remainingImgs) / numOfimg) * 100;
          setCcprogress(progressPercent);
        } else {
          setCcprogress(0);
        }
      } catch (error) {
        console.error(`Error sending URL ${url} to API: ${error.message}`);
      }
    }

    const result = keys.reduce((acc, key, index) => {
      acc.push({
        [key1]: key,
        [key2]: values[index],
        [key3]: 'done',
        uid: values[index],
      });
      return acc;
    }, []);
    console.warn({ result });
    setFileList([...fileList, ...result]);
    setuploading(false);
    setselectedImages([]);
    setIsModalOpen(false);
    setCcprogress(0);
  }

  const handleButtonClick = () => {
    const externalUrl = 'https://app.companycam.com/access_tokens';
    // Open the URL in a new tab
    window.open(externalUrl, '_blank');
  };

  const fetchCompanycamProImg = (project_id, index) => {
    console.info({ index });
    setActive(index);
    setCamLoader(true);
    dispatch(
      getCompanycamProPhotosService.getCompanycamProjectPhotos(project_id)
    )
      .then((res) => {
        var selectedProject = companyCam.filter(function (el) {
          return el.id === project_id;
        });
        setCompanycamHeading(selectedProject[0]?.name);
        setCompanycamAddressStreet(
          selectedProject[0]?.address['street_address_1']
        );
        setCompanycamAddressCity(selectedProject[0]?.address['city']);
        setCompanycamAddressState(selectedProject[0]?.address['state']);
        setCompanycamAddressPostal(selectedProject[0]?.address['postal_code']);

        // To sort images based on ID
        const sortedImages = res.sort((a, b) => a.id - b.id);
        setCompanycamProImg(sortedImages);

        setCamLoader(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const hadleChangeCompanycam = (e) => {
    let companyCamimgs = [];
    let url = e.target.value;
    companyCamimgs = [...selectedImages];
    if (companyCamimgs?.includes(url)) {
      let deselectedUrl = companyCamimgs.indexOf(url);
      delete companyCamimgs[deselectedUrl];
    } else {
      companyCamimgs.push(url);
    }
    companyCamimgs = companyCamimgs?.filter((e) => e);
    setselectedImages(companyCamimgs);
  };
  useEffect(() => {
    setFileList(props.values.attachments ? props.values.attachments : []);
    let localCCTokenItem = localStorage.getItem('companycam_token');
    if (localCCTokenItem) {
      setcompanyCamToken(localCCTokenItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.attachments]);

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'image/jpg' ||
      file.type === 'image/heic';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    return isJpgOrPng;
  };
  const uploadImage = async (options, values) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess(res);
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
      console.warn(error);
    }
  };

  const onChange = (info) => {
    if (info.file.status === 'uploading') {
      setFileList(info.fileList);
    }
    if (info.file.status === 'removed') {
      setFileList(info.fileList);
    }
    if (info.file.status === 'done') {
      let newFileList = [...fileList];
      let index = newFileList.findIndex((ele) => ele.uid === info.file.uid);
      newFileList[index]['status'] = 'done';
      newFileList[index]['media_id'] = info.file.response.data.id;
      newFileList[index]['url'] = info.file.response.data.source_url;
      setFileList(newFileList);
    }
  };
  const onChangeCover = (e) => {
    props.values.cover_image = e.target.value;
  };

  const handlePreview = async (file) => {
    if (file.originFileObj) {
      setLoading(true);
      setPreviewImage(file);
      setLoading(false);
      setPreviewVisible(true);
      console.warn({ file });
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      );
    } else {
      if (isS3Url(file.url)) {
        message.error(`Photos cannot be edited after saving the report!`);
      } else {
        createFile(file);
      }
    }
  };
  async function createFile(file) {
    let response = await fetch(file.url);
    let data = await response.blob();
    file.originFileObj = data;
    setPreviewImage(file);
    setLoading(false);
    setPreviewVisible(true);
  }
  const isS3Url = (url) => {
    const s3BucketDomains = [
      's3.amazonaws.com',
      'https://fddatainsp.s3.us-east-2.amazonaws.com',
    ]; // Add more S3 bucket domains if needed

    return s3BucketDomains.some((domain) => url.includes(domain));
  };

  const showModal = () => {
    fetchCompanycam();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
  };
  const showTokenModal = () => {
    setIsModalToken(true);
  };
  const handleOkToken = () => {
    setIsModalToken(false);
  };
  const handleCancelToken = () => {
    setIsModalToken(false);
  };
  const showHelpModal = () => {
    setIsModalHelp(true);
  };
  const handleOkHelp = () => {
    setIsModalHelp(false);
  };
  const handleCancelHelp = () => {
    setIsModalHelp(false);
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Form.Item
          name="dragger"
          valuePropName={fileList}
          getValueFromEvent={normFile}
          noStyle
        >
          <Upload
            name="files"
            multiple={true}
            accept="image/*"
            beforeUpload={beforeUpload}
            customRequest={uploadImage}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={handlePreview}
            progress={progress}
            itemRender={(originNode, file, currFileList) => (
              <>
                <div className="uploaded-img-wrapper image_attachment_uploader">
                  <div className="img-section">
                    <Fancybox>
                      <a
                        data-fancybox="gallery"
                        href={file.url}
                        className="zIndex"
                      >
                        <EyeOutlined />
                      </a>
                    </Fancybox>
                    <DragableUploadListItem
                      originNode={originNode}
                      file={file}
                      fileList={currFileList}
                      moveRow={moveRow}
                      onChange={takefileList(currFileList)}
                    />
                    <div className="upload-img-number">
                      <span className="img-number">
                        {currFileList.indexOf(file) + 1}
                      </span>
                    </div>
                  </div>

                  <div className="img-text-wrapper">
                    <div className="input-header">
                      <Title level={5}>Description</Title>
                    </div>
                    <Form.Item
                      name={[
                        'Allimagedescription',
                        file.uid.toString(),
                        'caption',
                      ]}
                      onChange={handleChange(file.uid.toString())}
                      initialValue={
                        props.values.Allimagedescription?.[file.uid.toString()]
                          ?.caption
                          ? props.values?.Allimagedescription[
                              file?.uid.toString()
                            ].caption
                          : file.desc
                      }
                    >
                      <Input.TextArea className="input-img" />
                    </Form.Item>
                  </div>
                  <div className="img-footer">
                    <Form.Item name="cover_image">
                      <Radio.Group
                        defaultValue={
                          parseInt(file.cover) === file.uid ? file.uid : ''
                        }
                        onChange={onChangeCover}
                      >
                        <Radio
                          name="cover_image"
                          value={file?.media_id ? file?.media_id : file?.uid}
                        >
                          <p>Please Select Cover Photo</p>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          >
            <p>Drop files here to upload</p>
          </Upload>
        </Form.Item>
      </DndProvider>
      <div className="CompanySection">
        <Row gutter={16} className="mb-3">
          <Col xs={24} md={24} className="text-center companyCamButton">
            <Button className="btnPrimary2" onClick={showModal}>
              <img
                src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                alt="company-logos"
              ></img>
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        className="editor_rotate_Model"
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <ImageEditor
          image={previewImage}
          fileList={fileList}
          setFileList={setFileList}
          setPreviewVisible={setPreviewVisible}
          loading={loading}
          setLoadings={props.setLoadings}
        />
      </Modal>

      <Modal
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancelModal}
        closable={false}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title">
                <Title level={4}>CompanyCam's Projects</Title>
              </div>
              <div className="modal-header-logo model-logo-center">
                <div className="modal-header-edit-logo">
                  <Tooltip title="Edit Your Companycam Token" arrow>
                    <Button className="btnPrimary2" onClick={showTokenModal}>
                      <img
                        src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/24023652/cog-regular-36.png"
                        alt="edit-logo"
                      ></img>
                    </Button>
                  </Tooltip>
                </div>
                <Image
                  src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                  className="img-fluid"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Form
          name="companyCamform"
          layout="vertical"
          autoComplete="off"
          form={form1}
          onFinish={saveCompanyCamMedia}
        >
          <div>
            <Row className="my-2 footerRow" gutter={6}>
              <Col className="col-4">
                <Row className="my-2 footerRow" gutter={6}>
                  {Object.keys(companyCam).map((keyName, index) => (
                    <Col className="col-12 h-100 mb-2" key={index}>
                      {/* className="companyCam-project-box" */}
                      <a
                        href="#:"
                        className={
                          active === index
                            ? ' companyCam-project-box activeCard'
                            : 'companyCam-project-box'
                        }
                        onClick={() =>
                          fetchCompanycamProImg(companyCam[keyName]?.id, index)
                        }
                      >
                        <div className="logoDiv companyCam-project-outer">
                          <div className="companyCam-pro-thumb">
                            <Image
                              src={companyCam[keyName]?.feature_image[0]?.uri}
                              className="img-fluid"
                              alt="logos"
                              preview={false}
                              //onClick={alert(companyCam[keyName]?.id)}
                            />
                          </div>
                          <div className="company-project-details px-2">
                            <h6>
                              <span>
                                <b>Project </b>:-
                              </span>
                              <span className="company-project-title">
                                {companyCam[keyName]?.name}
                              </span>
                            </h6>
                            <p>
                              <span>
                                <b>Address </b>:-
                              </span>
                              <span>
                                {
                                  companyCam[keyName]?.address[
                                    'street_address_1'
                                  ]
                                }
                              </span>
                              ,{' '}
                              <span>
                                {companyCam[keyName]?.address['city']}
                              </span>
                              ,{' '}
                              <span>
                                {companyCam[keyName]?.address['state']}
                              </span>
                              ,{' '}
                              <span>
                                {companyCam[keyName]?.address['postal_code']}
                              </span>
                            </p>
                            <p>
                              <span>
                                <b>Photos </b>:-
                              </span>
                              {companyCam[keyName]?.photo_count}
                            </p>
                          </div>
                        </div>
                      </a>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col className="col-8">
                {uploading === true ? (
                  <CustomSpinner progress={cCprogress} />
                ) : (
                  ''
                )}

                {camLoader ? (
                  <div className="camLoader">
                    <Spin size="large" />
                    {/* <CustomSpinner /> */}
                  </div>
                ) : (
                  <div className="companyCam-single-project-details">
                    <h2>{companyCamHeading}</h2>
                    <div
                      className="form-check"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '25px',
                      }}
                    >
                      <h2>
                        <Checkbox
                          onChange={handleCheckAllChange}
                          checked={
                            selectedImages.length === companyCamProImg.length
                          }
                        >
                          Select all
                        </Checkbox>
                      </h2>
                    </div>
                    <p>
                      <span>{CompanycamAddressStreet}</span>
                      {CompanycamAddressStreet && ', '}
                      <span>{CompanycamAddressCity}</span>
                      {CompanycamAddressCity && ', '}
                      <span>{CompanycamAddressState}</span>
                      {CompanycamAddressState && ', '}
                      <span>{CompanycamAddressPostal}</span>
                    </p>
                  </div>
                )}
                <Row className="my-2 footerRow" gutter={6}>
                  {companyCamProImg.length > 0 ? (
                    <ul className="Companyproject_images-outer">
                      {Object.keys(companyCamProImg).map((keyName, index) => (
                        <>
                          <li className="Companyproject_images-box">
                            <input
                              type="checkbox"
                              className="checkboxstyle"
                              id={'checkbox' + index}
                              key={companyCamProImg[keyName]?.id}
                              value={companyCamProImg[keyName]?.uris[3]?.uri}
                              onChange={hadleChangeCompanycam}
                              checked={selectedImages?.includes(
                                companyCamProImg[keyName]?.uris[3]?.uri
                              )}
                            />
                            <label htmlFor={'checkbox' + index}>
                              <div className="logoDiv companyCam">
                                <Image
                                  src={companyCamProImg[keyName]?.uris[3]?.uri}
                                  className="img-fluid"
                                  alt="logos"
                                  preview={false}
                                />
                              </div>
                            </label>
                          </li>
                        </>
                      ))}
                    </ul>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <SmileOutlined style={{ fontSize: 20 }} />
                      <p>Please select a project from sidebar!</p>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </div>

          <div className="modelButtons">
            <Button className="btnPrimary" onClick={handleCancelModal}>
              Cancel
            </Button>
            <Button
              className="btnPrimary"
              htmlType="submit"
              disabled={uploading}
            >
              Upload and Exit
            </Button>
          </div>
        </Form>
      </Modal>

      {/* ADD TOKEN MODEL  */}
      <Modal
        // title={"CompanyCam's Projects" }
        centered
        open={isModalToken}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel"
        width={1000}
        footer={null}
        onOk={handleOkToken}
        onCancel={handleCancelToken}
        closable={true}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title">
                <Title level={4}>Add CompanyCam Token</Title>
              </div>
              <div className="modal-header-logo">
                <Image
                  src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                  className="img-fluid"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Form
          name="companyCamforms"
          layout="vertical"
          autoComplete="off"
          form={form2}
          onFinish={setCompanycamToken}
        >
          <div className="companyToken-inst">
            <Row className="my-2 footerRow" gutter={6}>
              <Col xs={24} md={24}>
                <h2 className="companyToken-title">
                  Please input your Companycam token
                </h2>
                <div className="companyToken-form">
                  <Row gutter={16} className="companyToken-row">
                    <Col xs={24} md={18} xl={18}>
                      <Item
                        name="companycam_token"
                        placeholder="Please input your Companycam token"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Companycam token',
                          },
                          {
                            min: 40,
                            message: 'Please enter a valid token',
                          },
                          {
                            max: 49,
                            message: 'Please enter a valid token',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please input your Companycam token"
                          className="formControl"
                          defaultValue={companyCamToken}
                        />
                      </Item>
                      {companyCamToken && companyCam.length === 0 ? (
                        <Result
                          status="error"
                          title="Your Token might be expired or invalid."
                        />
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col xs={24} md={6} xl={6}>
                      <Form.Item>
                        <Button
                          className="btnPrimary"
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
        <div className="helpModel-heading">
          <h3>How to create company cam access token?</h3>
          <Button className="btnPrimary" onClick={showHelpModal}>
            Help
          </Button>
        </div>
      </Modal>

      {/* HELP TOKEN MODEL  */}
      <Modal
        // title={"CompanyCam's Projects" }
        centered
        open={isModalHelp}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel HelpTokenModel"
        width={1000}
        footer={null}
        onOk={handleOkHelp}
        onCancel={handleCancelHelp}
        closable={false}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title"></div>
              <div className="modal-header-logo">
                <Image
                  src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                  className="img-fluid"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div>
          <Row className="my-2 footerRow" gutter={6}>
            <Col xs={24} md={24}>
              <section className="timeline customAntImageWrapper">
                <h1>How To Generate CompanyCam Access Token?</h1>
                <Image
                  src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/05/19041149/ezgif-1-346a85312d.gif"
                  className="img-fluid"
                  alt="images"
                  preview={false}
                />
              </section>
            </Col>
          </Row>
        </div>
        <div className="modelButtons">
          <Button
            className="btnPrimary"
            htmlType="submit"
            onClick={handleButtonClick}
          >
            Generate Token
          </Button>
          <Button
            className="btnPrimary"
            htmlType="submit"
            onClick={handleCancelHelp}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default App;
