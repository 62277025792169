import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function createReport(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "carpet_basic_report", values)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}


// export function updateReport(values) {
//   return (dispatch) =>
//     new Promise((resolve, reject) => {
//       Http.post(INSPECTION_URL + "updatereport", values)
//         .then((res) => {
//           console.log("Res", res);
//           return resolve(res);
//         })
//         .catch((err) => {
//           return reject(err);
//         });
//     });
// }