import React from "react";
import { Row, Col, Input, Card, Form, Switch, Select } from "antd";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
const SecondStep = (props) => {
  console.warn("SecondStep", props);
  const [form] = Form.useForm();
  const { Option } = Select;

  return (
    <Form name="report" autoComplete="off" layout="vertical" form={form}>
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Installation Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                    <Form.Item
                       label="Product Installed?"
                       name="i2_product_ins"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.i2_product_ins}
                              onToggle={props.handleChange('i2_product_ins')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.productField === true || 
                  props.values.i2_product_ins === 'Yes'  ? (
                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Thinset Used"
                            name="tile_thinset"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.tile_thinset}
                                    onToggle={props.handleChange('tile_thinset')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Grout Used" 
                            name="tile_tog_grout"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.tile_tog_grout}
                                    onToggle={props.handleChange('tile_tog_grout')}
                            />
                            </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                             label="Spacers Used During Install? Y/N"
                             name="spacers_used_during_install"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.spacers_used_during_install}
                                    onToggle={props.handleChange('spacers_used_during_install')}
                            />
                            </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Thinset Manufacturer"
                            name="tile_thin_manf"
                          >
                            <Input
                              placeholder="Thinset Manufacturer"
                              className="formControl"
                              onChange={props.handleChange("tile_thin_manf")}
                              defaultValue={props.values.tile_thin_manf}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Thinset Batch Number"
                            name="tile_thin_batch"
                          >
                            <Input
                              placeholder="Thinset Batch Number"
                              className="formControl"
                              onChange={props.handleChange("tile_thin_batch")}
                              defaultValue={props.values.tile_thin_batch}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Grout Manufacturer"
                            name="tile_grout_manf"
                          >
                            <Input
                              placeholder="Grout Manufacturer"
                              className="formControl"
                              onChange={props.handleChange("tile_grout_manf")}
                              defaultValue={props.values.tile_grout_manf}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Additive Used"
                            name="tile_drop_additive"
                          >
                            <Select
                              onChange={props.handleChange(
                                "tile_drop_additive"
                              )}
                              defaultValue={
                                props.values.tile_drop_additive
                                  ? props.values.tile_drop_additive
                                  : "Additive Used"
                              }
                            >
                              <Option value="Dry">Dry</Option>
                              <Option value="Liquid">Liquid</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.tiledropFiled === true ||
                      props.values.tile_drop_additive === "Other" ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="additive_used_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  "additive_used_other"
                                )}
                                defaultValue={props.values.additive_used_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Bed Thinset" name="tile_drop_bed">
                            <Select
                              defaultValue={
                                props.values.tile_drop_bed
                                  ? props.values.tile_drop_bed
                                  : "Bed Thinset"
                              }
                              onChange={props.handleChange("tile_drop_bed")}
                            >
                              <Option value="Light">Light</Option>
                              <Option value="Medium">Medium</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.dropbedFiled === true ||
                      props.values.tile_drop_bed === "Other" ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="bedthinset_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  "bedthinset_other"
                                )}
                                defaultValue={props.values.bedthinset_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Grout Batch Number"
                            name="tile_grout_batch"
                          >
                            <Input
                              placeholder="Grout Batch Number"
                              className="formControl"
                              onChange={props.handleChange("tile_grout_batch")}
                              defaultValue={props.values.tile_grout_batch}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Grout Type" name="tile_drop_epoxy">
                            <Select
                              defaultValue={
                                props.values.tile_drop_epoxy
                                  ? props.values.tile_drop_epoxy
                                  : "Grout Type"
                              }
                              onChange={props.handleChange("tile_drop_epoxy")}
                            >
                              <Option value="Epoxy">Epoxy</Option>
                              <Option value="Resin">Resin</Option>
                              <Option value="Sanded">Sanded</Option>
                              <Option value="Unsanded">Unsanded</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.dropproxyFiled === true ||
                      props.values.tile_drop_epoxy === "Other" ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="grout_type_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange("grout_type_other")}
                                defaultValue={props.values.grout_type_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Grout Additive"
                            name="tile_drop_grout_add"
                          >
                            <Select
                              defaultValue={
                                props.values.tile_drop_grout_add
                                  ? props.values.tile_drop_grout_add
                                  : "Grout Additive"
                              }
                              onChange={props.handleChange(
                                "tile_drop_grout_add"
                              )}
                            >
                              <Option value="Dry">Dry</Option>
                              <Option value="Liquid">Liquid</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.dropgroutFiled === true ||
                      props.values.tile_drop_grout_add === "Other" ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other"
                              name="grout_additive_other"
                            >
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  "grout_additive_other"
                                )}
                                defaultValue={props.values.grout_additive_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Space Between Backerboard"
                            name="tile_drop_space"
                          >
                            <Select
                              defaultValue={
                                props.values.tile_drop_space
                                  ? props.values.tile_drop_space
                                  : "Space Between Backerboard"
                              }
                              onChange={props.handleChange("tile_drop_space")}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                              <Option value="Unknown">Unknown</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Thinset Used to Fill Joint Space"
                            name="tile_drop_thin"
                          >
                            <Select
                              defaultValue={
                                props.values.tile_drop_thin
                                  ? props.values.tile_drop_thin
                                  : "Thinset Used to Fill Joint Space"
                              }
                              onChange={props.handleChange("tile_drop_thin")}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                              <Option value="Unknown">Unknown</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Backerboard Type"
                            name="backboard_type"
                          >
                            <Input
                              placeholder="Backerboard Type"
                              className="formControl"
                              onChange={props.handleChange("backboard_type")}
                              defaultValue={props.values.backboard_type}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Backerboard Thickness"
                            name="backboard_thickness"
                          >
                            <Input
                              placeholder="Backerboard Thickness"
                              className="formControl"
                              onChange={props.handleChange(
                                "backboard_thickness"
                              )}
                              defaultValue={props.values.backboard_thickness}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Backerboard Fastener Type"
                            name="backboard_fastener_type"
                          >
                            <Input
                              placeholder="Backerboard Fastener Type"
                              className="formControl"
                              onChange={props.handleChange(
                                "backboard_fastener_type"
                              )}
                              defaultValue={
                                props.values.backboard_fastener_type
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Joint Tape Used on Backerboard"
                            name="tile_join_tape"
                          >
                            <Input
                              placeholder="Joint Tape Used on Backerboard"
                              className="formControl"
                              onChange={props.handleChange("tile_join_tape")}
                              defaultValue={props.values.tile_join_tape}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Shade on Box" name="tile_shade">
                            <Input
                              placeholder="Shade on Box"
                              className="formControl"
                              onChange={props.handleChange("tile_shade")}
                              defaultValue={props.values.tile_shade}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Caliber on Box" name="tile_caliber">
                            <Input
                              placeholder="Caliber on Box"
                              className="formControl"
                              onChange={props.handleChange("tile_caliber")}
                              defaultValue={props.values.tile_caliber}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Joint Tape Type"
                            name="tile_join_type"
                          >
                            <Input
                              placeholder="Joint Tape Type"
                              className="formControl"
                              onChange={props.handleChange("tile_join_type")}
                              defaultValue={props.values.tile_join_type}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Cleaning Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                    <Form.Item
                        label="Cleaned?"
                        name="cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.cleaned}
                              onToggle={props.handleChange('cleaned')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.cleanedField === true || props.values.cleaned === 'Yes' ? (
                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                  
                          <Form.Item
                            label="Professionally Cleaned?"
                            name="professinally_cleaned"
                                >
                                  {console.warn("tripleToggle")}
                                  <TripleToggle
                                  value={props.values.professinally_cleaned}
                                  onToggle={props.handleChange('professinally_cleaned')}
                                />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.proCleanedField === true ||
                      props.values.professinally_cleaned === 'Yes' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Company Name"
                              name="cleaning_company_name"
                            >
                              <Input
                                placeholder="Company Name"
                                className="formControl"
                                onChange={props.handleChange(
                                  "cleaning_company_name"
                                )}
                                defaultValue={
                                  props.values.cleaning_company_name
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Do It Yourself Cleaning"
                            name="do_it_yourself"
                                >
                                  {console.warn("tripleToggle")}
                                  <TripleToggle
                                  value={props.values.do_it_yourself}
                                  onToggle={props.handleChange('do_it_yourself')}
                                />
                            </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="What Cleaning Products Used?"
                            name="clean_product"
                          >
                            <Input
                              placeholder="What Cleaning Products Used?"
                              className="formControl"
                              onChange={props.handleChange("clean_product")}
                              defaultValue={props.values.clean_product}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning Method"
                            name="cleaning_method"
                          >
                            <Input
                              placeholder="Cleaning Method"
                              className="formControl"
                              onChange={props.handleChange("cleaning_method")}
                              defaultValue={props.values.cleaning_method}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="How Often Cleaned?"
                            name="often_cleaned"
                          >
                            <Input
                              placeholder="How Often Cleaned?"
                              className="formControl"
                              onChange={props.handleChange("often_cleaned")}
                              defaultValue={props.values.often_cleaned}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning History and Details"
                            name="cleaning_history"
                          >
                            <Input
                              placeholder="Cleaning History and Details"
                              className="formControl"
                              onChange={props.handleChange("cleaning_history")}
                              defaultValue={props.values.cleaning_history}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default SecondStep;
