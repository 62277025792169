import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function PdfSettingsService(values) {
  let val = {'temp_id': values};
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "change_pdf_template", val)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getpdfTemplate() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_pdf_template')
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err)
          return reject(err);
        });
    });
}