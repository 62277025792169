import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Row,
  Col,
  Card,
  Divider,
  Switch,Skeleton,Empty
} from "antd";
import {  SearchOutlined } from "@ant-design/icons";

import * as DealerRequest from "../../services/DealerRequest";
import { IconButton } from "../../components/Buttons/buttons";
import { EyeFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import * as Clientsrvices from "../../services/AllClients";
// import SkeltonTable from "../../pages/InspectionReport/SkeltonTable";

const Dealers = () => {
  var dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dealerlisting, setDealerlisting] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchedText, setSearchedText] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    fetchDealerListing();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchDealerListing = (params = {}) => {
    setLoading(true);
    dispatch(DealerRequest.getInspectionDealerListingData())
      .then((res) => {
        console.warn('setDealerlisting', res);
        setDealerlisting(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const reportCheck = (event, id) => {
    console.warn("record", event, id);
    let status = event;
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      confirmButtonText: `Yes`,
      showDenyButton: true,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (status === true) {
          setLoading(true);
          let vals = {
            id: id,
            val: status,
          };
          dispatch(Clientsrvices.disable_client(vals))
            .then((res) => {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Enabled!",
                text: "Client has been enabled.",
                showLoaderOnConfirm: true,
              });
            })
            .catch((err) => {
              console.info("Get Error Edit State...");
              setLoading(false);
            });
        } else {
          setLoading(true);
          let vals = {
            id: id,
            val: status,
          };
          dispatch(Clientsrvices.disable_client(vals))
            .then((res) => {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Disabled!",
                text: "Client has been disabled.",
                showLoaderOnConfirm: true,
              });
            })
            .catch((err) => {
              console.info("Get Error Edit State...");
              setLoading(false);
            });
        }
      } else if (result.isDenied) {
        if (event === true) {
          Swal.fire({
            icon: "warning",
            text: "Client Not Disabled!",
            showLoaderOnConfirm: true,
          });
        } else {
          Swal.fire({
            icon: "warning",
            text: "Client Not Enabled.",
            showLoaderOnConfirm: true,
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Dealer ID.",
      dataIndex: "user_id",
      key: "Dealer_ID",
      sortDirections: ['descend'],
      sorter: {
        compare: (a, b) => a.user_id - b.user_id,
      },


    },
    {
      title: "Dealer Name",
      dataIndex: "fullname",
      key: "Dealer_Name",
      sortDirections: ['descend'],
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.fullname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
            String(record.user_id)
              .toLowerCase()
              .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => {
        a = a.fullname || "";
        b = b.fullname || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Completed Inspections",
      dataIndex: "req_count",
      key: "Completed_Inspections",
      sorter: (a, b) => {
        a = a.req_count || "";
        b = b.req_count || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Status",
      dataIndex: "user_status",
      key: "status",
      render: (e, record) => {
        return (
          <div className="actionBtn">
            <Switch
              checkedChildren="ENABLE"
              unCheckedChildren="DISABLE"
              onChange={(event) => reportCheck(event, record.user_id)}
              defaultChecked={e === '1' ? false : true}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (row, record) => {
        // console.log({ row });
        return (
          <div className="actionBtn">
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              tooltip="View"
              text="View"
              handleClick={() => navigate("/single-dealer/?user_id=" + record.user_id)}
            />
          </div>
        );
      },
    },
  ];

  return (<><Col md={6} xs={24}>
    <div className="searchWrapper mb-4">
      <Input
        className="formControl"
        prefix={<SearchOutlined />}
        allowClear
        placeholder="Search..."
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
      />
    </div>
  </Col>
    <Card className="reportCard">
      <Row gutter={16}>
        <Col>
          <div className="d-flex align-Items-center">
            <p className="tableHeaderText">Dealers Listing</p>
          </div>
          <div>
            <p className="paragSubtitle">{dealerlisting?.length} Dealer</p>
          </div>
        </Col>
      </Row>

      <Divider />
      <Table
        bordered
        className="clientsDataTable"
        tableLayout="auto"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={dealerlisting}
        locale={{
          emptyText: loading ? columns.map(u => <Skeleton.Input height={50} style={{marginTop: '2px',height:'48px',padding:'0px'}} block={true}active={true} />): <Empty />
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
          position: ["bottomCenter"],
        }}
      //   loading={{
      //     indicator: <SkeltonTable />,
      //    spinning: loading,
      //  }}
      />
    </Card>
    </>
  );
};

export default Dealers;
