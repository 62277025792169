import axios from "axios";
import {  DatePicker, Upload, Select,Spin, Modal, message } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as NwfaVerifyService from "../../services/NwfaVerifyService";
import { MEDIA_UPLOAD_URL } from "../../Helper";
import moment from 'moment';
import { useDispatch } from "react-redux";


const Repeater = (props) => {
  var dispatch = useDispatch();
  const DateFormat = 'MM-DD-YYYY';
  const OPTIONS = [
    {
      key: 1,
      value: 'IICRC Certified',
      label: <>
        <div className='row select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/IICRC-Logo.png" alt="not found" />
          </div>
          <div className='col-11'>
            IICRC Certified
          </div>
        </div>
      </>
    },
    {
      key: 2,
      value: 'IFCII',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/IFCII-Logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            IFCII
          </div>
        </div>
      </>
    },
    {
      key: 3,
      value: 'FCITS',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/09/FCITS-Logo.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            FCITS
          </div>
        </div>
      </>
    },
    {
      key: 4,
      value: 'NWFA',
      disabled: props.nwfaCertified !== "1" ? true : false,
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2022/01/NWFA.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            NWFA Inspector
          </div>
        </div>
      </>
    },
    {
      key: 5,
      value: 'NWFA Wood Flooring',
      disabled: props.nwfaCertified !== "1" ? true : false,
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/NWFA-Logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            NWFA Wood Flooring
          </div>
        </div>
      </>
    },
    {
      key: 6,
      value: 'NOFMA',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/NOFMA-Logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            NOFMA
          </div>
        </div>
      </>
    },
    {
      key: 7,
      value: 'CFIU',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/CFIU_Logo-1-1.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            CFI University
          </div>
        </div>
      </>
    },
    {
      key: 8,
      value: 'Stone Forensics',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/stone-forensics-logo.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            Stone Forensics
          </div>
        </div>
      </>
    },
    {
      key: 9,
      value: 'NICFI',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/NICFI-Logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            NICFI
          </div>
        </div>
      </>
    },
    {
      key: 10,
      value: 'NFIC',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/NFIC-Logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            NFIC
          </div>
        </div>
      </>
    },
    {
      key: 11,
      value: 'CTEF',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/08/CTEF-Logo.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            CTEF
          </div>
        </div>
      </>
    },
    {
      key: 12,
      value: 'Inspector Training Services',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2022/03/25f32954-46ff-43c1-ba33-9ef95541dc96.gif" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            Inspector Training Services
          </div>
        </div>
      </>
    },
    {
      key: 13,
      value: 'WoolSafe',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2021/10/WoolSafe-logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            WoolSafe
          </div>
        </div>
      </>
    },
    {
      key: 14,
      value: 'NALFA',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://www.floordetective.com/wp-content/uploads/2022/03/NALFA-Logo.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            NALFA
          </div>
        </div>
      </>
    },
    {
      key: 15,
      value: 'ICRI',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/03/04012715/image-30.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            ICRI
          </div>
        </div>
      </>
    },
    {
      key: 16,
      value: 'Nafct',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/03/27020003/nafct_logo.png" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            Nafct
          </div>
        </div>
      </>
    },
    {
      key: 17,
      value: 'CFI',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/03/04021626/cfi-image65e575487546a65e57548754f9.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            CFI
          </div>
        </div>
      </>
    },
    {
      key: 18,
      value: 'CTI',
      label: <>
        <div className='row  select-dropdown-row'>
          <div className='col-1'>
            <img className='Certification-icon-img' src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/03/04021638/CTILogo_FINAL_Color65e575547bc4765e575547bcd4.jpg" width="20" height="20" alt="not found" />
          </div>
          <div className='col-11'>
            CTI
          </div>
        </div>
      </>
    },
  ];

  const uploadImage = async (options, index) => {
    const { onSuccess, onError, file } = options;
    const updatedItems = [...props.items];
      updatedItems[index].loading = true; // Assuming API returns the file URL
      props.setItems(updatedItems);
    const fmData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess(res.data.id);
      updatedItems[index].file = res.data.source_url; // Assuming API returns the file URL
      updatedItems[index].file_id = res.data.id;
      updatedItems[index].loading = false;
      props.setItems(updatedItems);
    } catch (err) {
      console.error('Error: ', err);
      onError(err);
    }
  };

  const handleDateChange = (index, date) => {
    let expDate;
    if(date){
       expDate = moment(date).format('MM-DD-YYYY');
    }else{
      expDate = undefined;
    }
     
    const updatedItems = [...props.items];
    updatedItems[index].date = expDate;
    props.setItems(updatedItems);
  };
  const handleCertChange = (index, value) => {
    const updatedItems = [...props.items];
    updatedItems[index].certification_authority = value;
    props.setItems(updatedItems);
    if((value === "NWFA Wood Flooring" || value === "NWFA") && props.nwfaCertified !=='1'){
      showVerifyPopup(updatedItems); 
    }
  };

  const showVerifyPopup = (values) => {
    Modal.info({
      title: 'NWFA Certification will be verified by the administrator before it can be added to your listing.',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>This ensures the credibility and authenticity of the certification.</p>
        </div>
      ),
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(resolve, 1000);
          dispatch(NwfaVerifyService.sendVerfyEmail(values))
            .then((res) => {
              message.success("Verification request sent to admin!");
            })
            .catch((err) => {
              console.log('Oops errors!');
            });
        });
      },
    });
  };

  const handleAddItem = () => {
    props.setItems([...props.items, { file: null, certification_authority: '', date: new Date() }]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...props.items];
    updatedItems.splice(index, 1);
    props.setItems(updatedItems);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <UploadOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <div>
      {props.items.map((item, index) => (
        <div className='file-Repeater row' key={index}>
          <div className='col-md-3 mb-3'>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(options) => uploadImage(options, index)}
            >
              {item.file ? (
                  item.file.toLowerCase().endsWith('.pdf') ? (
                    <img
                      src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/03/13062734/PDF_file_icon.svg_65f17f92c093265f17f92c09d7.png"
                      alt="PDF icon"
                      style={{ width: "100%", height: "70px", objectFit: "contain" }}
                    />
                  ) : item.file.toLowerCase().endsWith('.docx') || item.file.toLowerCase().endsWith('.doc') ? (
                    <img
                      src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/03/13062726/824298865f17f8c0ccdf65f17f8c0cd69.png"
                      alt="DOCX/DOC icon"
                      style={{ width: "100%", height: "70px", objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      src={item.file}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  )
                ) : (
                  item.loading ? <Spin /> : uploadButton
                )}
            </Upload>
          </div>
          <div className='col-md-4 mb-3'>
            <Select
              placeholder="Select Certification"
              value={item.certification_authority}
              onChange={(value) => handleCertChange(index, value)}
              style={{ width: '100%' }}
              options={OPTIONS.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
            />
          </div>
          <div className='col-md-3 mb-3'>
            <DatePicker format={DateFormat} value={item.date !== "" && item.date !== null  ? moment(item.date) : undefined} onChange={(date) => handleDateChange(index, date)} />
          </div>
          <div className='col-md-2 mb-3'>
            <button type='button' className='remove-btn' onClick={() => handleRemoveItem(index)}>Remove</button>
            {props.nwfaCertified !== "1" && (item.certification_authority === "NWFA Wood Flooring" || item.certification_authority === "NWFA")  ? "(Pending Review)" : ""}
          </div>
          
        </div>
      ))}
      <button type='button' className='add-item-rep' onClick={handleAddItem}>Add Item</button>
    </div>
  );
};

export default Repeater;
