import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Collapse,
  Input,
  Card,
  Form,
  DatePicker,
  Button,
  Cascader,
  Select,
  Space,
  Checkbox,
  Divider,
  Empty,
  Spin,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import * as getClientbyIdService from '../../../services/getClientbyID';
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import { useDispatch, useSelector } from 'react-redux';

const { TextArea } = Input;
const { Panel } = Collapse;

const FirstStep = (props) => {
  const [form] = Form.useForm();
  var dispatch = useDispatch();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [loading, setLoading] = useState(true);
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenBtn, setIsOpenBtn] = useState(true);
  const [commissioningData, setCommissioningData] = useState({});
  const [manufacturerData, setManufacturerData] = useState({});
  const [InstallerData, setInstallerData] = useState({});
  const [dealerData, setDealerData] = useState({});
  const [generalData, setGeneralData] = useState({});

  const getCommissioningData = (e) => {
    let value = props.values;
    let newval = {
      name: value.company_name_qr,
      end_email: value.comm_email,
      Home_street: value.street_name_number,
      Home_city: value.city,
      Home_stateProvince: value.state,
      Home_country: value.country,
      //end_address: value.city,
      h_eu_zip: value.zip_code,
      h_eu_contact: value.agent_claim_name,
      eu_phone1: value.phone_no_party,
    };
    if (e.target.checked) {
    setCommissioningData(newval);
    
    props.values.h_eu_name = newval.name;
    props.values.end_email = newval.end_email;
    props.values.h_eu_street = newval.Home_street;
    props.values.h_eu_city = newval.Home_city;
    props.values.h_eu_stateProvince = newval.Home_stateProvince;
    props.values.h_eu_country = newval.Home_country;
    //props.values.end_address = newval.end_address;
    props.values.h_eu_zip = newval.h_eu_zip;
    props.values.h_eu_contact = newval.h_eu_contact;
    props.values.eu_phone1 = newval.eu_phone1;
    props.values.commishomeowner = true;
    }else{
      setCommissioningData({});
      props.values.h_eu_name = "";
      props.values.end_email = "";
      props.values.h_eu_street = "";
      props.values.h_eu_city = "";
      props.values.h_eu_stateProvince = "";
      props.values.h_eu_country = "";
      props.values.h_eu_zip = "";
      props.values.h_eu_contact = "";
      props.values.eu_phone1 = "";
      props.values.commishomeowner = false;
    }
  };
  const getInstallerData = (e) => {
    let installeValue = props.values;
    let InstallerVal = {
      InstallerName: installeValue.company_name_qr,
      InstallerEmail: installeValue.comm_email,
      InstallerStreetNameNumber: installeValue.street_name_number,
      InstallerCity: installeValue.city,
      InstallerState: installeValue.state,
      installerCountry: installeValue.country,
      InstallerZip: installeValue.zip_code,
      InstallerContact: installeValue.agent_claim_name,
      InstallerPhone: installeValue.phone_no_party,
    };
    if (e.target.checked) {
    setInstallerData(InstallerVal);
    props.values.installer_name = InstallerVal.InstallerName;
    props.values.installer_email = InstallerVal.InstallerEmail;
    props.values.installer_street_name_number = InstallerVal.InstallerStreetNameNumber;
    props.values.installer_city = InstallerVal.InstallerCity;
    props.values.installer_phone = InstallerVal.InstallerPhone;
    props.values.installer_state = InstallerVal.InstallerState;
    props.values.installer_country = InstallerVal.installerCountry;
    props.values.installer_zip = InstallerVal.InstallerZip;
    props.values.installer_contact = InstallerVal.InstallerContact;
    props.values.commisInstaller = true;
    }else{
      setInstallerData({});
      props.values.installer_name = "";
      props.values.installer_email = "";
      props.values.installer_street_name_number = "";
      props.values.installer_city = "";
      props.values.installer_phone = "";
      props.values.installer_state = "";
      props.values.installer_country = "";
      props.values.installer_zip = "";
      props.values.installer_contact = "";
      props.values.commisInstaller = false;

    }
  };
  const getMenufacturerData = (e) => {
    let ischecked = e.target.checked;
    if (ischecked) {
      let manufacturerValue = props.values;
      let manuVal = {
        manuName: manufacturerValue.company_name_qr,
        manuEmail: manufacturerValue.comm_email,
        manuStreet: manufacturerValue.street_name_number,
        manuCity: manufacturerValue.city,
        manuState: manufacturerValue.state,
        manuCountry: manufacturerValue.country,
        manuZip: manufacturerValue.zip_code,
        manuContact: manufacturerValue.agent_claim_name,
        manuPhone: manufacturerValue.phone_no_party,
      };
      setManufacturerData(manuVal);
      console.info('MenufacturerData', manuVal);
      props.values.manf_name = manuVal.manuName;
      props.values.manf_contact = manuVal.manuContact;
      props.values.manf_street_name_number = manuVal.manuStreet;
      props.values.manf_city = manuVal.manuCity;
      props.values.manf_state = manuVal.manuState;
      props.values.manf_country = manuVal.manuCountry;
      props.values.manf_zip = manuVal.manuZip;
      props.values.manf_phone = manuVal.manuPhone;
      props.values.manf_email = manuVal.manuEmail;
      props.values.manufiscomm = true;
    } else {
      let manuVal = {
        manuName: '',
        manuEmail: '',
        manuStreet: '',
        manuCity: '',
        manuState: '',
        manuCountry: '',
        manuZip: '',
        manuContact: '',
        manuPhone: '',
      };
      setManufacturerData(manuVal);
      console.info('MenufacturerData', manuVal);
      props.values.manf_name = manuVal.manuName;
      props.values.manf_contact = manuVal.manuContact;
      props.values.manf_street_name_number = manuVal.manuStreet;
      props.values.manf_city = manuVal.manuCity;
      props.values.manf_state = manuVal.manuState;
      props.values.manf_country = manuVal.manuCountry;
      props.values.manf_zip = manuVal.manuZip;
      props.values.manf_phone = manuVal.manuPhone;
      props.values.manf_email = manuVal.manuEmail;
      props.values.manufiscomm = true;
    }
  };

  const getDealerData = (e) => {
    let dealerValue = props.values;
    let dealVal = {
      dealerName: dealerValue.company_name_qr,
      dealerEmail: dealerValue.comm_email,
      dealerStreet: dealerValue.street_name_number,
      dealerCity: dealerValue.city,
      dealerState: dealerValue.state,
      dealerCountry: dealerValue.country,
      dealerZip: dealerValue.zip_code,
      dealerContact: dealerValue.agent_claim_name,
      dealerPhone: dealerValue.phone_no_party,
    };
    if (e.target.checked) {
      setDealerData(dealVal);
      props.values.dealer_name = dealVal.dealerName;
      props.values.dealer_contact = dealVal.dealerContact;
      props.values.dealer_street_name_number = dealVal.dealerStreet;
      props.values.dealer_city = dealVal.dealerCity;
      props.values.dealer_state = dealVal.dealerState;
      props.values.dealer_country = dealVal.dealerCountry;
      props.values.dealer_zip = dealVal.dealerZip;
      props.values.dealer_phone = dealVal.dealerPhone;
      props.values.dealer_email = dealVal.dealerEmail;
      props.values.commisdealer = true;
    }else{
      setDealerData({});
      props.values.dealer_name = "";
      props.values.dealer_contact = "";
      props.values.dealer_street_name_number = "";
      props.values.dealer_city = "";
      props.values.dealer_state = "";
      props.values.dealer_country = "";
      props.values.dealer_zip = "";
      props.values.dealer_phone = "";
      props.values.dealer_email = "";
      props.values.commisdealer = false;
    }

  };
  const getGeneralData = (e) => {
    let generalValue = props.values;
    let generVal = {
      generalName: generalValue.company_name_qr,
      generalEmail: generalValue.comm_email,
      generalStreet: generalValue.street_name_number,
      generalCity: generalValue.city,
      generalState: generalValue.state,
      generalCountry: generalValue.country,
      generalZip: generalValue.zip_code,
      generalContact: generalValue.agent_claim_name,
      generalPhone: generalValue.phone_no_party,
    };
    if(e.target.checked){
      setGeneralData(generVal);
      props.values.gc_name = generVal.generalName;
      props.values.gc_contact_name = generVal.generalContact;
      props.values.gc_contac_street = generVal.generalStreet;
      props.values.gc_contac_city = generVal.generalCity;
      props.values.gc_contac_state_province = generVal.generalState;
      props.values.gc_contac_country = generVal.generalCountry;
      props.values.gc_zip = generVal.generalZip;
      props.values.gc_phone = generVal.generalPhone;
      props.values.gc_email = generVal.generalEmail;
      props.values.commisGeneral = true;
    }else{
      setGeneralData({});
      props.values.gc_name = "";
      props.values.gc_contact_name = "";
      props.values.gc_contac_street = "";
      props.values.gc_contac_city = "";
      props.values.gc_contac_state_province = "";
      props.values.gc_contac_country = "";
      props.values.gc_zip = "";
      props.values.gc_phone = "";
      props.values.gc_email = "";
      props.values.commisGeneral = false;

    }
   
  };

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const timeampm = [
    {
      value: 'AM',
      label: 'AM',
    },
    {
      value: 'PM',
      label: 'PM',
    },
  ];

  const options = [
    {
      value: 'sq/ft',
      label: 'sq/ft',
    },
    {
      value: 'sq/yds',
      label: 'sq/yds',
    },
    {
      value: 'boxes',
      label: 'boxes',
    },
  ];

  const { Option } = Select;

  const DateFormat = 'MM-DD-YYYY';

  const getClientbyId = (key) => {
    setIsOpenBtn(false);
    props.values.client_select_drop = key;
    dispatch(getClientbyIdService.getClientbyId(key))
      .then((res) => {
        form.setFieldsValue({
          company_name_qr: res.company_name,
          agent_claim_name: res.name,
          comm_email: res.email,
          street_name_number: res.street,
          city: res.city,
          state: res.states,
          country: res.country,
          zip_code: res.zip_code,
          phone_no_party: res.phone_personal,
        });
        props.handleFill({
          company_name_qr: res.company_name,
          agent_claim_name: res.name,
          comm_email: res.email,
          street_name_number: res.street,
          city: res.city,
          state: res.states,
          country: res.country,
          zip_code: res.zip_code,
          phone_no_party: res.phone_personal,
        });
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const getManufbyId = (key) => {
    dispatch(getClientbyIdService.getManufbyId(key))
      .then((res) => {
        console.warn('resmanuf', res.manf_name);
        form.setFieldsValue({
          manf_name: res.manf_name,
          manf_contact: res.manf_contact,
          manf_street_name_number: res.manf_street_name_number,
          manf_country: res.manf_country,
          manf_state: res.manf_state,
          manf_city: res.manf_city,
          manf_address: res.manf_address,
          manf_zip: res.manf_zip,
          manf_phone_pre: res.manf_phone_pre,
          manf_phone: res.manf_phone,
          manf_email: res.manf_email,
        });
        props.handleFill({
          manf_name: res.manf_name,
          manf_contact: res.manf_contact,
          manf_street_name_number: res.manf_street_name_number,
          manf_country: res.manf_country,
          manf_state: res.manf_state,
          manf_city: res.manf_city,
          manf_address: res.manf_address,
          manf_zip: res.manf_zip,
          manf_phone_pre: res.manf_phone_pre,
          manf_phone: res.manf_phone,
          manf_email: res.manf_email,
        });
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const getDealerbyId = (key) => {
    dispatch(getClientbyIdService.getDealerbyId(key))
      .then((res) => {
        form.setFieldsValue({
          dealer_name: res.dealer_name,
          dealer_contact: res.dealer_contact,
          dealer_street_name_number: res.dealer_street_name_number,
          dealer_country: res.dealer_country,
          dealer_state: res.dealer_state,
          dealer_city: res.dealer_city,
          dealer_address: res.dealer_address,
          dealer_zip: res.dealer_zip,
          dealer_phone: res.dealer_phone,
          dealer_email: res.dealer_email,
        });
        props.handleFill({
          dealer_name: res.dealer_name,
          dealer_contact: res.dealer_contact,
          dealer_street_name_number: res.dealer_street_name_number,
          dealer_country: res.dealer_country,
          dealer_state: res.dealer_state,
          dealer_city: res.dealer_city,
          dealer_address: res.dealer_address,
          dealer_zip: res.dealer_zip,
          dealer_phone: res.dealer_phone,
          dealer_email: res.dealer_email,
        });
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const addClient = () => {
    setIsOpenClient(false);
    setIsOpenBtn(true);
    form.setFieldsValue({
      company_name_qr: '',
      client_select_drop: '',
      comm_party_drop: '',
      agent_claim_name: '',
      comm_email: '',
      street_name_number: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      phone_no_party: '',
    });
  };

  const hideClient = () => {
    setIsOpenBtn(false);
  };

  useEffect(() => {
    console.warn('data', Data, loading);
    setLoading(true);
    setLoading(false);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.loading ? (
        <div className="spinDiv fullHeight">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          name="report"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Card className="reportCard">
            <div className="reportCardBody">
              <div className="formSection">
                <div className="inspectionSection">
                  <div className="inspecHead">
                    <h4 className="formGroupTitle">Inspection Details</h4>
                  </div>
                  <div className="inspecContent">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Claim Number" name="claim_num">
                            <Input
                              placeholder="Claim Number"
                              className="formControl"
                              onChange={props.handleChange('claim_num')}
                              defaultValue={props.values.claim_num}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Inspection Date"
                            name="h_bi_ins_date"
                          >
                            <Input.Group compact className="mt-0">
                              <Input
                                placeholder="Inspection Date"
                                className="formControl"
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'h_bi_ins_date',
                                    'text'
                                  )
                                }
                                value={props.values.h_bi_ins_date}
                                onFocus={() => props.setCalOpen(!props.calOpen)}
                                onKeyUp={() => props.setCalOpen(false)}
                                onBlur={() => props.setCalOpen(false)}
                              />

                              <Button
                                className="btnAddOn"
                                icon={<CalendarOutlined />}
                                onClick={() => props.setCalOpen(!props.calOpen)}
                              />
                            </Input.Group>
                            <DatePicker
                              className="formControl dateHide"
                              format={DateFormat}
                              open={props.calOpen}
                              style={{ visibility: 'hidden', width: 0 }}
                              onChange={(e) =>
                                props.handleDateformate(
                                  e,
                                  'h_bi_ins_date',
                                  'date'
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup custom-form-group">
                          <Form.Item label="Inspection Time" name="ins_time">
                            <Input
                              className="formGroup customPadding"
                              addonAfter={
                                <Cascader
                                  placeholder="AM"
                                  onChange={props.handleChange('time_type')}
                                  options={timeampm}
                                  className="custom-cascadar"
                                  defaultValue={props.values.time_type}
                                />
                              }
                              onChange={props.handleChange('ins_time')}
                              defaultValue={props.values.ins_time}
                              placeholder="12:00"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Inspection Type"
                            name="inspection_type"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.inspection_type
                                  ? props.values.inspection_type
                                  : 'Select Inspection Type'
                              }
                              onChange={props.handleChange('inspection_type')}
                            >
                              <Option value="Residential Inspection">
                                Residential Inspection
                              </Option>
                              <Option value="Commercial Inspection">
                                Commercial Inspection
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="CollapseSection">
                  <Collapse
                    defaultActiveKey={
                      props.values.stepOneTogg
                        ? props.values.stepOneTogg
                        : ['1', '2', '3', '4', '5', '6', '7', '8']
                    }
                    onChange={props.handleChange('stepOneTogg')}
                    className="reportCollapse"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusOutlined className="custom-icon" />
                      ) : (
                        <PlusOutlined className="custom-icon" />
                      )
                    }
                    ghost
                  >
                    <Panel header="Commissioning Party Information" key="1">
                      <Row gutter={16}>
                        {props.values?.client_type ===
                        'Flooring Manufacturer' ? (
                          ''
                        ) : (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Select Client"
                                name="client_select_drop"
                              >
                                <Select
                                  showSearch
                                  defaultValue="Select Client"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    //option.children.includes(input)
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                     
                                  }
                                  onChange={getClientbyId}
                                  open={isOpenClient}
                                  onDropdownVisibleChange={(visible) =>
                                    setIsOpenClient(visible)
                                  }
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <Divider
                                        style={{
                                          margin: '0',
                                        }}
                                      />
                                      <Space className="renderBtn">
                                        <Button
                                          type="text"
                                          icon={<PlusOutlined />}
                                          onClick={() => addClient()}
                                          block
                                        >
                                          Add New Client
                                        </Button>
                                      </Space>
                                    </>
                                  )}
                                >
                                  {props.clientDropdown ? (
                                    props.clientDropdown.map((option, id) => {
                                      return (
                                        <Option value={option.id} key={id}>
                                          {option.name}
                                        </Option>
                                      );
                                    })
                                  ) : (
                                    <Option value="No data">
                                      <Empty />
                                    </Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Commissioning Party"
                              name="comm_party_drop"
                            >
                              <Select
                                defaultValue={
                                  props.values.comm_party_drop
                                    ? props.values.comm_party_drop
                                    : 'Commissioning Party'
                                }
                                onChange={props.handleChange('comm_party_drop')}
                              >
                                <Option value="Manufacturer">
                                  Manufacturer
                                </Option>
                                <Option value="Dealer">Dealer</Option>
                                <Option value="Distributor">Distributor</Option>
                                <Option value="Homeowner">Homeowner</Option>
                                <Option value="Attorney">Attorney</Option>
                                <Option value="Builder">Builder</Option>
                                <Option value="Insurance">
                                  Insurance company
                                </Option>
                                <Option value="Installer">Installer</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.commPartyopen === true ||
                        props.values.comm_party_drop === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="comm_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange('comm_other')}
                                  defaultValue={props.values.comm_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Company Name"
                              name="company_name_qr"
                            >
                              <Input
                                placeholder="Company Name"
                                className="formControl"
                                onChange={props.handleChange('company_name_qr')}
                                defaultValue={props.values.company_name_qr}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Full Name"
                              name="agent_claim_name"
                            >
                              <Input
                                placeholder="Full Name"
                                className="formControl"
                                onChange={props.handleChange(
                                  'agent_claim_name'
                                )}
                                defaultValue={props.values.agent_claim_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Email Address" name="comm_email">
                              <Input
                                placeholder="example@mail.com"
                                className="formControl"
                                onChange={props.handleChange('comm_email')}
                                defaultValue={props.values.comm_email}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Street Name/Number"
                              name="street_name_number"
                            >
                              <Input
                                placeholder="Street Name/Number"
                                className="formControl"
                                onChange={props.handleChange(
                                  'street_name_number'
                                )}
                                defaultValue={props.values.street_name_number}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="City" name="city">
                              <Input
                                placeholder="City"
                                className="formControl"
                                onChange={props.handleChange('city')}
                                defaultValue={props.values.city}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="State/Province" name="state">
                              <Input
                                placeholder="State/Province"
                                className="formControl"
                                onChange={props.handleChange('state')}
                                defaultValue={props.values.state}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Country" name="country">
                              <Input
                                placeholder="Country"
                                className="formControl"
                                onChange={props.handleChange('country')}
                                defaultValue={props.values.country}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Zip Code/Postal Code"
                              name="zip_code"
                            >
                              <Input
                                placeholder="Zip Code/Postal Code"
                                className="formControl"
                                onChange={props.handleChange('zip_code')}
                                defaultValue={props.values.zip_code}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Phone Number"
                              name="phone_no_party"
                            >
                              <Input
                                placeholder="(000)00000"
                                className="formControl"
                                onChange={props.handleChange('phone_no_party')}
                                defaultValue={props.values.phone_no_party}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {isOpenBtn ? (
                          <Col
                            xs={24}
                            md={6}
                            xl={6}
                            className="d-flex justify-content-start align-items-center"
                          >
                            <div className="formGroup">
                              <Button
                                className="btnSecondary me-2"
                                onClick={props.addClient}
                              >
                                Add Client
                              </Button>
                              <Button
                                className="btnSecondary me-2"
                                onClick={() => hideClient()}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Col>
                        ) : (
                          ''
                        )}
                      </Row>
                    </Panel>
                    <Panel header="Homeowner/End-User Information" key="2">
                      <Row gutter={16}>
                        <div className="m-2">
                          <Form.Item name="commishomeowner">
                            <Checkbox
                                onChange={getCommissioningData}
                              defaultChecked={props.values.commishomeowner}
                            >
                              Check this box if Homeowner is Commissioning Party
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Name"
                              name="h_eu_name"
                              valuePropName={commissioningData?.name}
                            >
                              <Input
                                placeholder="Homeowner Name"
                                className="formControl"
                                onChange={props.handleChange('h_eu_name')}
                                value={commissioningData?.name}
                                defaultValue={props.values.h_eu_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Street Name/Number"
                              name="h_eu_street"
                              valuePropName={commissioningData?.Home_street}
                            >
                              <Input
                                placeholder="Street Name/Number"
                                className="formControl"
                                onChange={props.handleChange('h_eu_street')}
                                value={commissioningData?.Home_street}
                                defaultValue={props.values.h_eu_street}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="City "
                              name="h_eu_city"
                              valuePropName={commissioningData?.Home_city}
                            >
                              <Input
                                placeholder="City"
                                className="formControl"
                                onChange={props.handleChange('h_eu_city')}
                                value={commissioningData?.Home_city}
                                defaultValue={props.values.h_eu_city}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="State/Province "
                              name="h_eu_stateProvince"
                              valuePropName={
                                commissioningData?.Home_stateProvince
                              }
                            >
                              <Input
                                placeholder="State/Province"
                                className="formControl"
                                onChange={props.handleChange(
                                  'h_eu_stateProvince'
                                )}
                                value={commissioningData?.Home_stateProvince}
                                defaultValue={props.values.h_eu_stateProvince}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Country"
                              name="h_eu_country"
                              valuePropName={commissioningData?.Home_country}
                            >
                              <Input
                                placeholder="Country"
                                className="formControl"
                                onChange={props.handleChange('h_eu_country')}
                                defaultValue={props.values.h_eu_country}
                                value={commissioningData?.Home_country}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {/* <Col xs={24} md={12} xl={12}>
                          <div className="formGroup">
                            <Form.Item label="Address" name="end_address" valuePropName={commissioningData?.end_address}>
                              <Input
                                placeholder="Address"
                                className="formControl"
                                onChange={props.handleChange("end_address")}
                                value={commissioningData?.end_address}
                                defaultValue={props.values.end_address}
                              />
                            </Form.Item>
                          </div>
                        </Col> */}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Zip Code/Postal Code"
                              name="h_eu_zip"
                              valuePropName={commissioningData?.h_eu_zip}
                            >
                              <Input
                                placeholder="Zip Code/Postal Code"
                                className="formControl"
                                onChange={props.handleChange('h_eu_zip')}
                                value={commissioningData?.h_eu_zip}
                                defaultValue={props.values.h_eu_zip}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Contact"
                              name="h_eu_contact"
                              valuePropName={commissioningData?.h_eu_contact}
                            >
                              <Input
                                placeholder="Contact"
                                className="formControl"
                                onChange={props.handleChange('h_eu_contact')}
                                value={commissioningData?.h_eu_contact}
                                defaultValue={props.values.h_eu_contact}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Phone Number"
                              name="eu_phone1"
                              valuePropName={commissioningData?.eu_phone1}
                            >
                              <Input
                                placeholder="Phone Number"
                                className="formControl"
                                onChange={props.handleChange('eu_phone1')}
                                value={commissioningData?.eu_phone1}
                                defaultValue={props.values.eu_phone1}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Mobile Number"
                              name="end_mobile"
                              valuePropName={commissioningData?.end_mobile}
                            >
                              <Input
                                placeholder="Mobile Number"
                                className="formControl"
                                onChange={props.handleChange('end_mobile')}
                                value={commissioningData?.end_mobile}
                                defaultValue={props.values.end_mobile}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Email Address"
                              name="end_email"
                              valuePropName={commissioningData?.end_email}
                            >
                              <Input
                                placeholder="email@mail.com"
                                className="formControl"
                                onChange={props.handleChange('end_email')}
                                value={commissioningData?.end_email}
                                defaultValue={props.values.end_email}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Manufacturers Contact Information" key="3">
                      <Row gutter={16}>
                        <div className="m-2">
                          <Form.Item name="manufiscomm">
                            <Checkbox
                              onChange={(e) => getMenufacturerData(e)}
                              defaultChecked={props.values.manufiscomm}
                            >
                              Check this box if Manufacturer is Commissioning
                              Party
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Select Manufacturer"
                              name="manuf_resuse_drop"
                              valuePropName
                            >
                              <Select
                              showSearch
                                defaultValue="Select Manufacturer"
                                // onChange={props.handleChange("manuf_resuse_drop")}

                                filterOption={(input, option) =>
                                  //option.children.includes(input)
                                  option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                   
                                }
                                onChange={getManufbyId}
                              >
                                {props.manufDropdown ? (
                                  props.manufDropdown.map((option, id) => {
                                    return (
                                      <Option value={option.id} key={id}>
                                        {option.name}
                                      </Option>
                                    );
                                  })
                                ) : (
                                  <Option value="No data">
                                    <Empty />
                                  </Option>
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Manufacturers name"
                              name="manf_name"
                              valuePropName={manufacturerData?.manuName}
                            >
                              <Input
                                placeholder="Manufacturers Name"
                                className="formControl"
                                onChange={props.handleChange('manf_name')}
                                value={manufacturerData?.manuName}
                                defaultValue={props.values.manf_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Contact Name"
                              name="manf_contact"
                              valuePropName={manufacturerData?.manuContact}
                            >
                              <Input
                                placeholder="Contact Name"
                                className="formControl"
                                onChange={props.handleChange('manf_contact')}
                                value={manufacturerData?.manuContact}
                                defaultValue={props.values.manf_contact}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Street Name/Number"
                              name="manf_street_name_number"
                              valuePropName={manufacturerData?.manuStreet}
                            >
                              <Input
                                placeholder="Street Name/Number"
                                className="formControl"
                                onChange={props.handleChange(
                                  'manf_street_name_number'
                                )}
                                value={manufacturerData?.manuStreet}
                                defaultValue={
                                  props.values.manf_street_name_number
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="City"
                              name="manf_city"
                              valuePropName={manufacturerData?.manuCity}
                            >
                              {/* {console.log()} */}
                              <Input
                                placeholder="City"
                                className="formControl"
                                onChange={props.handleChange('manf_city')}
                                value={manufacturerData?.manuCity}
                                defaultValue={props.values.manf_city}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="State/Province"
                              name="manf_state"
                              valuePropName={manufacturerData?.manuState}
                            >
                              <Input
                                placeholder="State/Province"
                                className="formControl"
                                onChange={props.handleChange('manf_state')}
                                value={manufacturerData?.manuState}
                                defaultValue={props.values.manf_state}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Country"
                              name="manf_country"
                              valuePropName={manufacturerData?.manuCountry}
                            >
                              <Input
                                placeholder="Country"
                                className="formControl"
                                onChange={props.handleChange('manf_country')}
                                value={manufacturerData?.manuCountry}
                                defaultValue={props.values.manf_country}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Zip Code/Postal Code"
                              name="manf_zip"
                              valuePropName={manufacturerData?.manuZip}
                            >
                              <Input
                                placeholder="Zip Code/Postal Code"
                                className="formControl"
                                onChange={props.handleChange('manf_zip')}
                                value={manufacturerData?.manuZip}
                                defaultValue={props.values.manf_zip}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Phone Number"
                              name="manf_phone"
                              valuePropName={manufacturerData?.manuPhone}
                            >
                              <Input
                                placeholder="(000)00000"
                                className="formControl"
                                onChange={props.handleChange('manf_phone')}
                                value={manufacturerData?.manuPhone}
                                defaultValue={props.values.manf_phone}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Email Address"
                              name="manf_email"
                              valuePropName={manufacturerData?.manuEmail}
                            >
                              <Input
                                placeholder="example@mail.com"
                                className="formControl"
                                onChange={props.handleChange('manf_email')}
                                value={manufacturerData?.manuEmail}
                                defaultValue={props.values.manf_email}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Dealer Contact Information" key="4">
                      <Row gutter={16}>
                        <div className="m-2">
                          <Form.Item name="commisdealer">
                            <Checkbox
                              onChange={getDealerData}
                              defaultChecked={props.values.commisdealer}
                            >
                              <p>
                                Check this box if Dealer is Commissioning Party
                              </p>
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Select Dealer"
                              name="dealer_resuse_drop"
                              valuePropName
                            >
                              <Select
                                defaultValue="Select "
                                showSearch
                                // onChange={props.handleChange("dealer_resuse_drop")}
                                filterOption={(input, option) =>
                                  //option.children.includes(input)
                                  option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                   
                                }
                                onChange={getDealerbyId}
                              >
                                {props.dealerDropdown ? (
                                  props.dealerDropdown.map((option, id) => {
                                    return (
                                      <Option value={option.id} key={id}>
                                        {option.name}
                                      </Option>
                                    );
                                  })
                                ) : (
                                  <Option value="No data">
                                    <Empty />
                                  </Option>
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Dealer Name"
                              name="dealer_name"
                              valuePropName={dealerData?.dealerName}
                            >
                              <Input
                                placeholder="Dealer Name"
                                className="formControl"
                                onChange={props.handleChange('dealer_name')}
                                value={dealerData?.dealerName}
                                defaultValue={props.values.dealer_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Contact Name"
                              name="dealer_contact"
                              valuePropName={dealerData?.dealerContact}
                            >
                              <Input
                                placeholder="Contact Name"
                                className="formControl"
                                onChange={props.handleChange('dealer_contact')}
                                value={dealerData?.dealerContact}
                                defaultValue={props.values.dealer_contact}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Street Name/Number"
                              name="dealer_street_name_number"
                              valuePropName={dealerData?.dealerStreet}
                            >
                              <Input
                                placeholder="Street Name/Number"
                                className="formControl"
                                onChange={props.handleChange(
                                  'dealer_street_name_number'
                                )}
                                value={dealerData?.dealerStreet}
                                defaultValue={
                                  props.values.dealer_street_name_number
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="City"
                              name="dealer_city"
                              valuePropName={dealerData?.dealerCity}
                            >
                              <Input
                                placeholder="City"
                                className="formControl"
                                onChange={props.handleChange('dealer_city')}
                                value={dealerData?.dealerCity}
                                defaultValue={props.values.dealer_city}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="State/Province"
                              name="dealer_state"
                              valuePropName={dealerData?.dealerState}
                            >
                              <Input
                                placeholder="State/Province"
                                className="formControl"
                                onChange={props.handleChange('dealer_state')}
                                value={dealerData?.dealerState}
                                defaultValue={props.values.dealer_state}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Country"
                              name="dealer_country"
                              valuePropName={dealerData?.dealerCountry}
                            >
                              <Input
                                placeholder="Country"
                                className="formControl"
                                onChange={props.handleChange('dealer_country')}
                                value={dealerData?.dealerCountry}
                                defaultValue={props.values.dealer_country}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Zip Code/Postal Code"
                              name="dealer_zip"
                              valuePropName={dealerData?.dealerZip}
                            >
                              <Input
                                placeholder="Zip Code/Postal Code"
                                className="formControl"
                                onChange={props.handleChange('dealer_zip')}
                                value={dealerData?.dealerZip}
                                defaultValue={props.values.dealer_zip}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Phone Number"
                              name="dealer_phone"
                              valuePropName={dealerData?.dealerPhone}
                            >
                              <Input
                                placeholder="(000)00000"
                                className="formControl"
                                onChange={props.handleChange('dealer_phone')}
                                value={dealerData?.dealerPhone}
                                defaultValue={props.values.dealer_phone}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Email Address"
                              name="dealer_email"
                              valuePropName={dealerData?.dealerEmail}
                            >
                              <Input
                                placeholder="example@mail.com"
                                className="formControl"
                                onChange={props.handleChange('dealer_email')}
                                value={dealerData?.dealerEmail}
                                defaultValue={props.values.dealer_email}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>      
                    <Panel
                        header="Installer Information"  key="8"
                        // className="custum-installer-panel"
                      >
                      <Row gutter={16}>
                        <div className="m-2">
                          <Form.Item name="commisInstaller">
                            <Checkbox
                                onChange={getInstallerData}
                              defaultChecked={props.values.commisInstaller}
                            >
                              <p>
                                Check this box if Installer is Commissioning
                                Party
                              </p>
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Installer Name"
                              name="installer_name"
                              valuePropName={InstallerData?.InstallerName}
                            >
                              <Input
                                placeholder="Installer Name"
                                className="formControl"
                                onChange={props.handleChange('installer_name')}
                                value={InstallerData?.InstallerName}
                                defaultValue={props.values.installer_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Contact Name"
                              name="installer_contact"
                              valuePropName={InstallerData?.InstallerContact}
                            >
                              <Input
                                placeholder="Contact Name"
                                className="formControl"
                                onChange={props.handleChange(
                                  'installer_contact'
                                )}
                                value={InstallerData?.InstallerContact}
                                defaultValue={props.values.installer_contact}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Street Name/Number"
                              name="installer_street_name_number"
                              valuePropName={
                                InstallerData?.InstallerStreetNameNumber
                              }
                            >
                              <Input
                                placeholder="Street Name/Number"
                                className="formControl"
                                onChange={props.handleChange(
                                  'installer_street_name_number'
                                )}
                                value={InstallerData?.InstallerStreetNameNumber}
                                defaultValue={
                                  props.values.installer_street_name_number
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="City"
                              name="installer_city"
                              valuePropName={InstallerData?.InstallerCity}
                            >
                              <Input
                                placeholder="City"
                                className="formControl"
                                onChange={props.handleChange('installer_city')}
                                value={InstallerData?.InstallerCity}
                                defaultValue={props.values.installer_city}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="State/Province"
                              name="installer_state"
                              valuePropName={InstallerData?.InstallerState}
                            >
                              <Input
                                placeholder="State/Province"
                                className="formControl"
                                onChange={props.handleChange('installer_state')}
                                value={InstallerData?.InstallerState}
                                defaultValue={props.values.installer_state}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Country"
                              name="installer_country"
                              valuePropName={InstallerData?.installerCountry}
                            >
                              <Input
                                placeholder="Country"
                                className="formControl"
                                onChange={props.handleChange(
                                  'installer_country'
                                )}
                                value={InstallerData?.installerCountry}
                                defaultValue={props.values.installer_country}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Zip Code/Postal Code"
                              name="installer_zip"
                              valuePropName={InstallerData?.InstallerZip}
                            >
                              <Input
                                placeholder="Zip Code/Postal Code"
                                className="formControl"
                                onChange={props.handleChange('installer_zip')}
                                value={InstallerData?.InstallerZip}
                                defaultValue={props.values.installer_zip}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Phone Number"
                              name="installer_phone"
                              valuePropName={InstallerData?.InstallerPhone}
                            >
                              <Input
                                placeholder="Phone Number"
                                className="formControl"
                                onChange={props.handleChange('installer_phone')}
                                defaultValue={props.values.installer_phone}
                                value={InstallerData?.InstallerPhone}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Email"
                              name="installer_email"
                              valuePropName={InstallerData?.InstallerEmail}
                            >
                              <Input
                                placeholder="Email"
                                className="formControl"
                                onChange={props.handleChange('installer_email')}
                                defaultValue={props.values.installer_email}
                                value={InstallerData?.InstallerEmail}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <div className="m-2">
                          
                          <Form.Item
                              name="installer_present"
                              label="Was Installer Present"
                          >

                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.installer_present}
                              onToggle={props.handleChange('installer_present')}
                            />
                          </Form.Item>
                        </div>
                      </Row>
                    </Panel>
                    <Panel header="General Contractor/Builder" key="5">
                      <Row gutter={16}>
                        <div className="m-2">
                          <Form.Item name="commisGeneral">
                            <Checkbox
                                onChange={getGeneralData}
                              defaultChecked={props.values.commisGeneral}
                            >
                              Check this box if General Contractor/Builder is
                              Commissioning Party
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="General Contractor Name"
                              name="gc_name"
                              valuePropName={generalData?.generalName}
                            >
                              <Input
                                placeholder="General Contractor Name"
                                className="formControl"
                                onChange={props.handleChange('gc_name')}
                                defaultValue={props.values.gc_name}
                                value={generalData?.generalName}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Contact Name"
                              name="gc_contact_name"
                              valuePropName={generalData?.generalContact}
                            >
                              <Input
                                placeholder="Contact Name"
                                className="formControl"
                                onChange={props.handleChange('gc_contact_name')}
                                defaultValue={props.values.gc_contact_name}
                                value={generalData?.generalContact}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Street Name/Number"
                              name="gc_contac_street"
                              valuePropName={generalData?.generalStreet}
                            >
                              <Input
                                placeholder="Street Name/Number"
                                className="formControl"
                                onChange={props.handleChange(
                                  'gc_contac_street'
                                )}
                                value={generalData?.generalStreet}
                                defaultValue={props.values.gc_contac_street}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="City "
                              name="gc_contac_city"
                              valuePropName={generalData?.generalCity}
                            >
                              <Input
                                placeholder="City"
                                className="formControl"
                                onChange={props.handleChange('gc_contac_city')}
                                value={generalData?.generalCity}
                                defaultValue={props.values.gc_contac_city}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="State/Province"
                              name="gc_contac_state_province"
                              valuePropName={generalData?.generalState}
                            >
                              <Input
                                placeholder="State/Province"
                                className="formControl"
                                onChange={props.handleChange(
                                  'gc_contac_state_province'
                                )}
                                value={generalData?.generalState}
                                defaultValue={
                                  props.values.gc_contac_state_province
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Country"
                              name="gc_contac_country"
                              valuePropName={generalData?.generalCountry}
                            >
                              <Input
                                placeholder="Country"
                                className="formControl"
                                onChange={props.handleChange(
                                  'gc_contac_country'
                                )}
                                defaultValue={props.values.gc_contac_country}
                                value={generalData?.generalCountry}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {/* <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Address" name="gc_address">
                              <Input
                                className="formControl"
                                placeholder="Address"
                                onChange={props.handleChange("gc_address")}
                                defaultValue={props.values.gc_address}
                              />
                            </Form.Item>
                          </div>
                        </Col> */}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Zip Code/Postal Code"
                              name="gc_zip"
                              valuePropName={generalData?.generalZip}
                            >
                              <Input
                                placeholder="Zip Code/Postal Code"
                                className="formControl"
                                onChange={props.handleChange('gc_zip')}
                                defaultValue={props.values.gc_zip}
                                value={generalData?.generalZip}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Phone Number"
                              name="gc_phone"
                              valuePropName={generalData?.generalPhone}
                            >
                              <Input
                                placeholder="Phone Number"
                                className="formControl"
                                onChange={props.handleChange('gc_phone')}
                                defaultValue={props.values.gc_phone}
                                value={generalData?.generalPhone}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Email"
                              name="gc_email"
                              valuePropName={generalData?.generalEmail}
                            >
                              <Input
                                placeholder="Email"
                                className="formControl"
                                onChange={props.handleChange('gc_email')}
                                defaultValue={props.values.gc_email}
                                value={generalData?.generalEmail}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col
                          xs={24}
                          md={6}
                          xl={6}
                          className="appendDiv formGroup"
                        >
                          <label htmlFor="gc_subdivision" className="pb-2">
                            Sub Division
                          </label>
                          {props.subDivision.map((singlesubDivision, index) => (
                            <div key={index} className="services mb-2">
                              <div className="first-division">
                                <Input.Group compact>
                                  <input
                                    name="gc_subdivision"
                                    type="text"
                                    id="gc_subdivision"
                                    value={singlesubDivision.gc_subdivision}
                                    onChange={(e) =>
                                      props.handleServiceChange(e, index)
                                    }
                                    placeholder="Sub Division"
                                    className="formControl repeatorDivHide"
                                  />
                                  {props.subDivision.length - 1 === index &&
                                    props.subDivision.length < 4 && (
                                      <Button
                                        type="button"
                                        onClick={props.handleServiceAdd}
                                        className="formControl repeatorDivHideBtn"
                                        icon={<PlusOutlined />}
                                      />
                                    )}
                                  {props.subDivision.length !== 1 && (
                                    <Button
                                      type="button"
                                      icon={<MinusOutlined />}
                                      onClick={() =>
                                        props.handleServiceRemove(index)
                                      }
                                      className="formControl repeatorDivHideBtn"
                                    />
                                  )}
                                </Input.Group>
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col xs={24} md={6} xl={6} className="appendDiv">
                          <label htmlFor="gc_lotnumber" className="pb-2">
                            Lot Number
                          </label>
                          {props.lotNumber.map((singleLotNumber, index) => (
                            <div key={index} className="services mb-2">
                              <div className="first-division">
                                <Input.Group compact>
                                  <input
                                    name="gc_lotnumber"
                                    type="text"
                                    id="gc_lotnumber"
                                    value={singleLotNumber.gc_lotnumber}
                                    onChange={(e) =>
                                      props.handleLotChange(e, index)
                                    }
                                    placeholder="Lot Number"
                                    className="formControl repeatorDivHide"
                                  />
                                  {props.lotNumber.length - 1 === index &&
                                    props.lotNumber.length < 4 && (
                                      <Button
                                        type="button"
                                        onClick={props.handleLotAdd}
                                        className="formControl repeatorDivHideBtn"
                                        icon={<PlusOutlined />}
                                      />
                                    )}
                                  {props.lotNumber.length !== 1 && (
                                    <Button
                                      type="button"
                                      icon={<MinusOutlined />}
                                      onClick={() =>
                                        props.handleLotRemove(index)
                                      }
                                      className="formControl repeatorDivHideBtn"
                                    />
                                  )}
                                </Input.Group>
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Product and Claim Information" key="6">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date of Installation"
                              name="product_date_of_ins"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date of Installation"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      'product_date_of_ins',
                                      'text'
                                    )
                                  }
                                  value={props.values.product_date_of_ins}
                                  onFocus={() =>
                                    props.setCalOpen1(!props.calOpen1)
                                  }
                                  onKeyUp={() => props.setCalOpen1(false)}
                                  onBlur={() => props.setCalOpen1(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen1(!props.calOpen1)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen1}
                                style={{ visibility: 'hidden', width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'product_date_of_ins',
                                    'date'
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Problem Noticed"
                              name="date_problem_noticed"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Problem Noticed"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      'date_problem_noticed',
                                      'text'
                                    )
                                  }
                                  value={props.values.date_problem_noticed}
                                  onFocus={() =>
                                    props.setCalOpen2(!props.calOpen2)
                                  }
                                  onKeyUp={() => props.setCalOpen2(false)}
                                  onBlur={() => props.setCalOpen2(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen2(!props.calOpen2)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen2}
                                style={{ visibility: 'hidden', width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'date_problem_noticed',
                                    'date'
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Reported To" name="reported_to">
                              <Input
                                placeholder="Reported To"
                                className="formControl"
                                onChange={props.handleChange('reported_to')}
                                defaultValue={props.values.reported_to}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Manufacturer Name"
                              name="product_manf"
                            >
                              <Input
                                placeholder="Manufacturer Name"
                                className="formControl"
                                onChange={props.handleChange('product_manf')}
                                defaultValue={props.values.product_manf}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Previous Floor Covering"
                              name="previous_floor_covering"
                            >
                              <Input
                                placeholder="Previous Floor Covering"
                                className="formControl"
                                onChange={props.handleChange(
                                  'previous_floor_covering'
                                )}
                                defaultValue={
                                  props.values.previous_floor_covering
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Installation Type"
                              name="car_i2_inst_type"
                            >
                              <Select
                                defaultValue={
                                  props.values.car_i2_inst_type
                                    ? props.values.car_i2_inst_type
                                    : 'Select Installation Type'
                                }
                                onChange={props.handleChange(
                                  'car_i2_inst_type'
                                )}
                              >
                                <Option value="Stretch in">Stretch In</Option>
                                <Option value="Glue down">Glue Down</Option>
                                <Option value="Double stick">
                                  Double Stick
                                </Option>
                                <Option value="Loose lay">Loose Lay</Option>
                                <Option value="N/A">N/A</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {/* {showhide1 === true ? ( */}
                        {props.isOpen.installationOther === true ||
                        props.values.car_i2_inst_type === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="car_i2_other_inst_mthd"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'car_i2_other_inst_mthd'
                                  )}
                                  defaultValue={
                                    props.values.car_i2_other_inst_mthd
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}

                        {props.isOpen.installationGlue === true ||
                        props.values.car_i2_inst_type === 'Glue down' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                {/* <Form.Item
                                  label="Was the Floor Rolled ?"
                                  name="floor_rolled_toggle"
                                >
                                  <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    defaultChecked={
                                      props.values.floor_rolled_toggle === true
                                        ? true
                                        : false
                                    }
                                    onChange={props.handleChange(
                                      'floor_rolled_toggle'
                                    )}
                                  />
                                </Form.Item> */}
                                <Form.Item
                                    label="Was the Floor Rolled ?"
                                    name="floor_rolled_toggle"
                                  >
                                  {console.warn("tripleToggle")}
                                  <TripleToggle
                                    value={props.values.floor_rolled_toggle}
                                    onToggle={props.handleChange('floor_rolled_toggle')}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Adhesive Name/Number"
                                  name="adhesive_name_number"
                                >
                                  <Input
                                    placeholder="Adhesive Name/Number"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      'adhesive_name_number'
                                    )}
                                    defaultValue={
                                      props.values.adhesive_name_number
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Trowel Used-Size/Dimension"
                                  name="trowel_used_size_dimension"
                                >
                                  <Input
                                    placeholder="Trowel Used-Size/Dimension"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      'trowel_used_size_dimension'
                                    )}
                                    defaultValue={
                                      props.values.trowel_used_size_dimension
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Roller Weight LBS"
                                  name="roller_weight_lbs"
                                >
                                  <Input
                                    placeholder="Roller Weight LBS"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      'roller_weight_lbs'
                                    )}
                                    defaultValue={
                                      props.values.roller_weight_lbs
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : null}

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Style Name/Number"
                              name="style_name"
                            >
                              <Input
                                placeholder="Style Name/Number"
                                className="formControl"
                                onChange={props.handleChange('style_name')}
                                defaultValue={props.values.style_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Color Name/Number"
                              name="product_color"
                            >
                              <Input
                                placeholder="Brown, Wooden"
                                className="formControl"
                                onChange={props.handleChange('product_color')}
                                defaultValue={props.values.product_color}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Areas/Rooms Installed"
                              name="area_room_ins"
                            >
                              <Input
                                placeholder="Areas/Rooms Installed"
                                className="formControl"
                                onChange={props.handleChange('area_room_ins')}
                                defaultValue={props.values.area_room_ins}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Total Claim Quantity"
                              name="total_claim_qt"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    defaultValue={props.values.measure_type1}
                                    onChange={props.handleChange(
                                      'measure_type1'
                                    )}
                                    options={options}
                                    className="custom-cascadar"
                                  />
                                }
                                // defaultValue="10 Sq/Ft"
                                onChange={props.handleChange('total_claim_qt')}
                                defaultValue={props.values.total_claim_qt}
                                placeholder="10 sq/ft"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Total Affected"
                              name="total_affected"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    defaultValue={props.values.measure_type2}
                                    onChange={props.handleChange(
                                      'measure_type2'
                                    )}
                                    options={options}
                                    className="customCascadar"
                                  />
                                }
                                // defaultValue="10 Sq/Ft"
                                placeholder="10 sq/ft"
                                onChange={props.handleChange('total_affected')}
                                defaultValue={props.values.total_affected}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Invoice Number/PO Number"
                              name="product_invoice"
                            >
                              <Input
                                placeholder="#01234567"
                                className="formControl"
                                onChange={props.handleChange('product_invoice')}
                                defaultValue={props.values.product_invoice}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Cushion Type"
                              name="cushion_dropdown"
                            >
                              <Select
                                defaultValue={
                                  props.values.cushion_dropdown
                                    ? props.values.cushion_dropdown
                                    : 'Select Cushion Type'
                                }
                                onChange={props.handleChange(
                                  'cushion_dropdown'
                                )}
                              >
                                <Option value="Rebond">Rebond</Option>
                                <Option value="Prime Polyurethane">
                                  Prime Polyurethane
                                </Option>
                                <Option value="Polyolefin Foam">
                                  Polyolefin Foam
                                </Option>
                                <Option value="Fiber">Fiber</Option>
                                <Option value="Fiber Cushion">
                                  Fiber Cushion
                                </Option>
                                <Option value="Rubber">Rubber Cushion</Option>
                                <Option value="Frothed Polyurethane">
                                  Frothed Polyurethane
                                </Option>
                                <Option value="AttachedCushion">
                                  Attached Cushion
                                </Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>

                        {props.isOpen.cushionOther === true ||
                        props.values.cushion_dropdown === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="cushion_type_other"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'cushion_type_other'
                                  )}
                                  defaultValue={props.values.cushion_type_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Fiber Type" name="fiber_type">
                              <Select
                                defaultValue={
                                  props.values.fiber_type
                                    ? props.values.fiber_type
                                    : 'Select Fiber Type'
                                }
                                onChange={props.handleChange('fiber_type')}
                              >
                                <Option value="Nylon">Nylon</Option>
                                <Option value="Polyester">Polyester</Option>
                                <Option value="Triexta">Triexta</Option>
                                <Option value="Wool">Wool</Option>
                                <Option value="Polypropylene">
                                  Polypropylene
                                </Option>
                                <Option value="Cotton">Cotton</Option>
                                <Option value="Rayon">Rayon</Option>
                                <Option value="Viscose">Viscose</Option>
                                <Option value="Smartstrand">Smartstrand</Option>
                                <Option value="N/A">N/A</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.fiberOther === true ||
                        props.values.fiber_type === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="fiber_type_other"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'fiber_type_other'
                                  )}
                                  defaultValue={props.values.fiber_type_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={12} xl={12}>
                          <div className="formGroup">
                            <Form.Item
                              label="Individual(s) Present During the Inspection"
                              name="new_individuals"
                            >
                              <Input
                                placeholder="Individual(s) Present"
                                className="formControl"
                                onChange={props.handleChange('new_individuals')}
                                defaultValue={props.values.new_individuals}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                              label="Affected Area Connected To Balance"
                              name="balance_toggle"
                            >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.balance_toggle}
                              onToggle={props.handleChange('balance_toggle')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Flooring Installed"
                              name="flooring_installed_toggle"
                            >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.flooring_installed_toggle}
                              onToggle={props.handleChange('flooring_installed_toggle')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={12} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Statement of Concern"
                              name="concerns"
                            >
                              <TextArea
                                placeholder="Statement of Concern"
                                className="customTextarea"
                                onChange={props.handleChange('concerns')}
                                defaultValue={props.values.concerns}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel
                      header="Site Description and Occupancy Information"
                      key="7"
                    >
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Property Type"
                              name="property_dropdown"
                            >
                              <Select
                                defaultValue={
                                  props.values.property_dropdown
                                    ? props.values.property_dropdown
                                    : 'Select Property Type'
                                }
                                onChange={props.handleChange(
                                  'property_dropdown'
                                )}
                              >
                                <Option value="Residential">Residential</Option>
                                <Option value="Commercial">Commercial</Option>
                                <Option value="Rental Unit">Rental Unit</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Building Type"
                              name="building_type"
                            >
                              <Select
                                defaultValue={
                                  props.values.building_type
                                    ? props.values.building_type
                                    : 'Select Building Type'
                                }
                                onChange={props.handleChange('building_type')}
                              >
                                <Option value="Single Story">
                                  Single Story
                                </Option>
                                <Option value="Multi Story">Multi Story</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.buildingOther === true ||
                        props.values.building_type === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="build_type_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'build_type_other'
                                  )}
                                  defaultValue={props.values.build_type_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Building Style"
                              name="building_style"
                            >
                              <Select
                                defaultValue={
                                  props.values.building_style
                                    ? props.values.building_style
                                    : 'Select Building Style'
                                }
                                onChange={props.handleChange('building_style')}
                              >
                                <Option value="Single Family">
                                  Single Family
                                </Option>
                                <Option value="Apartment">Apartment</Option>
                                <Option value="Condominium">Condominium</Option>
                                <Option value="Church">Church</Option>
                                <Option value="Common Areas">
                                  Common Areas
                                </Option>
                                <Option value="Dental"> Dental</Option>
                                <Option value="Exterior">Exterior</Option>
                                <Option value="Educational Facility">
                                  Educational Facility
                                </Option>
                                <Option value="Golf Course">Golf Course</Option>
                                <Option value="Group Home">Group Home</Option>
                                <Option value="Hospital">Hospital</Option>
                                <Option value="Hospitality">
                                  {' '}
                                  Hospitality
                                </Option>
                                <Option value="Manufactured Home">
                                  Manufactured Home
                                </Option>
                                <Option value="Medical">Medical </Option>
                                <Option value="Offices">Offices</Option>
                                <Option value="Office Building">
                                  Office Building
                                </Option>
                                <Option value="Retail Store">
                                  Retail Store
                                </Option>
                                <Option value="Veterinary">Veterinary</Option>
                                <Option value="Watercraft">Watercraft</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.buildingStyleOther === true ||
                        props.values.building_style === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="other_building_style"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'other_building_style'
                                  )}
                                  defaultValue={
                                    props.values.other_building_style
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Traffic Type" name="traffic_type">
                              <Select
                                defaultValue={
                                  props.values.traffic_type
                                    ? props.values.traffic_type
                                    : 'Select Traffic Type'
                                }
                                onChange={props.handleChange('traffic_type')}
                              >
                                <Option value="Owner Occupied">
                                  Owner Occupied
                                </Option>
                                <Option value="Rental">Rental</Option>
                                <Option value="Seasonal Residents">
                                  Seasonal Residents
                                </Option>
                                <Option value="Unoccupied">Unoccupied</Option>
                                <Option value="Light Commercial Traffic">
                                  Light Commercial Traffic
                                </Option>
                                <Option value="Moderate Commercial Traffic">
                                  Moderate Commercial Traffic
                                </Option>
                                <Option value="Heavy Commercial Traffic">
                                  Heavy Commercial Traffic
                                </Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.trafficTypeOther === true ||
                        props.values.traffic_type === 'Other' ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="other_trafic">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange('other_trafic')}
                                  defaultValue={props.values.other_trafic}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Home/Building Fully Constructed"
                              name="date_home"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Home/Building Fully Constructed"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      'date_home',
                                      'text'
                                    )
                                  }
                                  value={props.values.date_home}
                                  onFocus={() =>
                                    props.setCalOpen7(!props.calOpen7)
                                  }
                                  onKeyUp={() => props.setCalOpen7(false)}
                                  onBlur={() => props.setCalOpen7(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen7(!props.calOpen7)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen7}
                                style={{ visibility: 'hidden', width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'date_home',
                                    'date'
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="HVAC Fully Operational/Functional"
                              name="date_hvac"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="HVAC Fully Operational/Functional"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      'date_hvac',
                                      'text'
                                    )
                                  }
                                  value={props.values.date_hvac}
                                  onFocus={() =>
                                    props.setCalOpen8(!props.calOpen8)
                                  }
                                  onKeyUp={() => props.setCalOpen8(false)}
                                  onBlur={() => props.setCalOpen8(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen8(!props.calOpen8)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen8}
                                style={{ visibility: 'hidden', width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'date_hvac',
                                    'date'
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Occupied Date"
                              name="site_occupied_date"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Occupied Date"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      'site_occupied_date',
                                      'text'
                                    )
                                  }
                                  value={props.values.site_occupied_date}
                                  onFocus={() =>
                                    props.setCalOpen9(!props.calOpen9)
                                  }
                                  onKeyUp={() => props.setCalOpen9(false)}
                                  onBlur={() => props.setCalOpen9(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen9(!props.calOpen9)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen9}
                                style={{ visibility: 'hidden', width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'site_occupied_date',
                                    'date'
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Building Age" name="building_age">
                              <Input
                                placeholder="10 Yrs"
                                className="formControl"
                                onChange={props.handleChange('building_age')}
                                defaultValue={props.values.building_age}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                            label="New Construction?"
                            name="h_bui_cons"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.h_bui_cons}
                              onToggle={props.handleChange('h_bui_cons')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.constructionField === true ||
                          props.values.h_bui_cons === 'Yes' ? (
                            <div>
                              <Row gutter={16}>
                                <Col xs={24} md={24} xl={24}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Closing Date"
                                      name="closing_date"
                                    >
                                      <Input.Group compact className="mt-0">
                                        <Input
                                          placeholder="Closing Date"
                                          className="formControl"
                                          onChange={(e) =>
                                            props.handleDateformate(
                                              e,
                                              'closing_date',
                                              'text'
                                            )
                                          }
                                          value={props.values.closing_date}
                                          onFocus={() =>
                                            props.setCalOpen3(!props.calOpen3)
                                          }
                                          onKeyUp={() =>
                                            props.setCalOpen3(false)
                                          }
                                          onBlur={() =>
                                            props.setCalOpen3(false)
                                          }
                                        />

                                        <Button
                                          className="btnAddOn"
                                          icon={<CalendarOutlined />}
                                          onClick={() =>
                                            props.setCalOpen3(!props.calOpen3)
                                          }
                                        />
                                      </Input.Group>
                                      <DatePicker
                                        className="formControl dateHide"
                                        format={DateFormat}
                                        open={props.calOpen3}
                                        style={{
                                          visibility: 'hidden',
                                          width: 0,
                                        }}
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'closing_date',
                                            'date'
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Move in Date"
                                      name="moving_date"
                                    >
                                      <Input.Group compact className="mt-0">
                                        <Input
                                          placeholder="Move in Date"
                                          className="formControl"
                                          onChange={(e) =>
                                            props.handleDateformate(
                                              e,
                                              'moving_date',
                                              'text'
                                            )
                                          }
                                          value={props.values.moving_date}
                                          onFocus={() =>
                                            props.setCalOpen4(!props.calOpen4)
                                          }
                                          onKeyUp={() =>
                                            props.setCalOpen4(false)
                                          }
                                          onBlur={() =>
                                            props.setCalOpen4(false)
                                          }
                                        />

                                        <Button
                                          className="btnAddOn"
                                          icon={<CalendarOutlined />}
                                          onClick={() =>
                                            props.setCalOpen4(!props.calOpen4)
                                          }
                                        />
                                      </Input.Group>
                                      <DatePicker
                                        className="formControl dateHide"
                                        format={DateFormat}
                                        open={props.calOpen4}
                                        style={{
                                          visibility: 'hidden',
                                          width: 0,
                                        }}
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'moving_date',
                                            'date'
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="HVAC Operational Date"
                                      name="operational_date"
                                    >
                                      <Input.Group compact className="mt-0">
                                        <Input
                                          placeholder="HVAC Operational Date"
                                          className="formControl"
                                          onChange={(e) =>
                                            props.handleDateformate(
                                              e,
                                              'operational_date',
                                              'text'
                                            )
                                          }
                                          value={props.values.operational_date}
                                          onFocus={() =>
                                            props.setCalOpen5(!props.calOpen5)
                                          }
                                          onKeyUp={() =>
                                            props.setCalOpen5(false)
                                          }
                                          onBlur={() =>
                                            props.setCalOpen5(false)
                                          }
                                        />

                                        <Button
                                          className="btnAddOn"
                                          icon={<CalendarOutlined />}
                                          onClick={() =>
                                            props.setCalOpen5(!props.calOpen5)
                                          }
                                        />
                                      </Input.Group>
                                      <DatePicker
                                        className="formControl dateHide"
                                        format={DateFormat}
                                        open={props.calOpen5}
                                        style={{
                                          visibility: 'hidden',
                                          width: 0,
                                        }}
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'operational_date',
                                            'date'
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                              label="Walk off Mats"
                              name="h_bui_walk_off"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.h_bui_walk_off}
                              onToggle={props.handleChange('h_bui_walk_off')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.walkField === true ||
                          props.values.h_bui_walk_off === 'Yes' ? (
                            <div>
                              <Row>
                                <Col xs={24} md={24} xl={24}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="All Exterior Building Entrances"
                                      name="all_exterior"
                                    >
                                      <Select
                                        defaultValue={
                                          props.values.all_exterior
                                            ? props.values.all_exterior
                                            : 'Select Exterior'
                                        }
                                        onChange={props.handleChange(
                                          'all_exterior'
                                        )}
                                      >
                                        <Option value="Interior Entrances">
                                          Interior Entrances
                                        </Option>
                                        <Option value="High Traffic Areas">
                                          High Traffic Areas
                                        </Option>
                                        <Option value="Other">Other</Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </Col>
                                {props.isOpen.exteriorOther === true ||
                                props.values.all_exterior === 'Other' ? (
                                  <Col xs={24} md={24} xl={24}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Other"
                                        name="other_exterior"
                                      >
                                        <Input
                                          placeholder="Other"
                                          className="formControl"
                                          onChange={props.handleChange(
                                            'other_exterior'
                                          )}
                                          defaultValue={
                                            props.values.other_exterior
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Building Occupied?"
                              name="h_bui_occupied"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.h_bui_occupied}
                              onToggle={props.handleChange('h_bui_occupied')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.buildField === true ||
                          props.values.h_bui_occupied === 'Yes' ? (
                            <div>
                              <Row>
                                <Col xs={24} md={24} xl={24}>
                                  <div className="formGroup">
                                    <Form.Item label="Adults" name="adults">
                                      <Input
                                        placeholder="Adults"
                                        className="formControl"
                                        onChange={props.handleChange('adults')}
                                        defaultValue={props.values.adults}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Children"
                                      name="childrens"
                                    >
                                      <Input
                                        placeholder="Children"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          'childrens'
                                        )}
                                        defaultValue={props.values.childrens}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="formGroup">
                                    <Form.Item label="Pets" name="pets">
                                      <Input
                                        placeholder="Pets"
                                        className="formControl"
                                        onChange={props.handleChange('pets')}
                                        defaultValue={props.values.pets}
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                      {/* switch end */}
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </Card>
        </Form>
      )}
    </>
  );
};

export default FirstStep;
