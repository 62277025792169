import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getInspectorProfileData(profile_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'get_inspector_profile/'+profile_id )
          .then((res) => {
            //console.warn('InspectorData', res.data)
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function updateInspectorProfile(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + 'update_inspector_profile' , values)
          .then((res) => {
            console.warn('InspectorData', res.data)
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }