// import React from 'react';
import React from 'react';
import { Row, Col, Input, Card, Form, Select} from 'antd';
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
const { Option } = Select;


const SecondStep = (props) => {
  const [form] = Form.useForm();
  console.log('SecondStep', props);

  return (
    <Form name="report" autoComplete="off" layout="vertical" form={form}>
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Cleaning Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                    <Form.Item
                        label="Cleaned?"
                        name="cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.cleaned}
                              onToggle={props.handleChange('cleaned')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.cleanedField === true || props.values.cleaned === 'Yes' ? (
                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                             label="Do It Yourself Cleaning"
                             name="do_it_yourself"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.do_it_yourself}
                              onToggle={props.handleChange('do_it_yourself')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                        <Form.Item
                            label="Professionally Cleaned?"
                            name="professinally_cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.professinally_cleaned}
                              onToggle={props.handleChange('professinally_cleaned')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.proCleanedField === true || props.values.professinally_cleaned === 'Yes' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Company Name"
                              name="cleaning_company_name"
                            >
                              <Input
                                placeholder="Company Name"
                                className="formControl"
                                onChange={props.handleChange(
                                  'cleaning_company_name'
                                )}
                                defaultValue={
                                  props.values.cleaning_company_name
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="What Cleaning Products Used?"
                            name="clean_product"
                          >
                            <Input
                              placeholder="What Cleaning Products Used?"
                              className="formControl"
                              onChange={props.handleChange('clean_product')}
                              defaultValue={props.values.clean_product}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning Method"
                            name="cleaning_method"
                          >
                            <Input
                              placeholder="Cleaning Method"
                              className="formControl"
                              onChange={props.handleChange('cleaning_method')}
                              defaultValue={props.values.cleaning_method}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="How often Cleaned?"
                            name="often_cleaned"
                          >
                            <Input
                              placeholder="How often Cleaned?"
                              className="formControl"
                              onChange={props.handleChange('often_cleaned')}
                              defaultValue={props.values.often_cleaned}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning History and Details"
                            name="cleaning_history"
                          >
                            <Input
                              placeholder="Cleaning History and Details"
                              className="formControl"
                              onChange={props.handleChange('cleaning_history')}
                              defaultValue={props.values.cleaning_history}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Vacuum Brand" name="vacumm_brand">
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.vacumm_brand
                                  ? props.values.vacumm_brand
                                  : 'Vacuum Brand'
                              }
                              onChange={props.handleChange('vacumm_brand')}
                            >
                              <Option value="Vacuum Brand">Vacuum Brand</Option>
                              <Option value="Bissel">Bissel</Option>
                              <Option value="BackPack">BackPack</Option>
                              <Option value="Central">Central</Option>
                              <Option value="Commercial">Commercial</Option>
                              <Option value="Dirt Devil">Dirt Devil</Option>
                              <Option value="Dyson">Dyson</Option>
                              <Option value="Electrolux">Electrolux</Option>
                              <Option value="Eureka">Eureka</Option>
                              <Option value="Hoover">Hoover</Option>
                              <Option value="Kenmore">Kenmore</Option>
                              <Option value="Kirby">Kirby</Option>
                              <Option value="Miele">Miele</Option>
                              <Option value="Oreck">Oreck</Option>
                              <Option value="Panasonic">Panasonic</Option>
                              <Option value="Rainbow">Rainbow</Option>
                              <Option value="Regina">Regina</Option>
                              <Option value="Riccar">Riccar</Option>
                              <Option value="Royal">Royal</Option>
                              <Option value="Shark">Shark</Option>
                              <Option value="Simplicity">Simplicity</Option>
                              <Option value="Windsor">Windsor</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="See Report">See Report</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.vacummBrandOther === true ||
                      props.values.vacumm_brand === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="vacum_brand_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  'vacum_brand_other'
                                )}
                                defaultValue={props.values.vacum_brand_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Vacuum Condition"
                            name="vacumm_condition"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.vacumm_condition
                                  ? props.values.vacumm_condition
                                  : 'Select Vacuum Condition'
                              }
                              onChange={props.handleChange('vacumm_condition')}
                            >
                              <Option value="Average">Average</Option>
                              <Option value="Good">Good</Option>
                              <Option value="Very Good">Very Good</Option>
                              <Option value="Excellent">Excellent</Option>
                              <Option value="Not Working">Not Working</Option>
                              <Option value="Present">Not Present</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="See Report">See Report</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Vacuum Cleaner Brush"
                            name="cleaner_brush"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.cleaner_brush
                                  ? props.values.cleaner_brush
                                  : 'Select Vacuum Cleaner Brush'
                              }
                              onChange={props.handleChange('cleaner_brush')}
                            >
                              <Option value="Vacuum Cleaner Brush">
                                Vacuum Cleaner Brush
                              </Option>
                              <Option value="Soft">Soft</Option>
                              <Option value="Medium Stiff">Medium Stiff</Option>
                              <Option value="Stiff">Stiff</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.vaccumCleanerBrushOther === true ||
                      props.values.cleaner_brush === 'Other' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="cleaner_brush_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  'cleaner_brush_other'
                                )}
                                defaultValue={props.values.cleaner_brush_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={12} xl={12}>
                        <div className="formGroup">
                          <Form.Item
                            label="Is Vacuum Cleaner on CRI Seal of Approve List"
                            name="cri_seal"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.cri_seal
                                  ? props.values.cri_seal
                                  : 'Is Vacuum Cleaner on CRI Seal of Approve List'
                              }
                              onChange={props.handleChange('cri_seal')}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                              <Option value="Unknown">Unknown</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="See Report">See Report</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Vacuum Frequency" name="vacumm">
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.vacumm
                                  ? props.values.vacumm
                                  : 'Vacuum Frequency'
                              }
                              onChange={props.handleChange('vacumm')}
                            >
                              <Option value="1 x Week">1 x Week</Option>
                              <Option value="2 x Week">2 x Week</Option>
                              <Option value="3-4 x Week">3-4 x Week</Option>
                              <Option value="Every Two Weeks">
                                Every Two Weeks
                              </Option>
                              <Option value="Every Three Weeks">
                                Every Three Weeks
                              </Option>
                              <Option value="1 x Per Month">
                                1 x Per Month
                              </Option>
                              <Option value="Daily">Daily</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>{' '}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Spot Cleaning History"
                            name="spot_clean"
                          >
                            <Input
                              placeholder="Spot Cleaning History"
                              className="formControl"
                              onChange={props.handleChange('spot_clean')}
                              defaultValue={props.values.spot_clean}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                             label="Was the Vacuum Cleaner Working Properly"
                             name="vacum_worker"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.vacum_worker}
                              onToggle={props.handleChange('vacum_worker')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={12} xl={12}>
                        <div className="formGroup">
                          <Form.Item
                            label="Was the Vacuum Cleaner Able to Raise the Pile"
                            name="car_cli_was_the"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.car_cli_was_the}
                              onToggle={props.handleChange('car_cli_was_the')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.wasTheVaccumCleaner === true ||
                      props.values.car_cli_was_the === 'Yes' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Percentage Raised"
                              name="percentage_raised"
                            >
                              <Input
                                placeholder="Percentage Raised"
                                className="formControl"
                                onChange={props.handleChange(
                                  'percentage_raised'
                                )}
                                defaultValue={props.values.percentage_raised}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}
                      <Col xs={24} md={12} xl={12}>
                        <div className="formGroup">
                          
                          <Form.Item
                            label="Vacuum Cleaner is on CRI Seal of Approval List"
                            name="vacum_cleaner"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.vacum_cleaner}
                              onToggle={props.handleChange('vacum_cleaner')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={9} xl={9}>
                        <div className="custum-paragraph">
                          <p>
                            Click Link to see CRI Approved List.
                            <a
                              href="https://carpet-rug.org/testing/seal-of-approval-program/certified-vacuums/"
                              target="#"
                              className="mx-1 custpara"
                            >
                              Here
                            </a>
                            is the Link.
                          </p>
                        </div>{' '}
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Field Assessments</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                  
                      <Form.Item
                            label="Field Assessments?"
                            name="field_assessments"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.field_assessments}
                              onToggle={props.handleChange('field_assessments')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                </Row>

                {props.values.field_assessments === 'Yes' ? (
                  <>
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Carpet pH in Affected Area"
                            name="pH_affected_area"
                          >
                            <Input
                              placeholder="Carpet pH in Affected Area"
                              className="formControl"
                              onChange={props.handleChange('pH_affected_area')}
                              defaultValue={props.values.pH_affected_area}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Carpet pH in Unaffected Area"
                            name="pH_unaffected_area"
                          >
                            <Input
                              placeholder="Carpet pH in Unaffected Area"
                              className="formControl"
                              onChange={props.handleChange(
                                'pH_unaffected_area'
                              )}
                              defaultValue={props.values.pH_unaffected_area}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                         
                          <Form.Item
                            label="Power Stretched/Backing Pinholes Elongated"
                            name="evidence_power_stretching"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.evidence_power_stretching}
                              onToggle={props.handleChange('evidence_power_stretching')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                         
                          <Form.Item
                            label="Seams Properly Constructed"
                            name="seam_construction"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.seam_construction}
                              onToggle={props.handleChange('seam_construction')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Seam Edges Sealed"
                            name="seam_edges_sealed"
                          >
                           {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.seam_edges_sealed}
                              onToggle={props.handleChange('seam_edges_sealed')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Transition Edges Sealed"
                            name="transition_edges_sealed"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.transition_edges_sealed}
                              onToggle={props.handleChange('transition_edges_sealed')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                      
                          <Form.Item
                             label="AzoStix/Urine Present"
                             name="azostix_test_results"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.azostix_test_results}
                              onToggle={props.handleChange('azostix_test_results')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                         
                          <Form.Item
                             label="Spots Fluoresce Under UV Light"
                             name="ultra_violet_light_fluorescing"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.ultra_violet_light_fluorescing}
                              onToggle={props.handleChange('ultra_violet_light_fluorescing')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
              
                          <Form.Item
                             label="Spin Finish"
                             name="spin_finish"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.spin_finish}
                              onToggle={props.handleChange('spin_finish')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                            label="Soiling Transfer To Towel"
                            name="soiling_transfer_towel"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.soiling_transfer_towel}
                              onToggle={props.handleChange('soiling_transfer_towel')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Hydrosensor/Moisture Present"
                            name="hygrosensor_result"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.hygrosensor_result}
                              onToggle={props.handleChange('hygrosensor_result')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                             label="Tuft Bind" name="tuft_bind"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.tuft_bind}
                              onToggle={props.handleChange('tuft_bind')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.values.tuft_bind === 'Yes' ? (
                        <>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Tuft Bind Value"
                                name="value_lbs"
                              >
                                <Input
                                  placeholder="LBS Force"
                                  className="formControl"
                                  onChange={props.handleChange('value_lbs')}
                                  defaultValue={props.values.value_lbs}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                              label="Twist Level" name="twist_level"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.twist_level}
                              onToggle={props.handleChange('twist_level')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.values.twist_level === 'Yes' ? (
                        <>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Twist Level Value"
                                name="value_twist_level"
                              >
                                <Input
                                  placeholder="Value"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'value_twist_level'
                                  )}
                                  defaultValue={props.values.value_twist_level}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                               label="Tuft Bloom" name="tuft_bloom"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.tuft_bloom}
                              onToggle={props.handleChange('tuft_bloom')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.values.tuft_bloom === 'Yes' ? (
                        <>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Tuft Bloom Percentage"
                                name="tuft_bloom_percentage"
                              >
                                <Input
                                  placeholder="Percentage"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'tuft_bloom_percentage'
                                  )}
                                  defaultValue={
                                    props.values.tuft_bloom_percentage
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                               label="Latex Encapsulation"
                               name="latex_encapsulation"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.latex_encapsulation}
                              onToggle={props.handleChange('latex_encapsulation')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.values.latex_encapsulation === 'Yes' ? (
                        <>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Latex Encapsulation Percentage"
                                name="latex_encapsulation_percentage"
                              >
                                <Input
                                  placeholder="Percentage"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'latex_encapsulation_percentage'
                                  )}
                                  defaultValue={
                                    props.values.latex_encapsulation_percentage
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default SecondStep;
