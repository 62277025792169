import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { logout } from '../../auth/helpers';
import { useNavigate } from 'react-router-dom';


const LogoutDropdown = ({userDetails}) => {
  let profile_pic = userDetails.profile_pic || null;
  let navigate = useNavigate();
  const handleMenuClick = (e) => {
    if (e.key === 'account') {
      // Handle account option click
      window.location.replace('https://www.floordetective.com/membership-account/');
      console.log('Account clicked');
    } else if (e.key === 'updateProfile') {
      // Handle update profile option click
      navigate('/update-profile');
    } else if (e.key === 'logout') {
      // Handle logout option click
      logout();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item title="account" key="account">Account</Menu.Item>
      <Menu.Item  key="updateProfile">Update Profile</Menu.Item>
      <Menu.Item title="logout" key="logout">Logout</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Avatar 
      icon={<UserOutlined />}
      src={profile_pic ? <img src={profile_pic} alt="avatar" /> : null}
      style={{ cursor: 'pointer',marginLeft: '15px' }}
      />
    </Dropdown>
  );
};

export default LogoutDropdown;
