import React, { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as getViewClientDataService from "../../services/ViewClientService";
import { Row, Col, Card, Divider, Skeleton,List } from "antd";

const SingleViewClient = () => {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState();

  var dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    fetchDealerListing();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const client_id = queryParams.get("client_id");
  const fetchDealerListing = (params = {}) => {
    setLoading(true);
    dispatch(getViewClientDataService.getViewClientData(client_id))
      .then((res) => {
        console.info("viewclientapiData", res);
        setReportData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader
          title="Inspection Request | Professional Report"
          type="secondary"
          text="Edit Form"
          handleClick={() =>
            navigate("/view-client-update/?client_id=" + client_id)
          }
        />
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
          {loading ? (
           <div className="">
           <Row gutter={16} className="mb-1 mt-2 skel_req">
                     <Col xs={24} md={4} xl={4} className="">
                     <Skeleton.Input block={true} active size="small" />
                     </Col>
                     <Col xs={24} md={3} xl={3} className="">
                     </Col>
                     <hr></hr>
                     <Col xs={24} md={17} xl={17} className="">
                      </Col>
                   </Row>
          <br></br>
         <hr/>
         <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
         </Skeleton>
         <br></br>
         <hr/>
         <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
          </Skeleton>
          <br></br>
          <hr/>
          <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
            </Skeleton>
            <br></br>
         <hr/>
         <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
          </Skeleton>
          <br></br>
          <hr/>
          <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
            </Skeleton>
            <br></br>
         <hr/>
         <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
          </Skeleton>
          <br></br>
          <hr/>
          <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
            </Skeleton>
            <br></br>
         <hr/>
         <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
          </Skeleton>
          <br></br>
          <hr/>
          <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
            </Skeleton>
            <br></br>
         <hr/>
         <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
          </Skeleton>
          <br></br>
          <hr/>
          <br></br>
         <Skeleton loading={loading} active >
           <List.Item.Meta
            />
            </Skeleton>
         </div>
          ) : (
            <Card className="reportCard">
              <div className="reportCardBody">
                <div className="formSection">
                  <div className="inspectionSection">
                    <div className="inspecDetailHead">
                      <h5 className="inspcTitle">View Client Details</h5>
                    </div>
                    <Divider />
                    <div className="inspectorDetails">
                      <div className="inovicecard">
                        <div className="inspecContent">
                          <div className="inspectionDetails">
                              <Divider orientation="left" plain>
                                Company Information
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  {reportData?.company_name ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Company Name</label>
                                        <p>{reportData?.company_name}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.website ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Website</label>
                                        <p>{reportData?.website}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.email ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Email</label>
                                        <p>{reportData?.email}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.phone ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Phone</label>
                                        <p>{reportData?.phone}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.company_type_ ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Company Type</label>
                                        <p>{reportData?.company_type_}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </div>
                            </div>
                            </div>
                           </div>
                      <div className="inovicecard">
                      <div className="inspecContent">
                         <div className="inspectionDetails">
                            <Divider orientation="left" plain>
                              Mailing and Shipping Address
                            </Divider>
                            <div className="reportData">
                              <Row gutter={16}>
                              {reportData?.street ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Street</label>
                                      <p>{reportData?.street}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.po_box ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>PO Box</label>
                                      <p>{reportData?.po_box}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                               {reportData?.country ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Country</label>
                                      <p>{reportData?.country}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {reportData?.city ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>City</label>
                                      <p>{reportData?.city}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.states ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>State</label>
                                      <p>{reportData?.states}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.zip_code ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Zip Code</label>
                                      <p>{reportData?.zip_code}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div> 
                          </div>
                       </div>
                      <div className="inovicecard">
                        <div className="inspecContent">
                          <div className="inspectionDetails">
                            <Divider orientation="left" plain>
                              Login Information
                            </Divider>
                            <div className="reportData">
                              <Row gutter={16}>
                                {reportData?.user_name ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Username</label>
                                      <p>{reportData?.user_name}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.first_name ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>First Name</label>
                                      <p>{reportData?.first_name}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.last_name ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Last Name</label>
                                      <p>{reportData?.last_name}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.email ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Email Address</label>
                                      <p>{reportData?.email}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.password ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Password</label>
                                      <p>{reportData?.password}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                
                                {reportData?.phone_personal ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Phone</label>
                                      <p>{reportData?.phone_personal}</p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {reportData?.send_copies_of_reports_or_invoice_to ? (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>
                                        Send copies of reports or invoice to
                                      </label>
                                      <p>
                                        {
                                          reportData?.send_copies_of_reports_or_invoice_to
                                        }
                                      </p>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleViewClient;