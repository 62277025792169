// UserProfileCard.js
import React from 'react';
import { Card, Button, Avatar ,Col,Row} from 'antd';
import { EditOutlined,EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './UserProfileCard.css'; // Import the CSS file for styling

const UserProfileCard = ({ profile }) => {
  const {
    ID,
    email_ins,
    profile_pic,
    insp_fullname,
    phone_number,
    address,
    organization_name,
    profile_permalink,
  } = profile;

  return (
    <Card
      title={`${insp_fullname}`}
      extra={
        <div>
          <a href={profile_permalink} className='indpector_profile_view'>
            <Button className='profileView_icon' type="primary" icon={<EyeOutlined />} />
          </a>
          <Link to={`/inspector-profile/?inspector_id=${ID}`}>
            <Button className='profileEdit_icon' type="primary" icon={<EditOutlined />} />
          </Link>
        </div>
      }
      className="user-profile-card" // Add a custom class for styling
    >
      <Row gutter={16}>
      <Col  xs={24} md={24}  xl={6}>
      <div className="avatar-container">
        <Avatar  className='profileEdit_image' size={85} src={profile_pic} alt="Profile Avatar" />
      </div>
      </Col>
      <Col  xs={24} md={24}  xl={18}>
      <div className="content-container-2">
      <p><span className='text-started'>Email:</span> {email_ins}</p>
      <p><span className='text-started'>Phone Number:</span> {phone_number}</p>
      <p><span className='text-started'>Address:</span> {address}</p>
      <p><span className='text-started'>Company:</span> {organization_name}</p>
      </div>
      </Col>
      </Row>
      {/* Add more fields as needed */}
    </Card>
  );
};

export default UserProfileCard;
