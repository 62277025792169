import axios from 'axios';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
 
axios.interceptors.request.use(function (config) {

  const token = config.url.includes("https://www.floordetective.com/wp-json") ? `Bearer ${localStorage.getItem('jwt_token')}` : `Bearer ${localStorage.getItem('companycam_token')}`  ;
  config.headers.Authorization = token;
  return config;
});
export default axios;
