import React from "react";
import PagesHeader from "../../components/PageHeader";
import ButtonBar from "../../components/ButtonNavigation";
import ClaimAnalystData from "../../datatables/allManufacturerClaim";
import { Button, Row, Col } from 'antd';

const manufacturer = () => {

  const handleNavigate = () => {
    window.open('https://www.floordetective.com/purchase-additional-10-inspections/', '_blank');
  }

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader title="All Inspections"/>
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            <Col xs={24} md={6}>
              <Button
                type='success'
                className="btn inspectionAdd" 
                onClick={() => handleNavigate()}
              >
                +10 More Inspection
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
         <ClaimAnalystData />
        </div>
      </div>
    </div>
  );
};

export default manufacturer;
