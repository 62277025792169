import React from "react";
import { Row, Col, Collapse, Input, Card, Form, Select } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
const { Option } = Select;
const { Panel } = Collapse;

const ThirdStep = (props) => {
  console.warn("ThirdStep", props);
  const [form] = Form.useForm();

  return (
    <Form name="report" autoComplete="off" layout="vertical" form={form}>
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="CollapseSection">
                <Collapse
                  defaultActiveKey={
                    props.values.stepThreeTogg
                      ? props.values.stepThreeTogg
                      : ["1", "2", "3"]
                  }
                  onChange={props.handleChange("stepThreeTogg")}
                  className="reportCollapse"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="custom-icon" />
                    ) : (
                      <PlusOutlined className="custom-icon" />
                    )
                  }
                  ghost
                >
                  <Panel header="Interior At The Time Of Inspection" key={1}>
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Form.Item>
                          <p>Relative Humidity in the Air Space</p>
                        </Form.Item>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="1st Floor"
                              name="floor1_relative_humidity"
                            >
                              <Input
                                placeholder="1st Floor"
                                className="formControl"
                                onChange={props.handleChange(
                                  "floor1_relative_humidity"
                                )}
                                defaultValue={
                                  props.values.floor1_relative_humidity
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="2nd Floor"
                              name="floor2_relative_humidity"
                            >
                              <Input
                                placeholder="2nd Floor"
                                className="formControl"
                                onChange={props.handleChange(
                                  "floor2_relative_humidity"
                                )}
                                defaultValue={
                                  props.values.floor2_relative_humidity
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Basement"
                              name="basement_relative_humidity"
                            >
                              <Input
                                placeholder="Basement"
                                className="formControl"
                                onChange={props.handleChange(
                                  "basement_relative_humidity"
                                )}
                                defaultValue={
                                  props.values.basement_relative_humidity
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other"
                              name="other_relative_humidity"
                            >
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  "other_relative_humidity"
                                )}
                                defaultValue={
                                  props.values.other_relative_humidity
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Form.Item>
                          <div className="formGroup">
                            <p>Temperature</p>
                          </div>
                        </Form.Item>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="1st Floor"
                              name="floor1_relative_temperature"
                            >
                              <Input
                                placeholder="1st Floor"
                                className="formControl"
                                onChange={props.handleChange(
                                  "floor1_relative_temperature"
                                )}
                                defaultValue={
                                  props.values.floor1_relative_temperature
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="2nd Floor"
                              name="floor2_relative_temperature"
                            >
                              <Input
                                placeholder="2nd Floor"
                                className="formControl"
                                onChange={props.handleChange(
                                  "floor2_relative_temperature"
                                )}
                                defaultValue={
                                  props.values.floor2_relative_temperature
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Basement"
                              name="basement_relative_temperature"
                            >
                              <Input
                                placeholder="Basement"
                                className="formControl"
                                onChange={props.handleChange(
                                  "basement_relative_temperature"
                                )}
                                defaultValue={
                                  props.values.basement_relative_temperature
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other"
                              name="other_relative_temperature"
                            >
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  "other_relative_temperature"
                                )}
                                defaultValue={
                                  props.values.other_relative_temperature
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Heating System"
                              name="heating_system"
                            >
                              <Select
                                className="formGroup"
                                defaultValue={
                                  props.values.heating_system
                                    ? props.values.heating_system
                                    : "Heating System"
                                }
                                onChange={props.handleChange("heating_system")}
                              >
                                <Option value="HeatingSystem">
                                  Heating System
                                </Option>
                                <Option value="Gas">Gas</Option>
                                <Option value="Electric">Electric</Option>
                                <Option value="Geothermal">Geothermal</Option>
                                <Option value="Heat Pump">Heat Pump</Option>
                                <Option value="Hot Water">Hot Water</Option>
                                <Option value="Radiant">Radiant</Option>
                                <Option value="Solar">Solar</Option>
                                <Option value="Steam">Steam</Option>
                                <Option value="Fireplace">Fireplace</Option>
                                <Option value="None">None</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.heatingField === true ||
                        props.values.heating_system === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other"
                                name="heating_system_other"
                              >
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "heating_system_other"
                                  )}
                                  defaultValue={
                                    props.values.heating_system_other
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Air Conditioning System"
                              name="air_conditioning"
                            >
                              <Select
                                className="formGroup"
                                defaultValue={
                                  props.values.air_conditioning
                                    ? props.values.air_conditioning
                                    : "Air Conditioning System"
                                }
                                onChange={props.handleChange(
                                  "air_conditioning"
                                )}
                              >
                                <Option value="Central">Central</Option>
                                <Option value="Window">Window</Option>
                                <Option value="Evaporate">Evaporate</Option>
                                <Option value="None">None</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Unknown">Unknown</Option>
                                <Option value="See Report">See Report</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.acField === true ||
                        props.values.air_conditioning === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="air_on_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange("air_on_other")}
                                  defaultValue={props.values.air_on_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                             label="Heat On?"
                             name="heat"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.heat}
                              onToggle={props.handleChange('heat')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                             label="Air On?"
                             name="airon"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.airon}
                              onToggle={props.handleChange('airon')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                        <Form.Item
                         label="Humidifier On?"
                         name="humidifier"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.humidifier}
                              onToggle={props.handleChange('humidifier')}
                            />
                            </Form.Item>
                          </div>
                          {props.isOpen.humidifierField === true ||
                          props.values.humidifier === 'Yes' ? (
                            <div className="formGroup">
                              <Form.Item
                                label="Humidifier On?"
                                name="humid_settings"
                              >
                                <Input
                                  placeholder="Humidifier Settings"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "humid_settings"
                                  )}
                                  defaultValue={props.values.humid_settings}
                                />
                              </Form.Item>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                            label="Dehumidifier On?"
                            name="dehumidifier"
                                >
                                  {console.warn("tripleToggle")}
                                  <TripleToggle
                                  value={props.values.dehumidifier}
                                  onToggle={props.handleChange('dehumidifier')}
                                />
                                </Form.Item>
                              </div>
                              {props.isOpen.dehumidifierField === true ||
                              props.values.dehumidifier === 'Yes' ? (
                            <div className="formGroup">
                              <Form.Item
                                label="Dehumidifier Settings"
                                name="dehumid_settings"
                              >
                                <Input
                                  placeholder="Dehumidifier Settings"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "dehumid_settings"
                                  )}
                                  defaultValue={props.values.dehumid_settings}
                                />
                              </Form.Item>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel
                    header="Flooring Moisture Content In Complaint Area During Inspection"
                    key={2}
                  >
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Top" name="top_flor">
                            <Input
                              placeholder="Top"
                              className="formControl"
                              onChange={props.handleChange("top_flor")}
                              defaultValue={props.values.top_flor}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Back" name="back_flor">
                            <Input
                              placeholder="Back"
                              className="formControl"
                              onChange={props.handleChange("back_flor")}
                              defaultValue={props.values.back_flor}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Baseboard" name="baseboard_flor">
                            <Input
                              placeholder="Baseboard"
                              className="formControl"
                              onChange={props.handleChange("baseboard_flor")}
                              defaultValue={props.values.baseboard_flor}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Subfloor" name="subfloor_flor">
                            <Input
                              placeholder="Subfloor"
                              className="formControl"
                              onChange={props.handleChange("subfloor_flor")}
                              defaultValue={props.values.subfloor_flor}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Joist" name="joist_flor">
                            <Input
                              placeholder="Joist"
                              className="formControl"
                              onChange={props.handleChange("joist_flor")}
                              defaultValue={props.values.joist_flor}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Concrete" name="concrete_flor">
                            <Input
                              placeholder="Concrete"
                              className="formControl"
                              onChange={props.handleChange("concrete_flor")}
                              defaultValue={props.values.concrete_flor}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Meter Name" name="meter_name">
                            <Input
                              placeholder="Meter Name"
                              className="formControl"
                              onChange={props.handleChange("meter_name")}
                              defaultValue={props.values.meter_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Meter Range" name="meter_range">
                            <Input
                              placeholder="Meter Range"
                              className="formControl"
                              onChange={props.handleChange("meter_range")}
                              defaultValue={props.values.meter_range}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Substrate Information" key="3">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Foundation" name="foundation">
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.foundation
                                  ? props.values.foundation
                                  : "Select Foundation"
                              }
                              onChange={props.handleChange("foundation")}
                            >
                              <Option value="Foundation">Foundation</Option>
                              <Option value="Basement">Basement</Option>
                              <Option value="Crawlspace">Crawl Space</Option>
                              <Option value="Basement/Crawl Space">Basement/Crawl Space</Option>
                              <Option value="Slab">Slab</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Grade/Gradient" name="grade">
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.grade
                                  ? props.values.grade
                                  : "Grade/Gradient"
                              }
                              onChange={props.handleChange("grade")}
                            >
                              <Option value="Grade/Gradient">
                                Grade/Gradient
                              </Option>
                              <Option value="Above Grade">Above Grade</Option>
                              <Option value="On Grade">On Grade</Option>
                              <Option value="Below Grade">Below Grade</Option>
                              <Option value="On Grade & Above Grade">
                                On Grade & Above Grade
                              </Option>
                              <Option value="On All Grades">
                                On All Grades
                              </Option>
                              <Option value="Level To The Building">
                                Level To The Building
                              </Option>
                              <Option value="Towards The Building">
                                Towards The Building
                              </Option>
                              <Option value="Away from Building">
                                Away from Building
                              </Option>
                              <Option value="Towards The Building With Gutter Attached">
                                Towards The Building With Gutter Attached
                              </Option>
                              <Option value="Away From The Building With Gutter Attached">
                                Away From The Building With Gutter Attached
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Home/Building Footprint"
                            name="footprint"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.footprint
                                  ? props.values.footprint
                                  : "Home/Building Footprint"
                              }
                              onChange={props.handleChange("footprint")}
                            >
                              <Option value="Surrounding Condition Home/Building">
                                Surrounding Condition Home/Building
                              </Option>
                              <Option value="Coastal">Coastal</Option>
                              <Option value="Valley">Valley</Option>
                              <Option value="Flatland">Flatland</Option>
                              <Option
                                value="Gradually Rolling Hill: Denote Side, East, West,
                                South Or North"
                              >
                                Gradually Rolling Hill: Denote Side, East, West,
                                South Or North
                              </Option>
                              <Option value="Foothills">Foothills</Option>
                              <Option value="Mountainous">Mountainous</Option>
                              <Option value="Desert">Desert</Option>
                              <Option value="Hight Desert">Hight Desert</Option>
                              <Option value="Island">Island</Option>
                              <Option value="Rural">Rural</Option>
                              <Option value="Urban">Urban</Option>
                              <Option value="High Rise">High Rise</Option>
                              <Option value="New Construction in New Developing Neighborhood">
                                New Construction in New Developing Neighborhood
                              </Option>
                              <Option value="New Construction: 1 Month - 12 Months">
                                New Construction: 1 Month - 12 Months
                              </Option>
                              <Option value="New Construction: 12-24 Months">
                                New Construction: 12-24 Months
                              </Option>
                              <Option value="New Constructed: 24-36 Month">
                                New Constructed: 24-36 Month
                              </Option>
                              <Option value="Concrete Slab">
                                Concrete Slab
                              </Option>
                              <Option value="Concrete below grade">
                                Concrete Below Grade
                              </Option>
                              <Option value="Concrete Suspended slab: Type Gypsum Etc">
                                Concrete Suspended Slab: Type Gypsum Etc
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Building Landscape"
                            name="building_landscape"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={
                                props.values.building_landscape
                                  ? props.values.building_landscape
                                  : "Building Landscape"
                              }
                              onChange={props.handleChange(
                                "building_landscape"
                              )}
                            >
                              <Option value="Building Landscape">
                                Building Landscape
                              </Option>
                              <Option value="Plant Life">Plant Life</Option>
                              <Option value="Trees Bushes">Trees Bushes</Option>
                              <Option value="Vegetation Foliage Trees">
                                Vegetation Foliage Trees
                              </Option>
                              <Option value="Shrubbery Grass">
                                Shrubbery Grass
                              </Option>
                              <Option value="An Automatic Drip Irrigation An Automatic
                                Sprinkler An Automatic Irrigation">
                                An Automatic Drip Irrigation An Automatic
                                Sprinkler An Automatic Irrigation
                              </Option>
                              <Option value="Rough Grade Complete">
                                Rough Grade Complete
                              </Option>
                              <Option value="Seeded">Seeded</Option>
                              <Option value="New Sod">New Sod</Option>
                              <Option value="Partial Complete">
                                Partial Complete
                              </Option>
                              <Option value="Completed">Completed</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    {props.values.foundation === "Foundation" || props.values.foundation === "" ||
                    props.values.foundation === "Crawlspace" || props.values.foundation === "Basement" ||
                      props.isOpen.foundationField === true || 
                      props.isOpen.crawalField === true || 
                      props.isOpen.basementField === true ? (
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Soil Subfloor Distance"
                              name="soil_subfloor"
                            >
                              <Input
                                placeholder="Soil Subfloor Distance"
                                className="formControl"
                                onChange={props.handleChange("soil_subfloor")}
                                defaultValue={props.values.soil_subfloor}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Relative Slab Soil Elevation"
                              name="relate_slab"
                            >
                              <Input
                                placeholder="Relative Slab Soil Elevation"
                                className="formControl"
                                onChange={props.handleChange("relate_slab")}
                                defaultValue={props.values.relate_slab}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Temperature Crawl Space"
                              name="temperature_crawl"
                            >
                              <Input
                                placeholder="Temperature Crawl Space"
                                className="formControl"
                                onChange={props.handleChange(
                                  "temperature_crawl"
                                )}
                                defaultValue={props.values.temperature_crawl}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Ground Covered %"
                              name="ground_cover_per"
                            >
                              <Input
                                placeholder="Ground Covered %"
                                className="formControl"
                                onChange={props.handleChange(
                                  "ground_cover_per"
                                )}
                                defaultValue={props.values.ground_cover_per}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Relative Humidity Reading Crawl Space"
                              name="relative_humidity"
                            >
                              <Input
                                placeholder="Relative Humidity Reading Crawl Space"
                                className="formControl"
                                onChange={props.handleChange(
                                  "relative_humidity"
                                )}
                                defaultValue={props.values.relative_humidity}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                             label="Above Grade"
                             name="car_tog_grade"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_grade}
                              onToggle={props.handleChange('car_tog_grade')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                             label="Foundation Alkali"
                             name="car_tog_alkali"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_alkali}
                              onToggle={props.handleChange('car_tog_alkali')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                             label="Cross Flow"
                             name="car_tog_cross"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_cross}
                              onToggle={props.handleChange('car_tog_cross')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                             label="Joist Discoloration"
                             name="car_tog_joist"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_joist}
                              onToggle={props.handleChange('car_tog_joist')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                             label="Mold Present" 
                             name="car_tog_mold"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_mold}
                              onToggle={props.handleChange('car_tog_mold')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                          <Form.Item
                              label="Standing Water Present"
                              name="car_tog_water"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_water}
                              onToggle={props.handleChange('car_tog_water')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    ) : null}

                    {props.isOpen.slabField === true ||
                    props.isOpen.basementField === true ||
                    props.isOpen.foundationField === true ||
                    props.isOpen.crawalField === true ||
                    props.values.foundation === "Slab" ||
                    props.values.foundation === "Basement" ||
                    props.values.foundation === "Basement/Crawl Space" ||
                    props.values.foundation === "Crawlspace" ||
                    props.values.foundation === "" ||
                    props.values.foundation === "Foundation" ? (
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Sub Floor Type  "
                              name="subfloor_type"
                            >
                              <Select
                                className="formGroup"
                                defaultValue={
                                  props.values.subfloor_type
                                    ? props.values.subfloor_type
                                    : "Select Sub Floor Type"
                                }
                                onChange={props.handleChange("subfloor_type")}
                              >
                                <Option value="Concrete">Concrete</Option>
                                <Option value="Concrete on Grade">
                                  Concrete on Grade
                                </Option>
                                <Option value="Concrete Above Grade">
                                  Concrete Above Grade
                                </Option>
                                <Option value="Concrete Below Grade">
                                  Concrete Below Grade
                                </Option>
                                <Option value="Gypsum Concrete">
                                  Gypsum Concrete
                                </Option>
                                <Option value="Wood">Wood</Option>
                                <Option value="Wood and Concrete">
                                  Wood and Concrete
                                </Option>
                                <Option value="Wood over Crawlspace">
                                  Wood over Crawlspace
                                </Option>
                                <Option value="Wood Over Basement">
                                  Wood Over Basement
                                </Option>
                                <Option value="Wood Over Basement/Crawlspace">
                                Wood Over Basement/Crawlspace
                                </Option>
                                <Option value="Lightweight Concrete">
                                  Lightweight Concrete
                                </Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="See Report">See Report</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.subFloorOther === true ||
                        props.values.subfloor_type === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Subfloor Type Other"
                                name="other_sub_floor_type"
                              >
                                <Input
                                  placeholder="Subfloor Type Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "other_sub_floor_type"
                                  )}
                                  defaultValue={
                                    props.values.other_sub_floor_type
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Joist Type  " name="joist_type">
                              <Select
                                className="formGroup"
                                defaultValue="Joist Type"
                                onChange={props.handleChange("joist_type")}
                              >
                                <Option value="Wood">Wood</Option>
                                <Option value="Engineered">Engineered</Option>
                                <Option value="I-Joist">I-Joist</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    ) : null}

{props.values.foundation === "Basement" || props.values.foundation === "Basement/Crawl Space" || props.isOpen.basementField === true ? (
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                               label="Wall Cracked" name="car_tog_wall"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_wall}
                              onToggle={props.handleChange('car_tog_wall')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                               label="Paint Peeling"
                               name="car_tog_paint"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_paint}
                              onToggle={props.handleChange('car_tog_paint')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                               label="Floor Stained"
                               name="car_tog_floor"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_floor}
                              onToggle={props.handleChange('car_tog_floor')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                                label="Vented" name="car_tog_vented"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_vented}
                              onToggle={props.handleChange('car_tog_vented')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Rusty Nails" name="car_tog_rusty"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_rusty}
                              onToggle={props.handleChange('car_tog_rusty')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                  label="Finished" name="car_tog_finish"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_finish}
                              onToggle={props.handleChange('car_tog_finish')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                  label="Insulated Ceiling"
                                  name="car_tog_insulated"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_insulated}
                              onToggle={props.handleChange('car_tog_insulated')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Moisture tested before installation"
                                   name="mois_test"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.mois_test}
                              onToggle={props.handleChange('mois_test')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default ThirdStep;
