import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

export function getLogos() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'footerlogo/')
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
