import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <div className="drawerMenu">
      <Menu mode="inline" className="mobileMenu">
        <Menu.Item key="1">
          <span>Home</span>
          <Link to={"//www.floordetective.com/"}></Link>
        </Menu.Item>
        <Menu.Item key="2">
          <span>Memberships</span>
          <Link to={"//www.floordetective.com/subscribe/"}></Link>
        </Menu.Item>
        <Menu.Item key="3">
          <span>Defects</span>
          <Link to={"//www.floordetective.com/"}></Link>
        </Menu.Item>
        <Menu.Item key="4">
          <span>Find A Inspector</span>
          <Link to={"//www.floordetective.com/locator/"}></Link>
        </Menu.Item>
        <Menu.Item key="5">
          <span>About Us</span>
          <Link to={"//www.floordetective.com/about-us/"}></Link>
        </Menu.Item>
        <Menu.Item key="6">
          <span>Accounts</span>
          <Link to={"//www.floordetective.com/membership-account/"}></Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Index;
