import React from "react";
import { Row, Col } from "antd";

const Copywrite = () => {
  return (
    <div className="copywriteSection">
      <div className="container-xxl">
        <Row gutter={16}>
          <Col md={24} className="text-center">
            <p>Copyright © Floor Detective. All Rights Reserved {(new Date().getFullYear())}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};


export default Copywrite;
