import { Row, Card, Col, Image, Divider, Skeleton, List, Button,Modal, Form,Radio} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import * as ReportServices from "../../services/SingleReport";
import { useDispatch } from "react-redux";
import {
  FormOutlined
} from "@ant-design/icons";
import PlaceHolderImg from '../../assets/images/logoPlaceholder.png';
import Swal from 'sweetalert2';
import * as setPdfSettingsService from "../../services/PdfSettingsService";

function SingleQuickReport() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(true);
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);
  const [savePdfLoading, setSavePdfLoading] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [reportLogos, setReportLogos] = useState(null);
  // const [defaultPdfTemplate, setDefaultPdfTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
    getPdfTemplateData();
    setAttachments();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pdfTemplates = {
    1: {
      index: 1,
      template_id: 1,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040610/template_pdf_img_1.png",
    },
    2: {
      index: 2,
      template_id: 2,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040629/template_pdf_img_2.png",
    },
    3: {
      index: 3,
      template_id: 3,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/22093235/template_3_img.png",
    },
    4: {
      index: 4,
      template_id: 4,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040619/template_pdf_img_4.png",
    },
    5: {
      index: 5,
      template_id: 5,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040615/template_pdf_img_5.png",
    },
    6: {
      index: 6,
      template_id: 6,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/08/28053646/template_pdf_img_6-1.png",
    },
  };
  const handleRadio = (e) => {
    
    setSelectedTemplate(e.target.value);
  }
  const setPdfSettings = (value) => {
    dispatch(setPdfSettingsService.PdfSettingsService(selectedTemplate))
      .then((res) => {
        console.warn({ res });
        Swal.fire({
          icon: 'success',
          title: 'PDF Template selected successfully',
        })
        handleCancel();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
 
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getPdfTemplateData = () => {
    setLoading(true);
    dispatch(setPdfSettingsService.getpdfTemplate(selectedTemplate))
    .then((res) => {
      setSelectedTemplate(res?.pdf_template_id);

    })
    .catch((err) => {
      console.info("Get Error Edit State...");
      setLoading(false);
    });
  }

  const getPdf = () => {
    setSavePdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setSavePdfLoading(false);
        
        // After generating the PDF, show the popup to choose the next action
        Swal.fire({
          title: 'PDF Generated Successfully',
          text: 'Please choose your next action:',
          icon: 'success',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Go to Invoice',
          denyButtonText: 'Download PDF',
          cancelButtonText: 'Skip to Cover Letter',
          reverseButtons: true,
          confirmButtonColor: '#0A497B',
          denyButtonColor: '#0A497B',
          cancelButtonColor: '#0A497B',
        }).then((result) => {
          if (result.isConfirmed) {
            if (reportData.invoice_generated === "NO") {
              navigate("/create-invoice/?req_id=" + reportData.req_id);
            } else {
              navigate("/report-invoice/?invoice_id=" + reportData.invoice_id);
            }
          } else if (result.isDenied) {
            // Logic to download the generated PDF
            const link = document.createElement('a');
            link.href = res.data; // Assuming res.data.pdf_url contains the URL of the generated PDF
            link.download = 'GeneratedReport.pdf'; // Specify the file name
            link.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            if (reportData.cover_generated === "NO") {
              navigate("/create-cover-letter/?req_id=" + reportData.req_id);
            } else {
              navigate("/cover-letter/?cover_id=" + reportData.cover_id);
            }
          }
        });
  
      })
      .catch((err) => {
        let errorMessage;
  
        if (err.response) {
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }
  
        alert(errorMessage);
        setSavePdfLoading(false);
      });
  };
  
  const downloadPdf = () => {
    setDownloadPdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setDownloadPdfLoading(false);
        // window.open(res.data, 'download');
        window.open(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  useEffect(() => {
    setAttachments(reportData?.attachments);
    setReportLogos(reportData?.selectededlogos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.attachments, reportData?.selectededlogos]);

  //console.warn(attachments)
  const fetchData = (params = {}) => {
    const queryParams = new URLSearchParams(window.location.search);

    const report_id = queryParams.get("report_id");
    setLoading(true);
    dispatch(ReportServices.getSingleReport(report_id))
      .then((res) => {
        console.info(res);
        setReportData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const handleNavigate = () => {
    navigate("/quick-report-update/?report_id=" + reportData.report_id)
  }
  
  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader
          title="Inspection Request | Professional Report"
        />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              {reportData?.current_user_role === "um_inspector" ? (
                <Button
                  type='success'
                  className="btn inspectionAdd" 
                  onClick={() => handleNavigate()}
                  icon={<FormOutlined />}
                >
                  Edit Report
                </Button>
              ) : (
                ''
              )}
              
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
          {loading ? (
            <div className="spinDiv fullHeight">
                    {/* <Skeleton  style={{margin:"20px"}}
                    loading={loading} active
                      avatar
                      paragraph={{
                        rows: 1,
                      }}
                    /> */}
                <Skeleton loading={loading} active avatar>
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
          
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
            </div>
          ) : (
            <Card className="reportCard" id="quickReportContent">
              <div className="reportCardBody">
                <div className="inspectorInfo">
                  <Row gutter={30}>
                    <Col xs={24} md={4}>
                      <div className="inspectorLogo">
                        <Image
                          src={
                            reportData?.logo_url ? reportData.logo_url : PlaceHolderImg
                          }
                          className="ing-fluid"
                          alt="InspectorLogo"
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={20}>
                      <div className="inspectorDetails">
                        <div className="inspecDetailHead">
                          <h5 className="inspcTitle">
                            Inspection Request | Professional Report
                          </h5>
                        </div>
                        <div className="inspcInfo">
                          <p className="orgName">
                            {reportData?.organization_name_ins}
                          </p>
                          <p className="inspcData">
                            <span className="inspcSpan">
                              {reportData?.insp_fullname_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.address_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Office- {reportData?.phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Cell- {reportData?.company_phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.websiteins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.email_ins}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* Inspection Details */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Inspection Details
                  </Divider>
                  <Card className="innerCard">
                  <div className="reportData">
                    <Row gutter={16}>
                      <Col xs={24} md={6}>
                        <div className="formGroup">
                          <label>Claim Number</label>
                          <p>{reportData?.claim_num}</p>
                        </div>
                      </Col>

                      {reportData?.h_bi_ins_date ? (
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Inspection Date</label>
                            <p>{reportData?.h_bi_ins_date}</p>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {reportData?.ins_time ? (
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Inspection Time</label>
                            <p>
                              {reportData?.ins_time} {reportData?.time_type}
                            </p>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {reportData?.inspection_type ? (
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Inspection Type</label>
                            <p>{reportData?.inspection_type}</p>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                  </Card>
                </div>

                {/* Commissioning Party Information */}
                {reportData.party_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Information
                    </Divider>
                    <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        {reportData?.comm_party_drop ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Commissioning Party</label>
                              {reportData?.comm_party_drop === "Other" ? (
                                <p>{reportData?.comm_other}</p>
                              ) : (
                                <p>{reportData?.comm_party_drop}</p>
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.company_name_qr ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Company Name</label>
                              <p>{reportData?.company_name_qr}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.agent_claim_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Full Name</label>
                              <p>{reportData?.agent_claim_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.comm_email ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Email Address</label>
                              <p>{reportData?.comm_email}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.street_name_number ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Street Name / Number</label>
                              <p>{reportData?.street_name_number}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.city ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>City</label>
                              <p>{reportData?.city}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.state ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>State/Province</label>
                              <p>{reportData?.state}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.country ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Country</label>
                              <p>{reportData?.country}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.zip_code ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Zip/Postal Code</label>
                              <p>{reportData?.zip_code}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.phone_no_party ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Phone Number</label>
                              <p>{reportData?.phone_no_party}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Dealer Contact Information */}
                {reportData.dealer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Dealer Contact Information
                    </Divider>
                    <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        {reportData?.dealer_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Dealer Name</label>
                              <p>{reportData?.dealer_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.dealer_phone ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Phone Number</label>
                              <p>{reportData?.dealer_phone}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Homeowner/End-User Information */}
                {reportData.enduser_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Homeowner/End-User Information
                    </Divider>
                    <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        {reportData?.h_eu_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Name</label>
                              <p>{reportData?.h_eu_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                             {reportData?.h_eu_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.h_eu_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.h_eu_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_stateProvince ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.h_eu_stateProvince}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.h_eu_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                        {reportData?.h_eu_address ? (
                          <Col xs={24} md={12}>
                            <div className="formGroup">
                              <label>Address</label>
                              <p>{reportData?.h_eu_address}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_eu_zip ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Zip/Postal Code</label>
                              <p>{reportData?.h_eu_zip}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_eu_contact ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Contact</label>
                              <p>{reportData?.h_eu_contact}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_eu_phone_number ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Phone Number</label>
                              <p>{reportData?.h_eu_phone_number}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_eu_alt_phone_number ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Mobile Number</label>
                              <p>{reportData?.h_eu_alt_phone_number}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_eu_email ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Email Address</label>
                              <p>{reportData?.h_eu_email}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_bui_occupied === "yes" ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Building Occupied?</label>
                              <p>
                                {reportData?.h_bui_occupied === "yes"
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_bui_occupied === "HideThisField" || reportData?.h_bui_occupied === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Building Occupied</label>
                                  <p>
                                    {reportData?.h_bui_occupied === "Yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_occupied === "No" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.adults ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Adults</label>
                                        <p>{reportData?.adults}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.childrens ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Children</label>
                                        <p>{reportData?.childrens}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.pets ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Pets</label>
                                        <p>{reportData?.pets}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                      </Row>
                    </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Product and Claim Information */}
                {reportData.basic_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Product and Claim Information
                    </Divider>
                    <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        {reportData?.product_date_of_ins ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Date of Installation</label>
                              <p>{reportData?.product_date_of_ins}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.date_problem_noticed ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Date Problem Noticed</label>
                              <p>{reportData?.date_problem_noticed}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.reported_to ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Reported To</label>
                              <p>{reportData?.reported_to}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_bi_manu_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Manufacturer Name</label>
                              <p>{reportData?.h_bi_manu_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.style_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Style Name/Number</label>
                              <p>{reportData?.style_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.h_bi_color_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Color Name/Number</label>
                              <p>{reportData?.h_bi_color_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.area_room_ins ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Area/Rooms Installed</label>
                              <p>{reportData?.area_room_ins}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.total_claim_qt ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Total Claim Quantity</label>
                              <p>{reportData?.total_claim_qt}  {reportData?.total_claim_qt_unit}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.total_affected ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Total Affected</label>
                              <p>{reportData?.total_affected} {reportData?.total_affected_unit}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.flooring_installed_toggle === "HideThisField" || reportData?.flooring_installed_toggle === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Flooring Installed</label>
                                  <p>{reportData?.flooring_installed_toggle}</p>
                                </div>
                              </Col>
                          )}

                        {reportData?.concerns ? (
                          <Col xs={24} md={24}>
                            <div className="formGroup">
                              <label>Statement Of Concern</label>
                              <p>{reportData?.concerns}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Substrate Information */}
                {reportData.subtrace_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Substrate Information
                    </Divider>
                    <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        {reportData?.subfloor_type ? (
                          <Col xs={24} md={8}>
                            <div className="formGroup">
                              <label>Sub Floor Type</label>
                              {reportData?.subfloor_type === "Other" ? (
                                <p>{reportData?.qr_subfloor_type}</p>
                              ) : (
                                <p>{reportData?.subfloor_type}</p>
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.subs_grade ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Grade/ Gradient</label>
                              <p>{reportData?.subs_grade}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.footprint ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Home Building Footprint</label>
                              <p>{reportData?.footprint}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.building_landscape ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Building Landscape</label>
                              <p>{reportData?.building_landscape}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Observations Information */}
                {reportData.obser_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Observations Information
                    </Divider>
                    <Card className="innerCard">
                      {reportData.sending_samples === "Yes" || reportData.sending_samples === "No" ?(
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Sending Sample?
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                                                          {reportData?.sending_samples === "HideThisField" || reportData?.sending_samples === "" ? (
                                                          ""
                                                        ) : (
                                                          <>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Sending Samples?</label>
                                      <p>
                                        {reportData?.sending_samples === "Yes" || reportData?.sending_samples === "yes"
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  {reportData?.sending_samples === "No" || reportData?.sending_samples === "no"? (
                                                          ""
                                                        ) : (
                                                          <>
                                      {reportData?.shipping_company ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Shipping Company</label>
                                              <p>{reportData?.shipping_company}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {reportData?.tracking_number ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Traking Number</label>
                                              <p>{reportData?.tracking_number}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {reportData?.date_shipped ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Date Shipped</label>
                                              <p>{reportData?.date_shipped}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                      )}
                                </>
                            )}
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Claim History */}
                      {reportData.claim_his_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Claim History and Comments of Involved Parties
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Claim History and Comments of Involved Parties</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        reportData?.claim_history_comments,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Observations or physical description and comments */}
                      {reportData.observe_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Observations or physical description and comments
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Observations or physical description and comments</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.observation_comments,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Applicable Field Tests */}
                      {reportData.app_field_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Applicable Field Tests
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Applicable Field Tests</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.applicable_field_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Industry Standards/Manufacturers Standards/Citations */}
                      {reportData.indus_stand_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Industry Standards/Manufacturers Standards/Citations
                            and Resources Cited
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Industry Standards/Manufacturers Standards/Citations and Resources Cited</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        reportData?.industry_standards_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Manufacturing issues, Site related issues, Performance related issues and Installation related issues */}
                {/* {reportData.issues_info_toggle === "yes" ? ( */}
                {reportData.issues_info_toggle === "yes" &&(reportData.manufacturing_issue_togg === "yes" || reportData.site_rel_issue_togg === "yes" || reportData.perfo_rel_issue_togg === "yes" || reportData.insta_rel_issue_togg === "yes")  ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                    Manufacturing issues, Site related issues, Performance related issues and Installation related issues
                    </Divider>
                    <Card className="innerCard">
                      {/* Manufacturing issues */}
                      {reportData.manufacturing_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Manufacturing issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.manufacturing_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    
                      {/* Site related issues */}
                      {reportData.site_rel_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Site related issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.site_rel_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    
                      {/* Performance related issues */}
                      {reportData.perfo_rel_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Performance related issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.perfo_rel_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    
                      {/* Installation related issues */}
                      {reportData.insta_rel_issue_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                          Installation related issues
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.insta_rel_issue,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                      {/* Conclusion */}
                      {reportData.conc_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Conclusion
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Industry Standards/Manufacturers Standards/Citations and Resources Cited</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.conclusion_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Cause */}
                      {reportData.cause_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Cause
                          </Divider>
                          <div className="reportData editor">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Industry Standards/Manufacturers Standards/Citations and Resources Cited</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.cause_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}
                

                {/* Confidential Information */}
                {reportData.confi === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Confidential Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="inspectionDetails">
                        <Divider orientation="left" plain>
                          Has confidential comments?
                        </Divider>
                        <div className="reportData">
                          <Row gutter={16}>
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                {/* <label>Industry Standards/Manufacturers Standards/Citations and Resources Cited</label> */}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.conf_comment,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Footer */}
                {reportData.footer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Footer
                    </Divider>
                    <Card className="innerCard">
                      {reportData.upload_sign_toggle === "yes" ? (
                        <div className="inspectionDetails">
                          {reportData?.signurl ? (
                            <>
                              <Divider orientation="left" plain>
                                Upload Signature
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={24}>
                                    <div className="formGroup customSignatureImage">
                                      <Image
                                        src={reportData?.signurl}
                                        alt="image1"
                                        preview={false}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Footer text */}
                      {reportData.footer_text_info === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Footer text
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  {/* <label>Industry Standards/Manufacturers Standards/Citations and Resources Cited</label> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.footer_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Add Attachments */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Add Attachments
                  </Divider>
                  <div className="reportData">
                    <Row gutter={16}>
                        <Image.PreviewGroup>
                      {attachments &&
                        Object.keys(attachments).map((keyName, index) => (
                          <Col xs={6} md={6} key={index}>
                            <div className="formGroup customFormGroup">
                              <Image
                                src={attachments[keyName].url}
                                alt="image1"
                                className="img-fluid"
                                preview={true}
                              />
                              <p className="count">{attachments[keyName].id}</p>
                              <p className="descriptions">
                                {attachments[keyName].desc}
                              </p>
                            </div>
                          </Col>
                        ))}
                        </Image.PreviewGroup>
                    </Row>
                  </div>
                </div>

                {/* CERTIFICATION */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    CERTIFICATION
                  </Divider>
                  <div className="reportData footerReportData">
                    <Row justify="center">
                      {reportLogos &&
                        Object.keys(reportLogos).map((keyName, index) => (
                          <Col xs={2} md={2} key={index} className="logoCol">
                            <div className="logoDiv">
                              <div className="innerDiv">
                                <img
                                  src={reportLogos[keyName].logo_url}
                                  alt="not found"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>

                {/* Private Note */}
                {reportData.private_notes_togg === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Private Note
                    </Divider>
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <div className="formGroup sunEditorEditable sun-editor-editable">
                            {/* <label>Industry Standards/Manufacturers Standards/Citations and Resources Cited</label> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: reportData?.private_notes,
                              }}
                            ></p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Row gutter={16} className="pt-3">
              <Col xs={24} md={24} className="text-center">
              <Button
                        className="btnPrimary"
                        onClick={showModal}
                      >
                        Select Your PDF Layout
                      </Button>
                    {reportData?.current_user_role === "um_manufacturer" ||
                      reportData?.current_user_role === "um_claim-analyst" ||
                      reportData?.current_user_role === "um_dealer" ? (
                      <Button
                        className="btnPrimary"
                        onClick={() => downloadPdf()}
                        loading={downloadPdfLoading}
                      >
                        Download PDF
                      </Button>
                    ) : reportData?.current_user_role === "um_inspector" ? (
                      <>
                        <Button
                          className="btnPrimary"
                          onClick={() => downloadPdf()}
                          loading={downloadPdfLoading}
                        >
                          Download PDF
                        </Button>
                        <Button
                          className="btnPrimary"
                          onClick={() => getPdf()}
                          loading={savePdfLoading}
                        >
                          Save and Continue
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
              </Row>
            </Card>
          )}
        </div>
      </div>
       <Modal
        title={"Choose PDF Template."}
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="pdfTemplateform"
          layout="vertical"
          onFinish={setPdfSettings}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          
          <div className="modalContent pdfTemp">
            <div className="formGroup">
              <Radio.Group
                name="radiogroup"
                onChange={handleRadio}
                defaultValue={parseInt(selectedTemplate)}>

                  {Object.keys(pdfTemplates).map((keyName, index) => (
                    <Radio.Button
                      value={pdfTemplates[keyName].template_id}
                      key={index}
                      
                    >
                      <Image
                        src={pdfTemplates[keyName].template_url}
                        className="img-fluid"
                        alt="logos"
                        preview={false}
                      />
                  </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
          </div>
          <Button className="btnPrimary" htmlType="submit">
            Save and Exit
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default SingleQuickReport;
