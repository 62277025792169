import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Image,
  Divider,
  Button,
  // Spin,
  List,
  Modal,
  Form,
  Radio, Skeleton
} from "antd";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import * as ReportServices from "../../services/SingleReport";
import { useDispatch } from "react-redux";
import { FormOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import PlaceHolderImg from "../../assets/images/logoPlaceholder.png";
import * as setPdfSettingsService from "../../services/PdfSettingsService";

function SingleCarpetBasic() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(true);
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);
  const [savePdfLoading, setSavePdfLoading] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [reportLogos, setReportLogos] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();



  useEffect(() => {
    fetchData();
    getPdfTemplateData();
    setAttachments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.info("Carpet Report Data", reportData);

  const pdfTemplates = {
    1: {
      index: 1,
      template_id: 1,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040610/template_pdf_img_1.png",
    },
    2: {
      index: 2,
      template_id: 2,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040629/template_pdf_img_2.png",
    },
    3: {
      index: 3,
      template_id: 3,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/22093235/template_3_img.png",
    },
    4: {
      index: 4,
      template_id: 4,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040619/template_pdf_img_4.png",
    },
    5: {
      index: 5,
      template_id: 5,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040615/template_pdf_img_5.png",
    },
    6: {
      index: 6,
      template_id: 6,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/08/28053646/template_pdf_img_6-1.png",
    },
  };
  const setPdfSettings = (value) => {
    dispatch(setPdfSettingsService.PdfSettingsService(selectedTemplate))
      .then((res) => {
        console.warn({ res });
        Swal.fire({
          icon: "success",
          title: "PDF Template selected successfully",
        });
        handleCancel();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getPdfTemplateData = () => {
    setLoading(true);
    dispatch(setPdfSettingsService.getpdfTemplate(selectedTemplate))
      .then((res) => {
        setSelectedTemplate(res?.pdf_template_id);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const handleRadio = (e) => {
    
    setSelectedTemplate(e.target.value);
  };
  const getPdf = () => {
    setSavePdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setSavePdfLoading(false);
        
        // After generating the PDF, show the popup to choose the next action
        Swal.fire({
          title: 'PDF Generated Successfully',
          text: 'Please choose your next action:',
          icon: 'success',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Go to Invoice',
          denyButtonText: 'Download PDF',
          cancelButtonText: 'Skip to Cover Letter',
          reverseButtons: true,
          confirmButtonColor: '#0A497B',
          denyButtonColor: '#0A497B',
          cancelButtonColor: '#0A497B',
        }).then((result) => {
          if (result.isConfirmed) {
            if (reportData.invoice_generated === "NO") {
              navigate("/create-invoice/?req_id=" + reportData.req_id);
            } else {
              navigate("/report-invoice/?invoice_id=" + reportData.invoice_id);
            }
          } else if (result.isDenied) {
            // Logic to download the generated PDF
            const link = document.createElement('a');
            link.href = res.data; // Assuming res.data.pdf_url contains the URL of the generated PDF
            link.download = 'GeneratedReport.pdf'; // Specify the file name
            link.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            if (reportData.cover_generated === "NO") {
              navigate("/create-cover-letter/?req_id=" + reportData.req_id);
            } else {
              navigate("/cover-letter/?cover_id=" + reportData.cover_id);
            }
          }
        });
  
      })
      .catch((err) => {
        let errorMessage;
  
        if (err.response) {
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }
  
        alert(errorMessage);
        setSavePdfLoading(false);
      });
  };
  
  const downloadPdf = () => {
    setDownloadPdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setDownloadPdfLoading(false);
        // window.open(res.data, 'download');
        window.open(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const fetchData = (params = {}) => {
    const queryParams = new URLSearchParams(window.location.search);

    const report_id = queryParams.get("report_id");
    setLoading(true);
    dispatch(ReportServices.getSingleReport(report_id))
      .then((res) => {
        console.info(res);
        setReportData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  useEffect(() => {
    const images = reportData?.attachments;
    // Create a new object to store the updated images
    const updatedImages = { ...images };

    // Iterate through the keys of the 'images' object
    for (const key in updatedImages) {
      if (updatedImages.hasOwnProperty(key)) {
        // Update the URL property with the updated URL
        updatedImages[key].url = updatedImages[key].url.replace(updatedImages[key].url, `${updatedImages[key].url}?t=${new Date().getTime()}`);
      }
    }
    setAttachments(updatedImages);
    setReportLogos(reportData?.selectededlogos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.attachments, reportData?.selectededlogos]);

  const handleNavigate = () => {
    navigate("/carpet-basic-update/?report_id=" + reportData.report_id);
  };

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Inspection Request | Professional Report" />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              {reportData?.current_user_role === "um_inspector" ? (
                <Button
                  type="success"
                  className="btn inspectionAdd"
                  onClick={() => handleNavigate()}
                  icon={<FormOutlined />}
                >
                  Edit Report
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
          {loading ? (
            <div className="spinDiv fullHeight">
              {/* <Spin size="large" /> */}
              <Skeleton loading={loading} active avatar>
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />

              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>

              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
              <br></br>
              <hr />
              <br></br>
              <Skeleton loading={loading} active >
                <List.Item.Meta
                />
              </Skeleton>
            </div>
          ) : (
            <Card className="reportCard" id="quickReportContent">
              <div className="reportCardBody">
                <div className="inspectorInfo">
                  <Row gutter={30}>
                    <Col xs={24} md={4}>
                      <div className="inspectorLogo">
                        <Image
                          src={
                            reportData?.logo_url
                              ? reportData.logo_url
                              : PlaceHolderImg
                          }

                          className="ing-fluid"
                          alt="InspectorLogo"
                          preview={false}
                        />

                      </div>
                    </Col>
                    <Col xs={24} md={20}>
                      <div className="inspectorDetails">
                        <div className="inspecDetailHead">
                          <h5 className="inspcTitle">
                            Inspection Request | Professional Report
                          </h5>
                        </div>
                        <div className="inspcInfo">
                          <p className="orgName">
                            {reportData?.organization_name_ins}
                          </p>
                          <p className="inspcData">
                            <span className="inspcSpan">
                              {reportData?.insp_fullname_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.address_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Office- {reportData?.phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Cell- {reportData?.company_phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.websiteins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.email_ins}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Inspection Details */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Inspection Details
                  </Divider>
                  <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Claim Number</label>
                            <p>{reportData?.claim_num}</p>
                          </div>
                        </Col>

                        {reportData?.h_bi_ins_date ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Date</label>
                              <p>{reportData?.h_bi_ins_date}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.ins_time ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Time</label>
                              <p>
                                {reportData?.ins_time} {reportData?.time_type}
                              </p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {reportData?.inspection_type ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Type</label>
                              <p>{reportData?.inspection_type}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>

                {/* Commissioning Party Information */}
                {reportData.party_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.comm_party_drop ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Commissioning Party</label>
                                {reportData?.comm_party_drop === "Other" ? (
                                  <p>{reportData?.comm_other}</p>
                                ) : (
                                  <p>{reportData?.comm_party_drop}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.company_name_qr ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Company Name</label>
                                <p>{reportData?.company_name_qr}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.agent_claim_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Full Name</label>
                                <p>{reportData?.agent_claim_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.comm_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.comm_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.street_name_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.street_name_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.zip_code ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip/Postal Code</label>
                                <p>{reportData?.zip_code}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.phone_no_party ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.phone_no_party}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/*  Homeowner/End-User Information*/}
                {reportData.enduser_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Homeowner/End-User Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.h_eu_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.h_eu_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.h_eu_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.h_eu_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_stateProvince ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State/Province</label>
                                <p>{reportData?.h_eu_stateProvince}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_eu_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.h_eu_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.end_address ? (
                            <Col xs={24} md={12}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.end_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.h_eu_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip/Postal Code</label>
                                <p>{reportData?.h_eu_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.h_eu_contact ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact</label>
                                <p>{reportData?.h_eu_contact}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.eu_phone1 ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.eu_phone1}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.end_mobile ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Mobile Number</label>
                                <p>{reportData?.end_mobile}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.end_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.end_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Manufacturers Contact Informationn*/}
                {reportData.mc_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Manufacturers Contact Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.manf_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Manufacturers Name</label>
                                <p>{reportData?.manf_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Dealer Contact Information*/}
                {reportData.dealer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Dealer Contact Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.dealer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Dealer Name</label>
                                <p>{reportData?.dealer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.dealer_contact ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Name</label>
                                <p>{reportData?.dealer_contact}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_phone ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Phone Number</label>
                                <p>{reportData?.product_phone}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email Address</label>
                                <p>{reportData?.product_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Product and Claim Information*/}
                {reportData.basic_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Product and Claim Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.product_date_of_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date of Installation</label>
                                <p>{reportData?.product_date_of_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.date_problem_noticed ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Problem Noticed</label>
                                <p>{reportData?.date_problem_noticed}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.reported_to ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Reported To</label>
                                <p>{reportData?.reported_to}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.car_i2_inst_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Installation Type</label>
                                {reportData?.car_i2_inst_type === "Other" ? (
                                  <p>{reportData?.car_i2_other_inst_mthd}</p>
                                ) : (
                                  <p>{reportData?.car_i2_inst_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.car_i2_inst_type === "Glue down" ? (
                            <>
                              {reportData?.floor_rolled_toggle === "yes" ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Was the Floor Rolled ?</label>
                                    <p>
                                      {reportData?.floor_rolled_toggle === "yes"
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.adhesive_name_number ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Adhesive Name/Number</label>
                                    <p>{reportData?.adhesive_name_number}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.trowel_used_size_dimension ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Trowel Used-Size/Dimension</label>
                                    <p>
                                      {reportData?.trowel_used_size_dimension}
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.roller_weight_lbs ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Roller Weight LBS</label>
                                    <p>{reportData?.roller_weight_lbs}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {reportData?.product_manf ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Manufacturer Name</label>
                                <p>{reportData?.product_manf}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.style_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Style Name/Number</label>
                                <p>{reportData?.style_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_color ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Color Name/Number</label>
                                <p>{reportData?.product_color}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.area_room_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Area/Rooms Installed</label>
                                <p>{reportData?.area_room_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.total_claim_qt ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Total Claim Quantity</label>
                                <p>
                                  {reportData?.total_claim_qt} {reportData?.total_claim_qt_unit}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.product_invoice ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Invoice Number/PO Number</label>
                                <p>{reportData?.product_invoice}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.cushion_dropdown ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Cushion Type</label>
                                {reportData?.cushion_dropdown === "Other" ? (
                                  <p>{reportData?.cushion_type_other}</p>
                                ) : (
                                  <p>{reportData?.cushion_dropdown}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.fiber_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Fiber Type</label>
                                {reportData?.fiber_type === "Other" ? (
                                  <p>{reportData?.fiber_type_other}</p>
                                ) : (
                                  <p>{reportData?.fiber_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row gutter={16}>
                          {reportData?.concerns ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>Statement Of Concern</label>
                                <p>{reportData?.concerns}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Site Description and Occupancy Information*/}
                {reportData.site_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Site Description and Occupancy Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.property_dropdown ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Property Type</label>
                                <p>{reportData?.property_dropdown}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.building_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Type</label>
                                {reportData?.building_type === "Other" ? (
                                  <p>{reportData?.build_type_other}</p>
                                ) : (
                                  <p>{reportData?.building_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.building_style ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Style</label>
                                {reportData?.building_style === "Other" ? (
                                  <p>{reportData?.other_building_style}</p>
                                ) : (
                                  <p>{reportData?.building_style}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.traffic_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Traffic Type</label>
                                {reportData?.traffic_type === "Other" ? (
                                  <p>{reportData?.other_trafic}</p>
                                ) : (
                                  <p>{reportData?.traffic_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.building_age ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Age</label>
                                <p>{reportData?.building_age}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.h_bui_cons === "HideThisField" || reportData?.h_bui_cons === "" ? (
                            ""
                          ) : (
                            <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>New Construction?</label>
                                  <p>
                                    {reportData?.h_bui_cons === "Yes" || reportData?.h_bui_cons === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_cons === "No" || reportData?.h_bui_cons === "no" ? (
                                ""
                              ) : (
                                <>
                                  {reportData?.closing_date ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Closing Date</label>
                                        <p>{reportData?.closing_date}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}


                                  {reportData?.moving_date ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Move in Date</label>
                                        <p>{reportData?.moving_date}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {reportData?.operational_date ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>HVAC Operational Date</label>
                                        <p>{reportData?.operational_date}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </>
                          )
                          }

                          {reportData?.h_bui_walk_off === "HideThisField" || reportData?.h_bui_walk_off === "" ? (
                            ""
                          ) : (
                            <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Walk off Mats</label>
                                  <p>
                                    {reportData?.h_bui_walk_off === "Yes" || reportData?.h_bui_walk_off === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_walk_off === "No" || reportData?.h_bui_walk_off === "no" ? (
                                ""
                              ) : (
                                <>
                                  {reportData?.all_exterior ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>All Exterior Building Entrances</label>
                                        {reportData?.all_exterior === "Other" ? (
                                          <p>{reportData?.other_exterior}</p>
                                        ) : (
                                          <p>{reportData?.all_exterior}</p>
                                        )}
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {reportData?.h_bui_occupied === "HideThisField" || reportData?.h_bui_occupied === "" ? (
                            ""
                          ) : (
                            <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Building Occupied</label>
                                  <p>
                                    {reportData?.h_bui_occupied === "Yes" || reportData?.h_bui_occupied === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_occupied === "No" || reportData?.h_bui_occupied === "no" ? (
                                ""
                              ) : (
                                <>
                                  {reportData?.adults ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Adults</label>
                                        <p>{reportData?.adults}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.childrens ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Children</label>
                                        <p>{reportData?.childrens}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.pets ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Pets</label>
                                        <p>{reportData?.pets}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Cleaning Information*/}
                {reportData.cleaned !== "HideThisField" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Cleaning Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.cleaned === "HideThisField" || reportData?.cleaned === "" ? (
                            ""
                          ) : (
                            <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Cleaned?</label>
                                  <p>
                                    {reportData?.cleaned === "Yes" || reportData?.cleaned === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.cleaned === "No" || reportData?.cleaned === "no" ? (
                                ""
                              ) : (
                                <>
                                  {reportData?.professinally_cleaned === "HideThisField" || reportData?.professinally_cleaned === "" ? (
                                    ""
                                  ) : (
                                    <>
                                      <Col xs={24} md={6}>
                                        <div className="formGroup">
                                          <label>Professionally Cleaned?</label>
                                          <p>
                                            {reportData?.professinally_cleaned === "Yes" || reportData?.professinally_cleaned === "yes"
                                              ? "Yes"
                                              : "No"}
                                          </p>
                                        </div>
                                      </Col>
                                      {reportData?.professinally_cleaned === "No" || reportData?.professinally_cleaned === "no" ? (
                                        ""
                                      ) : (
                                        <>
                                          {reportData?.cleaning_company_name ? (
                                            <Col xs={24} md={6}>
                                              <div className="formGroup">
                                                <label>Company Name</label>
                                                <p>{reportData?.cleaning_company_name}</p>
                                              </div>
                                            </Col>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  {reportData?.cleaning_history ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Cleaning History and Details</label>
                                        <p>{reportData?.cleaning_history}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {reportData?.vacumm_brand ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Vacuum Brand</label>
                                        {reportData?.vacumm_brand === "Other" ? (
                                          <p>{reportData?.vacum_brand_other}</p>
                                        ) : (
                                          <p>{reportData?.vacumm_brand}</p>
                                        )}
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {reportData?.vacumm_condition ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Vacuum Condition</label>
                                        <p>{reportData?.vacumm_condition}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {reportData?.cri_seal ? (
                                    <Col xs={24} md={8}>
                                      <div className="formGroup">
                                        <label>
                                          Is Vacuum Cleaner on CRI Seal of Approval List
                                        </label>
                                        <p>{reportData?.cri_seal}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                </>
                              )}
                            </>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Substrate Information*/}
                {reportData.subtrace_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Substrate Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.foundation ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Foundation</label>
                                <p>{reportData?.foundation}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.grade ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Grade/ Gradient</label>
                                <p>{reportData?.grade}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.footprint ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Home Building Footprint</label>
                                <p>{reportData?.footprint}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.building_landscape ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Building Landscape</label>
                                <p>{reportData?.building_landscape}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.subfloor_type ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Sub Floor Type</label>
                                {reportData?.subfloor_type === "Other" ? (
                                  <p>{reportData?.other_sub_floor_type}</p>
                                ) : (
                                  <p>{reportData?.subfloor_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}


                          {reportData?.foundation === "Foundation" || reportData?.foundation === "Basement" || reportData?.foundation === "Basement/Crawl Space" || reportData?.foundation === "Crawlspace" ? (

                            <>
                              {reportData?.soil_subfloor ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Soil Subfloor Distance</label>
                                    <p>{reportData?.soil_subfloor}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.relate_slab ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Relative Slab Soil Elevation</label>
                                    <p>{reportData?.relate_slab}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.temperature_crawl ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Temperature Crawl Space</label>
                                    <p>{reportData?.temperature_crawl}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.ground_cover_per ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Ground Covered %</label>
                                    <p>{reportData?.ground_cover_per}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.relative_humidity ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Relative Humidity Reading Crawl Space
                                    </label>
                                    <p>{reportData?.relative_humidity}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.car_tog_grade === "HideThisField" || reportData?.car_tog_grade === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Above Grade</label>
                                    <p>{reportData?.car_tog_grade}</p>
                                  </div>
                                </Col>
                              )}

                              {reportData?.car_tog_alkali === "HideThisField" || reportData?.car_tog_alkali === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Foundation Alkali</label>
                                    <p>{reportData?.car_tog_alkali}</p>
                                  </div>
                                </Col>
                              )}

                              {reportData?.car_tog_cross === "HideThisField" || reportData?.car_tog_cross === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Cross Flow</label>
                                    <p>{reportData?.car_tog_cross}</p>
                                  </div>
                                </Col>
                              )}


                              {reportData?.car_tog_joist === "HideThisField" || reportData?.car_tog_joist === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Joist Discoloration</label>
                                    <p>{reportData?.car_tog_joist}</p>
                                  </div>
                                </Col>
                              )}


                              {reportData?.car_tog_mold === "HideThisField" || reportData?.car_tog_mold === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Mold Present</label>
                                    <p>{reportData?.car_tog_mold}</p>
                                  </div>
                                </Col>
                              )}


                              {reportData?.car_tog_water === "HideThisField" || reportData?.car_tog_water === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Standing Water Present</label>
                                    <p>{reportData?.car_tog_water}</p>
                                  </div>
                                </Col>
                              )}

                            </>
                          ) : (
                            ""
                          )}



                          {reportData?.foundation === "Basement" || reportData?.foundation === "Basement/Crawl Space" ? (

                            <>
                              {reportData?.car_tog_wall === "HideThisField" || reportData?.car_tog_wall === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Walls Cracked</label>
                                    <p>{reportData?.car_tog_wall}</p>
                                  </div>
                                </Col>
                              )}
                              {reportData?.car_tog_paint === "HideThisField" || reportData?.car_tog_paint === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Paint Peeling</label>
                                    <p>{reportData?.car_tog_paint}</p>
                                  </div>
                                </Col>
                              )}

                              {reportData?.car_tog_floor === "HideThisField" || reportData?.car_tog_floor === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Floor Stained</label>
                                    <p>{reportData?.car_tog_floor}</p>
                                  </div>
                                </Col>
                              )}
                              {reportData?.car_tog_vented === "HideThisField" || reportData?.car_tog_vented === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Vented</label>
                                    <p>{reportData?.car_tog_vented}</p>
                                  </div>
                                </Col>
                              )}
                              {reportData?.car_tog_rusty === "HideThisField" || reportData?.car_tog_rusty === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Rusty Nails</label>
                                    <p>{reportData?.car_tog_rusty}</p>
                                  </div>
                                </Col>
                              )}
                              {reportData?.car_tog_finish === "HideThisField" || reportData?.car_tog_finish === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Finished</label>
                                    <p>{reportData?.car_tog_finish}</p>
                                  </div>
                                </Col>
                              )}
                              {reportData?.car_tog_insulated === "HideThisField" || reportData?.car_tog_insulated === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Insulated Ceiling</label>
                                    <p>{reportData?.car_tog_insulated}</p>
                                  </div>
                                </Col>
                              )}
                              {reportData?.mois_test === "HideThisField" || reportData?.mois_test === "" ? (
                                ""
                              ) : (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Moisture Tested Before Installation
                                    </label>
                                    <p>{reportData?.mois_test}</p>
                                  </div>
                                </Col>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Observations Information */}
                {reportData.obser_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Observations Information
                    </Divider>
                    <Card className="innerCard">
                      {reportData.sending_samples === "Yes" || reportData.sending_samples === "No" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Sending Sample?
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              {reportData?.sending_samples === "HideThisField" || reportData?.sending_samples === "" ? (
                                ""
                              ) : (
                                <>
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                      <label>Sending Samples?</label>
                                      <p>
                                        {reportData?.sending_samples === "Yes" || reportData?.sending_samples === "yes"
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  {reportData?.sending_samples === "No" || reportData?.sending_samples === "no" ? (
                                    ""
                                  ) : (
                                    <>
                                      {reportData?.shipping_company ? (
                                        <Col xs={24} md={6}>
                                          <div className="formGroup">
                                            <label>Shipping Company</label>
                                            <p>{reportData?.shipping_company}</p>
                                          </div>
                                        </Col>
                                      ) : (
                                        ""
                                      )}

                                      {reportData?.tracking_number ? (
                                        <Col xs={24} md={6}>
                                          <div className="formGroup">
                                            <label>Traking Number</label>
                                            <p>{reportData?.tracking_number}</p>
                                          </div>
                                        </Col>
                                      ) : (
                                        ""
                                      )}

                                      {reportData?.date_shipped ? (
                                        <Col xs={24} md={6}>
                                          <div className="formGroup">
                                            <label>Date Shipped</label>
                                            <p>{reportData?.date_shipped}</p>
                                          </div>
                                        </Col>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Claim History */}
                      {reportData.claim_his_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Claim History and Comments of Involved Parties
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        reportData?.claim_history_comments,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Observations or physical description and comments */}
                      {reportData.observe_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Observations or Physical Description and Comments
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.observation_comments,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Applicable Field Tests */}
                      {reportData.app_field_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Applicable Field Tests
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.applicable_field_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Industry Standards/Manufacturers Standards/Citations */}
                      {reportData.indus_stand_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Industry Standards/Manufacturers Standards/Citations
                            and Resources Cited
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        reportData?.industry_standards_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Manufacturing issues, Site related issues, Performance related issues and Installation related issues */}

                      {/* {reportData.issues_info_toggle === "yes" ? ( */}
                      {reportData.issues_info_toggle === "yes" && (reportData.manufacturing_issue_togg === "yes" || reportData.site_rel_issue_togg === "yes" || reportData.perfo_rel_issue_togg === "yes" || reportData.insta_rel_issue_togg === "yes") ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Manufacturing issues, Site related issues, Performance related issues and Installation related issues
                          </Divider>
                          <Card className="innerCard">
                            {/* Manufacturing issues */}
                            {reportData.manufacturing_issue_togg === "yes" ? (
                              <div className="inspectionDetails">
                                <Divider orientation="left" plain>
                                  Manufacturing issues
                                </Divider>
                                <div className="reportData">
                                  <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                      <div className="formGroup sunEditorEditable sun-editor-editable">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: reportData?.manufacturing_issue,
                                          }}
                                        ></p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Site related issues */}
                            {reportData.site_rel_issue_togg === "yes" ? (
                              <div className="inspectionDetails">
                                <Divider orientation="left" plain>
                                  Site related issues
                                </Divider>
                                <div className="reportData">
                                  <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                      <div className="formGroup sunEditorEditable sun-editor-editable">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: reportData?.site_rel_issue,
                                          }}
                                        ></p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Performance related issues */}
                            {reportData.perfo_rel_issue_togg === "yes" ? (
                              <div className="inspectionDetails">
                                <Divider orientation="left" plain>
                                  Performance related issues
                                </Divider>
                                <div className="reportData">
                                  <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                      <div className="formGroup sunEditorEditable sun-editor-editable">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: reportData?.perfo_rel_issue,
                                          }}
                                        ></p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Installation related issues */}
                            {reportData.insta_rel_issue_togg === "yes" ? (
                              <div className="inspectionDetails">
                                <Divider orientation="left" plain>
                                  Installation related issues
                                </Divider>
                                <div className="reportData">
                                  <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                      <div className="formGroup sunEditorEditable sun-editor-editable">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: reportData?.insta_rel_issue,
                                          }}
                                        ></p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Card>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Conclusion */}
                      {reportData.conc_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Conclusion
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.conclusion_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Cause */}
                      {reportData.cause_togg === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Cause
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.cause_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Confidential Information */}
                {reportData.confi === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Confidential Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="inspectionDetails">
                        <Divider orientation="left" plain>
                          Has Confidential Comments?
                        </Divider>
                        <div className="reportData">
                          <Row gutter={16}>
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.conf_comment,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Footer */}

                {reportData.footer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Footer
                    </Divider>
                    <Card className="innerCard">
                      {reportData.upload_sign_toggle === "yes" ? (
                        <div className="inspectionDetails">
                          {reportData?.signurl ? (
                            <>
                              <Divider orientation="left" plain>
                                Upload Signature
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={24}>
                                    <div className="formGroup customSignatureImage">
                                      <Image
                                        src={reportData?.signurl}
                                        alt="image1"
                                        preview={false}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Footer text */}
                      {reportData.footer_text_info === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Footer Text
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: reportData?.footer_text,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>
                ) : (
                  ""
                )}

                {/* Add Attachments */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Add Attachments
                  </Divider>
                  <div className="reportData">
                    <Row gutter={16}>
                      <Image.PreviewGroup>
                        {attachments &&
                          Object.keys(attachments).map((keyName, index) => (
                            <Col xs={6} md={6} key={index}>
                              <div className="formGroup customFormGroup">

                                <Image
                                  src={attachments[keyName].url}
                                  preview={true}
                                  alt="image1"
                                  className="img-fluid"
                                />

                                <p className="count">{attachments[keyName].id}</p>
                                <p className="descriptions">
                                  {attachments[keyName].desc}

                                </p>
                              </div>

                            </Col>
                          ))}
                      </Image.PreviewGroup>
                    </Row>
                  </div>
                </div>

                {/* CERTIFICATION */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    CERTIFICATION
                  </Divider>
                  <div className="reportData footerReportData">
                    <Row justify="center">
                      {reportLogos &&
                        Object.keys(reportLogos).map((keyName, index) => (
                          <Col xs={2} md={2} key={index} className="logoCol">
                            <div className="logoDiv">
                              <div className="innerDiv">
                                <img
                                  src={reportLogos[keyName].logo_url}
                                  alt="not found"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>

                {/* Private Note */}
                {reportData.private_notes_togg === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Private Notes
                    </Divider>
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <div className="formGroup sunEditorEditable sun-editor-editable">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: reportData?.private_notes,
                              }}
                            ></p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <Row gutter={16} className="pt-3">
                  <Col xs={24} md={24} className="text-center">
                    <Button className="btnPrimary" onClick={showModal}>
                      Select Your PDF Layout
                    </Button>
                    {reportData?.current_user_role === "um_manufacturer" ||
                      reportData?.current_user_role === "um_claim-analyst" ||
                      reportData?.current_user_role === "um_dealer" ? (
                      <Button
                        className="btnPrimary"
                        onClick={() => downloadPdf()}
                        loading={downloadPdfLoading}
                      >
                        Download PDF
                      </Button>
                    ) : reportData?.current_user_role === "um_inspector" ? (
                      <>
                        <Button
                          className="btnPrimary"
                          onClick={() => downloadPdf()}
                          loading={downloadPdfLoading}
                        >
                          Download PDF
                        </Button>
                        <Button
                          className="btnPrimary"
                          onClick={() => getPdf()}
                          loading={savePdfLoading}
                        >
                          Save and Continue
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </div>
      </div>
      <Modal
        title={"Choose PDF Template."}
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="pdfTemplateform"
          layout="vertical"
          onFinish={setPdfSettings}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <div className="modalContent pdfTemp">
            <div className="formGroup">
              <Radio.Group
                name="radiogroup"
                onChange={handleRadio}
                defaultValue={parseInt(selectedTemplate)}
              >
                {Object.keys(pdfTemplates).map((keyName, index) => (
                  <Radio.Button
                    value={pdfTemplates[keyName].template_id}
                    key={index}
                  >
                    <Image
                      src={pdfTemplates[keyName].template_url}
                      className="img-fluid"
                      alt="logos"
                      preview={false}
                    />
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
          </div>
          <Button className="btnPrimary" htmlType="submit">
            Save and Exit
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default SingleCarpetBasic;
