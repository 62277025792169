import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

export function createCoverLetter(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + 'createcover/', values)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function upateCoverLetter(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + 'updatecover/', values)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getCoverData(cover_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_cover/'+ cover_id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}



export function getCoverPdf(cover_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_cover_pdf/'+ cover_id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
export function getCoverimage(req_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + "get_cover_image/" + req_id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}