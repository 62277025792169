import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader/index";
import { Card, Row, Col, Image, Divider, Button,Skeleton,Modal,Form,Radio,List } from "antd";
import PlaceHolderImg from "../../assets/images/logoPlaceholder.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as NwfaSandFinishService from "../../services/createNwfaService";
import * as ReportServices from "../../services/SingleReport";
import { FormOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2';
import * as setPdfSettingsService from "../../services/PdfSettingsService";

const SingleNwfaSandReport = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(true);
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);
  const [savePdfLoading, setSavePdfLoading] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [reportLogos, setReportLogos] = useState(null);
  // const [defaultPdfTemplate, setDefaultPdfTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
    getPdfTemplateData();
    setAttachments();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setAttachments(reportData?.attachments);
    setReportLogos(reportData?.selectededlogos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.attachments, reportData?.selectededlogos]);
  console.info("Nwfa Sand Report Data", reportData);

  const pdfTemplates = {
    1: {
      index: 1,
      template_id: 1,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040610/template_pdf_img_1.png",
    },
    2: {
      index: 2,
      template_id: 2,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040629/template_pdf_img_2.png",
    },
    3: {
      index: 3,
      template_id: 3,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/22093235/template_3_img.png",
    },
    4: {
      index: 4,
      template_id: 4,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040619/template_pdf_img_4.png",
    },
    5: {
      index: 5,
      template_id: 5,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040615/template_pdf_img_5.png",
    },
    6: {
      index: 6,
      template_id: 6,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/08/28053646/template_pdf_img_6-1.png",
    },
  };
  const handleRadio = (e) => {
    
    setSelectedTemplate(e.target.value);
  }
  const setPdfSettings = (value) => {
    dispatch(setPdfSettingsService.PdfSettingsService(selectedTemplate))
      .then((res) => {
        console.warn({ res });
       Swal.fire({
          icon: 'success',
          title: 'PDF Template selected successfully',
        })
        handleCancel();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
 
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getPdfTemplateData = () => {
    setLoading(true);
    dispatch(setPdfSettingsService.getpdfTemplate(selectedTemplate))
    .then((res) => {
      setSelectedTemplate(res?.pdf_template_id);

    })
    .catch((err) => {
      console.info("Get Error Edit State...");
      setLoading(false);
    });
  }

    const getPdf = () => {
    setSavePdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setSavePdfLoading(false);
        
        // After generating the PDF, show the popup to choose the next action
        Swal.fire({
          title: 'PDF Generated Successfully',
          text: 'Please choose your next action:',
          icon: 'success',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Go to Invoice',
          denyButtonText: 'Download PDF',
          cancelButtonText: 'Skip to Cover Letter',
          reverseButtons: true,
          confirmButtonColor: '#0A497B',
          denyButtonColor: '#0A497B',
          cancelButtonColor: '#0A497B',
        }).then((result) => {
          if (result.isConfirmed) {
            if (reportData.invoice_generated === "NO") {
              navigate("/create-invoice/?req_id=" + reportData.req_id);
            } else {
              navigate("/report-invoice/?invoice_id=" + reportData.invoice_id);
            }
          } else if (result.isDenied) {
            // Logic to download the generated PDF
            const link = document.createElement('a');
            link.href = res.data; // Assuming res.data.pdf_url contains the URL of the generated PDF
            link.download = 'GeneratedReport.pdf'; // Specify the file name
            link.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            if (reportData.cover_generated === "NO") {
              navigate("/create-cover-letter/?req_id=" + reportData.req_id);
            } else {
              navigate("/cover-letter/?cover_id=" + reportData.cover_id);
            }
          }
        });
  
      })
      .catch((err) => {
        let errorMessage;
  
        if (err.response) {
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }
  
        alert(errorMessage);
        setSavePdfLoading(false);
      });
  };
  const downloadPdf = () => {
    setDownloadPdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setDownloadPdfLoading(false);
        // window.open(res.data, 'download');
        window.open(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const fetchData = (params = {}) => {
    const queryParams = new URLSearchParams(window.location.search);

    const report_id = queryParams.get("report_id");
    setLoading(true);
    dispatch(NwfaSandFinishService.getSingleNwfaReport(report_id))
      .then((res) => {
        console.info(res);
        setReportData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const handleNavigate = () => {
    navigate(
      "/update-nwfa-sand-finish-report/?report_id=" + reportData.report_id
    );
  };
  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Inspection Request | Professional Report" />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              {reportData?.current_user_role === "um_inspector" ? (
                <Button
                  type="success"
                  className="btn inspectionAdd"
                  onClick={() => handleNavigate()}
                  icon={<FormOutlined />}
                >
                  Edit Report
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
          {loading ? (
            <div className="spinDiv fullHeight">
              {/* <Spin size="large" /> */}
              <Skeleton loading={loading} active avatar>
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
          
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
            </div>
          ) : (
            <Card className="reportCard" id="quickReportContent">
              <div className="reportCardBody">
                <div className="inspectorInfo">
                  <Row gutter={30}>
                    <Col xs={24} md={4}>
                      <div className="inspectorLogo">
                        <Image
                          src={
                            reportData?.logo_url
                              ? reportData.logo_url
                              : PlaceHolderImg
                          }
                          className="ing-fluid"
                          alt="InspectorLogo"
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={20}>
                      <div className="inspectorDetails">
                        <div className="inspecDetailHead">
                          <h5 className="inspcTitle">
                            Inspection Request | Professional Report
                          </h5>
                        </div>
                        <div className="inspcInfo">
                          <p className="orgName">
                            {reportData?.organization_name_ins}
                          </p>
                          <p className="inspcData">
                            <span className="inspcSpan">
                              {reportData?.insp_fullname_ins}
                            </span>

                            <span className="inspcSpan">
                              {reportData?.address_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Office- {reportData?.phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Cell- {reportData?.company_phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.websiteins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.email_ins}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Inspection Details */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    General Information
                  </Divider>
                  <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Claim Number</label>
                            <p>{reportData?.claim_number}</p>
                          </div>
                        </Col>
                        {reportData?.inspection_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspector Name</label>
                              <p>{reportData?.inspection_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {reportData?.h_bi_ins_date ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Date</label>
                              <p>{reportData?.h_bi_ins_date}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {reportData?.inspection_cp ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspector CP #</label>
                              <p>{reportData?.inspection_cp}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>
                {/* Commissioning Party Information */}
                {reportData.comm_party_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.comm_party_drop ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Commissioning Party</label>
                                {reportData?.comm_party_drop === "Other" ? (
                                  <p>{reportData?.comm_party_drop_other}</p>
                                ) : (
                                  <p>{reportData?.comm_party_drop}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.commisioning_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.commisioning_party_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.commisioning_party_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.commisioning_party_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.commisioning_party_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.commisioning_party_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.commisioning_party_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.commisioning_party_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.commisioning_party_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>
                                  {reportData?.commisioning_party_contact_number}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.commisioning_party_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Homeowner/Building Information */}
                {reportData.homeowner_building_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Homeowner/Building Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.homeowner_Name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.homeowner_Name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.homeowner_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.homeowner_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.homeowner_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.homeowner_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.homeowner_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                              {reportData?.homeowne_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.homeowne_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.homeowner_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>{reportData?.homeowner_contact_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.homeowner_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Flooring Contractor Information */}
                {reportData.flooring_cont_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Flooring Contractor Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.flooring_contractor_Name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.flooring_contractor_Name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.flooring_contractor_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.flooring_contractor_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.flooring_contractor_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.flooring_contractor_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.flooring_contractor_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.flooring_contractor_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.flooring_contractor_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.flooring_contractor_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>
                                  {reportData?.flooring_contractor_contact_number}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.flooring_contractor_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* General Contractor Information */}
                {reportData.gener_cont_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      General Contractor Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.general_contractor_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.general_contractor_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                             {reportData?.general_contractor_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.general_contractor_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.general_contractor_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.general_contractor_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.general_contractor_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.general_contractor_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.general_contractor_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.general_contractor_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.general_contractor_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.general_contractor_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.general_contractor_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.general_contractor_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.general_contractor_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>
                                  {reportData?.general_contractor_contact_number}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.general_contractor_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.general_contractor_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Manufacturer Information */}
                {reportData.manuf_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Manufacturer Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.manufacturer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.manufacturer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.manufacturer_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.manufacturer_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.manufacturer_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.manufacturer_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.manufacturer_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.manufacturer_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.manufacturer_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.manufacturer_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_contact_info ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Main Contact Info</label>
                                <p>{reportData?.manufacturer_contact_info}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_title ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Title</label>
                                <p>{reportData?.manufacturer_title}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>{reportData?.manufacturer_contact_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.manufacturer_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Retailer Information */}
                {reportData.retailer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Retailer Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.retailer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.retailer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.retailer_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.retailer_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.retailer_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.retailer_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.retailer_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.retailer_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.retailer_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.retailer_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_contact_info ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Main Contact Info</label>
                                <p>{reportData?.retailer_contact_info}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_title ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Title</label>
                                <p>{reportData?.retailer_title}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>{reportData?.retailer_contact_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.retailer_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Jobsite Information */}
                {reportData.jobsite_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Jobsite Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.jobsite_date ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Jobsite Visit Date</label>
                                <p>{reportData?.jobsite_date}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.jobsite_time ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Time</label>
                                <p>{reportData?.jobsite_time} {reportData?.time_type}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.property_dropdown ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Property Type</label>
                                <p>{reportData?.property_dropdown}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.building_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Type</label>
                                {reportData?.building_type === "Other" ? (
                                  <p>{reportData?.build_type_other}</p>
                                ) : (
                                  <p>{reportData?.building_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {/* <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Other</label>
                            <p>{reportData?.general_contractor_name}</p>
                          </div>
                        </Col> */}
                          {reportData?.building_style ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Style</label>
                                {reportData?.building_style === "Other" ? (
                                  <p>{reportData?.other_building_style}</p>
                                ) : (
                                  <p>{reportData?.building_style}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {/* <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Other</label>
                            <p>{reportData?.general_contractor_name}</p>
                          </div>
                        </Col> */}
                          {reportData?.jobsite__age_building ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Age of Building</label>
                                <p>{reportData?.jobsite__age_building}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_bui_cons === "HideThisField" || reportData?.h_bui_cons === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>New Construction?</label>
                                  <p>
                                    {reportData?.h_bui_cons === "Yes" || reportData?.h_bui_cons === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_cons === "No" || reportData?.h_bui_cons === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.closing_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Closing Date</label>
                                    <p>{reportData?.closing_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}


                              {reportData?.moving_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Move in Date</label>
                                    <p>{reportData?.moving_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.operational_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>HVAC Operational Date</label>
                                    <p>{reportData?.operational_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                                  </>
                                  )}
                            </>
                          )
                        }
                          {reportData?.h_bui_occupied === "HideThisField" || reportData?.h_bui_occupied === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Occupied</label>
                                  <p>
                                    {reportData?.h_bui_occupied === "Yes" || reportData?.h_bui_occupied === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_occupied === "No" || reportData?.h_bui_occupied === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.adults ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Adults</label>
                                        <p>{reportData?.adults}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.childrens ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Children</label>
                                        <p>{reportData?.childrens}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.pets ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Pets</label>
                                        <p>{reportData?.pets}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                        </Row>
                        <Row gutter={16}>
                          {reportData?.concerns ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>Statement of Concern</label>
                                <p>{reportData?.concerns}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Claim History */}
                {reportData.claim_history === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Claim History
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.date_of_sanding ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date of Sanding</label>
                                <p>{reportData?.date_of_sanding}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_completion ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date of Completion</label>
                                <p>{reportData?.date_completion}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_occupied ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Occupied</label>
                                <p>{reportData?.date_occupied}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_completion_first ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Complaint First Noticed</label>
                                <p>{reportData?.date_completion_first}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_reported ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Reported</label>
                                <p>{reportData?.date_reported}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.reported_to ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Reported to</label>
                                <p>{reportData?.reported_to}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.clean_product ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Cleaning Products/Methods Used</label>
                                <p>{reportData?.clean_product}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.how_often ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>How Often Cleaned?</label>
                                <p>{reportData?.how_often}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.unusual_events ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Unusual Events</label>
                                <p>{reportData?.unusual_events}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Jobsite Conditions During Sanding and Finishing Process */}
                {reportData.jobsite_condition_ins_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Jobsite Conditions During Sanding and Finishing Process
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.mois_con_of_floor ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Moisture Content of Floor</label>
                                <p>{reportData?.mois_con_of_floor}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.mtr_mthod_used ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Method/Meter Used</label>
                                <p>{reportData?.mtr_mthod_used}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.temp_during_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Temperature (Interior)</label>
                                <p>
                                  {reportData?.temp_during_ins}
                                  {reportData?.temp_f_c}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rh_during_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>RH (Interior)</label>
                                <p>{reportData?.rh_during_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                         
                          {reportData?.hvac_oper_during_storage === "HideThisField" || reportData?.hvac_oper_during_storage === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>HVAC Operational</label>
                                  <p>{reportData?.hvac_oper_during_storage}</p>
                                </div>
                              </Col>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Sanding Process (Equipment & Abrasives) */}
                {reportData.sanding_process_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Sanding Process (Equipment & Abrasives)
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                        {reportData?.belt_drum_sander === "HideThisField" || reportData?.belt_drum_sander === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Belt/Drum Sander</label>
                                  <p>
                                    {reportData?.belt_drum_sander === "Yes" || reportData?.belt_drum_sander === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.belt_drum_sander === "No" || reportData?.belt_drum_sander === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.belt_drum_grit_seq ? (
                                      <Col xs={24} md={6}>
                                        <div className="formGroup">
                                          <label>Grit Sequence</label>
                                          <p>{reportData?.belt_drum_grit_seq}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    {reportData?.belt_drum_abrasive_used ? (
                                      <Col xs={24} md={6}>
                                        <div className="formGroup">
                                          <label>Abrasive Used</label>
                                          <p>{reportData?.belt_drum_abrasive_used}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                  )}
                            </>
                         )}








                        {reportData?.edger === "HideThisField" || reportData?.edger === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Edger</label>
                                  <p>
                                    {reportData?.edger === "Yes" || reportData?.edger === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.edger === "No" || reportData?.edger === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.edger_grit_seq ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Grit Sequence</label>
                                        <p>{reportData?.edger_grit_seq}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.edger_abrasive_used ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Abrasive Used</label>
                                        <p>{reportData?.edger_abrasive_used}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                         {reportData?.orbi_sander === "HideThisField" || reportData?.orbi_sander === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Orbital Sander</label>
                                  <p>
                                    {reportData?.orbi_sander === "Yes" || reportData?.orbi_sander === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.orbi_sander === "No" || reportData?.orbi_sander === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.orbi_sander_grit_seq ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Grit Sequence</label>
                                        <p>{reportData?.orbi_sander_grit_seq}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.orbi_sander_abrasive_used ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Abrasive Used</label>
                                        <p>{reportData?.orbi_sander_abrasive_used}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                         {reportData?.buffer === "HideThisField" || reportData?.buffer === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Buffer</label>
                                  <p>
                                    {reportData?.buffer === "Yes" || reportData?.buffer === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.buffer === "No" || reportData?.buffer === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.buffer_grit_seq ? (
                                <Col xs={24} md={12}>
                                  <div className="formGroup">
                                    <label>
                                      Grit Sequence & Pads/Papers Used
                                    </label>
                                    <p>{reportData?.buffer_grit_seq}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.buffer_abrasive_used ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Abrasive Used</label>
                                    <p>{reportData?.buffer_abrasive_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                                  </>
                                  )}
                            </>
                         )}
                         {reportData?.hard_plate_multi_head === "HideThisField" || reportData?.hard_plate_multi_head === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Hard Plate/Multi-Head Attachment</label>
                                  <p>
                                    {reportData?.hard_plate_multi_head === "Yes" || reportData?.hard_plate_multi_head === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.hard_plate_multi_head === "No" || reportData?.hard_plate_multi_head === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                 {reportData?.hard_plate_grit_seq ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Grit Sequence</label>
                                        <p>{reportData?.hard_plate_grit_seq}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.abrasive_abrasive_used ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Abrasive Used</label>
                                        <p>{reportData?.abrasive_abrasive_used}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                         {reportData?.planetary_sander === "HideThisField" || reportData?.planetary_sander === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Planetary Sander</label>
                                  <p>
                                    {reportData?.planetary_sander === "Yes" || reportData?.planetary_sander === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.planetary_sander === "No" || reportData?.planetary_sander === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                 {reportData?.planetary_sander_grit_seq ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Grit Sequence</label>
                                        <p>{reportData?.planetary_sander_grit_seq}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.plant_abrasive_used ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Abrasive Used</label>
                                        <p>{reportData?.plant_abrasive_used}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                         {reportData?.oth_sanders === "HideThisField" || reportData?.oth_sanders === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Other Sanders</label>
                                  <p>
                                    {reportData?.oth_sanders === "Yes" || reportData?.oth_sanders === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.oth_sanders === "No" || reportData?.oth_sanders === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                 {reportData?.oth_sanders_grit_seq ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Grit Sequence</label>
                                        <p>{reportData?.oth_sanders_grit_seq}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.oth_sanders_abrasive_used ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Abrasive Used</label>
                                        <p>{reportData?.oth_sanders_abrasive_used}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}

                          {reportData?.dust_contai === "HideThisField" || reportData?.dust_contai === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Dust Containment</label>
                                  <p>{reportData?.dust_contai}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.method_dust_contai ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Method of Containing Dust</label>
                                <p>{reportData?.method_dust_contai}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Finish/Colorant */}
                {reportData.finish_colorant_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Finish/Colorant
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {/* {reportData?.Stain_color_type === "yes" ? (
                            <> */}

                          {reportData?.Stain_color_type === "HideThisField" || reportData?.Stain_color_type === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Stain/Colorant Type(s)</label>
                                  <p>
                                    {reportData?.Stain_color_type === "Yes" || reportData?.Stain_color_type === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.Stain_color_type === "No" || reportData?.Stain_color_type === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.finish_products ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Product(s)</label>
                                    <p>{reportData?.finish_products}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_color ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Color</label>
                                    <p>{reportData?.finish_color}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.q_used ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Quantity Used</label>
                                    <p>{reportData?.q_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.covera_rat ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Coverage Rate</label>
                                    <p>{reportData?.covera_rat}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.tot_sqare_footage ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Total Square Footage</label>
                                    <p>{reportData?.tot_sqare_footage}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.app_m_tool ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Application Method/Tool</label>
                                    <p>{reportData?.app_m_tool}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.nof_coats ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Number of Coats</label>
                                    <p>{reportData?.nof_coats}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.dateof_appli ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date of Application</label>
                                    <p>{reportData?.dateof_appli}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}


                              {reportData?.manufac_req === "HideThisField" || reportData?.manufac_req === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                      <label>Manufacturer Requirements</label>
                                        <p>
                                          {reportData?.manufac_req === "Yes" || reportData?.manufac_req === "yes"
                                            ? "Yes"
                                            : "No"}
                                        </p>
                                      </div>
                                    </Col>
                                    {reportData?.manufac_req === "No" || reportData?.manufac_req === "no" ? (
                                                            ""
                                                          ) : (
                                                            <>
                                      {reportData?.manu_co_rate ? (
                                            <Col xs={24} md={6}>
                                              <div className="formGroup">
                                                <label>Coverage Rate</label>
                                                <p>{reportData?.manu_co_rate}</p>
                                              </div>
                                            </Col>
                                          ) : (
                                            ""
                                          )}
                                          {reportData?.manu_applica_method ? (
                                            <Col xs={24} md={6}>
                                              <div className="formGroup">
                                                <label>Application Method</label>
                                                <p>{reportData?.manu_applica_method}</p>
                                              </div>
                                            </Col>
                                          ) : (
                                            ""
                                          )}
                                          {reportData?.manu_day_time_req ? (
                                            <Col xs={24} md={6}>
                                              <div className="formGroup">
                                                <label>Dry Time Requirements</label>
                                                <p>{reportData?.manu_day_time_req}</p>
                                              </div>
                                            </Col>
                                          ) : (
                                            ""
                                          )}
                                          
                                        </>
                                        )}
                                  </>
                                )
                              }
                              {reportData?.process_system ? (
                                            <Col xs={24} md={24}>
                                              <div className="formGroup">
                                                <label>Process/System</label>
                                                <p>{reportData?.process_system}</p>
                                              </div>
                                            </Col>
                                          ) : (
                                            ""
                              )}
                            </>
                            )}
                            </>
                            )
                          }
                          
                          {reportData?.selaer_type === "HideThisField" || reportData?.selaer_type === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Sealer Type(s)</label>
                                  <p>
                                    {reportData?.selaer_type === "Yes" || reportData?.selaer_type === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.selaer_type === "No" || reportData?.selaer_type === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                
                              {reportData?.sealer_Product ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Product by Sealer</label>
                                    <p>{reportData?.sealer_Product}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.production_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Production Date by Sealer</label>
                                    <p>{reportData?.production_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.lot_number_by_sealer ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Lot Number by Sealer</label>
                                    <p>{reportData?.lot_number_by_sealer}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                               {reportData?.sealer_totalsquare ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Total Square Footage by Sealer</label>
                                    <p>{reportData?.sealer_totalsquare}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.product_q_used ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Quantity Used by Sealer</label>
                                    <p>{reportData?.product_q_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.prod_covera_rat ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Coverage Rate by Sealer</label>
                                    <p>{reportData?.prod_covera_rat}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                             
                              {reportData?.produ_app_m_tool ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>
                                      Application Method/Tool by Sealer
                                    </label>
                                    <p>{reportData?.produ_app_m_tool}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.prod_nof_coats ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Number of Coats by Sealer</label>
                                    <p>{reportData?.prod_nof_coats}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.product_dateof_appli ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date of Application by Sealer</label>
                                    <p>{reportData?.product_dateof_appli}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.prod_manufac_req === "HideThisField" || reportData?.prod_manufac_req === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label> Manufacturer Requirements by Sealer</label>
                                        <p>
                                          {reportData?.prod_manufac_req === "Yes" || reportData?.prod_manufac_req === "yes"
                                            ? "Yes"
                                            : "No"}
                                        </p>
                                      </div>
                                    </Col>
                                    {reportData?.prod_manufac_req === "No" || reportData?.prod_manufac_req === "no" ? (
                                                            ""
                                                          ) : (
                                                            <>
                                      {reportData?.product_manu_co_rate ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>Coverage Rate by Sealer</label>
                                              <p>{reportData?.product_manu_co_rate}</p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        {reportData?.prod_manu_applica_method ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>
                                                Application Method by Sealer
                                              </label>
                                              <p>
                                                {reportData?.prod_manu_applica_method}
                                              </p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        {reportData?.prod_manu_day_time_req ? (
                                          <Col xs={24} md={6}>
                                            <div className="formGroup">
                                              <label>
                                                Dry Time Requirements by Sealer
                                              </label>
                                              <p>
                                                {reportData?.prod_manu_day_time_req}
                                              </p>
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        </>
                                        )}
                                  </>
                                )
                              }
                              {reportData?.product_process_system ? (
                                <Col xs={24} md={24}>
                                  <div className="formGroup">
                                    <label>Process/System by Sealer</label>
                                    <p>{reportData?.product_process_system}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                            </>
                            )}
                            </>
                            )
                          }
                          {reportData?.finish_type === "HideThisField" || reportData?.finish_type === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Finish Type(s)</label>
                                  <p>
                                    {reportData?.finish_type === "Yes" || reportData?.finish_type === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.finish_type === "No" || reportData?.finish_type === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.finish_Product ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Product by Finish Type</label>
                                    <p>{reportData?.finish_Product}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.Sheen_finish ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Sheen by Finish Type</label>
                                    <p>{reportData?.Sheen_finish}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_production_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Production Date by Finish Type</label>
                                    <p>{reportData?.finish_production_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.lot_number_by_fini ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Lot Number by Finish Type</label>
                                    <p>{reportData?.lot_number_by_fini}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_totalsquare ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>
                                      Total Square Footage by Finish Type
                                    </label>
                                    <p>{reportData?.finish_totalsquare}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finsih_q_used ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Quantity Used by Finish Type</label>
                                    <p>{reportData?.finsih_q_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_prod_covera_rat ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Coverage Rate by Finish Type</label>
                                    <p>{reportData?.finish_prod_covera_rat}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_app_m_tool ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>
                                      Application Method/Tool by Finish Type
                                    </label>
                                    <p>{reportData?.finish_app_m_tool}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_nof_coats ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Number of Coats by Finish Type</label>
                                    <p>{reportData?.finish_nof_coats}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.date_st_coat_finish ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date of 1st Coat Finish</label>
                                    <p>{reportData?.date_st_coat_finish}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_nd_coat ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date of 2nd Coat</label>
                                    <p>{reportData?.finish_nd_coat}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.finish_rd_coat ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date of 3rd Coat</label>
                                    <p>{reportData?.finish_rd_coat}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.other_coat ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date of Other Coats</label>
                                    <p>{reportData?.other_coat}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}


                              
                              {reportData?.finish_manufac_req === "HideThisField" || reportData?.finish_manufac_req === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Manufacturer Requirements by Finish Type</label>
                                        <p>
                                          {reportData?.finish_manufac_req === "Yes" || reportData?.finish_manufac_req === "yes"
                                            ? "Yes"
                                            : "No"}
                                        </p>
                                      </div>
                                    </Col>
                                    {reportData?.finish_manufac_req === "No" || reportData?.finish_manufac_req === "no" ? (
                                                            ""
                                                          ) : (
                                                            <>
                                      {reportData?.finish_manu_co_rate ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>
                                          Coverage Rate by Finish Type
                                        </label>
                                        <p>{reportData?.finish_manu_co_rate}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.finish_manu_applica_method ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>
                                          Application Method by Finish Type
                                        </label>
                                        <p>
                                          {reportData?.finish_manu_applica_method}
                                        </p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.fini_day_time_req ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>
                                          Dry Time Requirements by Finish Type
                                        </label>
                                        <p>{reportData?.fini_day_time_req}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  
                                        </>
                                        )}
                                  </>
                                )
                              }
                              {reportData?.process_system_finishtype ? (
                                    <Col xs={24} md={24}>
                                      <div className="formGroup">
                                        <label>
                                          Process/System by Finish Type
                                        </label>
                                        <p>
                                          {reportData?.process_system_finishtype}
                                        </p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                              )}
                            </>
                            )}
                            </>
                            )
                          }
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Commissioning Party Comments */}
                {reportData.comm_party_comm_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Commissioning Party Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_comm_party_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* End-User Comments */}
                {reportData.enduser_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      End-User Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>End-User Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_end_user_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Flooring Contractor Comments */}
                {reportData.flooring_contractor_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Flooring Contractor Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Flooring Contractor Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_floor_con_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Other Involved Party Comments */}
                {reportData.other_invol_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Other Involved Party Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Other Involved Party Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_oth_inv_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Concerns */}
                {reportData.concern === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Concerns
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Condition of Existing Floor</label>
                              {reportData?.concern_con_exi_flr === "Other" ? (
                                <p>{reportData?.concern_con_exi_flr_other}</p>
                              ) : (
                                <p>{reportData?.concern_con_exi_flr}</p>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Flooring */}
                {reportData.flooring_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Flooring
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.concern_spec_drop ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Species</label>
                                <p>{reportData?.concern_spec_drop}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {/* {reportData?.flooringtype  ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Flooring Type</label>
                              {reportData?.flooringtype === "Other" ? (
                                <p>{reportData?.concern_spec_drop_other}</p>
                              ) : (
                                reportData?.flooringtype === "Plank" ?
                                <p>{reportData?.flooringtype}</p>
                                : <p>{reportData?.flooringtype}</p>
                              )}
                            </div>
                          </Col>
                        ) : (
                         ""
                        )} */}
                          {reportData?.flooringtype ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Flooring Type</label>
                                {reportData?.flooringtype === "Other" ? (
                                  <p>{reportData?.concern_spec_drop_other}</p>
                                ) : (
                                  <p>{reportData?.flooringtype}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.flooringtype === "Plank" ? (
                            <>
                              {reportData?.concern_spec_drop_width ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Width of Plank</label>

                                    <p>{reportData?.concern_spec_drop_width}</p>

                                  </div>
                                </Col> : ""}
                            </>
                          ) : (
                            ""
                          )}
                          {reportData?.pre_fini_floor ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Previously Finished Floor</label>
                                <p>{reportData?.pre_fini_floor}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.type_of_finish_exis_flr ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Type of Finish on Existing Floor</label>
                                <p>{reportData?.type_of_finish_exis_flr}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.totalsqft_per_cont ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Total Sq/ft</label>
                                <p>{reportData?.totalsqft_per_cont} {reportData?.measure_type15}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.per_flo_aff ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>% of Floor Affected</label>
                                <p>{reportData?.per_flo_aff}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Rooms Being Inspected */}
                {reportData.rooms_being_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Rooms Being Inspected
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.rooms_entry ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Entry</label>
                                <p>{reportData?.rooms_entry} {reportData?.measure_type1}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_formal ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Formal</label>
                                <p>{reportData?.rooms_formal} {reportData?.formal_temp}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_kit ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Kitchen</label>
                                <p>{reportData?.rooms_kit} {reportData?.measure_type3}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_study ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Study</label>
                                <p>{reportData?.rooms_study} {reportData?.measure_type4}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_hall ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Hall(s)</label>
                                <p>{reportData?.rooms_hall} {reportData?.measure_type5}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_library ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Library</label>
                                <p>{reportData?.rooms_library} {reportData?.measure_type6}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_great_room ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Great Room</label>
                                <p>{reportData?.rooms_great_room} {reportData?.measure_type7}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_living_room ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Living Room</label>
                                <p>{reportData?.rooms_living_room} {reportData?.measure_type9}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_dining_roo ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Dining Room</label>
                                <p>{reportData?.rooms_dining_roo} {reportData?.measure_type10}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_nook ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Nook</label>
                                <p>{reportData?.rooms_nook} {reportData?.measure_type11}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_part ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Pantry</label>
                                <p>{reportData?.rooms_part} {reportData?.measure_type12}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.powderBath ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Powder Bath</label>
                                <p>{reportData?.powderBath} {reportData?.measure_type13}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.bathArea[0]['bath_area'] !== "" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Bath</label>
                                {reportData?.bathArea.map((single) => (
                                  <p>
                                    {single.bath_area} {single.bath_area_unit}
                                  </p>
                                ))}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.masterbedroom ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Master Bedroom</label>
                                <p>{reportData?.masterbedroom} {reportData?.measure_type14}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.masterBedroomArea[0]['master_bedroom_area'] !== "" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Bed</label>
                                {reportData?.masterBedroomArea.map((single) => (
                                  <p>
                                    {single.master_bedroom_area}{" "}
                                    {single.master_bedroom_area_unit}
                                  </p>
                                ))}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.other_rooms ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Other Rooms</label>
                                <p>{reportData?.other_rooms}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Special Considerations */}
                {reportData.special_consideration_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Special Considerations
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.special_powder ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Power</label>

                                {reportData?.special_powder === "Other" ? (
                                  <p>{reportData?.special_powder_other}</p>
                                ) : (
                                  <p>{reportData?.special_powder}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.location_of_powder_source ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Location of Power Source</label>
                                <p>{reportData?.location_of_powder_source}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.traff_use ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Traffic Use</label>
                                <p>{reportData?.traff_use}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.airFlow_obstacles ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Airflow Obstacles</label>
                                <p>{reportData?.airFlow_obstacles}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.large_wind_flr_fc ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Large Windows on Floor Facing</label>
                                <p>{reportData?.large_wind_flr_fc}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.heating_system ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Type of Heating System</label>
                                {reportData?.heating_system === "Other" ? (
                                  <>
                                    <p>
                                      {reportData?.heating_system_other}
                                    </p>
                                  </>
                                ) : (
                                  <p>{reportData?.heating_system}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.type_cooling_sys ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Type of Cooling System</label>
                                {reportData?.type_cooling_sys === "Other" ? (
                                  <>
                                    <p>
                                      {reportData?.air_on_other}
                                    </p>
                                  </>
                                ) : (
                                  <p>{reportData?.type_cooling_sys}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          

                          {reportData?.window_coverings === "HideThisField" || reportData?.window_coverings === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Window Coverings</label>
                                  <p>{reportData?.window_coverings}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.tinted_glass_uv_protections === "HideThisField" || reportData?.tinted_glass_uv_protections === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Tinted Glass/UV Protections</label>
                                  <p>{reportData?.tinted_glass_uv_protections}</p>
                                </div>
                              </Col>
                          )}
                          
                          {reportData?.other_trades ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>
                                  Other Trades | Schedules Prior to, During or After
                                  Installation
                                </label>
                                <p>{reportData?.other_trades}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.any_special_or_unique_use ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>Any Special or Unique Use</label>
                                <p>{reportData?.any_special_or_unique_use}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.summ_phy_descri ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup">
                                <label>Summary of Physical Description</label>
                                <p>{reportData?.summ_phy_descri}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Jobsite Conditions at Inspection */}
                {reportData.jobsite_con_ins === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Jobsite Conditions at Inspection
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.moist_cont_floor ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Moisture Content of Floor</label>
                                <p>{reportData?.moist_cont_floor}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.meter_meth_used ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Method/Meter Used</label>
                                <p>{reportData?.meter_meth_used}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.hvac_sys_operating === "HideThisField" || reportData?.hvac_sys_operating === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>HVAC System Operating</label>
                                  <p>{reportData?.hvac_sys_operating}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.hvac_tsti === "HideThisField" || reportData?.hvac_tsti === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>HVAC Testing</label>
                                  <p>
                                    {reportData?.hvac_tsti === "Yes" || reportData?.hvac_tsti === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.hvac_tsti === "No" || reportData?.hvac_tsti === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.Temp_hvac ? (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                          <label>Temperature</label>
                                          <p>
                                            {reportData?.Temp_hvac}
                                            {reportData?.measure_type16}
                                          </p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                  )}
                                  {reportData?.rel_humidity ? (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                          <label>Relative Humidity(%)</label>
                                          <p>{reportData?.rel_humidity}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                  )}
                                  {reportData?.m_use ? (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                          <label>Meter Used</label>
                                          <p>{reportData?.m_use}</p>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                  )}
                                  </>
                                )}
                            </>
                          )}

                        </Row>

                        <Row gutter={16}>
                        
                          {reportData?.h_dehu_syst === "HideThisField" || reportData?.h_dehu_syst === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                  <label>Humidification/Dehumidification System</label>
                                  <p>
                                    {reportData?.h_dehu_syst === "Yes" || reportData?.h_dehu_syst === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_dehu_syst === "No" || reportData?.h_dehu_syst === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.humidification === "HideThisField" || reportData?.humidification === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Is Humidification System Operating?</label>
                                    <p>
                                      {reportData?.humidification === "Yes" || reportData?.humidification === "yes"
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </Col>
                                {reportData?.humidification === "No" || reportData?.humidification === "no" ? (
                                                        ""
                                                      ) : (
                                                        <>
                                  <Col xs={24} md={8}>
                                    <div className="formGroup">
                                      <label>
                                        Is Humidification System Operating?
                                      </label>
                                      <p>
                                        {reportData?.humidification === "yes"
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </Col>
                                  {reportData?.th_control_settings ? (
                                    <Col xs={24} md={8}>
                                      <div className="formGroup">
                                        <label>Thermostat Control Settings</label>
                                        <p>{reportData?.th_control_settings}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  

                                  {reportData?.data_logger === "HideThisField" || reportData?.data_logger === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Data Logger</label>
                                          <p>{reportData?.data_logger}</p>
                                        </div>
                                      </Col>
                                  )}
                                  {reportData?.programmable === "HideThisField" || reportData?.programmable === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>
                                          Is Humidification/Dehumidification System
                                          Programmable?
                                        </label>
                                          <p>{reportData?.programmable}</p>
                                        </div>
                                      </Col>
                                  )}
                                  {reportData?.surface_temp ? (
                                    <Col xs={24} md={12}>
                                      <div className="formGroup">
                                        <label>
                                          Surface Temperature for
                                          Humidification/Dehumidification System
                                        </label>
                                        <p>{reportData?.surface_temp}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.dehum_meter_meth_used ? (
                                    <Col xs={24} md={12}>
                                      <div className="formGroup">
                                        <label>
                                          Method/Meter for
                                          Humidification/Dehumidification System
                                          Used
                                        </label>
                                        <p>{reportData?.dehum_meter_meth_used}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                          
                                    </>
                                    )}
                                  </>
                                )}
                                </>
                                  )}
                              </>
                            )
                          }
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : ""}
                {/* Additional Testing Information */}
                {reportData.additional_testing_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Additional Testing Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Additional Testing Information</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.add_testing_information,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Applied Industry Standards */}
                {reportData.apply_industry_stndr_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Applied Industry Standards
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Applied Industry Standards</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.apply_industry_stndr,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Conclusion/Cause */}
                {reportData.conclusion_cause_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Conclusion/Cause
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Conclusion/Cause</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.conclusion_cause,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Footer */}
                {reportData.footer_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Footer
                    </Divider>
                    <Card className="innerCard">
                      {reportData.upload_sign_toggle === "yes" ? (
                        <div className="inspectionDetails">
                          {reportData?.signurl ? (
                            <>
                              <Divider orientation="left" plain>
                                Upload Signature
                              </Divider>
                              <div className="reportData">
                                <Row gutter={16}>
                                  <Col xs={24} md={24}>
                                    <div className="formGroup customSignatureImage">
                                      <Image
                                        src={reportData?.signurl}
                                        alt="image1"
                                        preview={false}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {reportData.footer_text_info === "yes" ? (
                        <div className="inspectionDetails">
                          <Divider orientation="left" plain>
                            Footer Text
                          </Divider>
                          <div className="reportData">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup sunEditorEditable sun-editor-editable">
                                  <label>Footer Text</label>
                                  <p>
                                    {" "}
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: reportData?.footer_text,
                                      }}
                                    ></p>
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  </div>) : ""}
                {/*  Add Attachements */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Add Attachments
                  </Divider>
                  <div className="reportData">
                    <Row gutter={16}>
                        <Image.PreviewGroup>
                      {attachments &&
                        Object.keys(attachments).map((keyName, index) => (
                          <Col xs={6} md={6} key={index}>
                            <div className="formGroup customFormGroup">
                              <Image
                                src={attachments[keyName].url}
                                alt="image1"
                                className="img-fluid"
                                preview={true}
                              />
                              <p className="count">{attachments[keyName].id}</p>
                              <p className="descriptions"> {attachments[keyName].desc}</p>
                            </div>
                          </Col>
                        ))}
                        </Image.PreviewGroup>
                    </Row>
                  </div>
                </div>
                {/*  Certification */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Certification
                  </Divider>
                  <div className="reportData footerReportData">
                    <Row justify="center">
                      {reportLogos &&
                        Object.keys(reportLogos).map((keyName, index) => (
                          <Col xs={2} md={2} key="" className="logoCol">
                            <div className="logoDiv">
                              <div className="innerDiv">
                                <img
                                  src={reportLogos[keyName].logo_url}
                                  alt="not found"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
                {/*   Private Notes */}
                {reportData.private_notes_togg === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Private Notes
                    </Divider>
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={24}>
                          <div className="formGroup sunEditorEditable sun-editor-editable">
                            <label>Private Notes</label>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: reportData?.private_notes,
                              }}
                            ></p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Row gutter={16} className="pt-3">
                  <Col xs={24} md={24} className="text-center">
                  <Button
                        className="btnPrimary"
                        onClick={showModal}
                      >
                        Select Your PDF Layout
                      </Button>
                    {reportData?.current_user_role === "um_manufacturer" ||
                      reportData?.current_user_role === "um_claim-analyst" ||
                      reportData?.current_user_role === "um_dealer" ? (
                      <Button
                        className="btnPrimary"
                        onClick={() => downloadPdf()}
                        loading={downloadPdfLoading}
                      >
                        Download PDF
                      </Button>
                    ) : reportData?.current_user_role === "um_inspector" ? (
                      <>
                        <Button
                          className="btnPrimary"
                          onClick={() => downloadPdf()}
                          loading={downloadPdfLoading}
                        >
                          Download PDF
                        </Button>
                        <Button
                          className="btnPrimary"
                          onClick={() => getPdf()}
                          loading={savePdfLoading}
                        >
                          Save and Continue
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </div>
      </div>
      <Modal
        title={"Choose PDF Template."}
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="pdfTemplateform"
          layout="vertical"
          onFinish={setPdfSettings}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          
          <div className="modalContent pdfTemp">
            <div className="formGroup">
              <Radio.Group
                name="radiogroup"
                onChange={handleRadio}
                defaultValue={parseInt(selectedTemplate)}>

                  {Object.keys(pdfTemplates).map((keyName, index) => (
                    <Radio.Button
                      value={pdfTemplates[keyName].template_id}
                      key={index}
                      
                    >
                      <Image
                        src={pdfTemplates[keyName].template_url}
                        className="img-fluid"
                        alt="logos"
                        preview={false}
                      />
                  </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
          </div>
          <Button className="btnPrimary" htmlType="submit">
            Save and Exit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default SingleNwfaSandReport;
