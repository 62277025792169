import React, { useState, useEffect } from 'react';
import PageHeader from "../../components/PageHeader";
import ButtonBar from '../../components/ButtonNavigation'
import { Card, Form, Button, Row } from 'antd';
import Upload from '../InspectionReport/upload';
import * as AttachmentsService from '../../services/Attachments';
import { useDispatch } from "react-redux";

function Attachements() {
  var dispatch = useDispatch();
  const [form] = Form.useForm();
  const [ attachments, setAttachments ] = useState([]);
  const [list1, setList1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(loading);

  const fetchData = (params = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    
    dispatch(AttachmentsService.getAttachments(report_id))
      .then((res) => {
        console.info("Attachments", res);
        let data1 = []
        if(res.attachments){
           Object.keys(res.attachments).forEach(function (key, index) {
            data1.push(res.attachments[key])
          });
        }
        setAttachments(data1);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const takefileList = (list) => {
    setList1(list);
  };

  const onFinish = (values) => {
    enterLoading(0);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    values.attachments = list1;
    values.report_id = report_id;
    console.log('Success:', list1);
    dispatch(AttachmentsService.addAttachments(values))
      .then((res) => {
        console.warn("Submit", res);
        return;
        //navigate("/single-quick-report/?report_id=" + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="attachmentsWrapper">
      <div className="pageHeader">
        <PageHeader
          title="Attachments"
          type="secondary"
          //text="Add attachments"
          //handleClick={() => navigate("/update-cover-letter/?cover_id=" + coverLetterData.cover_id)}
        />
      </div>
      <div className='buttonSection'>
        <ButtonBar />
      </div>
      <div className='container-xxl'>
        <div className='attachmentsInnerWrapper'>
          <Form
            name='attachments'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <Card className="reportCard">
              <div className='reportCardBody'>
                <div className='formSection'>
                  <div className="attachmentSection">
                    <div className="attachmentHead mb-4">
                      <h4 className="formGroupTitle">Add Attachements</h4>
                    </div>
                    <div className="custom-dropzone-row">
                      <Upload takefileList={takefileList} images={attachments}></Upload>
                    </div>
                  </div>
                  <Row span={24} justify="center">
                    <Button
                      className="btnPrimary"
                      htmlType="submit"
                      loading={loadings[0]}
                    >
                      Submit
                    </Button>
                  </Row>
                </div>
              </div>
            </Card>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Attachements;