import React from 'react';
import { Layout, PageHeader , Row,
  Col, } from 'antd';

import UserProfileList from './UserProfileList';
import {
  EnvironmentOutlined,
} from "@ant-design/icons";
const { Content } = Layout;


const UserProfilePage = ({profiles}) => {
  return (
    <Layout>
      <PageHeader title="User Profiles" />
      <Row gutter={16}>
                <Col xs={24} md={8} xl={4}><div className='Purchasing-location-button'><a id="Purchasing-Other-Location" class="profile-btn" href="https://www.floordetective.com/purchasing-other-location/">Add New Location <EnvironmentOutlined /></a></div></Col>
      </Row>
      
      
      <Content style={{ padding: '24px' }}>
        <UserProfileList profiles={profiles} />
      </Content>
      
    </Layout>
   
  );
};

export default UserProfilePage;
