import React from "react";
import { Row, Col,Image } from "antd";
import Copywrite from "./copywrite";

const footer = () => {
  return (
    <div className="footer">
      <div className="footerInner">
        <div className="container-xxl">
          <Row gutter={16}>
            <Col xs={24} md={8} lg={8}>
              <div className="footeColFirst">
                <div className="footerColTitle">
                  <h5>About</h5>
                </div>
                <div className="footerContent">
                  <p className="aboutDesc">
                    FloorDetective.com is a comprehensive diagnostic tool
                    designed to assist flooring retailers, inspectors, claims
                    analyst, regional sales reps, interior designers, builders
                    and consumers, understand and more accurately identify
                    complaints associated with flooring products.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="footeColFirst">
                <div className="footerColTitle">
                  <h5>Quick Links</h5>
                </div>
                <div className="footerContent">
                  <ul className="footerMenu">
                  <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://www.floordetective.com/flooring-installer-locator/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Find An Installer
                      </a>
                    </li>
                    <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://www.floordetective.com/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Home Owners
                      </a>
                    </li>
                    <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://www.floordetective.com/locator/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Inspector
                      </a>
                    </li>
                    <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://www.floordetective.com/subscribe/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Memberships
                      </a>
                    </li>
                    <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://www.floordetective.com/locator/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Locator
                      </a>
                    </li>
                    <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://companycam.com/floordetective"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Try It Now!
                      </a>
                    </li>
                    <li className="ftrMenuitems">
                      <a
                        className="ftrMenuLink"
                        href="https://www.floordetective.com/video-tutorials/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Tutorials
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="footeColFirst">
                <div className="footerColTitle">
                  <h5>Social Links</h5>
                </div>
                <div className="footerContent">
                  <p className="newsletterTitle"></p>
                  {/* <span className="newsletterSubTitle">
                    Be the first one to be notified.
                  </span> */}
                 </div>
                <div className="social-menu  d-flex">
                <ul  className=" d-flex  px-2">
                            <li><a href="https://twitter.com/floor_detective/" target="blank"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28055501/icons8-twitter-24.png"
  /></a></li>
                            <li><a href="https://www.instagram.com/floor_detective/" target="blank"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28065620/icons8-instagram-26.png"
  /></a></li>
                            <li><a href="https://www.linkedin.com/company/floor-detective/" target="blank"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28055505/icons8-linkedin-24.png"
  /></a></li>
                            <li><a href="https://www.facebook.com/floordetective1"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28055438/icons8-facebook-24.png"
  /></a></li>
                        </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Copywrite />
    </div>
  );
};

export default footer;
