import React from 'react'
import NavBar from './navBar';
import TopBar from './topBar';

function index() {
  return (
    <div className='header'>
      <TopBar />
      <NavBar />
    </div>
  )
}

export default index