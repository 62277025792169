import 'suneditor/dist/css/suneditor.min.css';
import 'antd/dist/antd.min.css';
import { UpOutlined } from '@ant-design/icons';
import { Spin, BackTop, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import AppRoute from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import './assets/style/style.css';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import { useDispatch, useSelector } from 'react-redux';

import * as UserDataService from './services/GetUserData';
import * as Actions from './store/actions/user';


const { Content } = Layout;

function App() {
  var dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const userDetails = useSelector((state) => state.User.userDetails);
  const getUserDetails = () => {
    dispatch(UserDataService.getUserData())
      .then((res) => {
        dispatch(Actions.set_user_auth(res));
        if (res?.expired === true || res?.remaining_inspections <= 0) {
          window.location.href = 'https://www.floordetective.com/plan-expired';
        }
        if (
          res?.membersdhip_level === '7' ||
          res?.user_role === 'um_retailer' ||
          res?.user_role === 'um_dealer'
        ) {
          window.location.href = 'https://www.floordetective.com/';
        }
        if (res?.companycam_token){
          localStorage.setItem('companycam_token', res.companycam_token);
        }
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      notification.error({ message: 'Error', description: errorMessage });
      handleLogout(); // Call the logout function
      });
  };
  const handleLogout = () => {
    // Clear tokens or session data
    localStorage.removeItem('jwt_token');
    sessionStorage.clear();
  
    // Redirect to the logout route
    window.location.href = 'https://www.floordetective.com/logout/'; 
  };
  const validateToken = async () => {
    try {
      const response = await dispatch(UserDataService.validateToken());
      console.log({response});
      return true; // Return true if the token is valid
    } catch (err) {
      // Extract and display a meaningful error message
      let errorMessage;
  
      if (err.response) {
        // The request was made and the server responded with a status code outside the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }
  
      // Use a proper notification component instead of alert
      console.error(errorMessage); 
      notification.error({ message: 'Token Validation Error', description: errorMessage });
  
      handleLogout(); // Call the logout function
      return false; // Return false if the token is invalid
    }
  };
  
  useEffect(() => {
    setisLoading(true);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let tokenVal = params.get('token');
    let localTokenItem = localStorage.getItem('jwt_token');
    if (tokenVal) {
      localStorage.setItem('jwt_token', tokenVal);
      getUserDetails();
      window.history.pushState({}, document.title, '/');
    } else {
      if (localTokenItem && validateToken()) {
        if (!userDetails) {
          getUserDetails();
        }
      } else {
        window.location.href = 'https://www.floordetective.com/logout/';
      }
    }
    setisLoading(false);
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //lucky orange
  window.__lo_site_id = 318791;

  (function () {
    var wa = document.createElement('script');
    wa.type = 'text/javascript';
    wa.async = true;
    wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wa, s);
  })();

  return (
      <>
      {isLoading ? (
        <div className="isloading">
          <Spin className="spinDiv fullHeight" />
        </div>
      ) : (
        <BrowserRouter>
          <Layout>
            <Header />
            <Content>
              <AppRoute />
            </Content>
            <Footer />
            <BackTop className="backToTop">
              <UpOutlined />
            </BackTop>
          </Layout>
        </BrowserRouter>
      )}
      </>
  );
}

export default App;
