import React from "react";
import PagesHeader from "../../components/PageHeader";
import ClaimAnalyst from "../../datatables/Manufacturer/ClaimAnalyst";
import ButtonBar from "../../components/ButtonNavigation/index";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AllClaimAnalysts = () => {
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/add-claim-analyst")
  }

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader title="Claim Analysts Listing" />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            <Col xs={24} md={6}>
              <Button
                type='success'
                className="btn inspectionAdd" 
                onClick={() => handleNavigate()}
                icon={<PlusOutlined />}
              >
                Add New Claim Analyst
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
          <ClaimAnalyst />
        </div>
      </div>
    </div>
  );
};
export default AllClaimAnalysts;