import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Form,
  Divider,
  Button,
  message,
  // Spin,
  // Skeleton,
  Upload,
  Tooltip,
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import * as EmailDataService from "../../services/sendEmail";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import * as PdfServices from "../../services/GetPdf";
import { MEDIA_UPLOAD_URL } from "../../Helper";
import WaitSpin from '../../components/SpinnerWait';

const CreateEmailReport = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [emailData, setEmailData] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [additionalAttachments, setAdditionalAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [reports, setReports] = useState([]);
 
  const queryParams = new URLSearchParams(window.location.search);
  const req_id = queryParams.get("req_id");
  const [form] = Form.useForm();
  const userDetails = useSelector((state) => state.User.userDetails);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const previewPdf = () => {
    setIsActive(!isActive);
  };
  const checkboxcheck = (data) => {
    let check = [...isChecked];
    console.warn({ check });
    let index = check.indexOf(data);
    if (index > -1) {
      delete check[index];
      check = check.filter((e) => e);
    } else {
      if (reports.includes(data)) {
        check.push(data);
      }
    }
    setIsChecked(check);
    if (data === "invoice") {
      var footerLink =  userDetails.user_role === "um_inspector" ? `<a href="/create-invoice/?req_id=${req_id}">Click here to generate invoice</a>` : ""
      
      if (!reports.includes(data)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Invoice not found!",
          showLoaderOnConfirm: true,
          footer: footerLink,
        });
      }
    }
    if (data === "attach") {
      if (!reports.includes(data)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Attachments not found!",
          showLoaderOnConfirm: true,
          footer: `<a href="/attachments/?report_id=${emailData.report_id}">Click here to add attachments</a>`,
        });
      }
    }
    if (data === "cover") {
      if (!reports.includes(data)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Cover not found!",
          showLoaderOnConfirm: true,
          footer: `<a href="/create-cover-letter/?req_id=${req_id}">Click here to generate cover</a>`,
        });
      }
    }
    if (data === "report") {
      if (!reports.includes(data)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Cover not found!",
          showLoaderOnConfirm: true,
          footer: `<a href="/create-cover-letter/?req_id=${req_id}">Click here to generate cover</a>`,
        });
      }
    }
    console.warn(index);
  };

  const getPdf = () => {
    enterLoading(2, true);
    let values = {};
    values.report_id = emailData.report_id;
    values.cover_pdf = emailData.cover_pdf;
    values.report_pdf = emailData.report_pdf;
    values.report = "yes";

    console.info("pdf Data", values);
    dispatch(PdfServices.getPdfReport(values))
      .then((res) => {
        console.info("getPdfReport", res);
        enterLoading(2, false);
        window.open(res, "download");
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const getPdfInvoice = () => {
    // enterLoading(2);
    enterLoading(3, true);
    let values = {};
    values.report_id = emailData.report_id;

    console.info("pdfInvoice Data", values);
    dispatch(PdfServices.getPdfInvoice(values))
      .then((res) => {
        console.info("getPdfReport", res);
        enterLoading(3, false);
        window.open(res, "download");
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const getPdfConfidential = () => {
    enterLoading(4, true);
    let values = {};
    values.report_id = emailData.report_id;

    console.info("pdfInvoice Data", values);
    dispatch(PdfServices.getPdfConfidential(values))
      .then((res) => {
        console.info("getPdfConfidential", res);
        enterLoading(4, false);
        window.open(res, "download");
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const getPdfAttachments = () => {
    enterLoading(5, true);
    let values = {};
    values.report_id = emailData.report_id;

    console.info("getPdfAttachments Data", values);
    dispatch(PdfServices.getPdfAttachments(values))
      .then((res) => {
        console.info("getPdfConfidential", res);
        enterLoading(5, false);
        window.open(res, "download");
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const enterLoading = (index, state) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = state;
      return newLoadings;
    });
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const req_id = queryParams.get("req_id");

    dispatch(EmailDataService.getEmailData(req_id))
      .then((res) => {
        form.setFieldsValue({
          email_to : res.comm_email
        });
        console.info("Email data", res);
        setEmailData(res);
        setIsChecked(res.checked);
        setReports(res.checked);
        console.info("checked data", res.checked);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess(res);
     
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };

  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // };



  const handleChangeImage = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
       setAdditionalAttachments([...additionalAttachments, info.file.response.data.id]);
      console.warn("Attachments", additionalAttachments);
      console.warn("res", info.file.response.data.id);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    enterLoading(1, true);
    setLoading(true);
    values.additionalAttachments = additionalAttachments;
    values.invoice_pdf = emailData.invoice_pdf;
    values.cover_pdf = emailData.cover_pdf;
    values.report_pdf = emailData.report_pdf;
    values.confidential_info = emailData.confidential_info;
    values.report_id = emailData.report_id;

    if (isChecked.includes('invoice')) {
      values.invoice = "yes";
    } else {
      values.invoice = "no";
    }

    if (isChecked.includes('report')) {
      values.report = "yes";
    } else {
      values.report = "no";
    }
    
    if (isChecked.includes('attach')) {
      values.images = "yes";
    } else {
      values.images = "no";
    }
    
    if (isChecked.includes('cover')) {
      values.cover_sheet = "yes";
    } else {
      values.cover_sheet = "no";
    }
    
    if (isChecked.includes('confidential')) {
      values.confidential_info = "yes";
    } else {
      values.confidential_info = "no";
    }

    console.info("Success:", values);
    dispatch(EmailDataService.sendEmailData(values));
    setTimeout(() => {
      setLoading(false);
      enterLoading(1, false);
      message.success("Email request sent successfully.");
      navigate(`/inspection-reports`);
    }, 5000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;

  return (
    <div className="quickReportWrapper emailWrapper">
      <div className="pageHeader">
        <PageHeader title="Create Email" />
      </div>
     {loading ?
      (
        <WaitSpin />
      ) : ( <>
          {/* <Spin spinning={loading} size="large" > */}
        
    
        <div className="container">
          <Row gutter={16} justify="center">
            <Col xs={24} md={16}>
              <div className="reportInner">
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                  form={form}
                >
                  <Card className="reportCard">
                    <div className="reportCardBody">
                      <div className="formSection">
                        <div className="inspectionSection">
                          <div className="headerTitle">
                            <p>The Report has been Saved.</p>
                            <p>What do you want to do now?</p>
                          </div>
                          <Divider />
                          <div className="inspecContent">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Email To :"
                                    name="email_to"
                                    className="mb-0"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter email",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Email"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                  <p className="inputnote mb-3">
                                    Only 1 Email Address per field, comma
                                    separated emails are no longer supported.
                                  </p>
                                </div>
                              </Col>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Send to CC Email :"
                                    name="cc_email"
                                  >
                                    <Input
                                      placeholder="Send to CC Email"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Send a Copy To :"
                                    name="copy_to"
                                  >
                                    <Input
                                      placeholder="Send a Copy To"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <Form.Item label="Message :" name="msg-claim">
                                    <TextArea
                                      rows={4}
                                      placeholder="Message"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="paragraphCheckbox">
                                  <p>
                                    Check mark indicates documents being sent.
                                    Click to select Attachements.
                                  </p>
                                </div>
                                <Row gutter={16}>
                                  <Col xs={24} md={12} className="d-flex">
                                    {emailData?.report_generated ? (
                                      <Form.Item
                                        name="report"
                                        className="inputCheck"
                                      >
                                        <input
                                          type="checkbox"
                                          onChange={() =>
                                            checkboxcheck("report")
                                          }
                                          defaultChecked={
                                            isChecked.includes("report")
                                              ? true
                                              : false
                                          }
                                        />
                                        Report
                                      </Form.Item>
                                    ) : (
                                      ""
                                    )}

                                    <Form.Item
                                      name="images"
                                      className="inputCheck"
                                    >
                                      <input
                                        type="checkbox"
                                        onChange={() => checkboxcheck("attach")}
                                        checked={
                                          isChecked.includes("attach")
                                            ? true
                                            : false
                                        }
                                      />
                                      Attachements
                                    </Form.Item>
                                    <Form.Item
                                      name="invoice"
                                      className="inputCheck"
                                    >
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          checkboxcheck("invoice")
                                        }
                                        checked={
                                          isChecked.includes("invoice")
                                            ? true
                                            : false
                                        }
                                      />
                                      Invoice
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    xs={24}
                                    md={12}
                                    className="d-flex justify-content-end"
                                  >
                                    <Form.Item
                                      name="cover_sheet"
                                      className="inputCheck"
                                    >
                                      <input
                                        type="checkbox"
                                        onChange={() => checkboxcheck("cover")}
                                        checked={
                                          isChecked.includes("cover")
                                            ? true
                                            : false
                                        }
                                      />
                                      Cover Sheet
                                    </Form.Item>
                                    <Form.Item
                                      name="confidential_info"
                                      className="inputCheck"
                                    >
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          checkboxcheck("confidential")
                                        }
                                        checked={
                                          isChecked.includes("confidential")
                                            ? true
                                            : false
                                        }
                                      />
                                      Confidential Information
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row gutter={10} className="mailTemplateBtn">
                              <Col
                                xs={24}
                                md={10}
                                className="d-flex bottomBtnRow justify-content-start"
                              >
                                <Button
                                  className={
                                    isActive
                                      ? "bottomBtn pdfBtnActive"
                                      : "bottomBtn"
                                  }
                                  onClick={() => previewPdf()}
                                >
                                  Preview PDF
                                </Button>
                              </Col>
                              <Col
                                xs={24}
                                md={14}
                                className="d-flex justify-content-between bottomBtnRow"
                              >
                                <Upload
                                  name="additionalAttachments"
                                  showUploadList={true}
                                  multiple
                                  customRequest={uploadImage}
                                  onChange={handleChangeImage}
                                  className="me-2"
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                    className="bottomBtn additionalAttachments"
                                  >
                                    Additional Attachments
                                  </Button>
                                </Upload>

                                <Button
                                  className="bottomBtn"
                                  htmlType="submit"
                                  loading={loadings[1]}
                                >
                                  Email Now
                                </Button>
                              </Col>
                            </Row>
                            {isActive ? (
                              <Row gutter={16} className="mailTemplateBtn">
                                <Col className="d-flex bottomBtnRow justify-content-start">
                                  <Tooltip
                                    color="#000000"
                                    title={
                                      emailData?.report_generated === "YES" && emailData?.report_pdf !== false
                                        ? "Preview Report PDF"
                                        : "Report PDF Not Generated"
                                    }
                                  >
                                    <Button
                                      className="bottomBtn me-2"
                                      onClick={() => getPdf()}
                                      loading={loadings[2]}
                                      disabled={
                                        emailData?.report_generated === "YES" && emailData?.report_pdf !== false
                                          ? false
                                          : true
                                      }
                                    >
                                      Report PDF
                                    </Button>
                                  </Tooltip>
                                </Col>
                                <Col className="d-flex bottomBtnRow justify-content-start">
                                  <Tooltip
                                    color="#000000"
                                    title={
                                      emailData?.attachments_generated === "YES"
                                        ? "Preview Attachments PDF"
                                        : "Attachments PDF Not Generated"
                                    }
                                  >
                                    <Button
                                      className="bottomBtn me-2"
                                      onClick={() => getPdfAttachments()}
                                      loading={loadings[5]}
                                      disabled={
                                        emailData?.attachments_generated ===
                                        "YES"
                                          ? false
                                          : true
                                      }
                                    >
                                      Attachments PDF
                                    </Button>
                                  </Tooltip>
                                </Col>
                                <Col className="d-flex bottomBtnRow justify-content-start">
                                  <Tooltip
                                    color="#000000"
                                    title={
                                      emailData?.confi === "yes"
                                        ? "Preview Confidential PDF"
                                        : "Confidential PDF Not Generated"
                                    }
                                  >
                                    <Button
                                      className="bottomBtn me-2"
                                      onClick={() => getPdfConfidential()}
                                      loading={loadings[4]}
                                      disabled={
                                        emailData?.confi === "yes"
                                          ? false
                                          : true
                                      }
                                    >
                                      Confidential Information
                                    </Button>
                                  </Tooltip>
                                </Col>
                                <Col className="d-flex bottomBtnRow justify-content-start">
                                  <Tooltip
                                    color="#000000"
                                    title={
                                      emailData?.invoice_generated === "YES" && emailData?.invoice_pdf !== false
                                        ? "Preview Invoice PDF"
                                        : "Invoice PDF Not Generated"
                                    }
                                  >
                                    <Button
                                      className="bottomBtn me-2"
                                      onClick={() => getPdfInvoice()}
                                      loading={loadings[3]}
                                      disabled={
                                        emailData?.invoice_generated === "YES" && emailData?.invoice_pdf !== false
                                          ? false
                                          : true
                                      }
                                    >
                                      Invoice PDF
                                    </Button>
                                  </Tooltip>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        {/* </Spin> */}
        </>)}
      
    
    </div>
  );
};

export default CreateEmailReport;
