import {
  MANUAL_REQ,
  CREATE_REPORT,
  UPDATE_REPORT,
  MEDIA_UPLOAD,
} from "../action-type";

export const create_manual_req = (payload) => {
  return {
    type: MANUAL_REQ,
    payload: payload,
  };
};

export const create_report = (payload) => {
  return {
    type: CREATE_REPORT,
    payload: payload,
  };
};
export const update_report = (payload) => {
  return {
    type: UPDATE_REPORT,
    payload: payload,
  };
};
export const media_upload = (payload) => {
  return {
    type: MEDIA_UPLOAD,
    payload: payload,
  };
};