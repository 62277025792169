import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

export function getAttachments(report_id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(INSPECTION_URL + 'getattachments/' + report_id)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function addAttachments(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(INSPECTION_URL + 'addattachments/', values)
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
