import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getEmailData(req_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + "get_email_data/" + req_id)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
}

export function sendEmailData(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + "sendemail" , values)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
        });
    }
export function sendDecision(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + "senddecision" , values)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
        });
    }