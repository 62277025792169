import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function createQuickReport(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "createreport", values)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}


export function updateQuickReport(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "updatereport", values)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
  