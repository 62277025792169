import React, { useEffect, useState } from "react";
import PagesHeader from "../../components/PageHeader";
import DealerPanelDataTable from "../../datatables/Manufacturer/dealerPanel";
import * as DealerReqDataService from '../../services/DealerRequest';
import { useDispatch } from "react-redux";
import { Row, Col, Button } from 'antd';

const DealerPanel = () => {
  var dispatch = useDispatch();
  const [dealerReqData, setDealerReqData] = useState();

  useEffect(() => {
    dataDealerReq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  console.info("dealerReqData", dealerReqData)

  const dataDealerReq = () => {
    dispatch(DealerReqDataService.dealerRequest())
    .then((res) => {
      console.warn('dataDealerReq', res);
      setDealerReqData(res);
    })
    .catch((err) => {
      console.info("Get Error Edit State...");
      // setLoading(false);
    });
  }

  const handleClick = () => {
    window.open(`https://www.floordetective.com/request-manufacturer/?manuf-email=${dealerReqData.manuf_email}&free-access=${dealerReqData.free_access}&claimreq-user=${dealerReqData.claimreq_user}&claimreq-user-id=${dealerReqData.claimreq_user_id}&client-id=${dealerReqData.client_id}&auth-id=${dealerReqData.auth_id}`, '_blank')
  }

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              <Button
                type='success'
                className="btn inspectionAdd" 
                onClick={() => handleClick()}
              >
                Request for Claim
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
          <DealerPanelDataTable />
        </div>
      </div>
    </div>
  );


};

export default DealerPanel;