import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

// Quick Report Single API
export function getSingleReport(report_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'getreportdata/'+ report_id )
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            console.log(err)
            return reject(err);
          });
      });
  }

// Quick Report PDF API
export function quickReportPdf(report_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + "get_report_pdf/"+ report_id)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
export function getpdfTemplate(report_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_pdf_template/'+ report_id )
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err)
          return reject(err);
        });
    });
}
