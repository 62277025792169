import Http from "../Http";

export function getCompanycamProjectPhotos(project_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get('https://api.companycam.com/v2/projects/'+project_id+'/photos?per_page=400')
          .then((res) => {
            console.warn('Companycam pro pics', res.data)
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }