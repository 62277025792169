import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Input,
  Row,
  Select,
  message,
  Modal,
  Form,
  Button,
  Empty,
  Col,
  Skeleton
} from "antd";
import Swal from "sweetalert2";
import { IconButton } from "../components/Buttons/buttons";
import { DeleteOutlined, EyeFilled,SearchOutlined } from "@ant-design/icons";
import * as InvoiceServices from "../services/AllInvoices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const { Option } = Select;


function InvoiceData() {
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusModal, setStatusModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  let navigate = useNavigate();
  const [form] = Form.useForm();
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChanges = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const handleshowhide = (value) => {
    if (value === "Paid") {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(InvoiceServices.getInvoices())
      .then((res) => {
        console.info(res);
        setInvoices(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const openStatusModal = (invoice_ID) => {
    setInvoiceId(invoice_ID);
    setStatusModal(true);
  };
  const handleCancel = () => {
    setStatusModal(false);
  };
  const onFinish = (values) => {
    console.warn({ Success: values });
    values.invoiceId = invoiceId;
    dispatch(InvoiceServices.updateStatus(values))
      .then((res) => {
        if (res.data === true) {
          fetchData();
          message.success("Status updated!");
          setStatusModal(false);
        } else {
          message.error("Status update failed!");
        }
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);

      });

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleBtn = (invoiceId) => {
    let val = {
      invoiceId: invoiceId,
    };
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      text: "you want to delete invoice?",
      showLoaderOnConfirm: true,

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(InvoiceServices.delete_invoice(val))
        .then((res) => {
          message.success("Invoice deleted!");
          fetchData();
        })
        .catch((err) => {
          message.error(err);
        });
      } else if (result.isDenied) {
        Swal.fire('Invoice not deleted!', '', 'info')
      }
    })
    
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => <>{(page - 1) * pageSize + index + 1}</>,
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      key: "invoice_number",
      sorter: (a, b) => {
        a = a.invoice_number || "";
        b = b.invoice_number || "";
        return a.localeCompare(b);
      },
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.invoice_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
            String(record.client_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
            String(record.invoice_date)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      sorter: (a, b) => {
        a = a.client_name || "";
        b = b.client_name || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      sorter: (a, b) => {
        a = a.invoice_date || "";
        b = b.invoice_date || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Invoice Total",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => {
        a = a.total || "";
        b = b.total || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        a = a.status || "";
        b = b.status || "";
        return a.localeCompare(b);
      },
      render: (text, action) => (
        <>
          <Tag
            color="#ffffff"
            className="invoiceStatusTags"
            onClick={() => openStatusModal(action.invoice_ID)}
          >
            {action.status}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (row, action) => {
        // console.log({ row });
        return (
          <div className="actionBtn">
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              handleClick={() =>
                navigate("/report-invoice/?invoice_id=" + action.invoice_ID)
              }
              tooltip="View"
              text="View"
            />
            <IconButton
              icon={<DeleteOutlined />}
              type="primary"
              handleClick={() => handleBtn(action.invoice_ID)}
              tooltip="Delete"
              text="Delete"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="tableWrapper invoiceTableWrapper">
       <div className="filterWrap">
       <Row gutter={16} justify="space-between">
          <Col md={6} xs={24}>
            <div className="searchWrapper">
              <Input
                className="formControl"
                prefix={<SearchOutlined />}
                allowClear
                placeholder="Search..."
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  setSearchedText(e.target.value);
                }}
              />
            </div>
          </Col>
          </Row>
          </div>
      <Table
        bordered
        className="inspectionDataTable clientsDataTable"
        tableLayout="auto"
        scroll={{ x: 1000 }}
        columns={columns}
        onChange={onChanges}
        dataSource={invoices}
        locale={{
          emptyText: loading ? columns.map(u => <Skeleton.Input height={50} style={{marginTop: '2px',height:'48px',padding:'0px'}} block={true}active={true} />): <Empty />
        }}
        rowKey={(record) => record.inspections}
        showSorterTooltip={false}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
          position: ["bottomCenter"],
        }}
        
      />
      <Modal
        title="Change Status"
        centered
        open={statusModal}
        className="inspectionCategoryModal industryModal customInvoiceModal"
        width={580}
        footer={[
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={form.submit}
          >
            Save Changes
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <div className="modalContent">
          <div className="formGroup">
            <Form
              form={form}
              name="statusForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Row className="statusModalRow d-flex justify-content-center">
                <div className="formGroup">
                  <Form.Item label="Status" name="status">
                    <Select
                      className="formGroup abc"
                      defaultValue="Change Status"
                      onChange={handleshowhide}
                    >
                      <Option value="Change Status">Change Status</Option>
                      <Option value="Paid">Paid</Option>
                      <Option value="Draft">Draft</Option>
                      <Option value="Sent">Sent</Option>
                      <Option value="Unpaid">Unpaid</Option>
                      <Option value="Partial">Partial</Option>
                    </Select>
                  </Form.Item>
                </div>
                {visible === true ? (
                  <div className="formGroup payfiled">
                    <Form.Item label="Payment Method" name="PaymentMethod">
                      <Input
                        className="formControl "
                        placeholder="Payment Method"
                      />
                    </Form.Item>
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InvoiceData;
