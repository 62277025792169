import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getindStandard(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + "industrystandards/?category="+values.category+"&label="+values.label)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
