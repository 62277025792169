import Http from '../Http';
import { INSPECTION_URL, TOKEN_VALIDATION_URL } from "../Helper";

export function getUserData() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(INSPECTION_URL + 'get_current_userdata/')
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    return reject(err);
                });
        });
}
export function validateToken() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(TOKEN_VALIDATION_URL)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    return reject(err);
                });
        });
}
