import Http from "../Http";
import { INSPECTION_URL } from "../Helper";
import * as Actions from "../store/actions/inspection";

export function createManualreq(category) {
  console.log("dispatch category", category);
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "create_manual_req/", category)
        .then((res) => {
            dispatch(Actions.create_manual_req(res));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
