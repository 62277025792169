import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getClaimAnalystData() {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'getclaimanalystlisting' )
          .then((res) => {
            console.warn('InspectorData', res.data)
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }