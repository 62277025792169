import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

export function getPdfReport(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(INSPECTION_URL + 'preview_report/', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    return reject(err);
                });
        });
}

export function getPdfInvoice(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(INSPECTION_URL + 'preview_invoice/', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    return reject(err);
                });
        });
}

export function getPdfConfidential(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(INSPECTION_URL + 'preview_confidential/', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    return reject(err);
                });
        });
}

export function getPdfAttachments(values) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(INSPECTION_URL + 'preview_attachments/', values)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    console.log(err)
                    return reject(err);
                });
        });
}
