import React, { useState, useEffect } from 'react';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import FirstStep from '../HardwoodDetailed/FirstStep';
import SecondStep from '../HardwoodDetailed/SecondStep';
import ThirdStep from '../HardwoodDetailed/ThirdStep';
import FourthStep from '../HardwoodDetailed/FourthStep';
import { Form, Button, notification, Row, Col, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import * as inspectorService from '../../../services/InspectorDataService';
import * as carpetBasicservice from '../../../services/reportWriter';
import * as getClientdropService from '../../../services/getClientdrop';
import * as createClientService from '../../../services/AllClients';
import { useDispatch, useSelector } from 'react-redux';
import { FilePdfOutlined, CheckCircleOutlined } from '@ant-design/icons';
import * as Actions from '../../../store/actions/inspection';
import * as SingleRequestServices from '../../../services/SingleRequest';
import ReportSpin from '../../../components/ReportSpinner';

const HardwoodDetailed = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [footerLogo, setFooterLogo] = useState([]);
  const [clientDropdown, setclientDropdown] = useState([]);
  const [manufDropdown, setmanufDropdown] = useState([]);
  const [dealerDropdown, setdealerDropdown] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [SpinnerReport, setSpinnerReport] = useState(false);
  const [subDivision, setSubDivision] = useState([{ gc_subdivision: '' }]);
  const [lotNumber, setLotNumber] = useState([{ gc_lotnumber: '' }]);
  const [calOpen, setCalOpen] = useState(false);
  const [calOpen1, setCalOpen1] = useState(false);
  const [calOpen2, setCalOpen2] = useState(false);
  const [calOpen3, setCalOpen3] = useState(false);
  const [calOpen4, setCalOpen4] = useState(false);
  const [calOpen5, setCalOpen5] = useState(false);
  const [calOpen6, setCalOpen6] = useState(false);
  const [calOpen7, setCalOpen7] = useState(false);
  const [calOpen8, setCalOpen8] = useState(false);
  const [calOpen9, setCalOpen9] = useState(false);
  const [block, setBlock] = useState(false);
  const [size, setSize] = useState('Large');
  const [avatarShape, setAvatarShape] = useState('circle');
  const reduxData = useSelector((state) => state.Inspection.reportDetails);
  const [vals, setVals] = useState({});
  const [isOpen, setIsOpen] = useState({
    commPartyopen: false,
    installationOther: false,
    stapleField: false,
    installationGlue: false,
    buildingOther: false,
    buildingStyleOther: false,
    vacummBrandOther: false,
    cushionOther: false,
    foundationField: false,
    crawalField: false,
    slabField: false,
    basementField: false,
    trafficTypeOther: false,
    exteriorOther: false,
    subFloorOther: false,
    constructionField: false,
    walkField: false,
    buildField: false,
    cleanedField: false,
    proCleanedField: false,
    steamField: false,
    sendingSampleField: false,
    claimHistoryField: true,
    observationField: true,
    applicableField: true,
    industryField: true,
    conclusionField: true,
    causeField: false,
    confidentialField: false,
    signatureField: true,
    footerField: false,
    notesField: false,
    floorOtherField: false,
    heatingField: false,
    acField: false,
    airOnField: false,
    humidifierField: false,
    dehumidifierField: false,
    underlayField: false,
    manuissuesField: false,
    siteissuesField: false,
    perfoissuesField: false,
    instaissuesField: false,
  });

  const [values, setValues] = useState({
    claim_num: '',
    h_bi_ins_date: '',
    ins_time: '',
    inspection_type: '',
    commishomeowner: false,
    manufiscomm: false,
    commisdealer: false,
    installer_present: 'HideThisField',
    client_select_drop: '',
    comm_party_drop: '',
    comm_other: '',
    company_name_qr: '',
    agent_claim_name: '',
    comm_email: '',
    street_name_number: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    phone_no_party: '',
    h_eu_name: '',
    h_eu_street: '',
    h_eu_city: '',
    h_eu_stateProvince: '',
    h_eu_country: '',
    end_address: '',
    h_eu_zip: '',
    h_eu_contact: '',
    eu_phone1: '',
    end_mobile: '',
    end_email: '',
    manuf_resuse_drop: '',
    manf_name: '',
    manf_contact: '',
    manf_street_name_number: '',
    manf_city: '',
    manf_state: '',
    manf_country: '',
    manf_zip: '',
    manf_phone: '',
    manf_email: '',
    dealer_resuse_drop: '',
    dealer_name: '',
    dealer_contact: '',
    dealer_street_name_number: '',
    dealer_state: '',
    dealer_country: '',
    dealer_zip: '',
    dealer_phone: '',
    dealer_email: '',
    dealer_city: '',
    installer_name: '',
    installer_contact: '',
    installer_street_name_number: '',
    installer_city: '',
    installer_state: '',
    installer_country: '',
    installer_zip: '',
    installer_phone: '',
    installer_email: '',
    gc_name: '',
    gc_contact_name: '',
    gc_contac_street: '',
    gc_contac_city: '',
    gc_contac_state_province: '',
    gc_contac_country: '',
    gc_address: '',
    gc_zip: '',
    gc_phone: '',
    gc_email: '',
    product_date_of_ins: '',
    date_problem_noticed: '',
    reported_to: '',
    flooring_type: '',
    hard_other: '',
    product_manf: '',
    previous_floor_covering: '',
    car_i2_inst_type: '',
    car_i2_other_inst_mthd: '',
    nail_staple_size: '',
    nail_staple_pattern_spacing: '',
    floor_rolled_toggle: 'HideThisField',
    adhesive_name_number: '',
    trowel_used_size_dimension: '',
    roller_weight_lbs: '',
    style_name: '',
    product_invoice: '',
    product_color: '',
    hardwood_species: '',
    cushion_type_other: '',
    run_date_no: '',
    balance_toggle: 'HideThisField',
    flooring_installed_toggle: 'HideThisField',
    concerns: '',
    property_dropdown: '',
    building_type: '',
    build_type_other: '',
    area_room_ins: '',
    total_claim_qt: '',
    total_affected: '',
    building_style: '',
    other_building_style: '',
    traffic_type: '',
    other_trafic: '',
    date_home: '',
    date_hvac: '',
    site_occupied_date: '',
    building_age: '',
    h_bui_cons: 'HideThisField',
    closing_date: '',
    moving_date: '',
    operational_date: '',
    h_bui_walk_off: 'HideThisField',
    all_exterior: '',
    other_exterior: '',
    h_bui_occupied: 'HideThisField',
    adults: '',
    childrens: '',
    pets: '',
    cleaned: 'HideThisField',
    do_it_yourself: 'HideThisField',
    professinally_cleaned: 'HideThisField',
    steam_mop: 'HideThisField',
    cleaning_company_name: '',
    steam_mop_often: '',
    clean_product: '',
    cleaning_method: '',
    often_cleaned: '',
    cleaning_history: '',
    floor1_relative_humidity: '',
    floor2_relative_humidity: '',
    basement_relative_humidity: '',
    floor_near_problem_relative_temperature: '',
    other_relative_humidity: '',
    floor1_relative_temperature: '',
    floor2_relative_temperature: '',
    basement_relative_temperature: '',
    floor_near_problem_relative_humidity: '',
    other_relative_temperature: '',
    heating_system: '',
    heating_system_other: '',
    air_conditioning: '',
    air_on_other: '',
    heat: 'HideThisField',
    airon: 'HideThisField',
    humidifier: 'HideThisField',
    humid_settings: '',
    dehumidifier: 'HideThisField',
    dehumid_settings: '',
    length_of_acclimation_hard: '',
    floor_mois: '',
    test_method_used: '',
    subfloor_mois: '',
    test_method_used_mois: '',
    top_flor: '',
    back_flor: '',
    baseboard_flor: '',
    subfloor_flor: '',
    joist_flor: '',
    concrete_flor: '',
    meter_name: '',
    meter_range: '',
    foundation: '',
    grade: '',
    footprint: '',
    subfloor_type: '',
    building_landscape: '',
    relate_slab: '',
    soil_subfloor: '',
    temperature_crawl: '',
    ground_cover_per: '',
    relative_humidity: '',
    elevation_of_slab: '',
    sub_floor_flatness: '',
    perimeter_expansion_space: '',
    measure_the_longest_width: '',
    measure_the_longest_length: '',
    sub_floor_levelness: '',
    joist_type: '',
    reading_basement: '',
    car_tog_grade: 'HideThisField',
    car_tog_plastic: 'HideThisField',
    car_tog_alkali: 'HideThisField',
    car_tog_cross: 'HideThisField',
    car_tog_joist: 'HideThisField',
    car_tog_mold: 'HideThisField',
    car_tog_water: 'HideThisField',
    car_tog_wall: 'HideThisField',
    car_tog_paint: 'HideThisField',
    car_tog_floor: 'HideThisField',
    car_tog_vented: 'HideThisField',
    car_tog_rusty: 'HideThisField',
    car_tog_finish: 'HideThisField',
    car_tog_insulated: 'HideThisField',
    mois_test: 'HideThisField',
    underlay_user: 'HideThisField',
    no_underlayment_install: 'HideThisField',
    underlay_manf: '',
    underlay_style: '',
    underlay_thickness: '',
    sending_samples: 'HideThisField',
    shipping_company: '',
    tracking_number: '',
    date_shipped: '',
    claim_his_togg: true,
    observe_togg: true,
    app_field_togg: true,
    indus_stand_togg: true,
    conc_togg: true,
    cause_togg: '',
    manufacturing_issue_togg: '',
    site_rel_issue_togg: '',
    insta_rel_issue_togg: '',
    perfo_rel_issue_togg: '',
    manufacturing_issue: '',
    site_rel_issue: '',
    insta_rel_issue: '',
    perfo_rel_issue: '',
    confi: '',
    upload_sign_toggle: true,
    avatar: '',
    footer_text_info: '',
    private_notes_togg: '',
    footer_info_toggle: '',
    other_sub_floor_type: '',
  });

  useEffect(() => {
    setLoading(true);
    if (
      Object.keys(reduxData).length !== 0 &&
      reduxData.constructor === Object &&
      reduxData.currentUrl !== ''
    ) {
      // setLoading(true);
      setValues(reduxData);
      fetchclientData();
      fetchmanufData();
      fetchdealerData();
      setDefaultPanel();
      setLoading(false);
    } else {
      fetchReqData();
      fetchInspectorData();
      fetchclientData();
      fetchmanufData();
      fetchdealerData();
      setDefaultPanel();
    }

    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.warn('unnesseryfield', setBlock, setSize, setAvatarShape);
  const fetchReqData = (params = {}) => {
    dispatch(SingleRequestServices.getSingleRequest(Data.req_id))
      .then((res) => {
        console.info(res);
        setLoading(true);
        values.client_type = res.client_type;
        values.claim_num = res.claim_number;
        values.concerns = res.concerns;
        values.dealer_name = res.dealer_name;
        values.dealer_phone = res.dealer_phone;
        values.dealer_email = res.dealer_email;
        values.end_email = res.eu_email;
        values.h_eu_name = res.eu_name;
        values.h_eu_street = res.eu_street;
        values.h_eu_city = res.eu_city;
        values.h_eu_country = res.eu_country;
        values.h_eu_stateProvince = res.eu_state;
        values.end_address = res.eu_address;
        values.h_eu_zip = res.eu_zip;
        values.end_mobile = res.eu_phone2;
        values.eu_phone1 = res.eu_mob;
        values.adults = res.adults;
        values.childrens = res.childrens;
        values.pets = res.cats;
        values.h_eu_contact = res.manufacturer_contact;
        values.product_date_of_ins = res.installation_date;
        values.date_problem_noticed = res.problem_date;
        values.h_bi_manu_name = res.manufacturer_name;
        values.style_name = res.style_name;
        values.product_color = res.color;
        values.total_claim_qt = res.quanty;
        values.area_room_ins = res.room_installed;
        values.manf_name = res.manufacturer_name;
        values.manf_contact = res.manufacturer_contact;
        values.manf_phone = res.manufacturer_phone_number;
        values.manf_email = res.manufacturer_email;
        values.product_manf = res.manufacturer_name;
        values.dealer_contact = res.dealer_contact;
        values.product_phone = res.dealer_phone;
        values.product_email = res.dealer_email;
        values.company_name_qr = res.comm_party_company_name;
        values.agent_claim_name = res.comm_party_first_last_name;
        values.comm_email = res.comm_party_email;
        values.street_name_number = res.comm_party_street_name_number;
        values.city = res.comm_party_city;
        values.state = res.comm_party_state;
        values.country = res.comm_party_country;
        values.zip_code = res.comm_party_zip_code;
        values.phone_no_party = res.comm_party_phone_number;
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...', loading);
        setLoading(false);
      });
  };

  const fetchInspectorData = (params = {}) => {
    dispatch(inspectorService.getInspectorData())
      .then((res) => {
        values.signId = res.sign_for_report;
        values.signature = res.sign_for_report;
        values.signurl = res.signurl;
        values.footer_text = res.footer_text;
        if (res.footerlogos) {
          var sphValues = res.footerlogos;
          setFooterLogo(res.footerlogos);
          values.footerLogo = sphValues.map(function (e) {
            return e.toString();
          });
        }
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };
  const setDefaultPanel = () => {
    values.stepOneTogg = ['1', '2', '3', '4', '5', '6', '7', '8'];
    values.stepThreeTogg = ['11', '12', '13', '14'];
    values.stepFourTogg = ['6', '7', '8', '10'];
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...subDivision];
    list[index][name] = value;
    setSubDivision(list);
    console.warn('subDivision', subDivision);
  };

  const handleServiceRemove = (index) => {
    const list = [...subDivision];
    list.splice(index, 1);
    setSubDivision(list);
  };

  const handleServiceAdd = () => {
    setSubDivision([...subDivision, { gc_subdivision: '' }]);
  };

  const handleLotChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...lotNumber];
    list[index][name] = value;
    setLotNumber(list);
    console.warn('lotNumber', lotNumber);
  };

  const handleLotRemove = (index) => {
    const list = [...lotNumber];
    list.splice(index, 1);
    setLotNumber(list);
  };

  const handleLotAdd = () => {
    setLotNumber([...lotNumber, { gc_lotnumber: '' }]);
  };

  function getStepContent(steps) {
    switch (steps) {
      case 0:
        return (
          <FirstStep
            handleChange={handleChange}
            handleFill={handleFill}
            values={values}
            loading={loading}
            isOpen={isOpen}
            addClient={addClient}
            clientDropdown={clientDropdown}
            manufDropdown={manufDropdown}
            dealerDropdown={dealerDropdown}
            subDivision={subDivision}
            handleServiceChange={handleServiceChange}
            handleServiceAdd={handleServiceAdd}
            handleServiceRemove={handleServiceRemove}
            lotNumber={lotNumber}
            handleLotChange={handleLotChange}
            handleLotAdd={handleLotAdd}
            handleLotRemove={handleLotRemove}
            handleDateformate={handleDateformate}
            calOpen={calOpen}
            setCalOpen={setCalOpen}
            calOpen1={calOpen1}
            setCalOpen1={setCalOpen1}
            calOpen2={calOpen2}
            setCalOpen2={setCalOpen2}
            calOpen3={calOpen3}
            setCalOpen3={setCalOpen3}
            calOpen4={calOpen4}
            setCalOpen4={setCalOpen4}
            calOpen5={calOpen5}
            setCalOpen5={setCalOpen5}
            calOpen7={calOpen7}
            setCalOpen7={setCalOpen7}
            calOpen8={calOpen8}
            setCalOpen8={setCalOpen8}
            calOpen9={calOpen9}
            setCalOpen9={setCalOpen9}
          />
        );
      case 1:
        return (
          <SecondStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
          />
        );
      case 3:
        return (
          <FourthStep
            handleChange={handleChange}
            handleEditor={handleEditor}
            values={values}
            isOpen={isOpen}
            handleDateformate={handleDateformate}
            calOpen6={calOpen6}
            setCalOpen6={setCalOpen6}
            setLoadings={setLoadings}

          />
        );
      default:
        return <FirstStep handleChange={handleChange} values={values} />;
    }
  }

  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        console.info(res);
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const fetchmanufData = (params = {}) => {
    dispatch(getClientdropService.getManufdrop())
      .then((res) => {
        setmanufDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const fetchdealerData = (params = {}) => {
    dispatch(getClientdropService.getDealerdrop())
      .then((res) => {
        setdealerDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  function getSteps() {
    return [
      'GENERAL',
      'HISTORY AND MAINTENANCE',
      'INDOOR ENVIRONMENT',
      'OBSERVATION & COMMENTS',
    ];
  }

  const steps = getSteps();

  const onFinish = () => {
    dispatch(Actions.create_report({}));
    setLoadings(true);

    let subbb = [];
    values.activeStep = activeStep;
    subDivision.forEach((subd) => (subbb = [...subbb, subd.gc_subdivision]));
    values.gc_subdivision = subbb;
    let lot = [];
    lotNumber.forEach((lotNum) => (lot = [...lot, lotNum.gc_lotnumber]));
    values.gc_lotnumber = lot;
    values.req_id = Data.req_id;
    values.category = Data.floor_category;
    values.total_claim_qt_unit = values?.measure_type1;
    values.total_affected_unit = values?.measure_type2;
    values.claim_his_togg = values.claim_his_togg === true ? 'yes' : 'no';
    values.observe_togg = values.observe_togg === true ? 'yes' : 'no';
    values.app_field_togg = values.app_field_togg === true ? 'yes' : 'no';
    values.indus_stand_togg = values.indus_stand_togg === true ? 'yes' : 'no';
    values.conc_togg = values.conc_togg === true ? 'yes' : 'no';
    values.cause_togg = values.cause_togg === true ? 'yes' : 'no';
    values.manufacturing_issue_togg = values.manufacturing_issue_togg === true ? 'yes' : 'no';
    values.site_rel_issue_togg = values.site_rel_issue_togg === true ? 'yes' : 'no';
    values.insta_rel_issue_togg = values.insta_rel_issue_togg === true ? 'yes' : 'no';
    values.perfo_rel_issue_togg = values.perfo_rel_issue_togg === true ? 'yes' : 'no';
    values.issues_info_toggle = values.stepFourTogg?.includes('10')
      ? 'yes'
      : 'no';
    values.confi = values.confi === true ? 'yes' : 'no';
    values.upload_sign_toggle =
      values.upload_sign_toggle === true ? 'yes' : 'no';
    values.footer_text_info = values.footer_text_info === true ? 'yes' : 'no';
    values.private_notes_togg =
      values.private_notes_togg === true ? 'yes' : 'no';
    values.footer_info_toggle =
      values.footer_info_toggle === true ? 'yes' : 'no';
    values.party_toggle = values.stepOneTogg?.includes('1') ? 'yes' : 'no';
    values.enduser_toggle = values.stepOneTogg?.includes('2') ? 'yes' : 'no';
    values.mc_info_toggle = values.stepOneTogg?.includes('3') ? 'yes' : 'no';
    values.dealer_info_toggle = values.stepOneTogg?.includes('4')
      ? 'yes'
      : 'no';
    values.gc_info_toggle = values.stepOneTogg?.includes('5') ? 'yes' : 'no';
    values.basic_info_toggle = values.stepOneTogg?.includes('6') ? 'yes' : 'no';
    values.site_info_toggle = values.stepOneTogg?.includes('7') ? 'yes' : 'no';
    values.installer_info_toggle = values.stepOneTogg?.includes('8') ? 'yes' : 'no';
    values.interior_info_toggle = values.stepThreeTogg?.includes('11')
      ? 'yes'
      : 'no';
    values.moisture_info = values.stepThreeTogg?.includes('12') ? 'yes' : 'no';
    values.floor_mois_info = values.stepThreeTogg?.includes('13')
      ? 'yes'
      : 'no';
    values.subtrace_toggle = values.stepThreeTogg?.includes('14')
      ? 'yes'
      : 'no';
    values.obser_info_toggle = values.stepFourTogg?.includes('6')
      ? 'yes'
      : 'no';
    //values.confi = values.stepFourTogg?.includes("7") ? "yes" : "no";
    values.footer_info_toggle = values.stepFourTogg?.includes('8')
      ? 'yes'
      : 'no';
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const addClient = () => {
    console.warn('addClient', values);
    let clientData = {
      company_name_qr: values.company_name_qr,
      comm_party_drop: values.comm_party_drop,
      agent_claim_name: values.agent_claim_name,
      comm_email: values.comm_email,
      street_name_number: values.street_name_number,
      state: values.state,
      city: values.city,
      country: values.country,
      zip_code: values.zip_code,
      phone_no_party: values.phone_no_party,
    };
    if (clientData.company_name_qr !== '' && clientData.comm_email !== '') {
      dispatch(createClientService.create_client(clientData))
        .then((res) => {
          console.warn('createClientService', res);
          if (res.status === 'client added') {
            fetchclientData();
            notification.open({
              message: 'Client successfully created!',
              description: 'You have successfully created a client.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            });
          } else {
            fetchclientData();
            notification.error({
              message: `${res.status}`,
              description:
                'The client that you are trying to create already exists.”',
              className: 'custom-class',
            });
          }
        })
        .catch((err) => {
          console.info('Get Error Edit State...', loading);
          notification.error('This is an error message');
          setLoading(false);
        });
    } else {
      notification.error('Client data is not valid');
    }
  };

  const handleChange = (input) => (e) => {
    console.warn('Event on select', input, e);
    console.warn('isOpen', isOpen);
    if (
      input === 'comm_party_drop' ||
      input === 'flooring_type' ||
      input === 'car_i2_inst_type' ||
      input === 'building_type' ||
      input === 'building_style' ||
      input === 'vacumm_brand' ||
      input === 'cushion_dropdown' ||
      input === 'foundation' ||
      input === 'traffic_type' ||
      input === 'all_exterior' ||
      input === 'heating_system' ||
      input === 'air_conditioning' ||
      input === 'subfloor_type' ||
      input === 'installer_present' ||
      input === 'balance_toggle' ||
      input === 'flooring_installed_toggle' ||
      input === 'h_bui_cons' ||
      input === 'h_bui_walk_off' ||
      input === 'heat' ||
      input === 'airon' ||
      input === 'humidifier' ||
      input === 'dehumidifier' ||
      input === 'car_tog_alkali' ||
      input === 'car_tog_grade' ||
      input === 'car_tog_cross' ||
      input === 'car_tog_joist' ||
      input === 'car_tog_mold' ||
      input === 'car_tog_water' ||
      input === 'underlay_user' ||
      input === 'no_underlayment_install' ||
      input === 'h_bui_occupied' ||
      input === 'cleaned' ||
      input === 'professinally_cleaned' ||
      input === 'do_it_yourself' ||
      input === 'steam_mop' ||
      input === 'sending_samples' ||
      input === 'claim_his_togg' ||
      input === 'observe_togg' ||
      input === 'app_field_togg' ||
      input === 'indus_stand_togg' ||
      input === 'conc_togg' ||
      input === 'cause_togg' ||
      input === 'manufacturing_issue_togg' ||
      input === 'site_rel_issue_togg' ||
      input === 'insta_rel_issue_togg' ||
      input === 'perfo_rel_issue_togg' ||
      input === 'confi' ||
      input === 'upload_sign_toggle' ||
      input === 'footer_text_info' ||
      input === 'private_notes_togg' ||
      input === 'floor_rolled_toggle' ||
      input === 'footer_info_toggle' ||
         
      input === 'car_tog_wall' ||
      input === 'car_tog_paint' ||
      input === 'car_tog_floor' ||
      input === 'car_tog_vented' ||
      input === 'car_tog_rusty' ||
      input === 'car_tog_finish' ||
      input === 'car_tog_insulated' ||
      input === 'mois_test' 
    ) {
      if (input === 'car_tog_wall') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_paint') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_floor') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_vented') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_rusty') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_finish') {
        setValues({ ...values, [input]: e });
      } 
      if (input === 'car_tog_insulated') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'mois_test') {
        setValues({ ...values, [input]: e });
      } 
      if (input === 'comm_party_drop' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          commPartyopen: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'comm_party_drop' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          commPartyopen: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'humidifier') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humidifierField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humidifierField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'floor_rolled_toggle') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'dehumidifier') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            dehumidifierField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            dehumidifierField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'do_it_yourself') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'heat') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'airon') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_alkali') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_grade') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_cross') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_joist') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_mold') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_tog_water') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'flooring_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          floorOtherField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooring_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          floorOtherField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'installer_present') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'balance_toggle') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'flooring_installed_toggle') {
        setValues({ ...values, [input]: e });
      }


      if (input === 'h_bui_occupied') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            buildField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            buildField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }

      if (input === 'h_bui_walk_off') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            walkField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            walkField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'h_bui_cons') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            constructionField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            constructionField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'underlay_user') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            underlayField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            underlayField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      
      if (input === 'no_underlayment_install') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'car_i2_inst_type' && e === 'Glue down') {
        setIsOpen({
          ...isOpen,
          installationGlue: true,
          installationOther: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Glue down') {
        setIsOpen({
          ...isOpen,
          installationGlue: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'car_i2_inst_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          installationGlue: false,
          installationOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          installationOther: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e === 'Nail/staple') {
        setIsOpen({
          ...isOpen,
          stapleField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'car_i2_inst_type' && e !== 'Nail/staple') {
        setIsOpen({
          ...isOpen,
          stapleField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'building_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'building_style' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_style' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'vacumm_brand' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          vacummBrandOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'vacumm_brand' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          vacummBrandOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'cushion_dropdown' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          cushionOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'cushion_dropdown' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          cushionOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (
        input === 'foundation' &&
        (e === 'Foundation' || e === 'Crawlspace')
      ) {
        setIsOpen({
          ...isOpen,
          foundationField: true,
          basementField: false,
          slabField: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'foundation' && e === 'Basement') {
        setIsOpen({
          ...isOpen,
          foundationField: false,
          basementField: true,
          slabField: false,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'foundation' && e === 'Slab') {
        setIsOpen({
          ...isOpen,
          foundationField: false,
          basementField: false,
          slabField: true,
        });
        setValues({ ...values, [input]: e });
      }else if (input === 'foundation' && e === 'Basement/Crawl Space') {
        setIsOpen({
          ...isOpen,
          foundationField: false,
          basementField:  true,
          slabField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'traffic_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          trafficTypeOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'traffic_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          trafficTypeOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'all_exterior' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          exteriorOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'all_exterior' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          exteriorOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'subfloor_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          subFloorOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'subfloor_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          subFloorOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'heating_system' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          heatingField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'heating_system' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          heatingField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'air_conditioning' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          acField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'air_conditioning' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          acField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'cleaned') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            cleanedField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            cleanedField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'professinally_cleaned') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            proCleanedField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            proCleanedField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }

      if (input === 'steam_mop') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            steamField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            steamField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }

      if (input === 'sending_samples') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            sendingSampleField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            sendingSampleField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }

      if (input === 'claim_his_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          claimHistoryField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'claim_his_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          claimHistoryField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'observe_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          observationField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'observe_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          observationField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'app_field_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          applicableField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'app_field_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          applicableField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'indus_stand_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          industryField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'indus_stand_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          industryField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'conc_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          conclusionField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'conc_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          conclusionField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'cause_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          causeField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'cause_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          causeField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'confi' && e === true) {
        setIsOpen({
          ...isOpen,
          confidentialField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'confi' && e === false) {
        setIsOpen({
          ...isOpen,
          confidentialField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'upload_sign_toggle' && e === true) {
        setIsOpen({
          ...isOpen,
          signatureField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'upload_sign_toggle' && e === false) {
        setIsOpen({
          ...isOpen,
          signatureField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'footer_text_info' && e === true) {
        setIsOpen({
          ...isOpen,
          footerField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'footer_text_info' && e === false) {
        setIsOpen({
          ...isOpen,
          footerField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'private_notes_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          notesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'private_notes_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          notesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'manufacturing_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          manuissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'manufacturing_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          manuissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'site_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          siteissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'site_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          siteissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'insta_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          instaissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'insta_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          instaissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'perfo_rel_issue_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          perfoissuesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'perfo_rel_issue_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          perfoissuesField: false,
        });
        setValues({ ...values, [input]: e });
      }
    } else if (
      input === 'inspection_type' ||
      input === 'client_select_drop' ||
      input === 'fiber_type' ||
      input === 'time_type' ||
      input === 'measure_type1' ||
      input === 'measure_type2' ||
      input === 'property_dropdown' ||
      input === 'building_type' ||
      input === 'building_style' ||
      input === 'traffic_type' ||
      input === 'vacumm_brand' ||
      input === 'vacumm_condition' ||
      input === 'cri_seal' ||
      input === 'foundation' ||
      input === 'grade' ||
      input === 'footprint' ||
      input === 'joist_type' ||
      input === 'building_landscape' ||
      input === 'subfloor_type' ||
      input === 'car_i2_inst_type' ||
      input === 'cushion_dropdown' ||
      input === 'heating_system' ||
      input === 'air_conditioning' ||
      input === 'all_exterior' ||
      input === 'stepOneTogg' ||
      input === 'stepThreeTogg' ||
      input === 'stepFourTogg' 

    ) {
      input === 'time_type' ||
      input === 'measure_type1' ||
      input === 'measure_type2'
        ? setValues({ ...values, [input]: e[0] })
        : setValues({ ...values, [input]: e });
    } else if (
      input === 'h_bi_ins_date' ||
      input === 'product_date_of_ins' ||
      input === 'date_problem_noticed' ||
      input === 'closing_date' ||
      input === 'moving_date' ||
      input === 'date_shipped' ||
      input === 'operational_date' ||
      input === 'date_home' ||
      input === 'date_hvac' ||
      input === 'site_occupied_date'
    ) {
      let date = e;
      setValues({ ...values, [input]: date });
    } else if (input === 'footerLogo') {
      //start
      let footerlogo1 = [];
      let id = e.target.value;
      footerlogo1 = [...footerLogo];
      if (footerlogo1?.includes(id)) {
        let footer_idex = footerlogo1.indexOf(id);
        delete footerlogo1[footer_idex];
      } else {
        footerlogo1.push(id);
      }
      footerlogo1 = footerlogo1?.filter((e) => e);
      setFooterLogo(footerlogo1);
      values.footerLogo = footerlogo1;
    } else {
      setValues({ ...values, [input]: e.target.value });
    }
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });
    dispatch(Actions.create_report(vals));
  };

  const handleEditor = (valEditor, label) => {
    setValues((l) => ({ ...l, [label]: valEditor }));
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });

    dispatch(Actions.create_report(vals));
  };

  const handleFill = (val) => {
    setValues({ ...values, ...val });
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });

    dispatch(Actions.create_report(vals));
  };

  const saveDraft = () => {
    setSpinnerReport(true);
    onFinish();
    values.status = 'Draft';
    console.warn('saveDraftvalues', values);
    dispatch(carpetBasicservice.createReport(values))
      .then((res) => {
        console.warn('Submit', res);
        setSpinnerReport(false);
        navigate('/hardwood-detailed-update/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const buttonSubmit = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    dispatch(carpetBasicservice.createReport(values))
      .then((res) => {
        console.warn('Submit', res);
        notification.open({
          message: 'Report successfully created!',
          description: 'You have successfully created a report.',
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#108ee9',
              }}
            />
          ),
        });
        //return;
        setSpinnerReport(false);
        navigate('/single-hardwood-detailed/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const handleNavigate = () => {
    window.open(
      '//www.floordetective.com/wp-content/uploads/2021/09/Hardwood-Details-Blank.pdf',
      '_blank'
    );
  };

  const handleDateformate = (e, name, type) => {
    console.log('ok', e);
    if (name === 'h_bi_ins_date') {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'product_date_of_ins') {
      if (type === 'date') {
        setCalOpen1(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_problem_noticed') {
      if (type === 'date') {
        setCalOpen2(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'closing_date') {
      if (type === 'date') {
        setCalOpen3(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'moving_date') {
      if (type === 'date') {
        setCalOpen4(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'operational_date') {
      if (type === 'date') {
        setCalOpen5(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_shipped') {
      if (type === 'date') {
        setCalOpen6(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_home') {
      if (type === 'date') {
        setCalOpen7(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_hvac') {
      if (type === 'date') {
        setCalOpen8(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'site_occupied_date') {
      if (type === 'date') {
        setCalOpen9(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    console.info('arra', e, name, type);
  };

  return (
    <>
      {SpinnerReport ? (
        <ReportSpin />
      ) : (
        <>
          <div className="quickReportWrapper ">
            <div className="pageHeader ">
              <PageHeader title="Hardwood" />
            </div>
            {loading ? (
              <div className="container-xxl">
                <div className="Skeleton_space">
                  <Row gutter={16} className="mb-3" justify="center">
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <>
                <div className="buttonSection">
                  <div className="container-xxl">
                    <Row>
                      <Col xs={24} md={24} className="text-end">
                        <Button
                          type="success"
                          className="btn inspectionAdd"
                          onClick={() => handleNavigate()}
                          icon={<FilePdfOutlined />}
                        >
                          Print Form
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="container-xxl CarpetDetailed ">
                  <div className="reportInner">
                    <Box sx={{ my: 5 }}>
                      <div>
                        <Stepper
                          activeStep={activeStep}
                          alternativeLabel
                          nonLinear
                        >
                          {steps.map((label, index) => (
                            <Step key={label} className="cusutom-stepp">
                              <StepButton
                                color="inherit"
                                onClick={handleStep(index)}
                              >
                                {label}
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    </Box>
                    <Form
                      name="basic"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="custom-form"
                    >
                      <div className="step-content">
                        {getStepContent(activeStep)}
                        <div className="custum-btngrp">
                          <Button
                            className="nxtbtn"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                          {activeStep === steps.length - 1 ? (
                            <Button
                              onClick={() => buttonSubmit()}
                              className="submitBtn"
                              loading={loadings}
                            >
                              Save And Continue To Preview Report
                            </Button>
                          ) : (
                            <Button
                              className="custom-nxtbtn"
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="saveDraftBtn">
                  <Button
                    className="float floatBtn sc_button_hover_fade"
                    id="draft"
                    name="update_button"
                    onClick={() => saveDraft()}
                    disabled={loadings}
                  >
                    Save Draft
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default HardwoodDetailed;
