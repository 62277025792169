import { AUTH_USER } from '../action-type';

export const set_user_auth = (payload) => {
  return {
    type: AUTH_USER,
    payload: payload,
  };
};



