import React from 'react';
import PagesHeader from '../../components/PageHeader';
import UnassignedClaims from '../../datatables/Mohawk/unAssignedDatatable';

const UnAssignedClaims = () => {
  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader
          title='Unassigned Claims'
        />
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
            <UnassignedClaims />
        </div>
      </div>
    </div>
  );
};

export default UnAssignedClaims;
