import React from "react";
import { Row, Col, Collapse, Input, Card, Form, Select } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";

const { Option } = Select;
const { Panel } = Collapse;

const ThirdStep = (props) => {
  console.warn("ThirdStep", props)
  const [form] = Form.useForm();

  return (
    <Form
      name="report"
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="CollapseSection">
                <Collapse
                  defaultActiveKey={
                    props.values.stepThreeTogg ? 
                    props.values.stepThreeTogg : 
                    ["1"]
                  }
                  onChange={props.handleChange("stepThreeTogg")}
                  className="reportCollapse"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="custom-icon" />
                    ) : (
                      <PlusOutlined className="custom-icon" />
                    )
                  }
                  ghost
                >
                  <Panel header="Substrate Information" key="1">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Foundation" name="foundation">
                            <Select
                              className="formGroup"
                              defaultValue={props.values.foundation ? props.values.foundation : 'Select Foundation'}
                              onChange={props.handleChange("foundation")}
                            >
                              <Option value="Foundation">Foundation</Option>
                              <Option value="Basement">Basement</Option>
                              <Option value="Crawlspace">Crawl Space</Option>
                              <Option value="Basement/Crawl Space">Basement/Crawl Space</Option>
                              <Option value="Slab">Slab</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Grade/Gradient" name="grade">
                            <Select
                              className="formGroup"
                              defaultValue={props.values.grade ? props.values.grade : 'Grade/Gradient'}
                              onChange={props.handleChange("grade")}
                            >
                              <Option value="Grade/Gradient">
                                Grade/Gradient
                              </Option>
                              <Option value="Above Grade">Above Grade</Option>
                              <Option value="On Grade">On Grade</Option>
                              <Option value="Below Grade">Below Grade</Option>
                              <Option value="On Grade & Above Grade">
                                On Grade & Above Grade
                              </Option>
                              <Option value="On All Grades">On All Grades</Option>
                              <Option value="Level To The Building">
                                Level To The Building
                              </Option>
                              <Option value="Towards The Building">Towards The Building</Option>
                              <Option value="Away from Building">
                                Away from Building
                              </Option>
                              <Option value="Towards The Building With Gutter Attached">
                                Towards The Building With Gutter Attached
                              </Option>
                              <Option value="Away From The Building With Gutter Attached">
                                Away From The Building With Gutter Attached
                              </Option>
                              <Option value="Squash Blocks">
                                Squash Blocks
                              </Option>
                              <Option value="Missing Gutters">
                                Missing Gutters
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Home/Building Footprint"
                            name="footprint"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={props.values.footprint ? props.values.footprint : 'Home/Building Footprint'}
                              onChange={props.handleChange("footprint")}
                            >
                              <Option value="Home/Building Footprint">
                                Home/Building Footprint
                              </Option>
                              <Option value="Surrounding Conditions Home/Building ">
                                Surrounding Conditions Home/Building
                              </Option>
                              <Option value="Coastal">Coastal</Option>
                              <Option value="Valley">Valley</Option>
                              <Option value="Flatland">Flatland</Option>
                              <Option value="Gradually Rolling Hill: Denote Side, East, West,
                                South and or North">
                                Gradually Rolling Hill: Denote Side, East, West,
                                South and or North
                              </Option>
                              <Option value="Foothills">Foothills</Option>
                              <Option value="Mountainous">Mountainous</Option>
                              <Option value="Desert">Desert</Option>
                              <Option value="High Desert">High Desert</Option>
                              <Option value="Island">Island</Option>
                              <Option value="Rural">Rural</Option>
                              <Option value="Urban">Urban</Option>
                              <Option value="High Rise">High Rise</Option>
                              <Option value="New Construction in New Developing Neighborhood">
                                New Construction in New Developing Neighborhood
                              </Option>
                              <Option value="New Construction: 1 Month - 12 Months">
                                New Construction: 1 Month - 12 Months
                              </Option>
                              <Option value="New Construction: 12-24 Months">
                                New Construction: 12-24 Months
                              </Option>
                              <Option value="New Constructed: 24-36 Month">
                                New Constructed: 24-36 Month
                              </Option>
                              <Option value="Concrete Slab">
                                Concrete Slab
                              </Option>
                              <Option value="Concrete Below Grade">
                                Concrete Below Grade
                              </Option>
                              <Option value="Concrete Suspended Slab: Type Gypsum Etc">
                                Concrete Suspended Slab: Type Gypsum Etc
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Building Landscape"
                            name="building_landscape"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={props.values.building_landscape ? props.values.building_landscape : 'Building Landscape'}
                              onChange={props.handleChange(
                                "building_landscape"
                              )}
                            >
                              <Option value="Building Landscape">
                                Building Landscape
                              </Option>
                              <Option value="Plant Life">Plant Life</Option>
                              <Option value="Trees Bushes">Trees Bushes</Option>
                              <Option value="Vegetation Foliage Trees">
                                Vegetation Foliage Trees
                              </Option>
                              <Option value="Shrubbery Grass">
                                Shrubbery Grass
                              </Option>
                              <Option value="An Automatic Drip Irrigation An Automatic
                                Sprinkler An Automatic Irrigation">
                                An Automatic Drip Irrigation An Automatic
                                Sprinkler An Automatic Irrigation
                              </Option>
                              <Option value="Rough Grade Complete">
                                Rough Grade Complete
                              </Option>
                              <Option value="Seeded">Seeded</Option>
                              <Option value="New Sod">New Sod</Option>
                              <Option value="Partial Complete">
                                Partial Complete
                              </Option>
                              <Option value="Completed">Completed</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    {props.values.foundation === "Foundation" || props.values.foundation === "" ||
                    props.values.foundation === "Crawlspace" || props.values.foundation === "Basement" || props.values.foundation === "Basement/Crawl Space" ||
                      props.isOpen.foundationField === true || 
                      props.isOpen.crawalField === true || 
                      props.isOpen.basementField === true ? (
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Soil Subfloor Distance"
                              name="soil_subfloor"
                            >
                              <Input
                                placeholder="Soil Subfloor Distance"
                                className="formControl"
                                onChange={props.handleChange("soil_subfloor")}
                                defaultValue={props.values.soil_subfloor}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Relative Slab Soil Elevation"
                              name="relate_slab"
                            >
                              <Input
                                placeholder="Relative Slab Soil Elevation"
                                className="formControl"
                                onChange={props.handleChange("relate_slab")}
                                defaultValue={props.values.relate_slab}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Temperature Crawl Space"
                              name="temperature_crawl"
                            >
                              <Input
                                placeholder="Temperature Crawl Space"
                                className="formControl"
                                onChange={props.handleChange(
                                  "temperature_crawl"
                                )}
                                defaultValue={props.values.temperature_crawl}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Ground Covered %"
                              name="ground_cover_per"
                            >
                              <Input
                                placeholder="Ground Covered %"
                                className="formControl"
                                onChange={props.handleChange(
                                  "ground_cover_per"
                                )}
                                defaultValue={props.values.ground_cover_per}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Relative Humidity Reading Crawl Space"
                              name="relative_humidity"
                            >
                              <Input
                                placeholder="Relative Humidity Reading Crawl Space"
                                className="formControl"
                                onChange={props.handleChange(
                                  "relative_humidity"
                                )}
                                defaultValue={props.values.relative_humidity}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                              label="Above Grade" 
                              name="car_tog_grade"
                            >
                              { console.warn("car_tog_grade",props.values.car_tog_grade)}
                            <TripleToggle
                              value={props.values.car_tog_grade}
                              onToggle={props.handleChange('car_tog_grade')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                              label="Foundation Alkali"
                              name="car_tog_alkali"
                            >
                              { console.warn("car_tog_alkali",props.values.car_tog_alkali)}
                            <TripleToggle
                              value={props.values.car_tog_alkali}
                              onToggle={props.handleChange('car_tog_alkali')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                              label="Cross Flow"
                              name="car_tog_cross"
                            >
                              { console.warn("car_tog_joist",props.values.car_tog_cross)}
                            <TripleToggle
                              value={props.values.car_tog_cross}
                              onToggle={props.handleChange('car_tog_cross')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                               label="Joist Discoloration"
                               name="car_tog_joist"
                            >
                              { console.warn("car_tog_joist",props.values.car_tog_joist)}
                            <TripleToggle
                              value={props.values.car_tog_joist}
                              onToggle={props.handleChange('car_tog_joist')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                               label="Mold Present"
                               name="car_tog_mold"
                            >
                            { console.warn("car_tog_mold",props.values.car_tog_mold)}

                            <TripleToggle
                              value={props.values.car_tog_mold}
                              onToggle={props.handleChange('car_tog_mold')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                               label="Standing Water Present"
                               name="car_tog_water"
                            >

                             { console.warn("car_tog_water",props.values.car_tog_water)}
                            <TripleToggle
                              value={props.values.car_tog_water}
                              onToggle={props.handleChange('car_tog_water')}
                            />
                          </Form.Item>
                          
                          </div>
                        </Col>
                      </Row>
                    ) : null}

                    {props.isOpen.slabField === true || 
                      props.isOpen.basementField === true || 
                      props.isOpen.foundationField === true || 
                      props.isOpen.crawalField === true || 
                      props.values.foundation === "Slab" ||
                      props.values.foundation === "Basement" ||
                      props.values.foundation === "Basement/Crawl Space" ||
                      props.values.foundation === "Crawlspace" ||
                      props.values.foundation === "Foundation" ?  (
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Sub Floor Type  "
                              name="subfloor_type"
                            >
                              <Select
                                className="formGroup"
                                defaultValue={props.values.subfloor_type ? props.values.subfloor_type :  "Select Sub Floor Type"}
                                onChange={props.handleChange("subfloor_type")}
                              >
                                <Option value="Concrete">Concrete</Option>
                                <Option value="Concrete on Grade">
                                  Concrete on Grade
                                </Option>
                                <Option value="Concrete Above Grade">
                                  Concrete Above Grade
                                </Option>
                                <Option value="Concrete Below Grade">
                                  Concrete Below Grade
                                </Option>
                                <Option value="Gypsum Concrete">
                                  Gypsum Concrete
                                </Option>
                                <Option value="Wood">Wood</Option>
                                <Option value="Wood and Concrete">
                                  Wood and Concrete
                                </Option>
                                <Option value="Wood over Crawlspace">
                                  Wood over Crawlspace
                                </Option>
                                <Option value="Wood Over Basement/Crawlspace">
                                Wood Over Basement/Crawlspace
                                </Option>
                                <Option value="Wood Over Basement">
                                  Wood Over Basement
                                </Option>
                                <Option value="Lightweight Concrete">
                                  Lightweight Concrete
                                </Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="See Report">See Report</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.subFloorOther === true || props.values.subfloor_type === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Subfloor Type Other"
                                name="other_sub_floor_type"
                              >
                                <Input
                                  placeholder="Subfloor Type Other"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    "other_sub_floor_type"
                                  )}
                                  defaultValue={
                                    props.values.other_sub_floor_type
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    ) : null}
                    
                    {props.values.foundation === "Basement" || props.values.foundation === "Basement/Crawl Space" || props.isOpen.basementField === true ? (
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                               label="Wall Cracked" name="car_tog_wall"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_wall}
                              onToggle={props.handleChange('car_tog_wall')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                               label="Paint Peeling"
                               name="car_tog_paint"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_paint}
                              onToggle={props.handleChange('car_tog_paint')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                               label="Floor Stained"
                               name="car_tog_floor"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_floor}
                              onToggle={props.handleChange('car_tog_floor')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                                label="Vented" name="car_tog_vented"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_vented}
                              onToggle={props.handleChange('car_tog_vented')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Rusty Nails" name="car_tog_rusty"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_rusty}
                              onToggle={props.handleChange('car_tog_rusty')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                  label="Finished" name="car_tog_finish"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_finish}
                              onToggle={props.handleChange('car_tog_finish')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                  label="Insulated Ceiling"
                                  name="car_tog_insulated"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.car_tog_insulated}
                              onToggle={props.handleChange('car_tog_insulated')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Moisture tested before installation"
                                   name="mois_test"
                            >
                              {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.mois_test}
                              onToggle={props.handleChange('mois_test')}
                            />
                          </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default ThirdStep;
