import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, Divider, Switch,Skeleton,Empty } from "antd";
import { IconButton } from "../../components/Buttons/buttons";
import { EyeFilled } from "@ant-design/icons";
import * as ClaimAnalystData from "../../services/ClaimAnalystData";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as Clientsrvices from "../../services/AllClients";
import { useNavigate } from "react-router-dom";
// import SkeltonTable from "../../pages/InspectionReport/SkeltonTable";

const ClaimAnalyst = () => {
  var dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    fetchClaimAnalystData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClaimAnalystData = (params = {}) => {
    setLoading(true);
    dispatch(ClaimAnalystData.getClaimAnalystData())
      .then((res) => {
        console.warn("setInspections", res);
        setInspections(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const reportCheck = (event, id) => {
    console.warn("record", event, id);
    let status = event;
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      confirmButtonText: `Yes`,
      showDenyButton: true,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (status === true) {
          setLoading(true);
          let vals = {
            id: id,
            val: status,
          };
          dispatch(Clientsrvices.disable_client(vals))
            .then((res) => {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Enabled!",
                text: "Client has been enabled.",
                showLoaderOnConfirm: true,
              });
            })
            .catch((err) => {
              console.info("Get Error Edit State...");
              setLoading(false);
            });
        } else {
          setLoading(true);
          let vals = {
            id: id,
            val: status,
          };
          dispatch(Clientsrvices.disable_client(vals))
            .then((res) => {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Disabled!",
                text: "Client has been disabled.",
                showLoaderOnConfirm: true,
              });
            })
            .catch((err) => {
              console.info("Get Error Edit State...");
              setLoading(false);
            });
        }
      } else if (result.isDenied) {
        if (event === true) {
          Swal.fire({
            icon: "warning",
            text: "Client Not Disabled!",
            showLoaderOnConfirm: true,
          });
        } else {
          Swal.fire({
            icon: "warning",
            text: "Client Not Enabled.",
            showLoaderOnConfirm: true,
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Claim Analyst ID.",
      dataIndex: "user_id",
      key: "Claim_Analyst_ID",
    },
    {
      title: "Claim Analyst Name",
      dataIndex: "fullname",
      key: "Claim_Analyst_Name",
      sorter: {
        compare: (a, b) => a.Claim_Analyst_Name - b.Claim_Analyst_Name,
      },
    },
    {
      title: "Completed Inspections",
      dataIndex: "req_count",
      key: "Completed_Inspections",
      sorter: {
        compare: (a, b) => a.Completed_Inspections - b.Completed_Inspections,
      },
    },
    {
      title: "Status",
      dataIndex: "user_status",
      key: "status",
      render: (e, record) => {
        return (
          <div className="actionBtn">
            <Switch
              checkedChildren="ENABLE"
              unCheckedChildren="DISABLE"
              onChange={(event) => reportCheck(event, record.user_id)}
              defaultChecked={e === "1" ? false : true}
            />
          </div>
        );
      },
      sorter: (a, b) => {
        a = a.status || "";
        b = b.status || "";
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (row, record) => {
        // console.log({ row });
        return (
          <div className="actionBtn">
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              tooltip="View"
              text="View"
              handleClick={() =>
                navigate("/single-claim-analyst/?user_id=" + record.user_id)
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <Card className="reportCard">
      <Row gutter={16}>
        <Col>
          <div className="d-flex align-Items-center">
            <p className="tableHeaderText">Claim Analysts Listing </p>
          </div>
          <div>
            <p className="paragSubtitle">{inspections.length} Claim Analysts</p>
          </div>
        </Col>
      </Row>

      <Divider />
      <Table
        bordered
        className="clientsDataTable"
        tableLayout="auto"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={inspections}
        locale={{
          emptyText: loading ? columns.map(u => <Skeleton.Input height={50} style={{marginTop: '2px',height:'48px',padding:'0px'}} block={true}active={true} />): <Empty />
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
          position: ["bottomCenter"],
        }}
      //   loading={{
      //     indicator: <SkeltonTable />,
      //    spinning: loading,
      //  }}
      />
    </Card>
  );
};

export default ClaimAnalyst;
