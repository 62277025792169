import React from "react";
import {
  Row,
  Col,
  Collapse,
  Input,
  Card,
  Form,
  Select,
  Button,
  Switch,
  Cascader,
} from "antd";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import {
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const options = [
  {
    value: "sq/ft",
    label: "sq/ft",
  },
  {
    value: "sq/yds",
    label: "sq/yds",
  },
  {
    value: "boxes",
    label: "boxes",
  },
];
const { Option } = Select;
const { Panel } = Collapse;

const ThirdStep = (props) => {
  console.warn("ThirdStep", props);
  const [form] = Form.useForm();

  return (
    <Form name="report" autoComplete="off" layout="vertical" form={form}>
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="CollapseSection">
                <Collapse
                  defaultActiveKey={
                    props.values.stepThreeTogg
                      ? props.values.stepThreeTogg
                      : ["1", "2", "3", "4"]
                  }
                  onChange={props.handleChange("stepThreeTogg")}
                  className="reportCollapse"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="custom-icon" />
                    ) : (
                      <PlusOutlined className="custom-icon" />
                    )
                  }
                  ghost
                >
                  <Panel header="Concerns" key="1">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Condition of Existing Floor"
                              name="concern_con_exi_flr"
                            >
                              <Select
                                defaultValue={
                                  props.values.concern_con_exi_flr
                                    ? props.values.concern_con_exi_flr
                                    : "Condition of Existing Floor"
                                }
                                onChange={props.handleChange(
                                  "concern_con_exi_flr"
                                )}
                              >
                                <Option value="General Throughout">
                                  General Throughout
                                </Option>
                                <Option value="Localized ">Localized </Option>
                                <Option value="Improving">Improving</Option>
                                <Option value="Stabilized">Stabilized</Option>
                                <Option value="Progressing">Progressing</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.concern_con_exi_flrField === true || props.values.concern_con_exi_flr === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="concern_con_exi_flr_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange("concern_con_exi_flr_other")}
                                  defaultValue={props.values.concern_con_exi_flr_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Flooring" key="2">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Species" name="concern_spec_drop">
                              <Input
                                  placeholder="Species"
                                  className="formControl"
                                  onChange={props.handleChange("concern_spec_drop")}
                                  defaultValue={props.values.concern_spec_drop}
                                />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Flooring Type" name="flooringtype">
                              <Select
                                defaultValue={
                                  props.values.flooringtype
                                    ? props.values.flooringtype
                                    : "Flooring Type"
                                }
                                onChange={props.handleChange(
                                  "flooringtype"
                                )}
                              >
                                <Option value="Factory Finished">
                                  Factory Finished
                                </Option>
                                <Option value="Unfinished ">Unfinished </Option>
                                <Option value="Solid">Solid</Option>
                                <Option value="Engineered">Engineered</Option>
                                <Option value="Strip">Strip</Option>
                                <Option value="Plank">Plank</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                              
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.concern_spec_drop_otherField === true || props.values.flooringtype === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="concern_spec_drop_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange("concern_spec_drop_other")}
                                  defaultValue={props.values.concern_spec_drop_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        {props.isOpen.concern_spec_dropField === true || props.values.flooringtype === "Plank" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Width of Plank" name="concern_spec_drop_width">
                                <Input
                                  placeholder="Width of Plank"
                                  className="formControl"
                                  onChange={props.handleChange("concern_spec_drop_width")}
                                  defaultValue={props.values.concern_spec_drop_width}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Previously Finished Floor" name="pre_fini_floor">
                              <Select
                                defaultValue={
                                  props.values.pre_fini_floor
                                    ? props.values.pre_fini_floor
                                    : "Previously Finished Floor"
                                }
                                onChange={props.handleChange(
                                  "pre_fini_floor"
                                )}
                              >
                                <Option value="Previously Finished Floor ">Previously Finished Floor </Option>
                                <Option value="Factory Finished">Factory Finished</Option>
                                <Option value="New Install">New Install</Option>
                                
                              </Select>
                            </Form.Item>
                          </div>
                        </Col><Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Type of Finish on Existing Floor"
                              name="type_of_finish_exis_flr"
                            >
                              <Input
                                placeholder="Type of Finish on Existing Floor"
                                className="formControl"
                                onChange={props.handleChange(
                                  "type_of_finish_exis_flr"
                                )}
                                defaultValue={
                                  props.values.type_of_finish_exis_flr
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Total Sq/ft" name="totalsqft_per_cont">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange("measure_type15")}
                                    options={options}
                                    defaultValue={props.values.measure_type15}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("totalsqft_per_cont")}
                                defaultValue={props.values.totalsqft_per_cont}
                                placeholder="Total Sq/ft"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                       <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="% of Floor Affected"
                              name="per_flo_aff"
                            >
                              <Input
                                placeholder="% of Floor Affected"
                                className="formControl"
                                onChange={props.handleChange("per_flo_aff")}
                                defaultValue={props.values.per_flo_aff}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Rooms Being Inspected" key="3">
                    <div className="inspecContent">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Entry" name="rooms_entry">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange("measure_type1")}
                                    options={options}
                                    defaultValue={props.values.measure_type1}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_entry")}
                                defaultValue={props.values.rooms_entry}
                                placeholder="Entry"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Formal" name="rooms_formal">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "formal_temp"
                                    )}
                                    options={options}
                                    defaultValue={props.values.formal_temp}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_formal")}
                                defaultValue={props.values.rooms_formal}
                                placeholder="Formal"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Kitchen" name="rooms_kit">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type3"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type3}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_kit")}
                                defaultValue={props.values.rooms_kit}
                                placeholder="Kitchen"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Study" name="rooms_study">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type4"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type4}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_study")}
                                defaultValue={props.values.rooms_study}
                                placeholder="Study"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Hall(s)" name="rooms_hall">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type5"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type5}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_hall")}
                                defaultValue={props.values.rooms_hall}
                                placeholder="Hall(s)"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Library" name="rooms_library">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type6"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type6}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_library")}
                                defaultValue={props.values.rooms_library}
                                placeholder="Library"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Great Room"
                              name="rooms_great_room"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type7"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type7}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange(
                                  "rooms_great_room"
                                )}
                                defaultValue={props.values.rooms_great_room}
                                placeholder="Great Room"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Living Room"
                              name="rooms_living_room"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type9"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type9}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange(
                                  "rooms_living_room"
                                )}
                                defaultValue={props.values.rooms_living_room}
                                placeholder="Living Room"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Dining Room"
                              name="rooms_dining_roo"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type10"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type10}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange(
                                  "rooms_dining_roo"
                                )}
                                defaultValue={props.values.rooms_dining_roo}
                                placeholder="Dining Room"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Nook" name="rooms_nook">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type11"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type11}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_nook")}
                                defaultValue={props.values.rooms_nook}
                                placeholder="Nook"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Pantry" name="rooms_part">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type12"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type12}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("rooms_part")}
                                defaultValue={props.values.rooms_part}
                                placeholder="Pantry"
                              />
                            </Form.Item>
                          </div>
                        </Col> 
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Powder Bath" name="powderBath">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type13"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type13}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("powderBath")}
                                defaultValue={props.values.powderBath}
                                placeholder="Powder Bath"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6} className="appendDiv formGroup">
                          <label htmlFor="Bath" className="pb-2">
                            Bath
                          </label>
                          {props.bathArea.map((singleBatharea, index) => (
                            <div key={index} className="services mb-2">
                              <div className="first-division">
                                <Input.Group compact>
                                  <div className="input-group customRepeatorDivHide">
                                    <Input
                                      name="bath_area"
                                      type="text"
                                      id="bath_area"
                                      value={singleBatharea.bath_area}
                                      onChange={(e) =>
                                        props.handleBathChange(e, index,'bath_area')
                                      }
                                      placeholder="Bath"
                                      className="formControl customPaddingg"
                                    />
                                    <Select
                                      name="bath_area_unit"
                                      defaultValue={singleBatharea.bath_area_unit}
                                      options={options}
                                      onChange={(e) =>
                                        props.handleBathChange(e, index,'bath_area_unit')
                                      }
                                      className="customselect"
                                    />
                                  </div>
                                  {props.bathArea.length - 1 === index &&
                                    props.bathArea.length < 5 && (
                                      <Button
                                        type="button"
                                        onClick={props.handleBathAdd}
                                        className="formControl repeatorDivHideBtn"
                                        icon={<PlusOutlined />}
                                      />
                                    )}
                                  {props.bathArea.length !== 1 && (
                                    <Button
                                      type="button"
                                      icon={<MinusOutlined />}
                                      onClick={() =>
                                        props.handleBathRemove(index)
                                      }
                                      className="formControl repeatorDivHideBtn"
                                    />
                                  )}
                                </Input.Group>
                              </div>
                            </div>
                          ))}
                        </Col>
                       <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item label="Master Bedroom" name="masterbedroom">
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="sq/ft"
                                    onChange={props.handleChange(
                                      "measure_type14"
                                    )}
                                    options={options}
                                    defaultValue={props.values.measure_type14}
                                    className="custom-cascadar"
                                  />
                                }
                                onChange={props.handleChange("masterbedroom")}
                                defaultValue={props.values.masterbedroom}
                                placeholder="Master Bedroom"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        
                        <Col xs={24} md={6} xl={6} className="appendDiv formGroup">
                          <label htmlFor="gc_subdivision" className="pb-2 formGroup">
                            Bed
                          </label>
                             {props.masterBedroomArea.map((singleMasterarea, index) => (
                            <div key={index} className="services mb-2">
                              <div className="first-division">
                                <Input.Group compact>
                                  <div className="input-group customRepeatorDivHide">
                                    <Input
                                      name="master_bedroom_area"
                                      type="text"
                                      id="master_bedroom_area"
                                      value={singleMasterarea.master_bedroom_area}
                                      onChange={(e) =>
                                        props.handleMasterChange(e, index,'master_bedroom_area')
                                      }
                                      placeholder="Bed"
                                      className="formControl customPaddingg"
                                    />
                                    <Select
                                      name="master_bedroom_area_unit"
                                      defaultValue={singleMasterarea.master_bedroom_area_unit}
                                      options={options}
                                      onChange={(e) =>
                                        props.handleMasterChange(e, index,'master_bedroom_area_unit')
                                      }
                                      className="customselect"
                                    />
                                  </div>
                                  {props.masterBedroomArea.length - 1 === index &&
                                    props.masterBedroomArea.length < 5 && (
                                      <Button
                                        type="button"
                                        onClick={props.handleMasterAdd}
                                        className="formControl repeatorDivHideBtn"
                                        icon={<PlusOutlined />}
                                      />
                                    )}
                                  {props.masterBedroomArea.length !== 1 && (
                                    <Button
                                      type="button"
                                      icon={<MinusOutlined />}
                                      onClick={() =>
                                        props.handleMasterRemove(index)
                                      }
                                      className="formControl repeatorDivHideBtn"
                                    />
                                  )}
                                </Input.Group>
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other Rooms" name="other_rooms">
                              <Input
                                placeholder="Other Rooms"
                                className="formControl"
                                onChange={props.handleChange("other_rooms")}
                                defaultValue={props.values.other_rooms}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Special Considerations" key="4">
                    <div className="inspecContent">
                      <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Power" name="special_powder">
                              <Select
                                defaultValue={
                                  props.values.special_powder
                                    ? props.values.special_powder
                                    : "Power"
                                }
                                onChange={props.handleChange(
                                  "special_powder"
                                )}
                              >
                                <Option value="110">110</Option>
                                <Option value="220">220</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.special_powderField === true || props.values.special_powder === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other" name="special_powder_other">
                                <Input
                                  placeholder="Other"
                                  className="formControl"
                                  onChange={props.handleChange("special_powder_other")}
                                  defaultValue={props.values.special_powder_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Location of Power Source"
                              name="location_of_powder_source"
                            >
                              <Input
                                placeholder="Location of Power Source"
                                className="formControl"
                                onChange={props.handleChange("location_of_powder_source")}
                                defaultValue={props.values.location_of_powder_source}
                              />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Traffic Use" name="traff_use">
                              <Select
                                defaultValue={
                                  props.values.traff_use
                                    ? props.values.traff_use
                                    : "Traffic Use"
                                }
                                onChange={props.handleChange("traff_use")}
                              >
                                <Option value="High">High</Option>
                                <Option value="Average">Average</Option>
                                <Option value="Low">Low</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Airflow Obstacles"
                              name="airFlow_obstacles"
                            >
                              <Input
                                placeholder="Airflow Obstacles"
                                className="formControl"
                                onChange={props.handleChange("airFlow_obstacles")}
                                defaultValue={props.values.airFlow_obstacles}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Large Windows on Floor Facing" name="large_wind_flr_fc">
                              <Select
                                defaultValue={
                                  props.values.large_wind_flr_fc
                                    ? props.values.large_wind_flr_fc
                                    : "Large Windows on Floor Facing"
                                } 
                                onChange={props.handleChange(
                                  "large_wind_flr_fc"
                                )}
                              >
                                <Option value="North">North</Option>
                                <Option value="South">South</Option>
                                <Option value="East">East</Option>
                                <Option value="West">West</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Type of Heating System"
                              name="heating_system"
                            >
                              <Select
                                className="formGroup"
                                defaultValue={props.values.heating_system ? props.values.heating_system : 'Type of Heating System'}
                                onChange={props.handleChange("heating_system")}
                              >
                                <Option value="HeatingSystem">
                                  Heating System
                                </Option>
                                <Option value="Forced Air">Forced Air</Option>
                                <Option value="Radiator">Radiator</Option>
                                <Option value="Wood Burning Stove">Wood Burning Stove</Option>
                                <Option value="Baseboard">Baseboard</Option>
                                <Option value="Gas">Gas</Option>
                                <Option value="Electric">Electric</Option>
                                <Option value="Geothermal">Geothermal</Option>
                                <Option value="Heat Pump">Heat Pump</Option>
                                <Option value="Hot Water">Hot Water</Option>
                                <Option value="Underfloor/Radiant">Underfloor/Radiant</Option>
                                <Option value="Solar">Solar</Option>
                                <Option value="Steam">Steam</Option>
                                <Option value="Fireplace">Fireplace</Option>
                                <Option value="None">None</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.heatingField === true || props.values.heating_system === "Other" ? (
                       <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Other(Type)"
                                name="heating_system_other"
                              >
                                <Input
                                  placeholder="Other(Type)"
                                  className="formControl"
                                  onChange={props.handleChange('heating_system_other')}
                                  defaultValue={props.values.heating_system_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                         <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Type of Cooling System"
                              name="type_cooling_sys"
                            >
                              <Select
                                className="formGroup"
                                defaultValue={props.values.type_cooling_sys ? props.values.type_cooling_sys : 'Type of Cooling System'}
                                onChange={props.handleChange("type_cooling_sys")}
                              >
                                <Option value="Central Air Conditioning">Central Air Conditioning</Option>
                                <Option value="Swamp Cooler">Swamp Cooler</Option>
                                <Option value="Exhaust Fan">Exhaust Fan</Option>
                                <Option value="Portable Air Conditioning">Portable Air Conditioning</Option>
                                <Option value="Underfloor/Radiant">Underfloor/Radiant</Option>
                                <Option value="Window">Window</Option>
                                <Option value="Evaporate">Evaporate</Option>
                                <Option value="None">None</Option>
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Unknown">Unknown</Option>
                                <Option value="See Report">See Report</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.acField === true || props.values.type_cooling_sys === "Other" ? (
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Other(Type)" name="air_on_other">
                                <Input
                                  placeholder="Other(Type)"
                                  className="formControl"
                                  onChange={props.handleChange('air_on_other')}
                                  defaultValue={props.values.air_on_other}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        ) : null}
                          <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Window Coverings"
                                   name="window_coverings"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.window_coverings}
                                        onToggle={props.handleChange('window_coverings')}
                                      />
                                </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Tinted Glass/UV Protections"
                                   name="tinted_glass_uv_protections"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.tinted_glass_uv_protections}
                                        onToggle={props.handleChange('tinted_glass_uv_protections')}
                                      />
                                </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Other Trades | Schedules Prior to, During or After Installation"
                              name="other_trades"
                            >
                              <TextArea
                                placeholder="Other Trades | Schedules Prior to, During or After Installation"
                                className="formControl"
                                onChange={props.handleChange("other_trades")}
                                defaultValue={props.values.other_trades}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Any Special or Unique Use"
                              name="any_special_or_unique_use"
                            >
                              <TextArea
                                placeholder="Any Special or Unique Use"
                                className="formControl"
                                onChange={props.handleChange("any_special_or_unique_use")}
                                defaultValue={props.values.any_special_or_unique_use}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Summary of Physical Description"
                              name="summ_phy_descri"
                            >
                              <TextArea
                                placeholder="Summary of Physical Description"
                                className="formControl"
                                onChange={props.handleChange("summ_phy_descri")}
                                defaultValue={props.values.summ_phy_descri}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default ThirdStep;
