import React, { useState, useEffect } from "react";
import { Row, Col, Card, Input, Tag, Button,Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../../components/Buttons/buttons";
import * as IndustryStandardsServices from "../../services/IndustryStandards";
import { useDispatch } from "react-redux";
import PagesHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SkletonIndustry from "./skletonIndustry";

function IndustryStandards() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filterStandards, setFilterStandards] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [standards, setStandards] = useState([]);

  console.warn({ standards: standards });

  useEffect(() => {
    fetchData();
    setLoading(true);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(IndustryStandardsServices.publicLibrary())
      .then((res) => {
        console.info("Public",res.data);
        setStandards(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const industryPageRoute = () => {
    navigate(`/industry-standards`)
  }

  const copyLibrary = (pid) => {
    Swal.fire({
      title: 'Add this standard to your library?',
      text: "This will be added in your private library!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, add it!'
    }).then((result) => {
      if (result.isConfirmed) {
        copySuggestion(pid);
        Swal.fire(
          'Added!',
          'Library Added',
          'success',
        )
      }
    })
  }

  const copySuggestion = (pid) => {
    setLoading(true);
    let val = {};
    val.pid = pid;
    dispatch(IndustryStandardsServices.copySuggestion(val))
      .then((res) => {
        console.info('Copy',res.data);
        //window.location.reload();
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const handleClicks = (item1) => {
    setModalIndustry(true);
    standards.filter((item) =>
      item.pid === item1 ? setindustryContent(item) : ""
    );
  };

  useEffect(() => {
    console.info('Useffect Called')
    if(standards) {
      const filteredData = standards.filter((entry) =>
      entry?.suggetion_title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="industryWrapper">
      <div className="industryHeader">
        <PagesHeader
          title="Public Library"
        />
      </div>
      <div className="industryInnerWrapper">
        <div className="container-xxl">
          <div className="searchWrap">
            <Row gutter={16} justify="space-between">
              <Col xs={24} md={6}>
                <div className="searchWrapper">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search..."
                    className="formControl"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={10} xs={24}>
                <div className="libraryBtnSection text-end">
                  <SecondaryButton
                    type="secondary"
                    handleClick={() => industryPageRoute()}
                    text="My Industry Standards/Citations"
                    style={{ marginRight: "0" }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="standardsWrapper">
            <Row gutter={[16, 16]}>
              {loading ? 
                (
                  <div className="spinDiv">
                    <SkletonIndustry />
                  </div>
                ) : (
                  <>
                    {filterStandards && search ? filterStandards?.map((item, index) => {
                      return (
                        <Col xs={24} md={8} key={index} className="gutter-row">
                          <Card className="industryCard">
                            <div className="industryCardBody">
                              <div className="cardTopSection">
                                <div className="headerSec">
                                  <h3
                                    className="industryTitle"
                                    dangerouslySetInnerHTML={{
                                      __html: item.suggetion_title,
                                    }}
                                  ></h3>
                                  <p className="industryDesc">{item?.excerpt}</p>
                                </div>
                              </div>
                              <div className="contentSec">
                                <p className="categoryRow">
                                  <span className="catLabel">Category- </span>
                                  <p>
                                    {item?.suggetion_categorylist?.map(
                                      (catName, i) => {
                                        return (
                                          <Tag className="categoryTag" key={i}>
                                            <Link
                                              to={"/"}
                                              className="categoryLink"
                                            >
                                              {catName}
                                            </Link>
                                          </Tag>
                                        );
                                      }
                                    )}
                                  </p>
                                </p>
                                <p className="categoryRow">
                                  <span className="catLabel">Label- </span>
                                  <span className="industryLable">
                                    <Link to={"/"} className="lblLink">
                                      {item?.suggetion_field_list}
                                    </Link>
                                  </span>
                                </p>
                                <p className="categoryRow">
                                  <span className="catLabel">Created- </span>
                                  <span className="industryLable">
                                    {item?.created_at}
                                  </span>
                                </p>
                                <div className="btnGroup">
                                  <Button
                                    type="text"
                                    id={item.id}
                                    className="indstryBtn viewBtn"
                                    onClick={() => copyLibrary(item.pid)}
                                    disabled={item.copied === true ? true : false}
                                  >
                                    Copy
                                  </Button>
                                  <Button
                                    type="text"
                                    id={item.id}
                                    className="indstryBtn viewBtn"
                                    onClick={() => handleClicks(item.pid)}
                                  >
                                    View More
                                </Button>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    }) :
                    standards?.map((item, index) => {
                      return (
                        <Col xs={24} md={8} key={index} className="gutter-row">
                          <Card className="industryCard">
                            <div className="industryCardBody">
                              <div className="cardTopSection">
                                <div className="headerSec">
                                  <h3
                                    className="industryTitle"
                                    dangerouslySetInnerHTML={{
                                      __html: item.suggetion_title,
                                    }}
                                  ></h3>
                                  <p className="industryDesc">{item?.excerpt}</p>
                                </div>
                              </div>
                              <div className="contentSec">
                                <p className="categoryRow">
                                  <span className="catLabel">Category- </span>
                                  <p>
                                    {item?.suggetion_categorylist?.map(
                                      (catName, i) => {
                                        return (
                                          <Tag className="categoryTag" key={i}>
                                            <Link
                                              to={"/"}
                                              className="categoryLink"
                                            >
                                              {catName}
                                            </Link>
                                          </Tag>
                                        );
                                      }
                                    )}
                                  </p>
                                </p>
                                <p className="categoryRow">
                                  <span className="catLabel">Label- </span>
                                  <span className="industryLable">
                                    <Link to={"/"} className="lblLink">
                                      {item?.suggetion_field_list}
                                    </Link>
                                  </span>
                                </p>
                                <p className="categoryRow">
                                  <span className="catLabel">Created- </span>
                                  <span className="industryLable">
                                    {item?.created_at}
                                  </span>
                                </p>
                                <div className="btnGroup">
                                  <Button
                                    type="text"
                                    id={item.id}
                                    className="indstryBtn viewBtn"
                                    onClick={() => copyLibrary(item.pid)}
                                    disabled={item.copied === true ? true : false}
                                  >
                                    Copy
                                  </Button>
                                  <Button
                                    type="text"
                                    id={item.id}
                                    className="indstryBtn viewBtn"
                                    onClick={() => handleClicks(item.pid)}
                                  >
                                    View More
                                </Button>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </>
                )
              }
            </Row>
          </div>
        </div>
      </div>

      {/* Industry View Modal */}
      <Modal
        title={industryContent.suggetion_title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={680}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{
                __html: industryContent.suggetion_content,
              }}
            ></p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default IndustryStandards;
