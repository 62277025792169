import React from 'react';
import { Row, Col,Skeleton } from "antd";

const skletonIndustry = () => {
  return (
    <div>
          <div className="container-xxl">
          <div className="">
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" style={{height:"120px" }}/>
                </Col>
               </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" style={{height:"120px" }}/>
                </Col>
                </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" style={{height:"120px" }}/>
                </Col>
              </Row>
              </div>
          </div>
    </div>
  )
}

export default skletonIndustry