import React from "react";
import { PageHeader, Button } from "antd";

const PagesHeader = ({ title, handleClick, text, type, icon }) => {
  return (
    <div className="">
      <PageHeader
        className="site-page-header container-xxl customPageHeader"
        ghost={false}
        onBack={() => window.history.back()}
        title={title}
        extra={[
          <Button type={type} className="btn btnAdd" onClick={handleClick} icon={icon}>
            {text}
          </Button>
        ]}
      />
    </div>
  );
};

export default PagesHeader;
