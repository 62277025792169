import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { MEDIA_UPLOAD_URL } from '../../Helper';

import { notification,Spin } from 'antd';
import {  CheckCircleOutlined } from '@ant-design/icons';

import './ImageEditor.css';
const filterOptions = [
  { id: 'brightness', name: 'Brightness' },
  { id: 'saturation', name: 'Saturation' },
  { id: 'inversion', name: 'Inversion' },
  { id: 'grayscale', name: 'Grayscale' },
];
function ImageEditor(props) {
  const [previewImg, setPreviewImg] = useState(null);
  const [activeFilter, setActiveFilter] = useState('brightness');
  const [sliderValue, setSliderValue] = useState(100);
  const [brightness, setBrightness] = useState('100');
  const [saturation, setSaturation] = useState('100');
  const [inversion, setInversion] = useState('0');
  const [grayscale, setGrayscale] = useState('0');
  const [rotate, setRotate] = useState(0);
  const [flipHorizontal, setFlipHorizontal] = useState(1);
  const [flipVertical, setFlipVertical] = useState(1);
  const [loading, setLoading] = useState(false);

  const previewImgRef = useRef(null);

  const loadImage = async (props) => {
    const file = props.image;
        setLoading(props.loading);
      setPreviewImg(file.originFileObj);
    if (!file) return;
    resetFilter();
  };
  useEffect(() => {
    loadImage(props);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.image]);
  const applyFilter = () => {
    previewImgRef.current.style.filter = `brightness(${brightness}%) saturate(${saturation}%) invert(${inversion}%) grayscale(${grayscale}%)`;
    previewImgRef.current.style.transform = `rotate(${rotate}deg) scale(${flipHorizontal}, ${flipVertical})`;
  };

  const resetFilter = () => {
    setBrightness('100');
    setSaturation('100');
    setInversion('0');
    setGrayscale('0');
    setRotate(0);
    setFlipHorizontal(1);
    setFlipVertical(1);
    setActiveFilter('brightness');
    setSliderValue(100);
  };


const saveImage = () => {
  setLoading(true);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const image = new Image();
  image.onload = () => {
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    ctx.filter = `brightness(${brightness}%) saturate(${saturation}%) invert(${inversion}%) grayscale(${grayscale}%)`;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    if (rotate !== 0) {
      ctx.rotate((rotate * Math.PI) / 180);
    }
    ctx.scale(flipHorizontal, flipVertical);
    ctx.drawImage(
      image,
      -canvas.width / 2,
      -canvas.height / 2,
      canvas.width,
      canvas.height
    );

    canvas.toBlob((blob) => {
                const mediaId = props.image.media_id ? props.image.media_id : props.image.uid;

      const formData = new FormData();
      //formData.append("file", blob, "image.jpg");
      const editedImageFile = new File([blob], 'edited-image.jpg'); // Replace with your edited image data and filename
      formData.append('file', editedImageFile);
          const updatedFileList = props.fileList.map((file) => {
            const currFileId = file.media_id ? file.media_id : file.uid;
      if (currFileId === mediaId) {
        return { ...file,
          url : URL.createObjectURL(blob),
          thumbUrl : '',
          originFileObj :blob, 
        };
      }
      return file;
    });
        props.setFileList(updatedFileList);

       setLoading(false);
      props.setLoadings(true);
          notification.open({
              message: 'Image successfully edited!',
              description: 'You have successfully edited an image.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            })
            props.setPreviewVisible(false);
      axios
        .post(MEDIA_UPLOAD_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          const newMediaId = response.data.id;
          // do something with the uploaded image
          console.warn(props.fileList)
          const updatedFileList = props.fileList.map((file) => {
            const currFileId = file.media_id ? file.media_id : file.uid;
      if (currFileId === mediaId) {
        return { ...file,
          url : response.data.source_url,
          thumbUrl : '',
          originFileObj :blob, 
          media_id: newMediaId, 
          uid : newMediaId
        };
      }
      return file;
    });
    props.setFileList(updatedFileList);
          props.setLoadings(false);
   
        })
        .catch((error) => {
          console.error(error);
          // handle upload error
        });
    }, "image/jpeg");
  };

  image.src = URL.createObjectURL(previewImg);
};



  const handleFilterClick = (option) => {
    setActiveFilter(option.id);

    switch (option.id) {
      case 'brightness':
        setSliderValue(brightness);
        break;
      case 'saturation':
        setSliderValue(saturation);
        break;
      case 'inversion':
        setSliderValue(inversion);
        break;
      default:
        setSliderValue(grayscale);
    }
  };
  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
    switch (activeFilter) {
      case 'brightness':
        setBrightness(event.target.value);
        break;
      case 'saturation':
        setSaturation(event.target.value);
        break;
      case 'inversion':
        setInversion(event.target.value);
        break;
      default:
        setGrayscale(event.target.value);
    }
  };
  const handleRotate = (option) => {
    if (option === 'left') {
      setRotate(rotate - 90);
    } else if (option === 'right') {
      setRotate(rotate + 90);
    } else if (option === 'horizontal') {
      setFlipHorizontal(flipHorizontal === 1 ? -1 : 1);
      //setRotate(rotate + 180);
    } else {
      setRotate(rotate + 180);
      //setFlipVertical(flipVertical === 1 ? -1 : 1);
    }
  };
  return (
    <Spin spinning={loading} delay={500}>
    <div className={`container ${!previewImg ? 'disable' : ''}`}>
      <h2 className="rotate_image_editor_title">Image Editor</h2>
      <div className="wrapper">
        <div className="editor-panel">
          <div className="filter">
            <label className="title">Filters</label>

            <div className="options">
              {filterOptions.map((option) => (
                <button
                  key={option.id}
                  id={option.id}
                  className={activeFilter === option.id ? 'active' : ''}
                  onClick={() => handleFilterClick(option)}
                >
                  {option.name}
                </button>
              ))}
            </div>
            <div className="slider">
              <div className="filter-info">
                <p className="name">{activeFilter}</p>
                <p className="value">{`${sliderValue}%`}</p>
              </div>
              <input
                type="range"
                min="0"
                max={
                  activeFilter === 'brightness' || activeFilter === 'saturation'
                    ? '200'
                    : '100'
                }
                value={sliderValue}
                onChange={handleSliderChange}
              />
            </div>
          </div>
          <div className="rotate">
            <label className="title">Rotate & Flip</label>
            <div className="options">
              <button id="left" onClick={() => handleRotate('left')}>
                <img
                  src={
                    'https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/18022342/icons8-rotate-left-20.png'
                  }
                  alt="preview"
                />
              </button>
              <button id="right" onClick={() => handleRotate('right')}>
                <img
                  src={
                    'https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/18022345/icons8-rotate-right-20.png'
                  }
                  alt="preview"
                />
              </button>
              {/* <button
                id="horizontal"
                onClick={() => handleRotate('horizontal')}
              >
                <img
                  src={
                    'https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/18022957/reflect-vertical-regular-24.png'
                  }
                  alt="preview"
                />
              </button> */}
              <button id="vertical" onClick={() => handleRotate('vertical')}>
                <img
                  src={
                    'https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/18022954/reflect-horizontal-regular-24.png'
                  }
                  alt="preview"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="preview-img">
          {previewImg ? (
            <img
              src={URL.createObjectURL(previewImg)}
              alt="preview"
              ref={previewImgRef}
              onLoad={applyFilter}
            />
          ) : (
            <img src="http://3.138.242.183/floordetective_v1/wp-content/uploads/2023/05/placeholder-image.png" alt="preview-img" />
          )}
        </div>
      </div>
      <div className="controls controls-save-btn">
        <button className="reset-filter" onClick={resetFilter}>
          Reset Filters
        </button>
        <div className="row">
          <button onClick={saveImage} className="save-img">
            Save Image
          </button>
        </div>
      </div>
    </div>
    </Spin>
  );
}

export default ImageEditor;
