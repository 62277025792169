import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "../pages/LandingPage/NotFoundPage";
import InspectionRequest from '../pages/InspectionRequest'
import InspectionRequestCompleted from '../pages/InspectionRequest/completedReports'
import CarpetBasic from "../pages/InspectionReport/CarpetBasic/CarpetBasic";
import QuickReport from "../pages/InspectionReport/QuickReport/QuickReport";
import QuickReportUpdate from "../pages/InspectionReport/QuickReport/QuickReportUpdate";
import SingleQuickReport from "../pages/SingleReports/SingleQuickReport";
import CarpetDetailed from "../pages/InspectionReport/CarpetDetail/CarpetDetailed";
import SingleInspectionRequest from "../pages/SingleRequest/index";
import AllClients from '../pages/Clients'
import AllInvoices from '../pages/Invoices'
import IndustryStandards from '../pages/IndustryStandards'
import PublicStandards from '../pages/IndustryStandards/PublicStandards'
import CreateInvoice from "../pages/Invoices/CreateInvoice";
import HardSurfaceBasic from "../pages/InspectionReport/HardSurfaceBasic/HardSurfaceBasic";
import CreateCoverLetter from "../pages/CoverLetter/CreateCoverLetter";
import UpdateCoverLetter from "../pages/CoverLetter/UpdateCoverLetter";
import CoverLetter from "../pages/CoverLetter/CoverLetter";
import SingleInvoice from "../pages/Invoices/SingleInvoice";
import UpdateInvoice from "../pages/Invoices/UpdateInvoice";
import CreateEmailReport from "../pages/Email/createEmailReport";
import HardwoodDetailed from "../pages/InspectionReport/HardwoodDetailed/HardwoodDetailed";
import Attachments from '../pages/Attachments'
import Tile from "../pages/InspectionReport/Tile/Tile";
import Laminate from '../pages/InspectionReport/Laminate/Laminate';
import Lvt from "../pages/InspectionReport/LVT/Lvt";
import Addnewclient from "../pages/Clients/addNewClient";
import Manufacturer from "../pages/manufacturer/index";
import AllClaimAnalysts from "../pages/manufacturer/AllClaimAnalysts"; 
import Dealers from "../pages/manufacturer/dealers";
import AddDealer from "../pages/manufacturer/addDealer";
import AddClaimAnalyst from "../pages/manufacturer/addClaimAnalyst";
import SingleCarpetBasic from "../pages/SingleReports/SingleCarpetBasic";
import SingleCarpetDetailed from "../pages/SingleReports/SingleCarpetDeatiled";
import SingleHardwoodDetailed from "../pages/SingleReports/SingleHardwoodDetailed";
import SingleHardwoodBasic from "../pages/SingleReports/SingleHardwoodBasic";
import SingleTile from "../pages/SingleReports/SingleTile.js";
import SingleLvt from "../pages/SingleReports/SingleLvt";
import SingleLaminate from "../pages/SingleReports/SingleLaminate";
import Archived from "../pages/manufacturer/archived";
import CarpetBasicUpdate from "../pages/InspectionReport/CarpetBasic/updateCarpetBasic";
import CarpetDetailedUpdate from "../pages/InspectionReport/CarpetDetail/updateCarpetDetailed";
import HardwoodBasicUpdate from "../pages/InspectionReport/HardSurfaceBasic/updateHardSurfaceBasic";
import LvtUpdate from "../pages/InspectionReport/LVT/updateLvt";
import LaminateUpdate from "../pages/InspectionReport/Laminate/updateLaminate";
import HardwoodDetailedUpdate from "../pages/InspectionReport/HardwoodDetailed/updateHardwoodDetailed";
import CreateNewClient from "../pages/Clients/addNewClient"
import TileUpdate from "../pages/InspectionReport/Tile/updateTile"
import SingleClaimAnalyst from "../pages/SingleReports/SingleClaimAnalyst";  
import SingleDealer from "../pages/DealerPanel/SingleDealer";
import AllClaimAnalystsUpdate from "../pages/manufacturer/updateClaimAnalyst";
import DealerUpdate from "../pages/manufacturer/updateDealer";
import ClaimAnalystPanel from "../pages/ClaimAnalystPanel";
import DealerPanel from "../pages/DealerPanel/";
import {  useSelector } from "react-redux";
import SendDicision from '../pages/SendDecision';
import NwfaInstallationInspectionReport from "../pages/InspectionReport/NwfaInstallationInspectionReport/NwfaInstallationInspection";
import NwfaSandFinishInspectionReport from "../pages/InspectionReport/NwfaSandFinish/NwfaSandFinish";
import SingleNwfaInstallation from "../pages/SingleReports/SingleNwfaInstallationReport";
import SingleNwfaSandReport from "../pages/SingleReports/SingleNwfaSandReport";
import NwfaSandFinishUpdate from "../pages/InspectionReport/NwfaSandFinish/updateNwfaSandFinish";
import NwfaInstallationUpdate from "../pages/InspectionReport/NwfaInstallationInspectionReport/updateNwfaInstallation";
import SingleViewClient from "../pages/SingleReports/SingleViewClient";
import UpdateClientView from "../pages/Clients/updateViewClient";
import InspectorProfile from "../pages/InspectorProfile.js";
import UserProfilePage from "../pages/UserProfilePage.js";
import UnAssignedClaims from "../pages/Mohawk/UnAssignedClaims.js";
import FieldServicesClaims from "../pages/Mohawk/FieldServicesClaims.js";

const AppRoute = () => {
    const userDetails = useSelector((state) => state.User.userDetails);
    return (
      <Routes>

        <Route
              exact
              path="/inspector-profile"
              element={<InspectorProfile />}
        />
        <Route
              exact
              path="/update-profile"
              element={<UserProfilePage profiles={userDetails.profiles} />}
        />

        {userDetails?.user_role === "um_inspector" ? (
          <>
            <Route exact path="/" element={<InspectionRequest />} />
            <Route
              exact
              path="/inspection-reports"
              element={<InspectionRequest />}
            />
            <Route
              exact
              path="/inspection-completed-reports"
              element={<InspectionRequestCompleted />}
            />
            <Route exact path="/carpetBasic" element={<CarpetBasic />} />
            <Route exact path="/carpetDetailed" element={<CarpetDetailed />} />
            <Route exact path="/quickReport" element={<QuickReport />} />
            <Route
              exact
              path="/quick-report-update"
              element={<QuickReportUpdate />}
            />
            <Route
              exact
              path="/carpet-basic-update"
              element={<CarpetBasicUpdate />}
            />
            <Route
              exact
              path="/carpet-detailed-update"
              element={<CarpetDetailedUpdate />}
            />
            <Route
              exact
              path="/hardwood-basic-update"
              element={<HardwoodBasicUpdate />}
            />
            <Route exact path="/lvt-update" element={<LvtUpdate />} />
            <Route
              exact
              path="/hardwood-detailed-update"
              element={<HardwoodDetailedUpdate />}
            />
            <Route exact path="/Tile" element={<Tile />} />
            <Route exact path="/laminate" element={<Laminate />} />
            <Route exact path="/Lvt" element={<Lvt />} />
            <Route exact path="nwfa-installation-inspection-report" element={<NwfaInstallationInspectionReport />} />
            <Route exact path="/update-nwfa-inspector-report" element={<NwfaInstallationUpdate />} />
            <Route exact path="/update-nwfa-sand-finish-report" element={<NwfaSandFinishUpdate />} />
            <Route
              exact
              path="/HardwoodDetailed"
              element={<HardwoodDetailed />}
            />
            <Route exct path="/nwfa-sand-finish-inspection-report" element={<NwfaSandFinishInspectionReport />} />
            <Route exact path="/update-invoice" element={<UpdateInvoice />} />
            <Route exact path="/create-invoice" element={<CreateInvoice />} />
            <Route exact path="/hardwoodBasic" element={<HardSurfaceBasic />} />
            <Route
              exact
              path="/create-cover-letter"
              element={<CreateCoverLetter />}
            />
            <Route
              exact
              path="/update-cover-letter"
              element={<UpdateCoverLetter />}
            />
            <Route exact path="/laminate-update" element={<LaminateUpdate />} />
            <Route exact path="/tile-report-update" element={<TileUpdate />} />
            <Route exact path="/attachments" element={<Attachments />} />
            <Route exact path="/all-invoices" element={<AllInvoices />} />
            <Route
              exact
              path="/create-new-client"
              element={<CreateNewClient />}
            />
            <Route exact path="/add-new-client" element={<Addnewclient />} />
            <Route
              exact
              path="/single-view-client"
              element={<SingleViewClient />}
            />
             <Route
              exact
              path="/view-client-update"
              element={<UpdateClientView />}
            />
            
          </>
        ) : (
          ""
        )}
        {userDetails?.user_role === "um_dealer" ? (
          <>
            <Route exact path="/" element={<DealerPanel />} />
            <Route exact path="/dealer-panel" element={<DealerPanel />} />
              <Route
              exact
              path="/inspection-reports"
              element={<DealerPanel />}
            />
          </>
        ) : (
          ""
        )}
        {userDetails?.user_role === "um_claim-analyst" ? (
          <>
            <Route exact path="/" element={<ClaimAnalystPanel />} />
            <Route
              exact
              path="/claim-analyst-panel"
              element={<ClaimAnalystPanel />}
            />
              <Route
              exact
              path="/inspection-reports"
              element={<ClaimAnalystPanel />}
            />
            <Route exact path="/send-decision" element={<SendDicision />} />
            <Route exact path="/archived" element={<Archived />} />
            <Route exact path="/unassigned-claims" element={<UnAssignedClaims />} />
            <Route exact path="/field-services" element={<FieldServicesClaims />} />
          </>
        ) : (
          ""
        )}
        {userDetails?.user_role === "um_manufacturer" ? (
          <>
            <Route exact path="/" element={<Manufacturer />} />
            <Route
              exact
              path="/manufacturer-panel"
              element={<Manufacturer />}
            />
             <Route
              exact
              path="/inspection-reports"
              element={<Manufacturer />}
            />
            <Route
              exact
              path="/AllClaimAnalysts"
              element={<AllClaimAnalysts />}
            />
            <Route exact path="/dealers" element={<Dealers />} />
            <Route exact path="/add-dealer" element={<AddDealer />} />
            <Route
              exact
              path="/add-claim-analyst"
              element={<AddClaimAnalyst />}
            />
            <Route
              exact
              path="/single-claim-analyst"
              element={<SingleClaimAnalyst />}
            />
           
            <Route exact path="/single-dealer" element={<SingleDealer />} />
            <Route
              exact
              path="/claim-analyst-update"
              element={<AllClaimAnalystsUpdate />}
            />
            <Route exact path="/dealer-update" element={<DealerUpdate />} />
            <Route exact path="/archived" element={<Archived />} />
            <Route exact path="/send-decision" element={<SendDicision />} />
            <Route exact path="/unassigned-claims" element={<UnAssignedClaims />} />
            <Route exact path="/field-services" element={<FieldServicesClaims />} />
          </>
        ) : (
          ""
        )}
        <Route
          exact
          path="/single-quick-report"
          element={<SingleQuickReport />}
        />
        <Route exact path="/single-nwfa-sandfinish" element={<SingleNwfaSandReport />} />
        <Route exact path="/single-nwfa-installation" element={<SingleNwfaInstallation />} />

        <Route
          exact
          path="/single-request"
          element={<SingleInspectionRequest />}
        />
        <Route exact path="/all-clients" element={<AllClients />} />

        <Route
          exact
          path="/industry-standards"
          element={<IndustryStandards />}
        />
        <Route
          exact
          path="/public-industry-standards"
          element={<PublicStandards />}
        />

        <Route exact path="/cover-letter" element={<CoverLetter />} />
        <Route exact path="/report-invoice" element={<SingleInvoice />} />

        <Route exact path="/send-email" element={<CreateEmailReport />} />

        <Route
          exact
          path="/single-carpet-basic"
          element={<SingleCarpetBasic />}
        />
        <Route
          exact
          path="/single-carpet-detailed"
          element={<SingleCarpetDetailed />}
        />
        <Route
          exact
          path="/single-hardwood-detailed"
          element={<SingleHardwoodDetailed />}
        />
        <Route
          exact
          path="/single-hardwood-basic"
          element={<SingleHardwoodBasic />}
        />
        <Route exact path="/single-tile-report" element={<SingleTile />} />
        <Route exact path="/single-lvt" element={<SingleLvt />} />
        <Route exact path="/single-Laminate" element={<SingleLaminate />} />
        <Route exact path="/NotFoundPage" element={<NotFoundPage />} />
      </Routes>
    );
}

export default AppRoute