import React from "react";
import PagesHeader from "../../components/PageHeader";
const NotFoundPage = () => {
  return (
    <>
      <div className="inspectionWrapper">
        <div className="inspectionHeader">
          <PagesHeader title="404" type="secondary" />
        </div>
        <div className="landingPageWrapper">
          <div className="innerWrapper">
            <div className="inspectionWrapInner">
              <div className="container-xxl">
                <div className="bannerSection">
                  <div style={{ textAlign: "center" }}>
                    <h1 className="Heading">404 </h1>
                    <h1 className="subHeading">
                      THE REQUESTED PAGE CANNOT BE FOUND
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotFoundPage;
