import React, { useState, useEffect } from 'react';
import { Radio, Tooltip } from 'antd';

const TripleToggle = ({value,onToggle}) => {
  console.warn('TripleToggle', value);
  const [selectedValue, setSelectedValue] = useState('HideThisField');

  useEffect(() => {
    setSelectedValue(value || 'HideThisField');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleToggleChange = (e) => {
    const value1 = e.target.value;
    console.warn('handleToggleChange',value1);
    setSelectedValue(value1);
    onToggle(value1);
  };

  return (
    <Radio.Group onChange={handleToggleChange} value={selectedValue}>
      {['Yes', 'HideThisField', 'No'].map((option) => (
        <Tooltip title={option === 'HideThisField' ? 'Hide this field' : null} key={option} placement="top">
          <Radio.Button
            value={option}
            className={selectedValue === option ? 'selected' : ''}
          >
            {option === 'HideThisField' ? 'N/A' : option}
          </Radio.Button>
        </Tooltip>
      ))}
    </Radio.Group>
  );
};

export default TripleToggle;
// yogendra sir code end

