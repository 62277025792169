import React, { useState } from "react";
import PagesHeader from "../../components/PageHeader";
import { Row, Col } from 'antd';
import ArchivedClaims from "../../datatables/allManufacturerClaim";
import ButtonBar from "../../components/ButtonNavigation/index";

const Archived = () => {

  const [isActiveKey, setActiveKey] = useState('6');
  console.info(setActiveKey);

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader title="Archived Claims" type="secondary" text="+10 More Inspection" />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
         <ArchivedClaims activeKey={isActiveKey}/>
        </div>
      </div>
    </div>
  );


};

export default Archived;
