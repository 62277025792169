import React from 'react';
import { Row, Col,Skeleton } from "antd";

const skletonIndustry = () => {
  return (
    <div>
          <div className="container-xxl">
          <div className="">
              {/* <Row gutter={16} className="skleton-top-pdf mb-3" justify="end">
                <Col xs={24} md={6} xl={6}>
                  <Skeleton.Input
                    active
                    size={size}
                    shape={buttonShape}
                    block={block}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3" justify="center">
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size={size}
                    shape={avatarShape}
                    block={block}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size={size}
                    block={block}
                    className="abccd"
                  />
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size={size}
                    shape={avatarShape}
                    block={block}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size={size}
                    block={block}
                    className="abccd"
                  />
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size={size}
                    shape={avatarShape}
                    block={block}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size={size}
                    block={block}
                    className="abccd"
                  />
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Button
                    active
                    size={size}
                    shape={avatarShape}
                    block={block}
                    className="abccc"
                  />
                  <Skeleton.Input
                    active
                    size={size}
                    block={block}
                    className="abccd"
                  />
                </Col>
              </Row> */}
              <Row gutter={16} className="mb-3">
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }}/>
                </Col>
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }} />
                </Col>
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }} />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }}/>
                </Col>
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }} />
                </Col>
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }} />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }}/>
                </Col>
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }} />
                </Col>
                <Col md={8}>
                  <Skeleton.Input block={true} active size="large" style={{height:"300px" }} />
                </Col>
              </Row>
              
            
              
            </div>
          </div>
    </div>
  )
}

export default skletonIndustry