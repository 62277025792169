import React from "react";
import { Row, Col, Input, Card, Form, Select } from "antd";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
const { Option } = Select;

const SecondStep = (props) => {
  console.warn("SecondStep", props);
  const [form] = Form.useForm();

  return (
    <Form
      name="report"
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Cleaning Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                    <Form.Item
                        label="Cleaned?"
                        name="cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.cleaned}
                              onToggle={props.handleChange('cleaned')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.cleanedField === true || props.values.cleaned === 'Yes' ? (
                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                        <Form.Item
                            label="Professionally Cleaned?"
                            name="professinally_cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.professinally_cleaned}
                              onToggle={props.handleChange('professinally_cleaned')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.proCleanedField === true || props.values.professinally_cleaned === 'Yes' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Company Name"
                              name="cleaning_company_name"
                            >
                              <Input
                                placeholder="Company Name"
                                className="formControl"
                                onChange={props.handleChange(
                                  "cleaning_company_name"
                                )}
                                defaultValue={
                                  props.values.cleaning_company_name
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning History and Details"
                            name="cleaning_history"
                          >
                            <Input
                              placeholder="Cleaning History and Details"
                              className="formControl"
                              onChange={props.handleChange("cleaning_history")}
                              defaultValue={props.values.cleaning_history}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Vacuum Brand" name="vacumm_brand">
                            <Select
                              className="formGroup"
                              defaultValue={props.values.vacumm_brand ? props.values.vacumm_brand : 'Select Vacuum Brand'}
                              onChange={props.handleChange("vacumm_brand")}
                            >
                              <Option value="Vacuum Brand">Vacuum Brand</Option>
                              <Option value="Bissel">Bissell</Option>
                              <Option value="BackPack">BackPack</Option>
                              <Option value="Central">Central</Option>
                              <Option value="Commercial"> Commercial</Option>
                              <Option value="Dirt Devil">Dirt Devil</Option>
                              <Option value="Dyson">Dyson</Option>
                              <Option value="Electrolux">Electrolux</Option>
                              <Option value="Eureka">Eureka</Option>
                              <Option value="Hoover">Hoover</Option>
                              <Option value="Kenmore">Kenmore</Option>
                              <Option value="Kirby">Kirby</Option>
                              <Option value="Miele">Miele</Option>
                              <Option value="Oreck">Oreck</Option>
                              <Option value="Panasonic">Panasonic</Option>
                              <Option value="Rainbow">Rainbow</Option>
                              <Option value="Regina">Regina</Option>
                              <Option value="Riccar">Riccar</Option>
                              <Option value="Royal">Royal</Option>
                              <Option value="Shark">Shark</Option>
                              <Option value="Simplicity">Simplicity</Option>
                              <Option value="Windsor">Windsor</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="See Report">See Report</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.vacummBrandOther === true || props.values.vacumm_brand === "Other" ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Other" name="vacum_brand_other">
                              <Input
                                placeholder="Other"
                                className="formControl"
                                onChange={props.handleChange(
                                  "vacum_brand_other"
                                )}
                                defaultValue={props.values.vacum_brand_other}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : null}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Vacuum Condition"
                            name="vacumm_condition"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={props.values.vacumm_condition ? props.values.vacumm_condition : 'Select Vacuum Condition'}
                              onChange={props.handleChange("vacumm_condition")}
                            >
                              <Option value="Average">Average</Option>
                              <Option value="Good">Good</Option>
                              <Option value="Very Good">Very Good</Option>
                              <Option value="Excellent">Excellent</Option>
                              <Option value="Not Working">Not Working</Option>
                              <Option value="Not Present">Not Present</Option>
                              <Option value="Not Applicable">Not Applicable</Option>
                              <Option value="See Report">See Report</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={12} xl={12}>
                        <div className="formGroup">
                          <Form.Item
                            label="Is Vacuum Cleaner on CRI Seal of Approve List"
                            name="cri_seal"
                          >
                            <Select
                              className="formGroup"
                              defaultValue={props.values.cri_seal ? props.values.cri_seal : 'Is Vacuum Cleaner on CRI Seal of Approval List'}
                              onChange={props.handleChange("cri_seal")}
                            >
                              <Option value="Yes">Yes</Option>
                              <Option value="No">No</Option>
                              <Option value="Unknown">Unknown</Option>
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="See Report">See Report</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={9} xl={9}>
                        <div className="custum-paragraph">
                          <p>
                            Click Link to see CRI Approved List.
                            <a
                              href="https://carpet-rug.org/testing/seal-of-approval-program/certified-vacuums/"
                              target="#"
                              className="mx-1 custpara"
                            >
                              Here
                            </a>
                            is the Link.
                          </p>
                        </div>{" "}
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default SecondStep;
