import {
  Row,
  Card,
  Col,
  Image,
  Form,
  Input,
  DatePicker,
  Button,
  Checkbox,
  Skeleton,
  notification
} from "antd";
import { PlusOutlined, DeleteOutlined, CalendarOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import * as invoiceService from "../../services/AllInvoices";
import * as inspectorService from "../../services/InspectorDataService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import moment from 'moment';
import Swal from "sweetalert2";

const CreateInvoice = () => {
  let navigate = useNavigate();
  var dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([{}]);
  const columnsArray = ["service", "amount"];
  const [loadings, setLoadings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inspectorData, setInspectorData] = useState();
  const [inspectonData, setInspectonData] = useState();
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const [calOpen, setCalOpen] = useState(false);
  const [valuesDate, setValuesDate] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const req_id = queryParams.get('req_id');

  const DateFormat = "MM-DD-YYYY";
  
  useEffect(() => {
    fetchData();
    if (req_id) {
      fetchInspectonData();
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data);

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(inspectorService.getInspectorData())
      .then((res) => {
        console.info(res);
        setInspectorData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  }
  const fetchInspectonData = (params = {}) => {
    setLoading(true);
    dispatch(invoiceService.getInspectonData(req_id))
      .then((res) => {
        console.warn('getInspectonData', res);
        setInspectonData(res);
        form.setFieldsValue({
          claim: res.claim_number,
          invoice_date: res.todaydate ? moment(res.todaydate) : '',
          company_name: res.organization_name,
          client_name: res.name,
          addressdiv: res.address,
          phone_personal: res.phone_number,
          email_address: res.email,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  }

  const invoiceCheck = () => {
    setChecked(!checked)
  }

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const handleAddRow = () => {
    const item = {};
    setRows([...rows, item]);
  };

  const handleRemoveSpecificRow = (idx) => {
    const tempRows = [...rows];
    tempRows.splice(idx, 1);
    setRows(tempRows);
  };

  const updateState = (e) => {
    let prope = e.target.attributes.column.value;
    let index = e.target.attributes.index.value;
    let fieldValue = e.target.value;
    const tempRows = [...rows];
    const tempObj = rows[index];
    tempObj[prope] = fieldValue;
    tempRows[index] = tempObj;
    setRows(tempRows);
    let ammmt = rows.map(item => item.amount).reduce((prev, next) => parseFloat(prev) + parseFloat(next));
    form.setFieldsValue({
      total: parseFloat(ammmt).toFixed(2),
    });
  };

  const onFinish = (values) => {
    const onInvoiceSubmit = () => {
      if (req_id) {
        values.req_id = req_id;
        values.report_id = inspectonData.report_id;
        values.gc_lotnumber = inspectonData.gc_lotnumber;
        values.gc_subdivision = inspectonData.gc_subdivision;
      }
      values.logo_invoice = inspectorData.logo;
      values.invoice_organization_name = inspectorData.organization_name;
      values.invoice_address = inspectorData.address;
      values.invoice_phone_number = inspectorData.phone_number;
      values.invoice_email = inspectorData.email;
      values.invoice_extra_notes = inspectorData.invoice_extra_notes;
      values.invoice_date = valuesDate.invoice_date ? valuesDate.invoice_date : inspectonData?.todaydate;
      console.log('Success:', values);
      enterLoading(0);
      dispatch(invoiceService.createInvoices(values))
        .then((res) => {
          console.warn("Submit", res);
          setData(res);
          notification.open({
            message: "Invoice successfully created!",
            description: 'You have successfully created a invoice.',
            icon: (
              <CheckCircleOutlined 
                style={{
                  color: '#108ee9',
                }}
              />
            ),
          });
          //return;
          navigate("/report-invoice/?invoice_id=" + res.data.invoice_id);
        })
        .catch((err) => {
            // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
        });
    }
    console.info("val", values)
    // values.preventdefault()
    if (values.company_name && values.email_address && values.phone_personal && values.addressdiv) {
      onInvoiceSubmit()
    }
    else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: 'Yes create it!',
        text: "You have not filled billing information, your invoice will be created without billing information!",
        showLoaderOnConfirm: true,

      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          onInvoiceSubmit()
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })

    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleDateformate = (e, name, type) => {
    if (
      name === 'invoice_date'
    ) {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValuesDate({ ...valuesDate, [name]: date });
        console.log('date', date, valuesDate);
      } else {
        let value = e.target.value;
        setValuesDate({ ...valuesDate, [name]: value });
      }

    }
    
  };
  console.log('afterSubmitdate', valuesDate);

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Create Invoice" type="secondary" />
      </div>
      {loading ?
        (
          <div className="container-xxl">
            <div className="srWrapperInner">
            <div className="Skeleton_invoice">
                <Row gutter={16} className="mb-4 mt-4" justify="space-between">
                  <Col xs={24} md={8} xl={8} className="">
                    <Row gutter={16} className="mb-3">
                      <Col xs={24} md={18} xl={18} className="">
                      <Skeleton.Input block={true} active size="large" style={{height:"90px" }}/>
                      </Col>
                      <Col xs={24} md={6} xl={6} className="">
                      </Col>
                    </Row>
                    <Row gutter={16} className="" >
                      <Col xs={24} md={12} xl={12} className="sklpad">
                      <Skeleton />
                      </Col>
                      <Col xs={24} md={12} xl={12} className="sklpad">
                      </Col>
                    </Row>
                    <Row gutter={16} className="mt-3" >
                      <Col xs={24} md={24} xl={24}>
                      
                    <Row gutter={16} className="mt-4 mb-1">
                    <Col xs={24} md={24} xl={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                    <Row gutter={16} className="mb-1">
                    <Col xs={24} md={24} xl={24} >
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                    <Row gutter={16} className="mb-1">
                    <Col xs={24} md={24} xl={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                    <Row gutter={16} className="">
                    <Col xs={24} md={24} xl={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                      </Col>
                      <Col xs={24} md={12} xl={12} className="sklpad">
                      </Col>
                    </Row> 
                  </Col>
                  <Col xs={24} md={6} xl={6} className="">
                    <Row gutter={16} className="">
                    <Col xs={24} md={24} xl={24} className="sklpad">
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                    <hr></hr>
                    <Row gutter={16} className="mt-4 mb-4">
                    <Col xs={24} md={24} xl={24} className="sklpad">
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                    <Row gutter={16} className="mb-4">
                    <Col xs={24} md={24} xl={24} className="sklpad">
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                    </Row>
                    
                  </Col>
                </Row>
                <Row gutter={16} className="mb-2">
                  <Col md={24}>
                  <Skeleton.Input block={true} active size="large" style={{height:"100px" }}/>
                  </Col>
                </Row>
                <Row gutter={16} className="mb-5 text-right_avatar" justify="right">
                  <Col xs={24} md={24} xl={24} className="text-right_avatar">
                  <Skeleton.Avatar active size="default" />
                  </Col>
                  
                </Row>
                <Row gutter={16} className="mb-4">
                  <Col md={24}>
                  <Skeleton.Input block={true} active size="large" style={{height:"130px" }}/>
                  </Col>
                </Row>

                <Row gutter={16} className="mb-5" justify="space-between">
                  <Col xs={24} md={18} xl={8} className="">
                  </Col>
                  <Col xs={24} md={6} xl={6} className="sklpad">
                  <Skeleton.Input block={true} active size="large" />
                  </Col>
                </Row>
              
                <Row gutter={16} className="mb-5" justify="center">
                  <Col xs={24} md={10} xl={10} className="sklpad">
                  
                  </Col>
                  <Col xs={24} md={4} xl={4} className="sklpad">
                  <Skeleton.Input block={true} active size="large" />
                  </Col>
                  <Col xs={24} md={10} xl={10} className="sklpad">
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-xxl">
            <div className="srWrapperInner">
              <Form
                name="report"
                autoComplete="off"
                layout="vertical"
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
                initialValues={{
                  checkinv: true,
                }}
              >
                <Card className="reportCard" id="quickReportContent">
                  <div className="reportCardBody">
                    <div className="inspectorInfo">
                      <Row gutter={30}>
                        <Col xs={24} md={6}>
                          <div className="inspectorLogo">
                            <Image
                              src={inspectorData?.logo_url}
                              className="ing-fluid mb-2"
                              alt="InspectorLogo"
                              preview={false}
                            />
                            <div className="inspectorInformation">
                              <p className="insOrgName">{inspectorData?.organization_name}</p>
                              <p>{inspectorData?.address}</p>
                              <p>{inspectorData?.phone_number}</p>
                              <p>{inspectorData?.email}</p>
                            </div>
                          </div>
                        </Col>
                        <Col sx={24} md={12}></Col>
                        <Col xs={24} md={6}>
                          <div className="inspectorDetails">
                            <div className="inspecDetailHead">
                              <h5 className="inspcTitle">Invoice</h5>
                            </div>
                            <div className="inspcInfo">
                              <div className="formGroup">
                                <Form.Item label="Claim#" name="claim" className="mb-0 ">
                                  <Input
                                    placeholder="Claim Number"
                                    className="formControl"
                                  />
                                </Form.Item>
                              </div>
                              <div className="formGroup">
                                <Form.Item label="Date" name="invoice_date" className="mb-2 mt-2">
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date"
                                      className="formControl"
                                      onChange={(e) =>
                                        handleDateformate(
                                          e,
                                          'invoice_date',
                                          'text'
                                        )
                                      }
                                      value={valuesDate.invoice_date ? valuesDate.invoice_date : inspectonData?.todaydate }
                                      onFocus={() => setCalOpen(!calOpen)}
                                      onKeyUp={() => setCalOpen(false)}
                                      onBlur={() => setCalOpen(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() => setCalOpen(!calOpen)}
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={calOpen}
                                    style={{ visibility: 'hidden', width: 0 }}
                                    onChange={(e) =>
                                      handleDateformate(
                                        e,
                                        'invoice_date',
                                        'date'
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                              <div className="formGroups">
                                <Form.Item
                                  name="checkinv"
                                  valuePropName="checked"
                                >
                                  <Checkbox onChange={() => invoiceCheck()}>Auto Generate Invoice Number?</Checkbox>
                                </Form.Item>
                              </div>
                              {checked ?
                                <div className="formGroup">
                                  <Form.Item label="Invoice#" name="invoice_no" className="mb-0">
                                    <Input
                                      placeholder="Invoice#"
                                      className="formControl"
                                    />
                                  </Form.Item>
                                </div> : ''}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="inspectorDetails">
                      <div className="inovicecards my-3">
                        <Row gutter={16}>
                          <Col xs={24} md={8} xl={8}>
                            <Form.Item name="client_name" className="mb-1">
                              <Input
                                placeholder="Client Name"
                                className="formControl"
                              />
                            </Form.Item>
                            <Form.Item name="company_name" className="mb-1">
                              <Input
                                placeholder="Company"
                                className="formControl"
                              />
                            </Form.Item>
                            <Form.Item name="addressdiv" className="mb-1">
                              <Input
                                placeholder="Address"
                                className="formControl"
                              />
                            </Form.Item>
                            <Form.Item name="phone_personal" className="mb-1">
                              <Input
                                placeholder="Phone"
                                className="formControl"
                              />
                            </Form.Item>
                            <Form.Item name="email_address" className="mb-1">
                              <Input
                                placeholder="email"
                                className="formControl"
                                type="email"
                              />
                            </Form.Item>
                          </Col>
                            
                          {inspectonData?.gc_subdivision && inspectonData?.gc_subdivision[0] !== null && inspectonData?.gc_subdivision[0][0] !== '' ? (
                            <Col xs={24} md={8} xl={8}>
                              <Card className="inovicecard">
                                <p className="siboxheading">Sub Division</p>
                                {Array.isArray(inspectonData?.gc_subdivision[0]) ? inspectonData?.gc_subdivision[0].map(name => <p> {name} </p>) : inspectonData?.gc_subdivision[0]}
                              </Card>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {inspectonData?.gc_lotnumber && inspectonData?.gc_lotnumber[0] !== null && inspectonData?.gc_lotnumber[0][0] !== '' ? (
                            <Col xs={24} md={8} xl={8}>
                              <Card className="inovicecard">
                                <p className="siboxheading">Lot Number</p>
                                {Array.isArray(inspectonData?.gc_lotnumber[0]) ? inspectonData?.gc_lotnumber[0].map(name => <p> {name} </p>) : inspectonData?.gc_lotnumber[0]}

                              </Card>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="invoiceTable">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          {columnsArray.map((column, index) => (
                            <th key={index}>
                              {column}
                            </th>
                          ))}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((item, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            {columnsArray.map((column, index) => (
                              <td key={index}>
                                <Form.Item name={[column, idx]} className="mb-0" key={index}>
                                  <Input
                                    placeholder={column}
                                    value={rows[idx][column]}
                                    index={idx}
                                    className="formControl mb-0"
                                    column={column}
                                    onChange={(e) => updateState(e)}
                                  />
                                </Form.Item>
                              </td>
                            ))}
                            <td>
                              <Button
                                type="btn"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => handleRemoveSpecificRow(idx)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="crbtn">
                      <Button
                        type="btn"
                        shape="circle"
                        icon={<PlusOutlined />}
                        className="repeatorbtn"
                        onClick={handleAddRow}
                      />
                    </div>
                  </div>
                  <div className="custom-Content">
                    <Row gutter={16}>
                      <Col xs={24} md={24} xl={24}>
                        <div className="formGroup">
                          <Form.Item label="Notes" name="invoice_notes">
                            <Input.TextArea
                              placeholder="Any relevant information not covered or additional terms and conditions can be mentioned here"
                              className="formControl"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} md={24} xl={24}>
                        
                        {inspectorData?.invoice_extra_notes !== "" ? (
                          <div className="formGroup">
                            <pre>{inspectorData?.invoice_extra_notes}</pre>
                          </div>
                        ) : (
                          <div className="formGroup"></div>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16} className="crbtn">
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Balance Due:" name="total">
                            <Input placeholder="00.00" prefix={'$'} className="formControl" />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={16} className="crbtn">
                      <Col xs={24} md={24}>
                        <div className="text-center my-3">
                          <Button htmlType="submit" loading={loadings[0]} className="btnPrimary"> Save and Continue</Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Form>
            </div>
          </div>
        )
      }
    </div>
  );
};
export default CreateInvoice;