import { Row, Card, Col, Image, Table, Form, Skeleton, Button } from "antd";
import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import * as invoiceDataService from "../../services/AllInvoices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import PlaceHolderImg from '../../assets/images/logoPlaceholder.png'

const SingleInvoice = () => {
  var dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);
  // const size = 'large';
  let navigate = useNavigate();
  const userDetails = useSelector((state) => state.User.userDetails);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };


  const fetchData = (params = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const invoice_id = queryParams.get("invoice_id");
    console.info("IdInvoice", invoice_id);
    dispatch(invoiceDataService.getInvoiceData(invoice_id))
      .then((res) => {
        console.info("invoicedata", res);
        setInvoiceData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const getPdf = () => {
    enterLoading(0);
    const queryParams = new URLSearchParams(window.location.search);
    const invoice_id = queryParams.get("invoice_id");
    console.info("invoice_id", invoice_id);
    dispatch(invoiceDataService.getInvoicePdf(invoice_id))
      .then((res) => {
        console.info("getInvoicePdf", res.data);
        //window.open(res.data, 'download');
        if(invoiceData.cover_generated === "NO") {
          navigate("/create-cover-letter/?req_id=" + invoiceData.req_id)
        } else {
          navigate("/cover-letter/?cover_id=" + invoiceData.cover_id)
        }
        
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  }
   const downloadPdf = () => {
    enterLoading(0);
    const queryParams = new URLSearchParams(window.location.search);
    const invoice_id = queryParams.get("invoice_id");
    console.info("invoice_id", invoice_id);
    dispatch(invoiceDataService.getInvoicePdf(invoice_id))
      .then((res) => {
      window.open(res.data);
        
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  }
  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      width: "10%",
      render: (id, record, index) => { ++index; return index; },
    },

    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      width: "70%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const handleNavigate = () => {
    navigate("/update-invoice/?invoice_id=" + invoiceData.invoice_id)
  }

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader
          title="Single Invoice"
        />
      </div>
       <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              {userDetails?.user_role === "um_inspector" ? (
                <Button
                  type='success'
                  className="btn inspectionAdd" 
                  onClick={() => handleNavigate()}
                >
                  Edit Invoice
                </Button>
              ) : (
                ''
              )}
              
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-xxl">
        {loading ? (
          <div className="">
          <div className="Skeleton_invoice">
              <Row gutter={16} className="mb-4 mt-4" justify="space-between">
                <Col xs={24} md={8} xl={8} className="">
                  <Row gutter={16} className="mb-3">
                    <Col xs={24} md={18} xl={18} className="">
                    <Skeleton.Input block={true} active size="large" style={{height:"90px" }}/>
                    </Col>
                    <Col xs={24} md={6} xl={6} className="">
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-5" >
                    <Col xs={24} md={12} xl={12} className="sklpad">
                    <Skeleton />
                    </Col>
                    <Col xs={24} md={12} xl={12} className="sklpad">
                    </Col>
                  </Row>
                  <Row gutter={16} className="mt-5" >
                    <Col xs={24} md={24} xl={24} className="sklpad">
                    <Skeleton />
                    </Col>
                    <Col xs={24} md={12} xl={12} className="sklpad">
                    </Col>
                  </Row> 
                </Col>
                <Col xs={24} md={6} xl={6} className="">
                  <Row gutter={16} className="">
                  <Col xs={24} md={24} xl={24} className="sklpad">
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                  </Row>
                  <hr></hr>
                  <Row gutter={16} className="mt-4 mb-4">
                  <Col xs={24} md={24} xl={24} className="sklpad">
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                  </Row>
                  <Row gutter={16} className="mb-4">
                  <Col xs={24} md={24} xl={24} className="sklpad">
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                  </Row>
                  <Row gutter={16} className="mb-4">
                  <Col xs={24} md={24} xl={24} className="sklpad">
                    <Skeleton.Input block={true} active size="large" />
                  </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={16} className="mb-4">
                <Col md={24}>
                <Skeleton.Input block={true} active size="large" style={{height:"110px" }}/>
                </Col>
              </Row>
              <Row gutter={16} className="mb-5" justify="space-between">
                <Col xs={24} md={18} xl={8} className="">
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-5 mt-4">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={9} xl={9} className="sklpad">
                
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={9} xl={9} className="sklpad">
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="srWrapperInner">
            <Form name="report" autoComplete="off" layout="vertical">
              <Card className="reportCard" id="quickReportContent">
                <div className="reportCardBody">
                  <div className="inspectorInfo">
                    <Row gutter={30}>
                      <Col xs={24} md={6}>
                        <div className="inspectorLogo">
                          <Image
                            src={invoiceData?.logo_url ? invoiceData?.logo_url : PlaceHolderImg}
                            className="ing-fluid"
                            alt="InspectorLogo"
                            preview={false}
                          />
                          <div className="adminInfo">
                            <p>{invoiceData?.ins_organization_name}</p>
                            <p>{invoiceData?.ins_address}</p>
                            <p>{invoiceData?.ins_phone_number}</p>
                            <p>{invoiceData?.ins_email}</p>
                          </div>
                        </div>
                      </Col>
                      <Col sx={24} md={12}></Col>
                      <Col xs={24} md={6}>
                        <div className="inspectorDetails">
                          <div className="inspecDetailHead">
                            <h5 className="inspcTitle">Invoice</h5>
                          </div>
                          <div className="inspcInfo">
                            <div className="formGroup">
                              <Form.Item
                                className="mb-1"
                                label="Invoice"
                                name="invoice"
                              >
                                <p className="formControl">
                                  {invoiceData?.invoice ? invoiceData?.invoice : '-'}
                                </p>
                              </Form.Item>
                            </div>
                            <div className="formGroup">
                              <Form.Item
                                className="mb-1"
                                label="Claim"
                                name="claim"
                              >
                                <p className="formControl">
                                  {invoiceData?.claimnumber ? invoiceData?.claimnumber : '-'}
                                </p>
                              </Form.Item>
                            </div>
                            <div className="formGroup">
                              <Form.Item  
                                className="mb-1"
                                label="Date"
                              >
                                {console.info('invoiceData?.invoice_date', invoiceData?.invoice_date)}
                                <p className="formControl">{invoiceData?.invoice_date ? invoiceData?.invoice_date : '-'}</p>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="inspectorDetails">
                    <div className="">
                      <Row gutter={16}>
                        <Col xs={24} md={8} xl={8}>
                          <Card className="inovicecard">
                            <p className="siboxheading">Bill To :</p>
                            <p>{invoiceData?.client_name}</p>
                            <p>{invoiceData?.company_name}</p>
                            <p>{invoiceData?.addressdiv}</p>
                            <p>{invoiceData?.phone_personal}</p>
                            <p>{invoiceData?.email_address}</p>
                          </Card>
                        </Col>

                        {invoiceData?.gc_subdivision && invoiceData?.gc_subdivision[0] !== null && invoiceData?.gc_subdivision[0][0] !== '' ? (
                          <Col xs={24} md={8} xl={8}>
                            <Card className="inovicecard">
                              <p className="siboxheading">Sub Division</p>
                              <div>
                                
                           {Array.isArray(invoiceData?.gc_subdivision[0]) ? invoiceData?.gc_subdivision[0].map(name => <p> {name} </p>) : invoiceData?.gc_subdivision[0]}
                              </div>
                            </Card>
                        </Col>
                        ) : (
                          ""
                        )}
                        
                        {invoiceData?.gc_lotnumber && invoiceData?.gc_lotnumber[0] !== null && invoiceData?.gc_lotnumber[0][0] !== '' ? (
                          <Col xs={24} md={8} xl={8}>
                            <Card className="inovicecard">
                              <p className="siboxheading">Lot Number</p>
                              <div>
                              {Array.isArray(invoiceData?.gc_lotnumber[0]) ? invoiceData?.gc_lotnumber[0].map(name => <p> {name} </p>) : invoiceData?.gc_lotnumber[0]}
                              </div>
                      
                            </Card>
                        </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="invoiceTable">
                  <Table
                    columns={columns}
                    dataSource={invoiceData.servicerepeater}
                    rowKey={(invoiceData) => invoiceData.servicerepeater}
                    pagination={false}
                    bordered
                  />
                </div>
                <div className="custom-Content">
                  <Row gutter={16} className="crbtn">
                    <Col xs={24} md={6} xl={6}>
                      <div className="formGroup">
                        <Form.Item label="Balance Due : $" name="blnc-due">
                          <p className="formControl">{invoiceData?.total}</p>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row gutter={16} className="crbtn">
                  <Col xs={24} md={24} xl={24}>
                    <div className="formGroup">
                      <Form.Item label="Notes" name="notes">
                        <p className="formControl">{invoiceData?.invoice_notes ? invoiceData?.invoice_notes : '-'}</p>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} className="crbtn">
                  <Col xs={24} md={24} xl={24}>
                    <div className="formGroup">
                    {invoiceData?.invoice_extra_notes !== "" ? (
                          <div className="formGroup">
                            <pre>{invoiceData?.invoice_extra_notes}</pre>
                          </div>
                        ) : (
                          <div className="formGroup"></div>
                        )}
                    </div>
                  </Col>
                </Row>
              </Card>
              <Row gutter={16} className="sibtn">
                <Col xs={24} md={24}>
                  <div className="text-center my-3">
                     {userDetails?.user_role === "um_manufacturer" ||
                      userDetails?.user_role === "um_claim-analyst" ||
                      !invoiceData?.req_id ||
                      userDetails?.user_role === "um_dealer" ? (
                      <Button
                        className="btnPrimary"
                        onClick={() => downloadPdf()}
                        loading={loadings[0]}
                      >
                        Download PDF
                      </Button>
                    ) : userDetails?.user_role === "um_inspector" ? (
                    <Button className="btnPrimary" onClick={() => getPdf()} loading={loadings[0]}>Save and Continue to Cover Letter</Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleInvoice;
