import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Collapse,
  Input,
  Card,
  Form,
  DatePicker,
  Cascader,
  Select,
  Switch,
  Modal,
  Divider,
  Space,
  Button,
  Empty,
  Skeleton,
  Image,
  Checkbox,
  Tooltip,
  Upload,
  message,
  Spin,
} from 'antd';
import SkeletonIndustryStandard from '../SkeletonIndustryStandard';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { MEDIA_UPLOAD_URL } from '../../../Helper';
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from 'suneditor/src/plugins';
import * as getSuggestionService from '../../../services/getSuggestion';
import {
  PlusOutlined,
  MinusOutlined,
  LoadingOutlined,
  SearchOutlined,
  CopyOutlined,
  FormOutlined,
  EyeOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import * as getClientbyIdService from '../../../services/getClientbyID';
import * as FooterLogoService from '../../../services/FooterLogo';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import Upload1 from '../uploadMultistep';

const { TextArea } = Input;
const { Panel } = Collapse;

const QuickReportForm = (props) => {
  console.warn({ props });
  const [form] = Form.useForm();
  var dispatch = useDispatch();
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenBtn, setIsOpenBtn] = useState(true);
  const [editorState1, setEditorState1] = useState('');
  const [editorState2, setEditorState2] = useState('');
  const [editorState3, setEditorState3] = useState('');
  const [editorState4, setEditorState4] = useState('');
  const [editorState5, setEditorState5] = useState('');
  const [editorState6, setEditorState6] = useState('');
  const [editorState7, setEditorState7] = useState('');
  const [editorState8, setEditorState8] = useState('');
  const [editorState9, setEditorState9] = useState('');
  const [editorState10, setEditorState10] = useState("");
  const [editorState11, setEditorState11] = useState("");
  const [editorState12, setEditorState12] = useState("");
  const [editorState13, setEditorState13] = useState("");
  const [loading, setLoading] = useState(false);
  const [sidebarState, setsidebarState] = useState();
  const [certificationLoader, setCertificationLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [filterStandards, setFilterStandards] = useState([]);
  const [footerLogo, setFooterLogo] = useState([]);
  const [selectedlogs, setselectedLogos] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [sign, setSign] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState('');
  const [commissioningData, setCommissioningData] = useState({});

  const handleClick = (item1) => {
    setModalIndustry(true);
    sidebarState.filter((item) =>
      item.id === item1 ? setindustryContent(item) : ''
    );
  };
  const getCommissioningData = (e) => {
    let value = props.values;
    let newval = {
      name: value.company_name_qr,
      h_eu_email: value.comm_email,
      Home_street: value.street_name_number,
      Home_city: value.city,
      Home_stateProvince: value.state,
      Home_country: value.country,
      //h_eu_address: value.city,
      h_eu_zip: value.zip_code,
      h_eu_contact: value.agent_claim_name,
      h_eu_phone_number: value.phone_no_party,
    };
    if(e.target.checked){
      setCommissioningData(newval);
      props.values.h_eu_name = newval.name;
      props.values.h_eu_email = newval.h_eu_email;
      props.values.h_eu_street = newval.Home_street;
      props.values.h_eu_city = newval.Home_city;
      props.values.h_eu_stateProvince = newval.Home_stateProvince;
      props.values.h_eu_country = newval.Home_country;
      props.values.h_eu_zip = newval.h_eu_zip;
      props.values.h_eu_phone_number = newval.h_eu_phone_number;
      props.values.h_eu_contact = newval.h_eu_contact;
      props.values.commishomeowner = true;
    }else{
      setCommissioningData({});
      props.values.h_eu_name = "";
      props.values.h_eu_email = "";
      props.values.h_eu_street = "";
      props.values.h_eu_city = "";
      props.values.h_eu_stateProvince = "";
      props.values.h_eu_country = "";
      props.values.h_eu_zip = "";
      props.values.h_eu_phone_number = "";
      props.values.h_eu_contact = "";
      props.values.commishomeowner = false;
    }
   
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(FooterLogoService.getLogos())
      .then((res) => {
        console.info(res);
        setCertificationLoader(true);
        setFooterLogo(res);
        setLoading(false);
        setCertificationLoader(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };
  useEffect(() => {
    props.values.claim_history_comments
      ? setEditorState1(props.values.claim_history_comments)
      : setEditorState1('');
    props.values.observation_comments
      ? setEditorState2(props.values.observation_comments)
      : setEditorState2('');
    props.values.applicable_field_text
      ? setEditorState3(props.values.applicable_field_text)
      : setEditorState3('');
    props.values.industry_standards_text
      ? setEditorState4(props.values.industry_standards_text)
      : setEditorState4('');
    props.values.conclusion_text
      ? setEditorState5(props.values.conclusion_text)
      : setEditorState5('');
    props.values.cause_text
      ? setEditorState6(props.values.cause_text)
      : setEditorState6('');
    props.values.conf_comment
      ? setEditorState7(props.values.conf_comment)
      : setEditorState7('');
    props.values.footer_text
      ? setEditorState8(props.values.footer_text)
      : setEditorState8('');
    props.values.private_notes
      ? setEditorState9(props.values.private_notes)
      : setEditorState9('');
      props.values.manufacturing_issue
      ? setEditorState10(props.values.manufacturing_issue)
      : setEditorState10("");
    props.values.site_rel_issue
      ? setEditorState11(props.values.site_rel_issue)
      : setEditorState11("");
    props.values.perfo_rel_issue
      ? setEditorState12(props.values.perfo_rel_issue)
      : setEditorState12("");
    props.values.insta_rel_issue
      ? setEditorState13(props.values.insta_rel_issue)
      : setEditorState13("");
    setselectedLogos(props.values?.footerLogo);
    setSign(props.values?.signId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.footerLogo]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    };
    formData.append('file', files[0]);
    axios.post(MEDIA_UPLOAD_URL, formData, config).then((res) => {
      const response = {
        // The response must have a "result" array.
        result: [
          {
            url: res.data.source_url,
            name: files[0].name,
            size: files[0].size,
          },
        ],
      };
      uploadHandler(response);
    });
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess('Ok');
      setSign(res);
      console.log('server res: ', res);
      props.values.signature = res?.data?.id;
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
      console.warn(error);
    }
  };

  const sendTextToEditor = (text, label) => {
    if (label === 'claim-history') {
      setEditorState1(editorState1 + text);
      onEditorStateChange1(editorState1 + text);
    } else if (label === 'observations_and_comments') {
      setEditorState2(editorState2 + text);
      onEditorStateChange2(editorState2 + text);
    } else if (label === 'applicable-field-tests') {
      setEditorState3(editorState3 + text);
      onEditorStateChange3(editorState3 + text);
    } else if (label === 'industry_standards') {
      setEditorState4(editorState4 + text);
      onEditorStateChange4(editorState4 + text);
    } else if (label === 'conclusion') {
      setEditorState5(editorState5 + text);
      onEditorStateChange5(editorState5 + text);
    } else if (label === 'cause') {
      setEditorState6(editorState6 + text);
      onEditorStateChange6(editorState6 + text);
    } else if (label === 'confidential-information') {
      setEditorState7(editorState7 + text);
      onEditorStateChange7(editorState7 + text);
    } else if (label === 'footer-text') {
      setEditorState8(editorState8 + text);
      onEditorStateChange8(editorState8 + text);
    } else if (label === 'private-notes') {
      setEditorState9(editorState9 + text);
      onEditorStateChange9(editorState9 + text);
    }
    else if (label === "manufacturing_issue") {
      setEditorState10(editorState10 + text);
      onEditorStateChange10(editorState10 + text);
    }
    else if (label === "site_rel_issue") {
      setEditorState11(editorState11 + text);
      onEditorStateChange11(editorState11 + text);
    }
    else if (label === "perfo_rel_issue") {
      setEditorState12(editorState12 + text);
      onEditorStateChange12(editorState12 + text);
    }
    else if (label === "insta_rel_issue") {
      setEditorState13(editorState13 + text);
      onEditorStateChange13(editorState13 + text);
    }
  };

  const onEditorStateChange1 = (editorData) => {
    setEditorState1(editorData);
    props.values.claim_history_comments = editorData;
    props.handleEditor(editorData, 'claim_history_comments');
  };

  const onEditorStateChange2 = (editorData) => {
    setEditorState2(editorData);
    props.values.observation_comments = editorData;
    props.handleEditor(editorData, 'observation_comments');
  };

  const onEditorStateChange3 = (editorData) => {
    setEditorState3(editorData);
    props.values.applicable_field_text = editorData;
    props.handleEditor(editorData, 'applicable_field_text');
  };

  const onEditorStateChange4 = (editorData) => {
    setEditorState4(editorData);
    props.values.industry_standards_text = editorData;
    props.handleEditor(editorData, 'industry_standards_text');
  };

  const onEditorStateChange5 = (editorData) => {
    setEditorState5(editorData);
    props.values.conclusion_text = editorData;
    props.handleEditor(editorData, 'conclusion_text');
  };

  const onEditorStateChange6 = (editorData) => {
    setEditorState6(editorData);
    props.values.cause_text = editorData;
    props.handleEditor(editorData, 'cause_text');
  };

  const onEditorStateChange7 = (editorData) => {
    setEditorState7(editorData);
    props.values.conf_comment = editorData;
    props.handleEditor(editorData, 'conf_comment');
  };

  const onEditorStateChange8 = (editorData) => {
    setEditorState8(editorData);
    props.values.footer_text = editorData;
    props.handleEditor(editorData, 'footer_text');
  };

  const onEditorStateChange9 = (editorData) => {
    setEditorState9(editorData);
    props.values.private_notes = editorData;
    props.handleEditor(editorData, 'private_notes');
  };
  const onEditorStateChange10 = (editorData) => {
    setEditorState10(editorData);
    props.values.manufacturing_issue = editorData;
    props.handleEditor(
      editorData,
      "manufacturing_issue"
    );
  };
  const onEditorStateChange11 = (editorData) => {
    setEditorState11(editorData);
    props.values.site_rel_issue = editorData;
    props.handleEditor(
      editorData,
      "site_rel_issue"
    );
  };
  const onEditorStateChange12 = (editorData) => {
    setEditorState12(editorData);
    props.values.perfo_rel_issue = editorData;
    props.handleEditor(
      editorData,
      "perfo_rel_issue"
    );
  };
  const onEditorStateChange13 = (editorData) => {
    setEditorState13(editorData);
    props.values.insta_rel_issue = editorData;
    props.handleEditor(
      editorData,
      "insta_rel_issue"
    );
  };
  const onFinish = (values) => {
    console.log('Success:', values);
    console.log(sign);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const timeampm = [
    {
      value: 'AM',
      label: 'AM',
    },
    {
      value: 'PM',
      label: 'PM',
    },
  ];

  const options = [
    {
      value: 'Sq/Ft',
      label: 'Sq/Ft',
    },
    {
      value: 'Sq/yds',
      label: 'Sq/yds',
    },
    {
      value: 'boxes',
      label: 'boxes',
    },
  ];

  const { Option } = Select;

  const takefileList = (list) => {
    props.values.attachments = list;
  };
  useEffect(() => {
    setCertificationLoader(true);
    setselectedLogos(props.values?.footerLogo);
    setImageUrl(props.values?.signurl);
    fetchData();
    props.values.claim_history_comments
      ? setEditorState1(props.values.claim_history_comments)
      : setEditorState1('');
    props.values.observation_comments
      ? setEditorState2(props.values.observation_comments)
      : setEditorState2('');
    props.values.applicable_field_text
      ? setEditorState3(props.values.applicable_field_text)
      : setEditorState3('');
    props.values.industry_standards_text
      ? setEditorState4(props.values.industry_standards_text)
      : setEditorState4('');
    props.values.conclusion_text
      ? setEditorState5(props.values.conclusion_text)
      : setEditorState5('');
    props.values.cause_text
      ? setEditorState6(props.values.cause_text)
      : setEditorState6('');
    props.values.conf_comment
      ? setEditorState7(props.values.conf_comment)
      : setEditorState7('');
    props.values.footer_text
      ? setEditorState8(props.values.footer_text)
      : setEditorState8('');
    props.values.private_notes
      ? setEditorState9(props.values.private_notes)
      : setEditorState9('');
      props.values.manufacturing_issue
      ? setEditorState10(props.values.manufacturing_issue)
      : setEditorState10("");
    props.values.site_rel_issue
      ? setEditorState11(props.values.site_rel_issue)
      : setEditorState11("");
    props.values.perfo_rel_issue
      ? setEditorState12(props.values.perfo_rel_issue)
      : setEditorState12("");
    props.values.insta_rel_issue
      ? setEditorState13(props.values.insta_rel_issue)
      : setEditorState13("");

    //setImageUrl(props.values?.signurl);

    //fetchFooterLogos();
    //fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values?.signurl]);

  useEffect(() => {
    console.info('Useffect Called');
    if (sidebarState) {
      const filteredData = sidebarState.filter((entry) =>
        entry?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChangeImage = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        props.values.signurl = url;
      });
    }
  };
  //15oct
  const getClientbyId = (key) => {
    setIsOpenBtn(false);
    props.values.client_select_drop = key;
    dispatch(getClientbyIdService.getClientbyId(key))
      .then((res) => {
        form.setFieldsValue({
          company_name_qr: res.company_name,
          comm_party_drop: res.company_type,
          agent_claim_name: res.name,
          comm_email: res.email,
          street_name_number: res.street,
          city: res.city,
          state: res.states,
          country: res.country,
          zip_code: res.zip_code,
          phone_no_party: res.phone_personal,
        });
        props.handleFill({
          company_name_qr: res.company_name,
          comm_party_drop: res.company_type_,
          agent_claim_name: res.name,
          comm_email: res.email,
          street_name_number: res.street,
          city: res.city,
          state: res.states,
          country: res.country,
          zip_code: res.zip_code,
          phone_no_party: res.phone_personal,
        });
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const addClient = () => {
    setIsOpenClient(false);
    setIsOpenBtn(true);
    form.setFieldsValue({
      company_name_qr: '',
      client_select_drop: '',
      comm_party_drop: '',
      agent_claim_name: '',
      comm_email: '',
      street_name_number: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      phone_no_party: '',
    });
  };

  const hideClient = () => {
    setIsOpenBtn(false);
  };

  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: '150px',
    imageHeight: '150px',
    height: 'auto',
    minHeight: '200px',
    requestHeaders: {
      'X-Sample': 'sample',
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ['undo', 'redo'],
      ['font', 'fontSize', 'formatBlock'],
      ['paragraphStyle'],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      ['fontColor', 'hiliteColor'],
      ['removeFormat'],
      ['align', 'horizontalRule', 'lineHeight'],
      ['outdent', 'indent'],
      ['list'],
      ['table', 'link', 'image'],
      ['fullScreen', 'showBlocks', 'preview'],
    ],
    formats: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    font: [
      'Arial',
      'Calibri',
      'Comic Sans',
      'Courier',
      'Garamond',
      'Georgia',
      'Impact',
      'Lucida Console',
      'Palatino Linotype',
      'Segoe UI',
      'Tahoma',
      'Times New Roman',
      'Trebuchet MS',
      'Roboto',
    ],
  };

  const getSuggestion = (label, category) => {
    setLoading(true);
    let suggestiondata = {
      label: label,
      category: category,
    };
    dispatch(getSuggestionService.getindStandard(suggestiondata))
      .then((res) => {
        setsidebarState(res);
        setLoading(false);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const DateFormat = 'MM-DD-YYYY';

  return (
    <>
      <div className="quickReportWrapper">
        <div className="pageHeader">
          <PageHeader title="Quick Report" />
        </div>
        {props.loading ? (
          <div className="container-xxl">
            <div className="quick_space">
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          // <Spin
          //   className="reportSpin"
          //   spinning={SpinnerReport}
          //   size="large"
          //   style={{ maxHeight: '100%' }}
          // >
          <div className="container-xxl">
            <div className="reportInner">
              <Form
                name="report"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Card className="reportCard">
                  <div className="reportCardBody">
                    <div className="formSection">
                      <div className="inspectionSection">
                        <div className="inspecHead">
                          <h4 className="formGroupTitle">Inspection Details</h4>
                        </div>
                        <div className="inspecContent">
                          <Row gutter={16}>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Claim Number"
                                  name="claim_num"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Please input your Claim Number!',
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Claim Number"
                                    className="formControl"
                                    onChange={props.handleChange('claim_num')}
                                    defaultValue={props.values.claim_num}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Inspection Date"
                                  name="h_bi_ins_date"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Inspection Date"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          'h_bi_ins_date',
                                          'text'
                                        )
                                      }
                                      value={props.values.h_bi_ins_date}
                                      onFocus={() =>
                                        props.setCalOpen(!props.calOpen)
                                      }
                                      onKeyUp={() => props.setCalOpen(false)}
                                      onBlur={() => props.setCalOpen(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen(!props.calOpen)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen}
                                    style={{ visibility: 'hidden', width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        'h_bi_ins_date',
                                        'date'
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup custom-form-groupp">
                                <Form.Item
                                  label="Inspection Time"
                                  name="ins_time"
                                >
                                  <Input
                                    className="formGroup customPadding"
                                    addonAfter={
                                      <Cascader
                                        placeholder="AM"
                                        onChange={props.handleChange(
                                          'time_type'
                                        )}
                                        options={timeampm}
                                        defaultValue={props.values.time_type}
                                        className="custom-cascadar"
                                      />
                                    }
                                    placeholder="12:00"
                                    onChange={props.handleChange('ins_time')}
                                    defaultValue={props.values.ins_time}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Inspection Type"
                                  name="inspection_type"
                                >
                                  <Select
                                    className="formGroup"
                                    onChange={props.handleChange(
                                      'inspection_type'
                                    )}
                                    defaultValue={
                                      props.values.inspection_type
                                        ? props.values.inspection_type
                                        : 'Inspection Type'
                                    }
                                  >
                                    <Option value="Residential Inspection">
                                      Residential Inspection
                                    </Option>
                                    <Option value="Commercial Inspection">
                                      Commercial Inspection
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="CollapseSection">
                        <Collapse
                          defaultActiveKey={
                            props.open ? props.open : ['1', '2', '3', '4', '5']
                          }
                          onChange={props.onChangeCollapse}
                          className="reportCollapse"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <MinusOutlined className="custom-icon" />
                            ) : (
                              <PlusOutlined className="custom-icon" />
                            )
                          }
                          ghost
                        >
                          {/* {console.warn('dsad',props.values?.client_type === "Flooring Manufacturer")} */}
                          <Panel
                            header="Commissioning Party Information"
                            key={1}
                          >
                            <Row gutter={16}>
                              {props.values?.client_type ===
                              'Flooring Manufacturer' ? (
                                ''
                              ) : (
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Select Client"
                                      name="client_select_drop"
                                    >
                                      <Select
                                        showSearch
                                        defaultValue="Select Client"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                          optionA.children
                                            .toLowerCase()
                                            .localeCompare(
                                              optionB.children.toLowerCase()
                                            )
                                        }
                                        onChange={getClientbyId}
                                        open={isOpenClient}
                                        onDropdownVisibleChange={(visible) =>
                                          setIsOpenClient(visible)
                                        }
                                        dropdownRender={(menu) => (
                                          <>
                                            {menu}
                                            <Divider
                                              style={{
                                                margin: '0',
                                              }}
                                            />
                                            <Space className="renderBtn">
                                              <Button
                                                type="text"
                                                icon={<PlusOutlined />}
                                                onClick={() => addClient()}
                                                block
                                              >
                                                Add New Client
                                              </Button>
                                            </Space>
                                          </>
                                        )}
                                      >
                                        {props.clientDropdown ? (
                                          props.clientDropdown.map(
                                            (option, id) => {
                                              return (
                                                <Option
                                                  value={option.id}
                                                  key={id}
                                                >
                                                  {option.name}
                                                </Option>
                                              );
                                            }
                                          )
                                        ) : (
                                          <Option value="No data">
                                            <Empty />
                                          </Option>
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </Col>
                              )}
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Commissioning Party"
                                    name="comm_party_drop"
                                  >
                                    <Select
                                      defaultValue={
                                        props.values.comm_party_drop
                                          ? props.values.comm_party_drop
                                          : 'Commissioning Party'
                                      }
                                      onChange={props.handleChange(
                                        'comm_party_drop'
                                      )}
                                    >
                                      <Option value="Manufacturer">
                                        Manufacturer
                                      </Option>
                                      <Option value="Dealer">Dealer</Option>
                                      <Option value="Distributor">
                                        Distributor
                                      </Option>
                                      <Option value="Homeowner">
                                        Homeowner
                                      </Option>
                                      <Option value="Attorney">Attorney</Option>
                                      <Option value="Builder">Builder</Option>
                                      <Option value="Insurance Company">
                                        Insurance Company
                                      </Option>
                                      <Option value="Installer">
                                        Installer
                                      </Option>
                                      <Option value="Other">other</Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                              {props?.isOpen?.commPartyopen === true ||
                              props?.values?.comm_party_drop === 'Other' ? (
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item label="Other" name="comm_other">
                                      <Input
                                        placeholder="Other"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          'comm_other'
                                        )}
                                        defaultValue={props.values.comm_other}
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              ) : (
                                ''
                              )}
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Company Name"
                                    name="company_name_qr"
                                  >
                                    <Input
                                      placeholder="Company Name"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'company_name_qr'
                                      )}
                                      defaultValue={
                                        props.values.company_name_qr
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="First and Last Name"
                                    name="agent_claim_name"
                                  >
                                    <Input
                                      placeholder="First and Last Name"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'agent_claim_name'
                                      )}
                                      defaultValue={
                                        props.values.agent_claim_name
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Email Address"
                                    name="comm_email"
                                  >
                                    <Input
                                      placeholder="email@mail.com"
                                      className="formControl"
                                      type="email"
                                      onChange={props.handleChange(
                                        'comm_email'
                                      )}
                                      defaultValue={props.values.comm_email}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Street Name/Number"
                                    name="street_name_number"
                                  >
                                    <Input
                                      placeholder="Street Name/Number"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'street_name_number'
                                      )}
                                      defaultValue={
                                        props.values.street_name_number
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="City" name="city">
                                    <Input
                                      placeholder="City"
                                      className="formControl"
                                      onChange={props.handleChange('city')}
                                      defaultValue={props.values.city}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="State/Province"
                                    name="state"
                                  >
                                    <Input
                                      placeholder="State/Province"
                                      className="formControl"
                                      onChange={props.handleChange('state')}
                                      defaultValue={props.values.state}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Country" name="country">
                                    <Input
                                      placeholder="Country"
                                      className="formControl"
                                      onChange={props.handleChange('country')}
                                      defaultValue={props.values.country}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Zip Code/Postal Code"
                                    name="zip_code"
                                  >
                                    <Input
                                      placeholder="Zip Code/Postal Code"
                                      className="formControl"
                                      onChange={props.handleChange('zip_code')}
                                      defaultValue={props.values.zip_code}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Phone Number"
                                    name="phone_no_party"
                                  >
                                    <Input
                                      placeholder="(000)00000"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'phone_no_party'
                                      )}
                                      defaultValue={props.values.phone_no_party}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              {isOpenBtn ? (
                                <Col
                                  xs={24}
                                  md={6}
                                  xl={6}
                                  className="d-flex justify-content-start align-items-center"
                                >
                                  <div className="formGroup">
                                    <Button
                                      className="btnSecondary me-2"
                                      onClick={props.addClient}
                                    >
                                      Add Client
                                    </Button>
                                    <Button
                                      className="btnSecondary me-2"
                                      onClick={() => hideClient()}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Col>
                              ) : (
                                ''
                              )}
                            </Row>
                          </Panel>
                          <Panel header="Dealer Contact Information" key={2}>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Dealer Name"
                                    name="dealer_name"
                                  >
                                    <Input
                                      placeholder="Dealer Name"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'dealer_name'
                                      )}
                                      defaultValue={props.values.dealer_name}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Phone Number"
                                    name="dealer_phone"
                                  >
                                    <Input
                                      placeholder="Phone Number"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'dealer_phone'
                                      )}
                                      defaultValue={props.values.dealer_phone}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel
                            header="Homeowner/End-User Information"
                            key={3}
                          >
                            <Row gutter={16}>
                              <div className="m-2">
                                <Form.Item name="commishomeowner">
                                  <Checkbox
                                      onChange={getCommissioningData}
                                    defaultChecked={
                                      props.values.commishomeowner
                                    }
                                  >
                                    Check this box if Homeowner is Commissioning
                                    Party
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Name"
                                    name="h_eu_name"
                                    valuePropName={commissioningData?.name}
                                  >
                                    <Input
                                      placeholder="Homeowner Name"
                                      className="formControl"
                                      value={commissioningData?.name}
                                      onChange={props.handleChange('h_eu_name')}
                                      defaultValue={props.values.h_eu_name}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Street Name/Number"
                                    name="h_eu_street"
                                    valuePropName={
                                      commissioningData?.Home_street
                                    }
                                  >
                                    <Input
                                      placeholder="Street Name/Number"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'h_eu_street'
                                      )}
                                      value={commissioningData?.Home_street}
                                      defaultValue={props.values.h_eu_street}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="City "
                                    name="h_eu_city"
                                    valuePropName={commissioningData?.Home_city}
                                  >
                                    <Input
                                      placeholder="City"
                                      className="formControl"
                                      onChange={props.handleChange('h_eu_city')}
                                      value={commissioningData?.Home_city}
                                      defaultValue={props.values.h_eu_city}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="State/Province "
                                    name="h_eu_stateProvince"
                                    valuePropName={
                                      commissioningData?.Home_stateProvince
                                    }
                                  >
                                    <Input
                                      placeholder="State/Province"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'h_eu_stateProvince'
                                      )}
                                      value={
                                        commissioningData?.Home_stateProvince
                                      }
                                      defaultValue={
                                        props.values.h_eu_stateProvince
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Country"
                                    name="h_eu_country"
                                    valuePropName={
                                      commissioningData?.Home_country
                                    }
                                  >
                                    <Input
                                      placeholder="Country"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'h_eu_country'
                                      )}
                                      defaultValue={props.values.h_eu_country}
                                      value={commissioningData?.Home_country}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              {/* <Col xs={24} md={12} xl={12}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Address"
                                     name="h_eu_address"
                                    // name="end_address"
                                    valuePropName={commissioningData?.h_eu_address}
                                  >
                                    <Input
                                      placeholder="Address"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "h_eu_address"
                                      )}
                                      value={commissioningData?.h_eu_address}
                                      defaultValue={props.values.h_eu_address}
                                    />
                                  </Form.Item>
                                </div>
                              </Col> */}
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Zip Code/Postal Code"
                                    name="h_eu_zip"
                                    valuePropName={commissioningData?.h_eu_zip}
                                  >
                                    <Input
                                      placeholder="Zip Code/Postal Code"
                                      className="formControl"
                                      value={commissioningData?.h_eu_zip}
                                      onChange={props.handleChange('h_eu_zip')}
                                      defaultValue={props.values.h_eu_zip}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Contact"
                                    name="h_eu_contact"
                                    valuePropName={
                                      commissioningData?.h_eu_contact
                                    }
                                  >
                                    <Input
                                      placeholder="Contact"
                                      className="formControl"
                                      value={commissioningData?.h_eu_contact}
                                      onChange={props.handleChange(
                                        'h_eu_contact'
                                      )}
                                      defaultValue={props.values.h_eu_contact}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Phone Number"
                                    name="h_eu_phone_number"
                                    valuePropName={
                                      commissioningData?.h_eu_phone_number
                                    }
                                  >
                                    <Input
                                      placeholder="Phone Number"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'h_eu_phone_number'
                                      )}
                                      value={
                                        commissioningData?.h_eu_phone_number
                                      }
                                      defaultValue={
                                        props.values.h_eu_phone_number
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Mobile Number"
                                    name="h_eu_alt_phone_number"
                                    valuePropName={
                                      commissioningData?.h_eu_alt_phone_number
                                    }
                                  >
                                    <Input
                                      placeholder="Mobile Number"
                                      className="formControl"
                                      value={
                                        commissioningData?.h_eu_alt_phone_number
                                      }
                                      onChange={props.handleChange(
                                        'h_eu_alt_phone_number'
                                      )}
                                      defaultValue={
                                        props.values.h_eu_alt_phone_number
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Email Address"
                                    name="h_eu_email"
                                    valuePropName={
                                      commissioningData?.h_eu_email
                                    }
                                  >
                                    <Input
                                      placeholder="email@mail.com"
                                      className="formControl"
                                      type="email"
                                      onChange={props.handleChange(
                                        'h_eu_email'
                                      )}
                                      value={commissioningData?.h_eu_email}
                                      defaultValue={props.values.h_eu_email}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                <Form.Item
                                    label="Building Occupied?"
                                    name="h_bui_occupied"
                                  >
                                    {console.warn('here')}
                                    <TripleToggle
                                    value={props.values.h_bui_occupied}
                                    onToggle={props.handleChange('h_bui_occupied')}
                                  />
                                </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={18} xl={18}>
                              {props.isOpen.buildField === true ||
                              props.values.h_bui_occupied === 'Yes' ? (
                                  <div id="myDiv3" className="switch-row">
                                    <Row gutter={16}>
                                      <Col xs={24} md={8} xl={8}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Adults"
                                            name="adults"
                                          >
                                            <Input
                                              placeholder="Adults"
                                              className="formControl"
                                              onChange={props.handleChange(
                                                'adults'
                                              )}
                                              defaultValue={props.values.adults}
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={8} xl={8}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Children"
                                            name="childrens"
                                          >
                                            <Input
                                              placeholder="Children"
                                              className="formControl"
                                              onChange={props.handleChange(
                                                'childrens'
                                              )}
                                              defaultValue={
                                                props.values.childrens
                                              }
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={8} xl={8}>
                                        <div className="formGroup">
                                          <Form.Item label="Pets" name="pets">
                                            <Input
                                              placeholder="Pets"
                                              className="formControl"
                                              onChange={props.handleChange(
                                                'pets'
                                              )}
                                              defaultValue={props.values.pets}
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Product and Claim Information" key={4}>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Date of Installation"
                                    name="product_date_of_ins"
                                  >
                                    <Input.Group compact className="mt-0">
                                      <Input
                                        placeholder="Date of Installation"
                                        className="formControl"
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'product_date_of_ins',
                                            'text'
                                          )
                                        }
                                        value={props.values.product_date_of_ins}
                                        onFocus={() =>
                                          props.setCalOpen1(!props.calOpen1)
                                        }
                                        onKeyUp={() => props.setCalOpen1(false)}
                                        onBlur={() => props.setCalOpen1(false)}
                                      />

                                      <Button
                                        className="btnAddOn"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                          props.setCalOpen1(!props.calOpen1)
                                        }
                                      />
                                    </Input.Group>
                                    <DatePicker
                                      className="formControl dateHide"
                                      format={DateFormat}
                                      open={props.calOpen1}
                                      style={{
                                        visibility: 'hidden',
                                        width: 0,
                                      }}
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          'product_date_of_ins',
                                          'date'
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Date Problem Noticed"
                                    name="date_problem_noticed"
                                  >
                                    <Input.Group compact className="mt-0">
                                      <Input
                                        placeholder="Date Problem Noticed"
                                        className="formControl"
                                        onChange={(e) =>
                                          props.handleDateformate(
                                            e,
                                            'date_problem_noticed',
                                            'text'
                                          )
                                        }
                                        value={
                                          props.values.date_problem_noticed
                                        }
                                        onFocus={() =>
                                          props.setCalOpen2(!props.calOpen2)
                                        }
                                        onKeyUp={() => props.setCalOpen2(false)}
                                        onBlur={() => props.setCalOpen2(false)}
                                      />

                                      <Button
                                        className="btnAddOn"
                                        icon={<CalendarOutlined />}
                                        onClick={() =>
                                          props.setCalOpen2(!props.calOpen2)
                                        }
                                      />
                                    </Input.Group>
                                    <DatePicker
                                      className="formControl dateHide"
                                      format={DateFormat}
                                      open={props.calOpen2}
                                      style={{
                                        visibility: 'hidden',
                                        width: 0,
                                      }}
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          'date_problem_noticed',
                                          'date'
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Reported To"
                                    name="reported_to"
                                  >
                                    <Input
                                      placeholder="Reported To"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'reported_to'
                                      )}
                                      defaultValue={props.values.reported_to}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Manufacturer Name"
                                    name="h_bi_manu_name"
                                  >
                                    <Input
                                      placeholder="Manufacturer Name"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'h_bi_manu_name'
                                      )}
                                      defaultValue={props.values.h_bi_manu_name}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Style Name/Number"
                                    name="style_name"
                                  >
                                    <Input
                                      placeholder="Style Name/Number"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'style_name'
                                      )}
                                      defaultValue={props.values.style_name}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Color Name / Number"
                                    name="h_bi_color_name"
                                  >
                                    <Input
                                      placeholder="Brown, Wooden"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'h_bi_color_name'
                                      )}
                                      defaultValue={
                                        props.values.h_bi_color_name
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Area/Rooms Installed"
                                    name="area_room_ins"
                                  >
                                    <Input
                                      placeholder="Area/Rooms Installed"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        'area_room_ins'
                                      )}
                                      defaultValue={props.values.area_room_ins}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup custom-form-group">
                                  <Form.Item
                                    label="Total Claim Quantity"
                                    name="total_claim_qt"
                                  >
                                    <Input
                                      className="formGroup customPadding"
                                      addonAfter={
                                        <Cascader
                                          placeholder="sq/ft"
                                          onChange={props.handleChange(
                                            'measure_type1'
                                          )}
                                          defaultValue={
                                            props.values.measure_type1
                                          }
                                          options={options}
                                        />
                                      }
                                      onChange={props.handleChange(
                                        'total_claim_qt'
                                      )}
                                      defaultValue={props.values.total_claim_qt}
                                      placeholder="10 sq/ft"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup custom-form-group">
                                  <Form.Item
                                    label="Total Affected"
                                    name="total_affected"
                                  >
                                    <Input
                                      className="formGroup customPadding"
                                      addonAfter={
                                        <Cascader
                                          placeholder="sq/ft"
                                          onChange={props.handleChange(
                                            'measure_type2'
                                          )}
                                          options={options}
                                          defaultValue={
                                            props.values.measure_type2
                                          }
                                          className="customCascadar"
                                        />
                                      }
                                      onChange={props.handleChange(
                                        'total_affected'
                                      )}
                                      defaultValue={props.values.total_affected}
                                      placeholder="10 sq/ft"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                <Form.Item
                                      label="Flooring Installed"
                                      name="flooring_installed_toggle"
                                >
                                  {console.warn("tripleToggle")}
                                  <TripleToggle
                                    value={props.values.flooring_installed_toggle}
                                    onToggle={props.handleChange('flooring_installed_toggle')}
                                  />
                                </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Statement Of Concern"
                                    name="concerns"
                                  >
                                    <TextArea
                                      placeholder="Statement of Concern"
                                      className="txtaea"
                                      onChange={props.handleChange('concerns')}
                                      defaultValue={props.values.concerns}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Substrate Information" key={5}>
                            <Row gutter={16}>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Sub Floor Type"
                                    name="subfloor_type"
                                  >
                                    <Select
                                      defaultValue={
                                        props.values.subfloor_type
                                          ? props.values.subfloor_type
                                          : 'Sub Floor Type'
                                      }
                                      onChange={props.handleChange(
                                        'subfloor_type'
                                      )}
                                    >
                                      <Option value="Concrete">Concrete</Option>
                                      <Option value="Concrete on Grade">
                                        Concrete on Grade
                                      </Option>
                                      <Option value="Concrete below Grade">
                                        Concrete below Grade
                                      </Option>
                                      <Option value="Concrete Above Grade">
                                        Concrete Above Grade
                                      </Option>
                                      <Option value="Gypsum Concrete">
                                        Gypsum Concrete
                                      </Option>
                                      <Option value="Wood">Wood</Option>
                                      <Option value="Wood & Concrete">
                                        Wood & Concrete
                                      </Option>
                                      <Option value="Wood Over Crawlspace">
                                        Wood Over Crawlspace
                                      </Option>
                                      <Option value="Wood Over Basement">
                                        Wood Over Basement
                                      </Option>
                                      <Option value="Not Applicable">
                                        Not Applicable
                                      </Option>
                                      <Option value="Lightweight Concrete">
                                        Lightweight Concrete
                                      </Option>
                                      <Option value="See Report">
                                        See Report
                                      </Option>
                                      <Option value="Other">Other</Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                              {props.isOpen.subFloorOther === true ||
                              props.values.subfloor_type === 'Other' ? (
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Other"
                                      name="qr_subfloor_type"
                                    >
                                      <Input
                                        placeholder="Other"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          'qr_subfloor_type'
                                        )}
                                        defaultValue={
                                          props.values.qr_subfloor_type
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              ) : (
                                ''
                              )}
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Grade/Gradient"
                                    name="subs_grade"
                                  >
                                    <Select
                                      onChange={props.handleChange(
                                        'subs_grade'
                                      )}
                                      defaultValue={
                                        props.values.subs_grade
                                          ? props.values.subs_grade
                                          : 'Grade/Gradient'
                                      }
                                    >
                                      <Option value="Above Grade">
                                        Above Grade
                                      </Option>
                                      <Option value="On Grade">
                                        On Grade{' '}
                                      </Option>
                                      <Option value="Below Grade">
                                        Below Grade{' '}
                                      </Option>
                                      <Option value="On Grade & Above Grade">
                                        On Grade & Above Grade{' '}
                                      </Option>
                                      <Option value="On Grade & Below Grade">
                                        On Grade & Below Grade{' '}
                                      </Option>
                                      <Option value="On All Grades">
                                        On All Grades{' '}
                                      </Option>
                                      <Option value="Level to the Building">
                                        Level to the Building
                                      </Option>
                                      <Option value="Towards the Building">
                                        Towards the Building
                                      </Option>
                                      <Option value="Away from Building">
                                        Away from Building
                                      </Option>
                                      <Option value="Towards the Building with Gutters Attached">
                                        Towards the Building with Gutters
                                        Attached
                                      </Option>
                                      <Option value="Away from Building with Gutters Attached, Away from Building">
                                        Away from Building with Gutters
                                        Attached, Away from Building
                                      </Option>
                                      <Option value="Squash Blocks">
                                        Squash Blocks
                                      </Option>
                                      <Option value="Missing Gutters">
                                        Missing Gutters
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Home Building Footprint"
                                    name="footprint"
                                  >
                                    <Select
                                      onChange={props.handleChange('footprint')}
                                      defaultValue={
                                        props.values.footprint
                                          ? props.values.footprint
                                          : 'Home Building Footprint'
                                      }
                                    >
                                      <Option value="Surrounding Conditions Home/Building">
                                        Surrounding Conditions Home/Building
                                      </Option>
                                      <Option value="Coastal">Coastal</Option>
                                      <Option value="Valley">Valley</Option>
                                      <Option value="Flatland">Flatland</Option>
                                      <Option value="Gradually Rolling hill: Denote Side, East, West, South and or North">
                                        Gradually Rolling hill: Denote Side,
                                        East, West, South and or North
                                      </Option>
                                      <Option value="Foothills">
                                        Foothills
                                      </Option>
                                      <Option value="Mountainous">
                                        Mountainous
                                      </Option>
                                      <Option value="Desert">Desert</Option>
                                      <Option value="High Desert">
                                        High Desert
                                      </Option>
                                      <Option value="Island">Island</Option>
                                      <Option value="Rural">Rural</Option>
                                      <Option value="Urban">Urban</Option>
                                      <Option value="High Rise">
                                        High Rise
                                      </Option>
                                      <Option value="New Construction in New Developing Neighborhood">
                                        New Construction in New Developing
                                        Neighborhood
                                      </Option>
                                      <Option value="New Construction: 1 month - 12 months">
                                        New Construction: 1 month - 12 months
                                      </Option>
                                      <Option value="New Construction: 12-24 months">
                                        New Construction: 12-24 months
                                      </Option>
                                      <Option value="New Construction: 24-36 months">
                                        New Construction: 24-36 months
                                      </Option>
                                      <Option value="Concrete Slab">
                                        Concrete Slab
                                      </Option>
                                      <Option value="Concrete Below Grade">
                                        Concrete Below Grade
                                      </Option>
                                      <Option value="Concrete Suspended Slab: Type Gypsum Etc">
                                        Concrete Suspended Slab: Type Gypsum Etc
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Building Landscape"
                                    name="building_landscape"
                                  >
                                    <Select
                                      onChange={props.handleChange(
                                        'building_landscape'
                                      )}
                                      defaultValue={
                                        props.values.building_landscape
                                          ? props.values.building_landscape
                                          : 'Building Landscape'
                                      }
                                    >
                                      <Option value="Plant Life">
                                        Plant Life
                                      </Option>
                                      <Option value="Trees Bushes">
                                        Trees Bushes
                                      </Option>
                                      <Option value="Vegetation Foliage Trees">
                                        Vegetation Foliage Trees
                                      </Option>
                                      <Option value="Shrubbery Grass">
                                        Shrubbery Grass
                                      </Option>
                                      <Option value="An Automatic Drip Irrigation An Automatic Sprinkler An Automatic Irrigation">
                                        An Automatic Drip Irrigation An
                                        Automatic Sprinkler An Automatic
                                        Irrigation
                                      </Option>
                                      <Option value="Rough Grade Complete">
                                        Rough Grade Complete
                                      </Option>
                                      <Option value="Seeded">Seeded</Option>
                                      <Option value="New Sod">New Sod</Option>
                                      <Option value="Partial Complete">
                                        Partial Complete
                                      </Option>
                                      <Option value="Completed">
                                        Completed
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <div className="customObservationPanel">
                            <Row gutter={16}>
                              <Col xs={24} md={16} xl={16}>
                                <Collapse
                                  defaultActiveKey={
                                    props.open ? props.open : ['6', '7', '8']
                                  }
                                  onChange={props.onChangeCollapse}
                                  expandIcon={({ isActive }) =>
                                    isActive ? (
                                      <MinusOutlined className="custom-icon" />
                                    ) : (
                                      <PlusOutlined className="custom-icon" />
                                    )
                                  }
                                >
                                  <Panel
                                    header="Observations Information"
                                    key={6}
                                  >
                                    <Row gutter={16}>
                                      <Col xs={24} md={24} xl={24}>
                                        <div className="formGroup">
                                        <Form.Item
                                          label="Sending Sample?"
                                          name="sending_samples"
                                              >
                                                {console.warn("tripleToggle")}
                                                <TripleToggle
                                                value={props.values.sending_samples}
                                                onToggle={props.handleChange('sending_samples')}
                                              />
                                        </Form.Item>
                                        </div>
                                        {props.isOpen.sendingSampleField === true && props.values.sending_samples === 'Yes' ? (
                                          <div
                                            id="myDiv2"
                                            className="hidden-section"
                                          >
                                            <Row gutter={16}>
                                              <Col xs={24} md={8} xl={8}>
                                                <div className="formGroup">
                                                  <Form.Item
                                                    label="Shipping Company"
                                                    name="shipping_company"
                                                  >
                                                    <Input
                                                      placeholder="Shipping Company"
                                                      className="formControl"
                                                      onChange={props.handleChange(
                                                        'shipping_company'
                                                      )}
                                                      defaultValue={
                                                        props.values
                                                          .shipping_company
                                                      }
                                                    />
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                              <Col xs={24} md={8} xl={8}>
                                                <div className="formGroup">
                                                  <Form.Item
                                                    label="Tracking Number"
                                                    name="tracking_number"
                                                  >
                                                    <Input
                                                      placeholder="Tracking Number"
                                                      className="formControl"
                                                      onChange={props.handleChange(
                                                        'tracking_number'
                                                      )}
                                                      defaultValue={
                                                        props.values
                                                          .tracking_number
                                                      }
                                                    />
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                              <Col xs={24} md={8} xl={8}>
                                                <div className="formGroup">
                                                  <Form.Item
                                                    label="Date Shipped"
                                                    name="date_shipped"
                                                  >
                                                    <Input.Group
                                                      compact
                                                      className="mt-0"
                                                    >
                                                      <Input
                                                        placeholder="Date Shipped"
                                                        className="formControl"
                                                        onChange={(e) =>
                                                          props.handleDateformate(
                                                            e,
                                                            'date_shipped',
                                                            'text'
                                                          )
                                                        }
                                                        value={
                                                          props.values
                                                            .date_shipped
                                                        }
                                                        onFocus={() =>
                                                          props.setCalOpen3(
                                                            !props.calOpen3
                                                          )
                                                        }
                                                        onKeyUp={() =>
                                                          props.setCalOpen3(
                                                            false
                                                          )
                                                        }
                                                        onBlur={() =>
                                                          props.setCalOpen3(
                                                            false
                                                          )
                                                        }
                                                      />

                                                      <Button
                                                        className="btnAddOn"
                                                        icon={
                                                          <CalendarOutlined />
                                                        }
                                                        onClick={() =>
                                                          props.setCalOpen3(
                                                            !props.calOpen3
                                                          )
                                                        }
                                                      />
                                                    </Input.Group>
                                                    <DatePicker
                                                      className="formControl dateHide"
                                                      format={DateFormat}
                                                      open={props.calOpen3}
                                                      style={{
                                                        visibility: 'hidden',
                                                        width: 0,
                                                      }}
                                                      onChange={(e) =>
                                                        props.handleDateformate(
                                                          e,
                                                          'date_shipped',
                                                          'date'
                                                        )
                                                      }
                                                    />
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <Row className="claim-history-section">
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Claim History and Comments of Involved Parties"
                                              name="claim_his_togg"
                                            >
                                              <Switch
                                                defaultChecked={
                                                  props.isOpen
                                                    .claimHistoryField ===
                                                    true &&
                                                  props.values
                                                    .claim_his_togg === true
                                                    ? true
                                                    : false
                                                }
                                                onChange={props.handleChange(
                                                  'claim_his_togg'
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                          {props.isOpen.claimHistoryField ===
                                            true &&
                                          props.values.claim_his_togg ===
                                            true ? (
                                            <div className="text-editor-wrapper">
                                              <SunEditor
                                                onFocus={(event) => {
                                                  getSuggestion(
                                                    'claim-history',
                                                    'Quick Report'
                                                  );
                                                }}
                                                setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                setOptions={SunEditorOpts}
                                                onImageUploadBefore={
                                                  onImageUploadBefore
                                                }
                                                setContents={editorState1}
                                                onChange={onEditorStateChange1}
                                              />
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Row>
                                        <Row className="physcial-description-section">
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Observations or Physical Description and Comments"
                                              name="observe_togg"
                                            >
                                              <Switch
                                                defaultChecked={
                                                  props.isOpen
                                                    .observationField ===
                                                    true &&
                                                  props.values.observe_togg ===
                                                    true
                                                    ? true
                                                    : false
                                                }
                                                onChange={props.handleChange(
                                                  'observe_togg'
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                          {props.isOpen.observationField ===
                                            true &&
                                          props.values.observe_togg === true ? (
                                            <div className="text-editor-wrapper">
                                              <SunEditor
                                                onFocus={(event) => {
                                                  getSuggestion(
                                                    'observations_and_comments',
                                                    'Quick Report'
                                                  );
                                                }}
                                                setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                setOptions={SunEditorOpts}
                                                onImageUploadBefore={
                                                  onImageUploadBefore
                                                }
                                                setContents={editorState2}
                                                onChange={onEditorStateChange2}
                                              />
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Row>
                                        <Row className="field-test-section">
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Applicable Field Tests"
                                              name="app_field_togg"
                                            >
                                              <Switch
                                                defaultChecked={
                                                  props.isOpen
                                                    .applicableField === true &&
                                                  props.values
                                                    .app_field_togg === true
                                                    ? true
                                                    : false
                                                }
                                                onChange={props.handleChange(
                                                  'app_field_togg'
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                          {props.isOpen.applicableField ===
                                            true &&
                                          props.values.app_field_togg ===
                                            true ? (
                                            <div className="text-editor-wrapper">
                                              <SunEditor
                                                onFocus={(event) => {
                                                  getSuggestion(
                                                    'applicable-field-tests',
                                                    'Quick Report'
                                                  );
                                                }}
                                                setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                setOptions={SunEditorOpts}
                                                onImageUploadBefore={
                                                  onImageUploadBefore
                                                }
                                                setContents={editorState3}
                                                onChange={onEditorStateChange3}
                                              />
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Row>
                                        <Row className="industry-section">
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Industry Standards/Manufacturers Standards/Citations and Resources Cited"
                                              name="indus_stand_togg"
                                            >
                                              <Switch
                                                defaultChecked={
                                                  props.isOpen.industryField ===
                                                    true &&
                                                  props.values
                                                    .indus_stand_togg === true
                                                    ? true
                                                    : false
                                                }
                                                onChange={props.handleChange(
                                                  'indus_stand_togg'
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                          {props.isOpen.industryField ===
                                            true &&
                                          props.values.indus_stand_togg ===
                                            true ? (
                                            <div className="text-editor-wrapper">
                                              <SunEditor
                                                onFocus={(event) => {
                                                  getSuggestion(
                                                    'industry_standards',
                                                    'Quick Report'
                                                  );
                                                }}
                                                setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                setOptions={SunEditorOpts}
                                                onImageUploadBefore={
                                                  onImageUploadBefore
                                                }
                                                setContents={editorState4}
                                                onChange={onEditorStateChange4}
                                              />
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Row>
                                        
                                        <Collapse
                                          defaultActiveKey={
                                            props.open ? props.open : ['10']
                                          }
                                          onChange={props.onChangeCollapse}
                                          expandIcon={({ isActive }) =>
                                            isActive ? (
                                              <MinusOutlined className="custom-icon" />
                                            ) : (
                                              <PlusOutlined className="custom-icon" />
                                            )
                                          }
                                        >
                                            <Panel header="Manufacturing issues, Site related issues, Performance related issues and Installation related issues" key={10}>
                                                <Row className="issues-panel-section">
                                                  <div className="formGroup">
                                                    <Form.Item
                                                      label="Manufacturing issues"
                                                      name="manufacturing_issue_togg"
                                                    >
                                                      <Switch
                                                        defaultChecked={
                                                          props.isOpen.manuissuesField ===
                                                            true &&
                                                            props.values.manufacturing_issue_togg === true
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={props.handleChange(
                                                          "manufacturing_issue_togg"
                                                        )}
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                  {props.isOpen.manuissuesField === true &&
                                                    props.values.manufacturing_issue_togg === true ? (
                                                    <div className="text-editor-wrapper">
                                                      <SunEditor
                                                        onFocus={(event) => {
                                                          getSuggestion(
                                                            "manufacturing_issue",
                                                            "Hardwood"
                                                          );
                                                        }}
                                                        setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                        setOptions={SunEditorOpts}
                                                        onImageUploadBefore={onImageUploadBefore}
                                                        setContents={editorState10}
                                                        onChange={onEditorStateChange10}
                                                      />
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Row>
                                                <Row className="issues-panel-section">
                                                  <div className="formGroup">
                                                    <Form.Item
                                                      label="Site related issues"
                                                      name="site_rel_issue_togg"
                                                    >
                                                      <Switch
                                                        defaultChecked={
                                                          props.isOpen.siteissuesField ===
                                                            true &&
                                                            props.values.site_rel_issue_togg === true
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={props.handleChange(
                                                          "site_rel_issue_togg"
                                                        )}
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                  {props.isOpen.siteissuesField === true &&
                                                    props.values.site_rel_issue_togg === true ? (
                                                    <div className="text-editor-wrapper">
                                                      <SunEditor
                                                        onFocus={(event) => {
                                                          getSuggestion(
                                                            "site_rel_issue",
                                                            "Hardwood"
                                                          );
                                                        }}
                                                        setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                        setOptions={SunEditorOpts}
                                                        onImageUploadBefore={onImageUploadBefore}
                                                        setContents={editorState11}
                                                        onChange={onEditorStateChange11}
                                                      />
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Row>
                                                <Row className="issues-panel-section">
                                                  <div className="formGroup">
                                                    <Form.Item
                                                      label="Performance related issues"
                                                      name="perfo_rel_issue_togg"
                                                    >
                                                      <Switch
                                                        defaultChecked={
                                                          props.isOpen.perfoissuesField ===
                                                            true &&
                                                            props.values.perfo_rel_issue_togg === true
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={props.handleChange(
                                                          "perfo_rel_issue_togg"
                                                        )}
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                  {props.isOpen.perfoissuesField === true &&
                                                    props.values.perfo_rel_issue_togg === true ? (
                                                    <div className="text-editor-wrapper">
                                                      <SunEditor
                                                        onFocus={(event) => {
                                                          getSuggestion(
                                                            "perfo_rel_issue",
                                                            "Hardwood"
                                                          );
                                                        }}
                                                        setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                        setOptions={SunEditorOpts}
                                                        onImageUploadBefore={onImageUploadBefore}
                                                        setContents={editorState12}
                                                        onChange={onEditorStateChange12}
                                                      />
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Row>
                                                <Row className="issues-panel-section">
                                                  <div className="formGroup">
                                                    <Form.Item
                                                      label="Installation related issues"
                                                      name="insta_rel_issue_togg"
                                                    >
                                                      <Switch
                                                        defaultChecked={
                                                          props.isOpen.instaissuesField ===
                                                            true &&
                                                            props.values.insta_rel_issue_togg === true
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={props.handleChange(
                                                          "insta_rel_issue_togg"
                                                        )}
                                                      />
                                                    </Form.Item>
                                                  </div>
                                                  {props.isOpen.instaissuesField === true &&
                                                    props.values.insta_rel_issue_togg === true ? (
                                                    <div className="text-editor-wrapper">
                                                      <SunEditor
                                                        onFocus={(event) => {
                                                          getSuggestion(
                                                            "insta_rel_issue",
                                                            "Hardwood"
                                                          );
                                                        }}
                                                        setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                        setOptions={SunEditorOpts}
                                                        onImageUploadBefore={onImageUploadBefore}
                                                        setContents={editorState13}
                                                        onChange={onEditorStateChange13}
                                                      />
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                        <Row className="conclusion-section">
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Conclusion"
                                              name="conc_togg"
                                            >
                                              <Switch
                                                defaultChecked={
                                                  props.isOpen
                                                    .conclusionField === true &&
                                                  props.values.conc_togg ===
                                                    true
                                                    ? true
                                                    : false
                                                }
                                                onChange={props.handleChange(
                                                  'conc_togg'
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                          {props.isOpen.conclusionField ===
                                            true &&
                                          props.values.conc_togg === true ? (
                                            <div className="text-editor-wrapper">
                                              <SunEditor
                                                onFocus={(event) => {
                                                  getSuggestion(
                                                    'conclusion',
                                                    'Quick Report'
                                                  );
                                                }}
                                                setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                setOptions={SunEditorOpts}
                                                onImageUploadBefore={
                                                  onImageUploadBefore
                                                }
                                                setContents={editorState5}
                                                onChange={onEditorStateChange5}
                                              />
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Row>
                                        <Row className="cause-section">
                                          <div className="formGroup">
                                            <Form.Item
                                              label="Cause"
                                              name="cause_togg"
                                            >
                                              <Switch
                                                defaultChecked={
                                                  props.values.cause_togg ===
                                                    true ||
                                                  props.isOpen.causeField ===
                                                    true
                                                    ? true
                                                    : false
                                                }
                                                onChange={props.handleChange(
                                                  'cause_togg'
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                          {props.isOpen.causeField === true ||
                                          props.values.cause_togg === true ? (
                                            <div className="text-editor-wrapper">
                                              <SunEditor
                                                onFocus={(event) => {
                                                  getSuggestion(
                                                    'cause',
                                                    'Quick Report'
                                                  );
                                                }}
                                                setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                setOptions={SunEditorOpts}
                                                onImageUploadBefore={
                                                  onImageUploadBefore
                                                }
                                                setContents={editorState6}
                                                onChange={onEditorStateChange6}
                                              />
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Panel>
                                  
                                  <Panel
                                    header="Confidential Information"
                                    key={7}
                                    className="confidential-panel"
                                  >
                                    <Col xs={24} md={24} xl={24}>
                                      <div className="formGroup">
                                        <Form.Item
                                          label="Has Confidential Comments?"
                                          name="confi"
                                          valuePropName="checked"
                                        >
                                          <Switch
                                            defaultChecked={
                                              props.values.confi === true &&
                                              props.isOpen.confidentialField ===
                                                true
                                                ? true
                                                : false
                                            }
                                            onChange={props.handleChange(
                                              'confi'
                                            )}
                                          />
                                        </Form.Item>
                                      </div>
                                      {props.isOpen.confidentialField ===
                                        true && props.values.confi === true ? (
                                        <div className="text-editor-wrapper">
                                          <SunEditor
                                            onFocus={(event) => {
                                              getSuggestion(
                                                'confidential-information',
                                                'Quick Report'
                                              );
                                            }}
                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                            setOptions={SunEditorOpts}
                                            onImageUploadBefore={
                                              onImageUploadBefore
                                            }
                                            setContents={editorState7}
                                            onChange={onEditorStateChange7}
                                          />
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </Col>
                                  </Panel>
                                  <Panel header="Footer" key={8}>
                                    <Col xs={24} md={24} xl={24}>
                                      <Row
                                        gutter={16}
                                        className="footer-singnature-section"
                                      >
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Upload Signature"
                                            name="upload_sign_toggle"
                                          >
                                            <Switch
                                              defaultChecked={
                                                props.values
                                                  .upload_sign_toggle ===
                                                  true ||
                                                props.isOpen.signatureField ===
                                                  true
                                                  ? true
                                                  : false
                                              }
                                              onChange={props.handleChange(
                                                'upload_sign_toggle'
                                              )}
                                            />
                                          </Form.Item>
                                        </div>
                                        {props.isOpen.signatureField === true ||
                                        props.values.upload_sign_toggle ===
                                          true ? (
                                          <>
                                            <div className="image-wrapper">
                                              <Form.Item
                                                name="upload"
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                              >
                                                <Upload
                                                  name="avatar"
                                                  listType="picture-card"
                                                  className="avatar-uploader"
                                                  showUploadList={false}
                                                  customRequest={uploadImage}
                                                  beforeUpload={beforeUpload}
                                                  onChange={handleChangeImage}
                                                >
                                                  {imageUrl ? (
                                                    <img
                                                      src={imageUrl}
                                                      alt="avatar"
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                    />
                                                  ) : (
                                                    uploadButton
                                                  )}
                                                </Upload>
                                              </Form.Item>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </Row>
                                    </Col>
                                    <Col xs={24} md={24} xl={24}>
                                      <Row gutter={16}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Footer text"
                                            name="footer_text_info"
                                            valuePropName="checked"
                                          >
                                            <Switch
                                              defaultChecked={
                                                props.values
                                                  .footer_text_info === true &&
                                                props.isOpen.footerField ===
                                                  true
                                                  ? true
                                                  : false
                                              }
                                              onChange={props.handleChange(
                                                'footer_text_info'
                                              )}
                                            />
                                          </Form.Item>
                                        </div>
                                        {props.isOpen.footerField === true &&
                                        props.values.footer_text_info ===
                                          true ? (
                                          <div className="text-editor-wrapper">
                                            <SunEditor
                                              onFocus={(event) => {
                                                getSuggestion(
                                                  'footer-text',
                                                  'Quick Report'
                                                );
                                              }}
                                              setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                              setOptions={SunEditorOpts}
                                              onImageUploadBefore={
                                                onImageUploadBefore
                                              }
                                              setContents={editorState8}
                                              onChange={onEditorStateChange8}
                                            />
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </Row>
                                    </Col>
                                  </Panel>
                                </Collapse>
                              </Col>
                              <Col
                                xs={24}
                                md={8}
                                xl={8}
                                className="sticySidebar"
                              >
                                <Card className="custom-sidecard">
                                  <h5 className="formGroupTitle1">
                                    Industry Standards and Citations
                                  </h5>
                                  <div className="searchWrapper">
                                    <Input
                                      prefix={<SearchOutlined />}
                                      placeholder="Search for names.."
                                      className="formControl"
                                      value={search}
                                      onChange={(e) =>
                                        setSearch(e.target.value)
                                      }
                                    />
                                    <p>
                                      Your citations will start appearing here
                                      when you will start writing under
                                      observation or industry standards.
                                    </p>
                                    {/* <Spin size="large" spinning={loading}> */}
                                    {/* <SkeletonIndustryStandard  spinning={loading} /> */}
                                    {loading ? (
                                      <div className="spinDiv">
                                        <SkeletonIndustryStandard />
                                      </div>
                                    ) : (
                                      <div className="innerCardWrap">
                                        {filterStandards && search
                                          ? filterStandards?.map(
                                              (item, index) => {
                                                return (
                                                  <Card
                                                    className="industryCard mb-2 sidebarCard"
                                                    key={index}
                                                  >
                                                    <div className="industryCardBody">
                                                      <div className="cardTopSection">
                                                        <div className="headerSec">
                                                          <h3
                                                            className="industryTitle"
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                item.title,
                                                            }}
                                                          ></h3>
                                                          <p
                                                            className="industryDesc"
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                item.excerpt,
                                                            }}
                                                          ></p>
                                                        </div>
                                                      </div>
                                                      <div className="contentSec">
                                                        <div className="btnGroup">
                                                          <Tooltip title="Copy/Paste">
                                                            <Button
                                                              type="text"
                                                              id={item.id}
                                                              className="indstryBtn viewBtn"
                                                              onClick={() =>
                                                                sendTextToEditor(
                                                                  item.description,
                                                                  item.label
                                                                )
                                                              }
                                                            >
                                                              <CopyOutlined />
                                                            </Button>
                                                          </Tooltip>
                                                          <Tooltip title="Edit">
                                                            <Button
                                                              type="text"
                                                              id={item.id}
                                                              className="indstryBtn"
                                                            >
                                                              <FormOutlined />
                                                            </Button>
                                                          </Tooltip>
                                                          <Tooltip title="View More">
                                                            <Button
                                                              type="text"
                                                              id={item.id}
                                                              className="indstryBtn"
                                                              onClick={() =>
                                                                handleClick(
                                                                  item.id
                                                                )
                                                              }
                                                            >
                                                              <EyeOutlined />
                                                            </Button>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              }
                                            )
                                          : sidebarState?.map((item, index) => {
                                              return (
                                                <Card
                                                  className="industryCard mb-2 sidebarCard"
                                                  key={index}
                                                >
                                                  <div className="industryCardBody">
                                                    <div className="cardTopSection">
                                                      <div className="headerSec">
                                                        <h3
                                                          className="industryTitle"
                                                          dangerouslySetInnerHTML={{
                                                            __html: item.title,
                                                          }}
                                                        ></h3>
                                                        <p
                                                          className="industryDesc"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.excerpt,
                                                          }}
                                                        ></p>
                                                      </div>
                                                    </div>
                                                    <div className="contentSec">
                                                      <div className="btnGroup">
                                                        <Tooltip title="Copy/Paste">
                                                          <Button
                                                            type="text"
                                                            id={item.id}
                                                            className="indstryBtn viewBtn"
                                                            onClick={() =>
                                                              sendTextToEditor(
                                                                item.description,
                                                                item.label
                                                              )
                                                            }
                                                          >
                                                            <CopyOutlined />
                                                          </Button>
                                                        </Tooltip>

                                                        <Tooltip title="View More">
                                                          <Button
                                                            type="text"
                                                            id={item.id}
                                                            className="indstryBtn"
                                                            onClick={() =>
                                                              handleClick(
                                                                item.id
                                                              )
                                                            }
                                                          >
                                                            <EyeOutlined />
                                                          </Button>
                                                        </Tooltip>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Card>
                                              );
                                            })}
                                      </div>
                                    )}
                                    {/* </Spin> */}
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </Collapse>
                        <div className="attachmentSection">
                          <div className="attachmentHead">
                            <h4 className="formGroupTitle">Add Attachements</h4>
                          </div>
                          <Row className="custom-dropzone-row" gutter={16}>
                            <Upload1
                              takefileList={takefileList}
                              values={props.values}
                              setLoadings = {props.setLoadings}
                            ></Upload1>
                          </Row>
                        </div>
                        <div className="attachmentSection">
                          <div className="CertificationHead">
                            <h4 className="formGroupTitle">Certification</h4>
                          </div>
                          {certificationLoader ? (
                            <div className="spinDiv fullHeight">
                              <Spin size="large" />
                            </div>
                          ) : (
                            <Row className="my-2 footerRow" gutter={6}>
                              {Object.keys(footerLogo).map((keyName, index) => (
                                <Form.Item
                                  name={[`footerLogo`, index]}
                                  key={index}
                                >
                                  <Col className="col-auto h-100" key={index}>
                                    <div className="logoDiv">
                                      <input
                                        type="checkbox"
                                        id={footerLogo[keyName].logo_id}
                                        name="logoSelect[]"
                                        defaultChecked={selectedlogs?.includes(
                                          footerLogo[
                                            keyName
                                          ].logo_id?.toString()
                                        )}
                                        onChange={props.handleChange(
                                          'footerLogo'
                                        )}
                                        value={[footerLogo[keyName].logo_id]}
                                      />

                                      <label
                                        htmlFor={footerLogo[keyName].logo_id}
                                      >
                                        <Image
                                          src={footerLogo[keyName].logo_url}
                                          className="img-fluid"
                                          alt="logos"
                                          preview={false}
                                        />
                                      </label>
                                    </div>
                                  </Col>
                                </Form.Item>
                              ))}
                            </Row>
                          )}
                        </div>

                        <div className="attachmentSection">
                          <div className="CertificationHead">
                            <h4 className="formGroupTitle">Private Notes</h4>
                          </div>
                          <div className="p-3">
                            <div className="formGroup">
                              <Form.Item
                                label="Private Notes"
                                name="private_notes_togg"
                              >
                                <Switch
                                  defaultChecked={
                                    props.values.private_notes_togg === true &&
                                    props.isOpen.notesField === true
                                      ? true
                                      : false
                                  }
                                  onChange={props.handleChange(
                                    'private_notes_togg'
                                  )}
                                />
                              </Form.Item>
                            </div>
                            {props.values.private_notes_togg === true &&
                            props.isOpen.notesField === true ? (
                              <div className="text-editor-wrapper">
                                <SunEditor
                                  onFocus={(event) => {
                                    getSuggestion(
                                      'private_notes',
                                      'Quick Report'
                                    );
                                  }}
                                  setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                  setOptions={SunEditorOpts}
                                  onImageUploadBefore={onImageUploadBefore}
                                  setContents={editorState9}
                                  onChange={onEditorStateChange9}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <Row span={24} className="bottomBtnRow">
                        <Button
                          className="submitBtn"
                          onClick={() => props.buttonSubmit()}
                          loading={props.loadings}
                        >
                          Save And Continue To Preview Report
                        </Button>
                      </Row>
                    </div>
                  </div>
                </Card>
              </Form>
            </div>
          </div>
          // </Spin>
        )}
        <div className="saveDraftBtn">
          <Button
            className="float floatBtn sc_button_hover_fade"
            id="draft"
            name="update_button"
            onClick={() => props.saveDraft()}
            disabled = {props.loadings}
          >
            Save Draft
          </Button>
        </div>
      </div>
      <Modal
        title={industryContent.title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{ __html: industryContent.description }}
            ></p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuickReportForm;
