import Http from '../Http';
import { INSPECTION_URL } from '../Helper';

export function getInspection() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'insp_requests/' )
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getComments(req_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'getcomments/' + req_id)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function updateStatus(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "updatestatus", values)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

export function updateStatusClaim(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "updatestatus_manufpanel", values)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function deletInspection(req_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + "delete_inspection", req_id)
        .then((res) => {
          console.log("Res", res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getLimitedReq(req_id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + "get_limited_req/" + req_id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}