import React from 'react';
import {
  Row,
  Col,
  Button,
} from "antd";
import PagesHeader from '../../components/PageHeader';
import ClientsData from '../../datatables/clientsDatatable';
import ButtonBar from '../../components/ButtonNavigation';
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';

const AllClients = () => {
 let navigate = useNavigate();
  
  const handleClick = () => {
    navigate("/add-new-client")
  };

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader
          title='All Clients'
        />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            <Col xs={24} md={6}>
              <Button
                type='success'
                className="btn inspectionAdd" 
                onClick={() => handleClick()}
                icon={<PlusOutlined />}
              >
                Add New Client
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
            <ClientsData />
        </div>
      </div>
    </div>
  );
};

export default AllClients;
