import React from 'react';
import PagesHeader from '../../components/PageHeader';
import FieldServicesClaims from '../../datatables/Mohawk/FieldServicesDatatable';

const FieldServices = () => {
  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader
          title='Field Services'
        />
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
            <FieldServicesClaims />
        </div>
      </div>
    </div>
  );
};

export default FieldServices;
