import Http from '../Http';
import { INSPECTION_URL } from "../Helper";



export function createNwfaReport(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + "create_nwfa_sand_finish_report", values)
          .then((res) => {
            console.log("Res", res);
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
  
  
  export function updateNwfaReport(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + "update_nwfa_sand_finish_report", values)
          .then((res) => {
            console.log("Res", res);
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
    

//   Nwfa Sand Finsih get Report 
  export function getSingleNwfaReport(report_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'get_nwfa_sand_finish_report/'+ report_id )
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            console.log(err)
            return reject(err);
          });
      });
  }
//  create Nwfa Installation Service
  export function createNwfaInstReport(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + "create_nwfa_installation_report", values)
          .then((res) => {
            console.log("Res", res);
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
  
  // update Nwfa installation Service
  export function updateNwfaInstReport(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + "update_nwfa_installation_report", values)
          .then((res) => {
            console.log("Res", res);
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
    

//   Nwfa Installtion get Report 
  export function getSingleNwfaInstaReport(report_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'get_nwfa_installation_report/'+ report_id )
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            console.log(err)
            return reject(err);
          });
      });
  }