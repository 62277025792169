import React, { useState, useEffect } from 'react';
import PageHeader from '../../../components/PageHeader';
import FirstStep from '../NwfaSandFinish/FirstStep';
import SecondStep from '../NwfaSandFinish/SecondStep';
import ThirdStep from '../NwfaSandFinish/ThirdStep';
import FourthStep from '../NwfaSandFinish/FourthStep';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Form, Button, Row, Col, Skeleton, notification } from 'antd';
import moment from 'moment';
// import * as inspectorService from "../../../services/InspectorDataService";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../store/actions/inspection';
import { useNavigate } from 'react-router-dom';
import * as createClientService from '../../../services/AllClients';
import * as getClientdropService from '../../../services/getClientdrop';
import * as NwfaSandFinishService from '../../../services/createNwfaService';
import { CheckCircleOutlined } from '@ant-design/icons';
import ReportSpin from '../../../components/ReportSpinner';

const UpdateNwfaSandFinish = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const Data = useSelector((state) => state.Inspection.reqId);
  const [vals, setVals] = useState({});
  const [clientDropdown, setclientDropdown] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [SpinnerReport, setSpinnerReport] = useState(false);
  const [calOpen, setCalOpen] = useState(false);
  const [calOpen1, setCalOpen1] = useState(false);
  const [calOpen2, setCalOpen2] = useState(false);
  const [calOpen3, setCalOpen3] = useState(false);
  const [calOpen4, setCalOpen4] = useState(false);
  const [calOpen5, setCalOpen5] = useState(false);
  const [calOpen6, setCalOpen6] = useState(false);
  const [calOpen7, setCalOpen7] = useState(false);
  const [calOpen8, setCalOpen8] = useState(false);
  const [calOpen9, setCalOpen9] = useState(false);
  const [calOpen10, setCalOpen10] = useState(false);
  const [calOpen11, setCalOpen11] = useState(false);
  const [calOpen12, setCalOpen12] = useState(false);
  const [calOpen17, setCalOpen17] = useState(false);
  const [calOpen18, setCalOpen18] = useState(false);
  const [calOpen19, setCalOpen19] = useState(false);
  const [calOpen20, setCalOpen20] = useState(false);
  const [calOpen21, setCalOpen21] = useState(false);
  const [calOpen22, setCalOpen22] = useState(false);
  const [block, setBlock] = useState(false);
  const [size, setSize] = useState('Large');
  const [avatarShape, setAvatarShape] = useState('circle');
  const [footerLogo, setFooterLogo] = useState([]);
  const [bathArea, setBathArea] = useState([
    { bath_area: '', bath_area_unit: '' },
  ]);
  const [masterBedroomArea, setMasterBedroomArea] = useState([
    { master_bedroom_area: '', master_bedroom_area_unit: '' },
  ]);
  const [values, setValues] = useState({
    large_wind_flr_fc: '',
    bathArea: {},
    masterBedroomArea: {},
    apply_industry_stndr: '',
    conclusion_cause: '',
    add_testing_information: '',
    date_st_coat_finish: '',
    finish_nd_coat: '',
    general_contractor_name: '',
    general_contractor_street: '',
    general_contractor_address: '',
    general_contractor_city: '',
    general_contractor_state: '',
    general_contractor_country: '',
    general_contractor_zip: '',
    general_contractor_contact_number: '',
    general_contractor_email: '',
    mois_con_of_floor: '',
    mtr_mthod_used: '',
    belt_drum_sander: '',
    belt_drum_grit_seq: '',
    belt_drum_abrasive_used: '',
    edger: '',
    edger_grit_seq: '',
    edger_abrasive_used: '',
    orbi_sander: '',
    orbi_sander_grit_seq: '',
    orbi_sander_abrasive_used: '',
    buffer: '',
    buffer_grit_seq: '',
    buffer_abrasive_used: '',
    hard_plate_multi_head: '',
    hard_plate_grit_seq: '',
    abrasive_abrasive_used: '',
    planetary_sander: '',
    planetary_sander_grit_seq: '',
    plant_abrasive_used: '',
    oth_sanders: '',
    oth_sanders_grit_seq: '',
    oth_sanders_abrasive_used: '',
    dust_contai: '',
    dateof_appli: '',
    nof_coats: '',
    finish_products: '',
    finish_color: '',
    q_used: '',
    covera_rat: '',
    app_m_tool: '',
    tot_sqare_footage: '',
    Stain_color_type: '',
    method_dust_contai: '',
    h_bi_ins_date: '',
    h_bui_occupied: '',
    building_type: '',
    building_style: '',
    claim_number: '',
    inspection_name: '',
    inspection_cp: '',
    inspection_date: '',
    comm_party_drop: '',
    commisioning_name: '',
    commisioning_party_street: '',
    commisioning_party_address: '',
    commisioning_party_city: '',
    commisioning_party_state: '',
    commisioning_party_country: '',
    commisioning_party_zip: '',
    commisioning_party_contact_number: '',
    commisioning_party_email: '',
    homeowner_Name: '',
    homeowner_street: '',
    homeowner_address: '',
    homeowner_city: '',
    homeowner_state: '',
    homeowne_country: '',
    homeowner_zip: '',
    homeowner_contact_number: '',
    homeowner_email: '',
    flooring_contractor_Name: '',
    flooring_contractor_street: '',
    flooring_contractor_address: '',
    flooring_contractor_city: '',
    flooring_contractor_state: '',
    flooring_contractor_country: '',
    flooring_contractor_zip: '',
    flooring_contractor_contact_number: '',
    flooring_contractor_email: '',
    manufacturer_name: '',
    manufacturer_street: '',
    manufacturer_address: '',
    manufacturer_city: '',
    manufacturer_state: '',
    manufacturer_country: '',
    manufacturer_zip: '',
    manufacturer_contact_info: '',
    manufacturer_title: '',
    manufacturer_contact_number: '',
    manufacturer_email: '',
    retailer_name: '',
    retailer_street: '',
    retailer_address: '',
    retailer_city: '',
    retailer_state: '',
    retailer_country: '',
    retailer_zip: '',
    retailer_contact_info: '',
    retailer_title: '',
    retailer_contact_number: '',
    retailer_email: '',
    jobsite_date: '',
    jobsite_time: '',
    property_dropdown: '',
    build_type_other: '',
    other_building_style: '',
    jobsite__age_building: '',
    h_bui_cons: '',
    closing_date: '',
    moving_date: '',
    operational_date: '',
    adults: '',
    childrens: '',
    pets: '',
    concerns: '',
    date_of_sanding: '',
    date_completion: '',
    date_occupied: '',
    date_completion_first: '',
    date_reported: '',
    clean_product: '',
    reported_to: '',
    how_often: '',
    unusual_events: '',
    temp_during_ins: '',
    rh_during_ins: '',
    hvac_oper_during_storage: '',
    hvac_oper_during_install: '',
    float_comm_party_comm: '',
    float_end_user_comm: '',
    float_floor_con_comm: '',
    float_oth_inv_comm: '',
    concern_con_exi_flr: '',
    concern_spec: '',
    concern_spec_drop: '',
    flooringtype: '',
    concern_add_inf_flr_con: '',
    totalsqft_per_cont: '',
    powderBath: '',
    total_sqft: '',
    per_flo_aff: '',
    oth: '',
    masterbedroom: '',
    traff_use: '',
    date_last_serv: '',
    data_logger: '',
    other_trades: '',
    rooms_entry: '',
    rooms_formal: '',
    rooms_kit: '',
    rooms_study: '',
    rooms_hall: '',
    rooms_library: '',
    rooms_great_room: '',
    rooms_powder_bath: '',
    rooms_living_room: '',
    rooms_dining_roo: '',
    rooms_nook: '',
    rooms_part: '',
    crawl_space_evelu: '',
    other_rooms: '',
    hvac_sys_operating: '',
    avr_mois_cont: '',
    avr_floor: '',
    avr_top: '',
    avr_back: '',
    surface_temp: '',
    avr_name: '',
    avr_model: '',
    avr_meter: '',
    avr_num_reading: '',
    avr_hight_read: '',
    avr_low_read: '',
    avr_loca_high: '',
    com_readings: '',
    oth_mois_test: '',
    hvac_tsti: '',
    Temp_hvac: '',
    m_use: '',
    rel_humidity: '',
    th_control_settings: '',
    oth_moi_test_conducted: '',
    concern_con_exi_flr_other: '',
    comm_party_drop_other: '',
    concern_spec_drop_width: '',
    footer_text_info: '',
    private_notes_togg: '',
    upload_sign_toggle: true,
    concern_spec_drop_other: '',
    process_system: '',
    manufac_req: '',
    manu_co_rate: '',
    manu_applica_method: '',
    manu_day_time_req: '',
    selaer_type: '',
    production_date: '',
    sealer_Product: '',
    sealer_totalsquare: '',
    product_q_used: '',
    produ_app_m_tool: '',
    prod_covera_rat: '',
    prod_nof_coats: '',
    product_dateof_appli: '',
    product_manu_co_rate: '',
    prod_manu_applica_method: '',
    prod_manufac_req: '',
    prod_manu_day_time_req: '',
    finish_type: '',
    finish_Product: '',
    Sheen_finish: '',
    finish_production_date: '',
    finish_totalsquare: '',
    finsih_q_used: '',
    finish_prod_covera_rat: '',
    finish_app_m_tool: '',
    finish_nof_coats: '',
    finish_dateof_appli: '',
    finish_manufac_req: '',
    finish_manu_co_rate: '',
    finish_manu_applica_method: '',
    fini_day_time_req: '',
    other_coat: '',
    finish_rd_coat: '',
    lot_number_by_sealer: '',
    lot_number_by_fini: '',
    pre_fini_floor: '',
    type_of_finish_exis_flr: '',
    special_powder: '',
    special_powder_other: '',
    location_of_powder_source: '',
    Traffic_use: '',
    airFlow_obstacles: '',
    other_trades_schedules_prior: '',
    heating_system_type: '',
    heating_system_other: '',
    heating_system: '',
    type_cooling_sys: '',
    ac_type: '',
    air_on_other: '',
    window_coverings: '',
    tinted_glass_uv_protections: '',
    any_special_or_unique_use: '',
    summ_phy_descri: '',
    moist_cont_floor: '',
    meter_meth_used: '',
    programmable: '',
    operating: '',
    humidification: '',
    h_dehu_syst: '',
    dehumidification: '',
    thermostat_control_settings: '',
    dehum_meter_meth_used: '',
    product_process_system: '',
    process_system_finishtype: '',
  });
  const [isOpen, setIsOpen] = useState({
    humidificationField: false,
    humi_dehum_sysField: false,
    dehumidificationField: false,
    acField: false,
    heatingField: false,
    special_powderField: false,
    finish_typeField: false,
    finish_manufac_reqField: false,
    prod_manufac_reqField: false,
    manufac_reqField: false,
    selaer_typeField: false,
    Stain_color_typeField: false,
    orbi_sanderField: false,
    belt_drum_sanderField: false,
    edgerField: false,
    bufferField: false,
    hard_plate_multi_headField: false,
    planetary_sander: false,
    oth_sandersField: false,
    buildingOther: false,
    buildingStyleOther: false,
    constructionField: false,
    installationOther: false,
    installationGlue: false,
    installationFloat: false,
    cleanedField: false,
    base_finishField: false,
    type_wood_open: false,
    avr_mois_contField: false,
    Co_s_testField: false,
    hvac_tstiField: false,
    wood_sub_testField: false,
    Mois_req_flo_open: false,
    concern_con_exi_flrField: false,
    comm_party_drop_Field: false,
    concern_spec_dropField: false,
    notesField: false,
    footerField: false,
    signatureField: false,
    concern_spec_drop_otherField: false,
  });
  const queryParams = new URLSearchParams(window.location.search);
  const report_id = queryParams.get('report_id');
  const handleEditor = (valEditor, label) => {
    setValues((l) => ({ ...l, [label]: valEditor }));
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });

    dispatch(Actions.create_report(vals));
  };

  useEffect(() => {
    setLoading(true);
    // fetchInspectorData();
    fetchclientData();
    getReportData();
    //setDefaultPanel();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.warn('unnesseryfield', setBlock, setSize, setAvatarShape);
  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        console.info(res);
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };
  const handleFill = (val) => {
    setValues({ ...values, ...val });
    setVals({ ...values, currentUrl: window.location.href, attachments: [] });

    dispatch(Actions.create_report(vals));
  };
  const getReportData = () => {
    setLoading(true);
    dispatch(NwfaSandFinishService.getSingleNwfaReport(report_id))
      .then((res) => {
        let reportRes = res;
        setActiveStep(
          parseInt(reportRes.activeStep) ? parseInt(reportRes.activeStep) : 0
        );
   

          reportRes.h_bui_occupied =
          reportRes?.h_bui_occupied === 'yes' || reportRes?.h_bui_occupied === 'Yes' ? 'Yes' : reportRes?.h_bui_occupied;
          reportRes.h_bui_cons =
          reportRes?.h_bui_cons === 'yes' || reportRes?.h_bui_cons === 'Yes' ? 'Yes' : reportRes?.h_bui_cons;

          reportRes.hvac_oper_during_storage =
          reportRes?.hvac_oper_during_storage === 'yes' || reportRes?.hvac_oper_during_storage === 'Yes' ? 'Yes' : reportRes?.hvac_oper_during_storage;

          reportRes.dust_contai =
          reportRes?.dust_contai === 'yes' || reportRes?.dust_contai === 'Yes' ? 'Yes' : reportRes?.dust_contai;

          reportRes.orbi_sander =
          reportRes?.orbi_sander === 'yes' || reportRes?.orbi_sander === 'Yes' ? 'Yes' : reportRes?.orbi_sander;
          reportRes.buffer =
          reportRes?.buffer === 'yes' || reportRes?.buffer === 'Yes' ? 'Yes' : reportRes?.buffer;
          reportRes.hard_plate_multi_head =
          reportRes?.hard_plate_multi_head === 'yes' || reportRes?.hard_plate_multi_head === 'Yes' ? 'Yes' : reportRes?.hard_plate_multi_head;
          reportRes.planetary_sander =
          reportRes?.planetary_sander === 'yes' || reportRes?.planetary_sander === 'Yes' ? 'Yes' : reportRes?.planetary_sander;
          reportRes.oth_sanders =
          reportRes?.oth_sanders === 'yes' || reportRes?.oth_sanders === 'Yes' ? 'Yes' : reportRes?.oth_sanders;
          reportRes.edger =
          reportRes?.edger === 'yes' || reportRes?.edger === 'Yes' ? 'Yes' : reportRes?.edger;
          reportRes.belt_drum_sander =
          reportRes?.belt_drum_sander === 'yes' || reportRes?.belt_drum_sander === 'Yes' ? 'Yes' : reportRes?.belt_drum_sander;



          reportRes.selaer_type =
          reportRes?.selaer_type === 'yes' || reportRes?.selaer_type === 'Yes' ? 'Yes' : reportRes?.selaer_type;
          reportRes.prod_manufac_req =
          reportRes?.prod_manufac_req === 'yes' || reportRes?.prod_manufac_req === 'Yes' ? 'Yes' : reportRes?.prod_manufac_req;
          reportRes.manufac_req =
          reportRes?.manufac_req === 'yes' || reportRes?.manufac_req === 'Yes' ? 'Yes' : reportRes?.manufac_req;
          reportRes.finish_type =
          reportRes?.finish_type === 'yes' || reportRes?.finish_type === 'Yes' ? 'Yes' : reportRes?.finish_type;
          reportRes.finish_manufac_req =
          reportRes?.finish_manufac_req === 'yes' || reportRes?.finish_manufac_req === 'Yes' ? 'Yes' : reportRes?.finish_manufac_req;
          reportRes.Stain_color_type =
          reportRes?.Stain_color_type === 'yes' || reportRes?.Stain_color_type === 'Yes' ? 'Yes' : reportRes?.Stain_color_type;
          reportRes.window_coverings =
          reportRes?.window_coverings === 'yes' || reportRes?.window_coverings === 'Yes' ? 'Yes' : reportRes?.window_coverings;
          reportRes.tinted_glass_uv_protections =
          reportRes?.tinted_glass_uv_protections === 'yes' || reportRes?.tinted_glass_uv_protections === 'Yes' ? 'Yes' : reportRes?.tinted_glass_uv_protections;

          reportRes.hvac_sys_operating =
          reportRes?.hvac_sys_operating === 'yes' || reportRes?.hvac_sys_operating === 'Yes' ? 'Yes' : reportRes?.hvac_sys_operating;
          reportRes.hvac_tsti =
          reportRes?.hvac_tsti === 'yes' || reportRes?.hvac_tsti === 'Yes' ? 'Yes' : reportRes?.hvac_tsti;
          reportRes.humidification =
          reportRes?.humidification === 'yes' || reportRes?.humidification === 'Yes' ? 'Yes' : reportRes?.humidification;
          reportRes.h_dehu_syst =
          reportRes?.h_dehu_syst === 'yes' || reportRes?.h_dehu_syst === 'Yes' ? 'Yes' : reportRes?.h_dehu_syst;

          reportRes.data_logger =
          reportRes?.data_logger === 'yes' || reportRes?.data_logger === 'Yes' ? 'Yes' : reportRes?.data_logger;
          reportRes.programmable =
          reportRes?.programmable === 'yes' || reportRes?.programmable === 'Yes' ? 'Yes' : reportRes?.programmable;




        reportRes.upload_sign_toggle =
          reportRes.upload_sign_toggle === 'yes' ? true : false;
        reportRes.footer_text_info =
          reportRes.footer_text_info === 'yes' ? true : false;
        reportRes.private_notes_togg =
          reportRes.private_notes_togg === 'yes' ? true : false;
        reportRes.stepOneTogg = reportRes.stepOneTogg[0]
          ? reportRes.stepOneTogg[0]
          : ['1', '2', '3', '4', '5', '6', '7'];
        reportRes.stepTwoTogg1 = reportRes.stepTwoTogg1[0]
          ? reportRes.stepTwoTogg1[0]
          : ['1', '2', '3', '4'];
        reportRes.stepTwoTogg2 = reportRes.stepTwoTogg2[0]
          ? reportRes.stepTwoTogg2[0]
          : ['1', '2', '3', '4'];
        reportRes.stepThreeTogg = reportRes.stepThreeTogg[0]
          ? reportRes.stepThreeTogg[0]
          : ['1', '2', '3', '4'];
        reportRes.stepFourTogg1 = reportRes.stepFourTogg1[0]
          ? reportRes.stepFourTogg1[0]
          : ['1'];
        reportRes.stepFourTogg2 = reportRes.stepFourTogg2[0]
          ? reportRes.stepFourTogg2[0]
          : ['1', '2', '3', '4'];

        let data1 = [];
        if (reportRes.attachments) {
          Object.keys(reportRes.attachments).forEach(function (key, index) {
            data1.push(reportRes.attachments[key]);
          });
        }
        reportRes.attachments = data1;

        let Allimagedescription = {};
        reportRes.attachments.forEach((attachment) => {
          let indexVal = String(attachment['uid']);
          Allimagedescription[indexVal] = {};
          Allimagedescription[indexVal]['caption'] = attachment['desc'];
        });
        reportRes.Allimagedescription = Allimagedescription;

        //footerlogos
        let newlogos = [];
        // console.warn('response quick reports', typeof(res.selectededlogos))

        if (reportRes.selectededlogos) {
          Object.keys(reportRes.selectededlogos) &&
            Object.keys(reportRes.selectededlogos).forEach(function (
              key,
              index
            ) {
              newlogos.push(reportRes.selectededlogos[key].id?.toString());
            });
          //setselectedLogos(newlogos)
          reportRes.footerLogo = newlogos;
          setFooterLogo(newlogos);
        }
        console.log('serverDatttta', { reportRes });
        setBathArea(reportRes.bathArea);
        setMasterBedroomArea(
          reportRes.masterBedroomArea
            ? reportRes.masterBedroomArea
            : [{ master_bedroom_area: '', master_bedroom_area_unit: '' }]
        );
        setValues(reportRes);
        setLoading(false);
        console.info('Nwfa Sand Report Data Get', reportRes);
      })
      .catch((err) => {
        // alert("error",err);
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const handleChange = (input) => (e) => {
    console.warn('Event on select', input, e);
    console.warn('isOpen', isOpen);
    if (
      input === 'h_dehu_syst' ||
      input === 'humidification' ||
      input === 'dehumidification' ||
      input === 'special_powder' ||
      input === 'type_cooling_sys' ||
      input === 'belt_drum_sander' ||
      input === 'finish_manufac_req' ||
      input === 'finish_type' ||
      input === 'Stain_color_type' ||
      input === 'selaer_type' ||
      input === 'manufac_req' ||
      input === 'edger' ||
      input === 'orbi_sander' ||
      input === 'buffer' ||
      input === 'hard_plate_multi_head' ||
      input === 'planetary_sander' ||
      input === 'oth_sanders' ||
      input === 'h_bui_occupied' ||
      input === 'building_style' ||
      input === 'building_type' ||
      input === 'inst_method' ||
      input === 'concern_floor_thick' ||
      input === 'trowel_used' ||
      input === 'inst_method_type_underlayment_used' ||
      input === 'glue_type' ||
      input === 'float_floor_measured_for_flat' ||
      input === 'float_ins_met' ||
      input === 'flooringtype' ||
      input === 'buil_over' ||
      input === 'wall_cracks_present' ||
      input === 'peeling_paint' ||
      input === 'floor_stains' ||
      input === 'rusty_nails' ||
      input === 'gapped_trim' ||
      input === 'base_finish' ||
      input === 'specify_side_below' ||
      input === 'light_weight_gypsum1' ||
      input === 'Normal_weight_con' ||
      input === 'wood_subfloor_evaluations' ||
      input === 'avr_mois_cont' ||
      input === 'Co_s_test' ||
      input === 'hvac_tsti' ||
      input === 'hvac_sys_operating' ||
      input === 'data_logger' ||
      input === 'programmable' ||
      input === 'concern_con_exi_flr' ||
      input === 'wood_sub_test' ||
      input === 'Mois_req_flo_man' ||
      input === 'type_heating_sys' ||
      input === 'comm_party_drop' ||
      input === 'private_notes_togg' ||
      input === 'upload_sign_toggle' ||
      input === 'footer_text_info' ||
      input === 'prod_manufac_req' ||
      input === 'heating_system' ||
      input === 'hvac_oper_during_storage' ||
      input === 'dust_contai' ||
      input === 'tinted_glass_uv_protections' ||
      input === 'window_coverings' ||
      input === 'h_bui_cons'
    ) {

      if (input === 'hvac_sys_operating') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'data_logger') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'programmable') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'hvac_tsti') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            hvac_tstiField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            hvac_tstiField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'h_dehu_syst') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humi_dehum_sysField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humi_dehum_sysField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'humidification') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            humidificationField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            humidificationField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'dehumidification' && e === true) {
        setIsOpen({
          ...isOpen,
          dehumidificationField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'dehumidification' && e === false) {
        setIsOpen({
          ...isOpen,
          dehumidificationField: false,
        });
        setValues({ ...values, [input]: e });
      }
  
 
      if (input === 'special_powder' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          special_powderField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'special_powder' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          special_powderField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'type_cooling_sys' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          acField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'type_cooling_sys' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          acField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'heating_system' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          heatingField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'heating_system' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          heatingField: false,
        });
        setValues({ ...values, [input]: e });
      }

      
      if (input === 'finish_type') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            finish_typeField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            finish_typeField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'finish_manufac_req') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            finish_manufac_reqField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            finish_manufac_reqField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'selaer_type') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            selaer_typeField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            selaer_typeField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'prod_manufac_req') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            prod_manufac_reqField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            prod_manufac_reqField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }


      if (input === 'Stain_color_type') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            Stain_color_typeField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            Stain_color_typeField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'manufac_req') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            manufac_reqField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            manufac_reqField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }



      if (input === 'tinted_glass_uv_protections') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'window_coverings') {
        setValues({ ...values, [input]: e });
      }

      if (input === 'hvac_oper_during_storage') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'dust_contai') {
        setValues({ ...values, [input]: e });
      }
      if (input === 'belt_drum_sander') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            belt_drum_sanderField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            belt_drum_sanderField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'edger') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            edgerField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            edgerField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }

      if (input === 'orbi_sander') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            orbi_sanderField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            orbi_sanderField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'buffer') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            bufferField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            bufferField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'hard_plate_multi_head') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            hard_plate_multi_headField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            hard_plate_multi_headField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'planetary_sander') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            planetary_sanderField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            planetary_sanderField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'oth_sanders') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            oth_sandersField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            oth_sandersField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }


      if (input === 'flooringtype' && e === 'Plank') {
        setIsOpen({
          ...isOpen,
          concern_spec_dropField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooringtype' && e !== 'Plank') {
        setIsOpen({
          ...isOpen,
          concern_spec_dropField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'flooringtype' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          concern_spec_drop_otherField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'flooringtype' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          concern_spec_drop_otherField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'h_bui_cons') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            constructionField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            constructionField: false,
          });
          setValues({ ...values, [input]: e });
        }
      }
      if (input === 'h_bui_occupied') {
        if (e === 'Yes') {
          setIsOpen({
            ...isOpen,
            buildField: true,
          });
          setValues({ ...values, [input]: e });
        } else if (e === 'HideThisField' || e === 'No') {
          setIsOpen({
            ...isOpen,
            buildField: false,
          });
          setValues({ ...values, [input]: e });
        }
        
      }
      if (input === 'building_type' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_type' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingOther: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'building_style' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'building_style' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          buildingStyleOther: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'base_finish' && e === 'Finished') {
        setIsOpen({
          ...isOpen,
          base_finishField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'base_finish' && e !== 'Finished') {
        setIsOpen({
          ...isOpen,
          base_finishField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'avr_mois_cont' && e === true) {
        setIsOpen({
          ...isOpen,
          avr_mois_contField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'avr_mois_cont' && e === false) {
        setIsOpen({
          ...isOpen,
          avr_mois_contField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'Co_s_test' && e === true) {
        setIsOpen({
          ...isOpen,
          Co_s_testField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'Co_s_test' && e === false) {
        setIsOpen({
          ...isOpen,
          Co_s_testField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'wood_sub_test' && e === true) {
        setIsOpen({
          ...isOpen,
          wood_sub_testField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'wood_sub_test' && e === false) {
        setIsOpen({
          ...isOpen,
          wood_sub_testField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'Mois_req_flo_man' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_flo_open: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'Mois_req_flo_man' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          Mois_req_flo_open: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'concern_con_exi_flr' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          concern_con_exi_flrField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'concern_con_exi_flr' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          concern_con_exi_flrField: false,
        });
        setValues({ ...values, [input]: e });
      }

      if (input === 'comm_party_drop' && e === 'Other') {
        setIsOpen({
          ...isOpen,
          comm_party_drop_Field: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'comm_party_drop' && e !== 'Other') {
        setIsOpen({
          ...isOpen,
          comm_party_drop_Field: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'footer_text_info' && e === true) {
        setIsOpen({
          ...isOpen,
          footerField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'footer_text_info' && e === false) {
        setIsOpen({
          ...isOpen,
          footerField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'private_notes_togg' && e === true) {
        setIsOpen({
          ...isOpen,
          notesField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'private_notes_togg' && e === false) {
        setIsOpen({
          ...isOpen,
          notesField: false,
        });
        setValues({ ...values, [input]: e });
      }
      if (input === 'upload_sign_toggle' && e === true) {
        setIsOpen({
          ...isOpen,
          signatureField: true,
        });
        setValues({ ...values, [input]: e });
      } else if (input === 'upload_sign_toggle' && e === false) {
        setIsOpen({
          ...isOpen,
          signatureField: false,
        });
        setValues({ ...values, [input]: e });
      }
    } else if (
      input === 'time_type' ||
      input === 'time_type1' ||
      input === 'formal_temp' ||
      input === 'measure_type1' ||
      input === 'measure_type2' ||
      input === 'measure_type3' ||
      input === 'measure_type4' ||
      input === 'measure_type5' ||
      input === 'measure_type6' ||
      input === 'measure_type7' ||
      input === 'measure_type8' ||
      input === 'measure_type9' ||
      input === 'measure_type10' ||
      input === 'measure_type11' ||
      input === 'measure_type12' ||
      input === 'measure_type14' ||
      input === 'measure_type13' ||
      input === 'measure_type15' ||
      input === 'measure_type16' ||
      input === 'measure_type21' ||
      input === 'temp_f_c' ||

     
      input === 'pre_fini_floor' ||
      input === 'traff_use' ||

      input === 'property_dropdown' ||
      input === 'large_wind_flr_fc' ||
  
      input === 'stepOneTogg' ||
      input === 'stepTwoTogg1' ||
      input === 'stepTwoTogg2' ||
      input === 'stepThreeTogg' ||
      input === 'stepFourTogg1' ||
      input === 'stepFourTogg2'
    ) {
      input === 'time_type' ||
      input === 'formal_temp' ||
      input === 'time_type1' ||
      input === 'measure_type1' ||
      input === 'measure_type2' ||
      input === 'measure_type3' ||
      input === 'measure_type4' ||
      input === 'measure_type5' ||
      input === 'measure_type6' ||
      input === 'measure_type7' ||
      input === 'measure_type8' ||
      input === 'measure_type9' ||
      input === 'measure_type10' ||
      input === 'measure_type11' ||
      input === 'measure_type12' ||
      input === 'measure_type14' ||
      input === 'measure_type13' ||
      input === 'measure_type15' ||
      input === 'measure_type16' ||
      input === 'measure_type21' ||
      input === 'temp_f_c'
        ? setValues({ ...values, [input]: e[0] })
        : setValues({ ...values, [input]: e });
    } else if (input === 'footerLogo') {
      //start
      let footerlogo1 = [];
      let id = e.target.value;
      footerlogo1 = [...footerLogo];
      if (footerlogo1?.includes(id)) {
        let footer_idex = footerlogo1.indexOf(id);
        delete footerlogo1[footer_idex];
      } else {
        footerlogo1.push(id);
      }
      footerlogo1 = footerlogo1?.filter((e) => e);
      setFooterLogo(footerlogo1);
      values.footerLogo = footerlogo1;
    } else {
      setValues({ ...values, [input]: e.target.value });
    }
    setVals({
      ...values,
      currentUrl: window.location.href,
      attachments: [],
    });
    dispatch(Actions.create_report(vals));
  };

  // const fetchInspectorData = (params = {}) => {
  //   setLoading(true);
  //   dispatch(inspectorService.getInspectorData())
  //     .then((res) => {
  //       setLoading(true);
  //       values.signId = res.sign_for_report;
  //       values.signature = res.sign_for_report;
  //       values.signurl = res.signurl;
  //       values.footer_text = res.footer_text;
  //       if (res.footerlogos) {
  //         var sphValues = res.footerlogos;
  //         setFooterLogo(res.footerlogos);
  //         values.footerLogo = sphValues.map(function (e) {
  //           return e.toString();
  //         });
  //       }

  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.info("Get Error Edit State...");
  //       // setLoading(false);
  //     });
  // };

  const saveDraft = () => {
    setSpinnerReport(true);
    onFinish();
    values.status = 'Draft';
    console.warn('saveDraftvalues', values);
    setLoading(true);
    dispatch(NwfaSandFinishService.updateNwfaReport(values))
      .then((res) => {
        console.warn('Submit', res);
        setSpinnerReport(false);
        //return;
        window.location.reload(false);
        navigate(
          '/update-nwfa-sand-finish-report/?report_id=' + res.data.report_id
        );
        setLoading(false);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  const handleBathAdd = () => {
    setBathArea([...bathArea, { bath_area: '', bath_area_unit: '' }]);
  };
  const handleMasterChange = (e, index, input) => {
    console.warn({ e, index, input });
    let master_bedroom_area_val = '';
    if (input === 'master_bedroom_area') {
      master_bedroom_area_val = e.target.value;
    } else {
      master_bedroom_area_val = e;
    }
    const list = [...masterBedroomArea];
    list[index][input] = master_bedroom_area_val;
    setMasterBedroomArea(list);
    console.warn('bathArea', bathArea);
  };

  const handleMasterRemove = (index) => {
    const list = [...masterBedroomArea];
    list.splice(index, 1);
    setMasterBedroomArea(list);
  };

  const handleMasterAdd = () => {
    setMasterBedroomArea([
      ...masterBedroomArea,
      { master_bedroom_area: '', master_bedroom_area_unit: '' },
    ]);
  };

  const handleBathChange = (e, index, input) => {
    console.warn({ e, index, input });
    let bath_area_val = '';
    if (input === 'bath_area') {
      bath_area_val = e.target.value;
    } else {
      bath_area_val = e;
    }
    const list = [...bathArea];
    list[index][input] = bath_area_val;
    setBathArea(list);
    console.warn('bathArea', bathArea);
  };
  const handleBathRemove = (index) => {
    const list = [...bathArea];
    list.splice(index, 1);
    setBathArea(list);
  };

  const handleDateformate = (e, name, type) => {
    console.log('ok', e);

    if (name === 'h_bi_ins_date') {
      if (type === 'date') {
        setCalOpen(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'product_dateof_appli') {
      if (type === 'date') {
        setCalOpen21(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_st_coat_finish') {
      if (type === 'date') {
        setCalOpen17(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'finish_nd_coat') {
      if (type === 'date') {
        setCalOpen18(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'finish_rd_coat') {
      if (type === 'date') {
        setCalOpen19(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'other_coat') {
      if (type === 'date') {
        setCalOpen20(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'jobsite_date') {
      if (type === 'date') {
        setCalOpen1(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'closing_date') {
      if (type === 'date') {
        setCalOpen3(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'moving_date') {
      if (type === 'date') {
        setCalOpen4(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'operational_date') {
      if (type === 'date') {
        setCalOpen5(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_sold_to_end_user') {
      if (type === 'date') {
        setCalOpen6(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'finish_production_date') {
      if (type === 'date') {
        setCalOpen7(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'production_date') {
      if (type === 'date') {
        setCalOpen8(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_of_sanding') {
      if (type === 'date') {
        setCalOpen6(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completion') {
      if (type === 'date') {
        setCalOpen9(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_occupied') {
      if (type === 'date') {
        setCalOpen10(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_completion_first') {
      if (type === 'date') {
        setCalOpen11(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'date_reported') {
      if (type === 'date') {
        setCalOpen12(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }

    if (name === 'date_st_coat_finish') {
      if (type === 'date') {
        setCalOpen17(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'finish_nd_coat') {
      if (type === 'date') {
        setCalOpen18(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
    if (name === 'dateof_appli') {
      if (type === 'date') {
        setCalOpen22(false);
        let date = moment(e).format('MM-DD-YYYY');
        setValues({ ...values, [name]: date });
        console.log('date', date);
      } else {
        let value = e.target.value;
        setValues({ ...values, [name]: value });
      }
    }
  };

  const addClient = () => {
    console.warn('addClient', values);
    let clientData = {
      agent_claim_name: values.commisioning_name,
      comm_party_drop: values.comm_party_drop,
      comm_email: values.commisioning_party_email,
      street_name_number: values.street_name_number,
      state: values.commisioning_party_state,
      city: values.commisioning_party_city,
      zip_code: values.commisioning_party_zip,
      phone_no_party: values.commisioning_party_contact_number,
    };
    if (clientData.company_name_qr !== '' && clientData.comm_email !== '') {
      dispatch(createClientService.create_client(clientData))
        .then((res) => {
          console.warn('createClientService', res);
          if (res.status === 'client added') {
            fetchclientData();
            notification.open({
              message: 'Client successfully created!',
              description: 'You have successfully created a client.',
              icon: (
                <CheckCircleOutlined
                  style={{
                    color: '#108ee9',
                  }}
                />
              ),
            });
          } else {
            fetchclientData();
            notification.error({
              message: `${res.status}`,
              description:
                'The client that you are trying to create already exists.”',
              className: 'custom-class',
            });
          }
        })
        .catch((err) => {
          console.info('Get Error Edit State...', loading);
          notification.error('This is an error message');
          setLoading(false);
        });
    } else {
      notification.error('Client data is not valid');
    }
  };
  // const setDefaultPanel = () => {
  //   //setLoading(true);
  //   values.stepOneTogg = ["1", "2", "3", "4", "5", "6", "7"];
  //   values.stepTwoTogg = ["1", "2", "3", "4", "6", "7", "8", "9"];
  //   values.stepThreeTogg = ["1", "2", "3", "4"];
  //   values.stepFourTogg = ["1", "2", "3", "4"];
  //   //setLoading(false);
  // };
  function getStepContent(steps) {
    switch (steps) {
      case 0:
        return (
          <FirstStep
            handleChange={handleChange}
            loading={loading}
            values={values}
            isOpen={isOpen}
            handleDateformate={handleDateformate}
            calOpen={calOpen}
            setCalOpen={setCalOpen}
            handleFill={handleFill}
            addClient={addClient}
            clientDropdown={clientDropdown}
            calOpen1={calOpen1}
            setCalOpen1={setCalOpen1}
            calOpen2={calOpen2}
            setCalOpen2={setCalOpen2}
            calOpen3={calOpen3}
            setCalOpen3={setCalOpen3}
            calOpen4={calOpen4}
            setCalOpen4={setCalOpen4}
            calOpen5={calOpen5}
            setCalOpen5={setCalOpen5}
          />
        );
      case 1:
        return (
          <SecondStep
            handleChange={handleChange}
            handleDateformate={handleDateformate}
            values={values}
            isOpen={isOpen}
            calOpen6={calOpen6}
            setCalOpen6={setCalOpen6}
            calOpen7={calOpen7}
            setCalOpen7={setCalOpen7}
            calOpen8={calOpen8}
            setCalOpen8={setCalOpen8}
            calOpen9={calOpen9}
            setCalOpen9={setCalOpen9}
            calOpen10={calOpen10}
            setCalOpen10={setCalOpen10}
            calOpen11={calOpen11}
            setCalOpen11={setCalOpen11}
            calOpen12={calOpen12}
            setCalOpen12={setCalOpen12}
            // calOpen13={calOpen13}
            // setCalOpen13={setCalOpen13}
            calOpen17={calOpen17}
            setCalOpen17={setCalOpen17}
            calOpen18={calOpen18}
            setCalOpen18={setCalOpen18}
            calOpen19={calOpen19}
            setCalOpen19={setCalOpen19}
            calOpen20={calOpen20}
            setCalOpen20={setCalOpen20}
            calOpen21={calOpen21}
            setCalOpen21={setCalOpen21}
            calOpen22={calOpen22}
            setCalOpen22={setCalOpen22}
            handleEditor={handleEditor}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
            handleDateformate={handleDateformate}
            bathArea={bathArea}
            handleBathChange={handleBathChange}
            handleBathAdd={handleBathAdd}
            handleBathRemove={handleBathRemove}
            masterBedroomArea={masterBedroomArea}
            handleMasterChange={handleMasterChange}
            handleMasterAdd={handleMasterAdd}
            handleMasterRemove={handleMasterRemove}
          />
        );
      case 3:
        return (
          <FourthStep
            handleChange={handleChange}
            values={values}
            isOpen={isOpen}
            handleEditor={handleEditor}
            setLoadings={setLoadings}

          />
        );
      default:
        return <FirstStep />;
    }
  }

  function getSteps() {
    return [
      'GENERAL INFORMATION',
      'CLAIM HISTORY',
      'PHYSICAL DESCRIPTION ',
      'TESTING AND CONCLUSION',
    ];
  }
  const steps = getSteps();
  const onFinish = () => {
    setLoadings(true);

    console.log('Success:', values);
    dispatch(Actions.create_report({}));
    values.activeStep = activeStep;
    values.bathArea = bathArea;
    values.masterBedroomArea = masterBedroomArea;

    values.req_id = Data.req_id;
    values.category = Data.floor_category;
  
    values.upload_sign_toggle =
      values.upload_sign_toggle === true ? 'yes' : 'no';
    values.footer_text_info = values.footer_text_info === true ? 'yes' : 'no';
    values.private_notes_togg =
      values.private_notes_togg === true ? 'yes' : 'no';

    values.comm_party_toggle = values.stepOneTogg?.includes('1') ? 'yes' : 'no';
    values.homeowner_building_toggle = values.stepOneTogg?.includes('2')
      ? 'yes'
      : 'no';
    values.flooring_cont_toggle = values.stepOneTogg?.includes('3')
      ? 'yes'
      : 'no';
    values.gener_cont_info_toggle = values.stepOneTogg?.includes('4')
      ? 'yes'
      : 'no';
    values.manuf_info_toggle = values.stepOneTogg?.includes('5') ? 'yes' : 'no';
    values.retailer_info_toggle = values.stepOneTogg?.includes('6')
      ? 'yes'
      : 'no';
    values.jobsite_info_toggle = values.stepOneTogg?.includes('7')
      ? 'yes'
      : 'no';
    values.claim_history = values.stepTwoTogg1?.includes('1') ? 'yes' : 'no';
    values.jobsite_condition_ins_toggle = values.stepTwoTogg1?.includes('2')
      ? 'yes'
      : 'no';
    values.sanding_process_toggle = values.stepTwoTogg1?.includes('3')
      ? 'yes'
      : 'no';
    values.finish_colorant_toggle = values.stepTwoTogg1?.includes('4')
      ? 'yes'
      : 'no';
    values.comm_party_comm_toggle = values.stepTwoTogg2?.includes('1')
      ? 'yes'
      : 'no';
    values.enduser_toggle = values.stepTwoTogg2?.includes('2') ? 'yes' : 'no';
    values.flooring_contractor_info_toggle = values.stepTwoTogg2?.includes('3')
      ? 'yes'
      : 'no';
    values.other_invol_toggle = values.stepTwoTogg2?.includes('4')
      ? 'yes'
      : 'no';
    values.concern = values.stepThreeTogg?.includes('1') ? 'yes' : 'no';
    values.flooring_toggle = values.stepThreeTogg?.includes('2') ? 'yes' : 'no';
    values.rooms_being_toggle = values.stepThreeTogg?.includes('3')
      ? 'yes'
      : 'no';
    values.special_consideration_toggle = values.stepThreeTogg?.includes('4')
      ? 'yes'
      : 'no';
    values.jobsite_con_ins = values.stepFourTogg1?.includes('1') ? 'yes' : 'no';
    values.apply_industry_stndr_toggle = values.stepFourTogg2?.includes('2')
      ? 'yes'
      : 'no';
    values.additional_testing_info_toggle = values.stepFourTogg2?.includes('1')
      ? 'yes'
      : 'no';
    values.conclusion_cause_toggle = values.stepFourTogg2?.includes('3')
      ? 'yes'
      : 'no';
    values.footer_toggle = values.stepFourTogg2?.includes('4') ? 'yes' : 'no';
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed', errorInfo);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const buttonSubmit = () => {
    setSpinnerReport(true);
    values.status = 'Saved';
    onFinish();
    // return;
    dispatch(NwfaSandFinishService.updateNwfaReport(values))
      .then((res) => {
        console.warn('Submit', res);
        notification.open({
          message: 'Report successfully updated!',
          description: 'You have successfully updated a report.',
          icon: (
            <CheckCircleOutlined
              style={{
                color: '#108ee9',
              }}
            />
          ),
        });
        //return;
        setSpinnerReport(false);
        navigate('/single-nwfa-sandfinish/?report_id=' + res.data.report_id);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };
  return (
    <>
      {SpinnerReport ? (
        <ReportSpin />
      ) : (
        <>
          <div className="quickReportWrapper">
            <div className="pageHeader">
              <PageHeader title="NWFA Sand/Finish Report" />
            </div>
            {loading ? (
              <div className="container-xxl">
                <div className="Skeleton_space">
                  <Row gutter={16} className="mb-3" justify="center">
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                    <Col xs={24} md={6} xl={6} className="sklpad">
                      <Skeleton.Button
                        active
                        size={size}
                        shape={avatarShape}
                        block={block}
                        className="abccc"
                      />
                      <Skeleton.Input
                        active
                        size={size}
                        block={block}
                        className="abccd"
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-3">
                    <Col md={24}>
                      <Skeleton.Input block={true} active size="large" />
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div className="container-xxl CarpetDetailed">
                <div className="reportInner">
                  <Box sx={{ my: 5 }}>
                    <div>
                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        nonLinear
                      >
                        {steps.map((label, index) => (
                          <Step key={label} className="cusutom-stepp">
                            <StepButton
                              color="inherit"
                              onClick={handleStep(index)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </Box>
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="custom-form"
                  >
                    <div className="step-content">
                      {getStepContent(activeStep)}
                      <div className="custum-btngrp">
                        <Button
                          className="nxtbtn"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                          <Button
                            onClick={() => buttonSubmit()}
                            className="submitBtn"
                            loading={loadings}
                          >
                            Save And Continue To Preview Report
                          </Button>
                        ) : (
                          <Button
                            className="custom-nxtbtn"
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </div>
          <div className="saveDraftBtn">
            <Button
              className="float floatBtn sc_button_hover_fade"
              id="draft"
              name="update_button"
              onClick={() => saveDraft()}
                disabled={loadings}

            >
              Save Draft
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateNwfaSandFinish;
