import Http from '../Http';
import { INSPECTION_URL } from '../Helper';

export function companyCamtokenService(values) {
  let val = { companycam_token: values };
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + 'save_companycam_token', val)
        .then((res) => {
          console.log('Res', res);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getcompanycamToken() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_companycam_token')
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
}
