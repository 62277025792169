const initialState = {
  reqId: '',
  reqDetails: {},
  reportDetails: {},
  attachments: {},
};

const State = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case "MANUAL_REQ":
      return manualReqcreate(state, payload);
    case "CREATE_REPORT":
      return createReport(state, payload);
    case "UPDATE_REPORT":
      return updateReport(state);
    case "MEDIA_UPLOAD":
      return mediaUpload(state, payload);
    default:
      return state;
  }
};

function manualReqcreate(state, payload) {
  console.warn('create_manual_req 3n', state, payload);
  state.reqId = payload.data
  return {
    ...state,
    reqId : payload.data,
  };
}

function createReport(state, payload) {
  return {
    ...state,
    reportDetails: payload
  };
}

function updateReport(state, payload) {
return {
  ...state,
  reportDetails: payload,
};
}

function mediaUpload(state, payload) {
  state.attachments = [...state.attachments,payload.data.id];
  console.warn("state.attachments", state.attachments);
  return {
    ...state,
    attachments: payload.data.id,
  };
}

export default State;
