import React from "react";
import { Row, Col, Input, Card, Form } from "antd";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
const SecondStep = (props) => {
  console.warn("SecondStep", props);
 
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Form
      name="report"
      autoComplete="off"
      layout="vertical"
      onChange={handleChange}
    >
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Installation Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                      
                      <Form.Item
                       label="Product Installed?"
                       name="i2_product_ins"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.i2_product_ins}
                              onToggle={props.handleChange('i2_product_ins')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.productField === true || 
                  props.values.i2_product_ins === 'Yes'  ? (
                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                             label="Items Obstructing Expansion of Flooring"
                             name="items_obstruct"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.items_obstruct}
                              onToggle={props.handleChange('items_obstruct')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                             label="Fasteners Applied Through Laminate"
                             name="fasteners_applied"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.fasteners_applied}
                              onToggle={props.handleChange('fasteners_applied')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                              label="Fasteners Obstruct Floor Expansion"
                              name="fast_floor"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.fast_floor}
                              onToggle={props.handleChange('fast_floor')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                              label="Evident Floor Unlevelness"
                              name="evident_floor"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.evident_floor}
                              onToggle={props.handleChange('evident_floor')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                              label="Vertical Deflection Evident"
                              name="vertical_delection"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.vertical_delection}
                              onToggle={props.handleChange('vertical_delection')}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Box Label Information"
                            name="laminate_box_label"
                          >
                            <Input
                              placeholder="Box Label Information"
                              className="formControl"
                              onChange={props.handleChange(
                                "laminate_box_label"
                              )}
                              defaultValue={props.values.laminate_box_label}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Plank Backstamp Information"
                            name="lam_plank_backstamp"
                          >
                            <Input
                              placeholder="Plank Backstamp Information"
                              className="formControl"
                                onChange={props.handleChange("lam_plank_backstamp")}
                                defaultValue={props.values.lam_plank_backstamp}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Largest Span of Flooring Length"
                            name="lam_largest"
                          >
                            <Input
                              placeholder="Largest Span of Flooring Length"
                              className="formControl"
                              onChange={props.handleChange("lam_largest")}
                              defaultValue={props.values.lam_largest}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Largest Span of Flooring Width"
                            name="lam_span"
                          >
                            <Input
                              placeholder="Largest Span of Flooring Width"
                              className="formControl"
                              onChange={props.handleChange("lam_span")}
                              defaultValue={props.values.lam_span}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Shortest Seam Stagger in Floor"
                            name="shortest_seam"
                          >
                            <Input
                              placeholder="Shortest Seam Stagger in Floor"
                              className="formControl"
                              onChange={props.handleChange("shortest_seam")}
                              defaultValue={props.values.shortest_seam}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Flatness Measurements"
                            name="unlevelness_new"
                          >
                            <Input
                              placeholder="Flatness Measurements"
                              className="formControl"
                              onChange={props.handleChange("unlevelness_new")}
                              defaultValue={props.values.unlevelness_new}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Vertical Deflection Measurement"
                            name="vertical_def"
                          >
                            <Input
                              placeholder="Vertical Deflection Measurement"
                              className="formControl"
                              onChange={props.handleChange("vertical_def")}
                              defaultValue={props.values.vertical_def}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Vertical Deflection Measurement Unit"
                            name="measure_unit"
                          >
                            <Input
                              placeholder="Vertical Deflection Measurement Unit"
                              className="formControl"
                              onChange={props.handleChange("measure_unit")}
                              defaultValue={props.values.measure_unit}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Expansion Space - Room Perimeter"
                            name="room_peri"
                          >
                            <Input
                              placeholder="Expansion Space - Room Perimeter"
                              className="formControl"
                              onChange={props.handleChange("room_peri")}
                              defaultValue={props.values.room_peri}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={12} xl={12}>
                        <div className="formGroup">
                          <Form.Item
                            label="Expansion Space - Other Vertical Obstructions"
                            name="ver_obs"
                          >
                            <Input
                              placeholder="Expansion Space - Other Vertical Obstructions"
                              className="formControl"
                              onChange={props.handleChange("ver_obs")}
                              defaultValue={props.values.ver_obs}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={9} xl={9}>
                        <div className="formGroup">
                          <Form.Item
                             label="Transitions Used Between All Adjoining Rooms?"
                             name="adjoining_rooms"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.adjoining_rooms}
                              onToggle={props.handleChange('adjoining_rooms')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={14} xl={14}>
                        <div className="formGroup">
                          <Form.Item
                             label='Doorways and Cased Openings Measuring Less Than or Equal to 72" have Transition Moldings'
                             name="doorways_togg"
                          >
                            {console.warn("tripleToggle")}
                            <TripleToggle
                              value={props.values.doorways_togg}
                              onToggle={props.handleChange('doorways_togg')}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Cleaning Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                    <Form.Item
                        label="Cleaned?"
                        name="cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.cleaned}
                              onToggle={props.handleChange('cleaned')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.cleanedField === true || props.values.cleaned === 'Yes' ? (
                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Professionally Cleaned?"
                            name="professinally_cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.professinally_cleaned}
                              onToggle={props.handleChange('professinally_cleaned')}
                            />
                            </Form.Item>
                        </div>
                      </Col>
                      {props.isOpen.proCleanedField === true || props.values.professinally_cleaned === 'Yes' ? (
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Company Name"
                              name="cleaning_company_name"
                            >
                              <Input
                                placeholder="Company Name"
                                className="formControl"
                                onChange={props.handleChange(
                                  "cleaning_company_name"
                                )}
                                defaultValue={
                                  props.values.cleaning_company_name
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          
                          <Form.Item
                            label="Do It Yourself Cleaning"
                            name="do_it_yourself"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.do_it_yourself}
                              onToggle={props.handleChange('do_it_yourself')}
                            />
                            </Form.Item>
                        </div>
                      </Col>

                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="What Cleaning Products Used?"
                            name="clean_product"
                          >
                            <Input
                              placeholder="What Cleaning Products Used?"
                              className="formControl"
                              onChange={props.handleChange("clean_product")}
                              defaultValue={props.values.clean_product}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning Method"
                            name="cleaning_method"
                          >
                            <Input
                              placeholder="Cleaning Method"
                              className="formControl"
                              onChange={props.handleChange("cleaning_method")}
                              defaultValue={props.values.cleaning_method}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="How Often Cleaned?"
                            name="often_cleaned"
                          >
                            <Input
                              placeholder="How Often Cleaned?"
                              className="formControl"
                              onChange={props.handleChange("often_cleaned")}
                              defaultValue={props.values.often_cleaned}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning History and Details"
                            name="cleaning_history"
                          >
                            <Input
                              placeholder="Cleaning History and Details"
                              className="formControl"
                              onChange={props.handleChange("cleaning_history")}
                              defaultValue={props.values.cleaning_history}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default SecondStep;
