import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

export function getSingleRequest(req_id) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(INSPECTION_URL + 'insp_request_data/'+ req_id )
          .then((res) => {
            console.info('Single Request Response',res.data);
            return resolve(res.data);
          })
          .catch((err) => {
            console.log(err)
            return reject(err);
          });
      });
  }
