import React from 'react';
import { Image, Progress } from 'antd';
const SpinCustom = (props) => {
  return (
    <div className="show-loader">
      <div className="ajax-flipper-overlay"></div>
      <div className="ajax-flipper-container">
        <div className="ajax-flipper flip-animation">
          <Image
            src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/21051336/Company-Cam-logo-with-white-background.webp"
            className="ajax-flip-img"
            preview={false}
          />
        </div>
        <div className="ajax-flipper overlap-flip-animation">
          <Image
            src="https://app.floordetective.com/static/media/logo_tm.f4ec0903be892e30487c.png"
            className="ajax-flip-img rotate-180-y"
            preview={false}
          />
        </div>
      </div>
      <section className="loading-data">
        <div className="loading">
          <div className="loading__letter">U</div>
          <div className="loading__letter">p</div>
          <div className="loading__letter">l</div>
          <div className="loading__letter">o</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">d</div>
          <div className="loading__letter">i</div>
          <div className="loading__letter">n</div>
          <div className="loading__letter">g</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
        </div>
        <div className='progressbarSection'>
          <Progress 
            percent={props.progress && `${parseFloat(props.progress.toFixed(2))}`} status="active" />
        </div>
      </section>

    </div>
  );
};

export default SpinCustom;
