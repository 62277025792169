import React from "react";
import { Row, Col, Input, Card, Form } from "antd";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";

const SecondStep = (props) => {
  console.warn('SecondStep', props);
  const [form] = Form.useForm();

  return (
    <Form
      name="report"
      autoComplete="off"
      layout="vertical"
      form={form}
    >
      <Card className="reportCard">
        <div className="reportCardBody">
          <div className="formSection">
            <div className="inspectionSection">
              <div className="inspecHead">
                <h4 className="formGroupTitle">Cleaning Information</h4>
              </div>
              <div className="inspecContent">
                <Row gutter={16}>
                  <Col xs={24} md={6} xl={6}>
                    <div className="formGroup">
                      
                      <Form.Item
                        label="Cleaned?"
                        name="cleaned"
                            >
                              {console.warn("tripleToggle")}
                              <TripleToggle
                              value={props.values.cleaned}
                              onToggle={props.handleChange('cleaned')}
                            />
                            </Form.Item>
                    </div>
                  </Col>
                  {props.isOpen.cleanedField === true || props.values.cleaned === 'Yes' ? (

                    <>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="What Cleaning Products Used?"
                            name="clean_product"
                          >
                            <Input
                              placeholder="What Cleaning Products Used?"
                              className="formControl"
                              onChange={props.handleChange('clean_product')}
                              defaultValue={props.values.clean_product}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning Method"
                            name="cleaning_method"
                          >
                            <Input
                              placeholder="Cleaning Method"
                              className="formControl"
                              onChange={props.handleChange('cleaning_method')}
                              defaultValue={props.values.cleaning_method}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="How often Cleaned?"
                            name="often_cleaned"
                          >
                            <Input
                              placeholder="How often Cleaned?"
                              className="formControl"
                              onChange={props.handleChange('often_cleaned')}
                              defaultValue={props.values.often_cleaned}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Cleaning History and Details"
                            name="cleaning_history"
                          >
                            <Input
                              placeholder="Cleaning History and Details"
                              className="formControl"
                              onChange={props.handleChange('cleaning_history')}
                              defaultValue={props.values.cleaning_history}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                    </>
                  ) : ("")}
                </Row>

              </div>
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default SecondStep;
