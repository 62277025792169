import Http from '../Http';

export function getCompanycamPhotos() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get('https://api.companycam.com/v2/projects?per_page=500')
        .then((res) => {
          console.warn('Companycam pics', res.data);
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
