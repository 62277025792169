import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Form,
  Button,
  DatePicker,
  Collapse,
  Select,
  Tooltip,
  Spin,
  Modal,
  Cascader,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
  CopyOutlined,
  FormOutlined,
  EyeOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import SunEditor from 'suneditor-react';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link

} from "suneditor/src/plugins";
import * as getSuggestionService from "../../../services/getSuggestion";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../../Helper";

const optionsTemp = [
  {
    value: "°F",
    label: "°F",
  },
  {
    value: "°C",
    label: "°C",
  },
];

const SecondStep = (props) => {
  console.warn("SecondStep", props);
  var dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterStandards, setFilterStandards] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [sidebarState, setsidebarState] = useState();
  const [editorState1, setEditorState1] = useState("");
  const [editorState2, setEditorState2] = useState("");
  const [editorState3, setEditorState3] = useState("");
  const [editorState4, setEditorState4] = useState("");

  const getSuggestion = (label, category) => {
    setLoading(true);
    let suggestiondata = {
      label: label,
      category: category,
    };
    dispatch(getSuggestionService.getindStandard(suggestiondata))
      .then((res) => {
        setsidebarState(res);
        setLoading(false);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule","lineHeight"],
      ["outdent", "indent"],["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };

  const onEditorStateChange1 = (editorData) => {
    setEditorState1(editorData);
    props.values.float_comm_party_comm = editorData;
    props.handleEditor(editorData, "float_comm_party_comm");
  };

  const onEditorStateChange2 = (editorData) => {
    setEditorState2(editorData);
    props.values.float_end_user_comm = editorData;
    props.handleEditor(editorData, "float_end_user_comm");
  };

  const onEditorStateChange3 = (editorData) => {
    setEditorState3(editorData);
    props.values.float_floor_con_comm = editorData;
    props.handleEditor(editorData, "float_floor_con_comm");
  };
  const onEditorStateChange4 = (editorData) => {
    setEditorState4(editorData);
    props.values.float_oth_inv_comm = editorData;
    props.handleEditor(editorData, "float_oth_inv_comm");
  };

  useEffect(() => {
    console.info("Useffect Called");
    props.values.float_comm_party_comm
      ? setEditorState1(props.values.float_comm_party_comm)
      : setEditorState1("");
    props.values.float_end_user_comm
      ? setEditorState2(props.values.float_end_user_comm)
      : setEditorState2("");
    props.values.float_floor_con_comm
      ? setEditorState3(props.values.float_floor_con_comm)
      : setEditorState3("");
    props.values.float_oth_inv_comm
      ? setEditorState4(props.values.float_oth_inv_comm)
      : setEditorState4("");
    if (sidebarState) {
      const filteredData = sidebarState.filter((entry) =>
        entry?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const DateFormat = "MM-DD-YYYY";
  const { Option } = Select;
  const { TextArea } = Input;
     const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData()
     const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    formData.append('file', files[0])
    axios.post(MEDIA_UPLOAD_URL, formData, config)
      .then((res)=>{
         const response = {
                // The response must have a "result" array.
                "result": [
                    {
                        "url": res.data.source_url,
                        "name": files[0].name,
                        "size": files[0].size
                    },
            ]}
        uploadHandler(response)
      })
  }
  const handleClick = (item1) => {
    setModalIndustry(true);
    sidebarState.filter((item) =>
      item.id === item1 ? setindustryContent(item) : ""
    );
  };
  const sendTextToEditor = (text, label) => {
//    if (label === "cause") {
//       setEditorState4(editorState4 + text);
//       onEditorStateChange4(editorState4 + text);
//    } else if (label === "applicable-field-tests") {
//     setEditorState1(editorState1 + text);
//     onEditorStateChange1(editorState1 + text);
//  } else if (label === "conclusion") {
//   setEditorState2(editorState2 + text);
//   onEditorStateChange2(editorState2 + text);
// } else if (label === "industry_standards") {
//   setEditorState3(editorState3 + text);
//   onEditorStateChange3(editorState3 + text);
// }
  };
  return (
    <>
      <Form name="report" autoComplete="off" layout="vertical" form={form}>
        <Card className="reportCard">
          <div className="reportCardBody">
            <div className="formSection">
              <div className="inspectionSection">
                <div className="CollapseSection">
                  <Collapse
                    defaultActiveKey={
                      props.values.stepTwoTogg1
                        ? props.values.stepTwoTogg1
                        : ["1", "2", "3", "4"]
                    }
                    onChange={props.handleChange("stepTwoTogg1")}
                    className="reportCollapse"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusOutlined className="custom-icon" />
                      ) : (
                        <PlusOutlined className="custom-icon" />
                      )
                    }
                    ghost
                  >
                    <Panel header="Claim History" key="1">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Sold to End-User"
                              name="date_sold_to_end_user"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Sold to End-User"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_sold_to_end_user",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_sold_to_end_user}
                                  onFocus={() =>
                                    props.setCalOpen6(!props.calOpen6)
                                  }
                                  onKeyUp={() => props.setCalOpen6(false)}
                                  onBlur={() => props.setCalOpen6(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen6(!props.calOpen6)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen6}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_sold_to_end_user",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Delivered to Jobsite"
                              name="date_deliver_to_jobsite"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Delivered to Jobsite"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_deliver_to_jobsite",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_deliver_to_jobsite}
                                  onFocus={() =>
                                    props.setCalOpen7(!props.calOpen7)
                                  }
                                  onKeyUp={() => props.setCalOpen7(false)}
                                  onBlur={() => props.setCalOpen7(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen7(!props.calOpen7)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen7}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_deliver_to_jobsite",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date of Installation"
                              name="date_of_installation"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date of Installation"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_of_installation",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_of_installation}
                                  onFocus={() =>
                                    props.setCalOpen8(!props.calOpen6)
                                  }
                                  onKeyUp={() => props.setCalOpen8(false)}
                                  onBlur={() => props.setCalOpen8(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen8(!props.calOpen8)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen8}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_of_installation",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date of Completion"
                              name="date_completion"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date of Completion"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_completion",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_completion}
                                  onFocus={() =>
                                    props.setCalOpen9(!props.calOpen6)
                                  }
                                  onKeyUp={() => props.setCalOpen9(false)}
                                  onBlur={() => props.setCalOpen9(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen9(!props.calOpen9)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen9}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_completion",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Occupied"
                              name="date_occupied"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Occupied"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_occupied",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_occupied}
                                  onFocus={() =>
                                    props.setCalOpen10(!props.calOpen10)
                                  }
                                  onKeyUp={() => props.setCalOpen10(false)}
                                  onBlur={() => props.setCalOpen10(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen10(!props.calOpen10)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen10}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_occupied",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Complaint First Noticed"
                              name="date_completion_first"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Complaint First Noticed"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_completion_first",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_completion_first}
                                  onFocus={() =>
                                    props.setCalOpen11(!props.calOpen11)
                                  }
                                  onKeyUp={() => props.setCalOpen11(false)}
                                  onBlur={() => props.setCalOpen11(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen11(!props.calOpen11)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen11}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_completion_first",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Reported"
                              name="date_reported"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Reported"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_reported",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_reported}
                                  onFocus={() =>
                                    props.setCalOpen12(!props.calOpen12)
                                  }
                                  onKeyUp={() => props.setCalOpen12(false)}
                                  onBlur={() => props.setCalOpen12(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen12(!props.calOpen12)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen12}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_reported",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Reported to" name="reported_to">
                              <Input
                                placeholder="Reported to"
                                className="formControl"
                                onChange={props.handleChange("reported_to")}
                                defaultValue={props.values.reported_to}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Cleaning Products/Methods Used"
                              name="clean_product"
                            >
                              <Input
                                placeholder="Cleaning Products/Methods Used"
                                className="formControl"
                                onChange={props.handleChange("clean_product")}
                                defaultValue={props.values.clean_product}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="How Often Cleaned?"
                              name="how_often"
                            >
                              <Input
                                placeholder="How Often Cleaned?"
                                className="formControl"
                                onChange={props.handleChange("how_often")}
                                defaultValue={props.values.how_often}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Unusual Events"
                              name="unusual_events"
                            >
                              <Input
                                placeholder="Unusual Events"
                                className="formControl"
                                onChange={props.handleChange("unusual_events")}
                                defaultValue={props.values.unusual_events}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Jobsite Condition at Installation" key="2">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Temperature (Interior)During Install"
                              name="temp_during_ins"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="F/C"
                                    onChange={props.handleChange("temp_f_c")}
                                    options={optionsTemp}
                                    defaultValue={props.values.temp_f_c}
                                    className="custom-cascadar"
                                  />
                                }
                                placeholder="Temperature (Interior)During Install"
                                onChange={props.handleChange("temp_during_ins")}
                                defaultValue={props.values.temp_during_ins}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="RH (Interior) During Install"
                              name="rh_during_ins"
                            >
                              <Input
                                placeholder="RH (Interior) During Install"
                                className="formControl"
                                onChange={props.handleChange("rh_during_ins")}
                                defaultValue={props.values.rh_during_ins}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="HVAC Operational During Storage"
                                   name="hvac_oper_during_storage"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.hvac_oper_during_storage}
                                    onToggle={props.handleChange('hvac_oper_during_storage')}
                                  />
                              </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="HVAC Operational During Install"
                                   name="hvac_oper_during_install"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.hvac_oper_during_install}
                                    onToggle={props.handleChange('hvac_oper_during_install')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Process of Acclimation"
                              name="process_acclimation"
                            >
                              <TextArea
                                placeholder="Process of Acclimation"
                                className="formControl"
                                onChange={props.handleChange(
                                  "process_acclimation"
                                )}
                                defaultValue={props.values.process_acclimation}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Location and Description of Storage Area at Jobsite"
                              name="loc_des_stor_area"
                            >
                              <TextArea
                                placeholder="Location and Description of Storage Area at Jobsite"
                                className="formControl"
                                onChange={props.handleChange(
                                  "loc_des_stor_area"
                                )}
                                defaultValue={props.values.loc_des_stor_area}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Moisture Content at Installations" key="3">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Moisture Meter Type"
                              name="mois_meter_type"
                            >
                              <Input
                                placeholder="Moisture Meter Type"
                                className="formControl"
                                onChange={props.handleChange("mois_meter_type")}
                                defaultValue={props.values.mois_meter_type}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Name" name="mois_cont_name">
                              <Input
                                placeholder="Name"
                                className="formControl"
                                onChange={props.handleChange("mois_cont_name")}
                                defaultValue={props.values.mois_cont_name}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Model" name="mois_cont_model">
                              <Input
                                placeholder="Model"
                                className="formControl"
                                onChange={props.handleChange("mois_cont_model")}
                                defaultValue={props.values.mois_cont_model}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Meter Species Correction"
                              name="mois_species_cor"
                            >
                              <Input
                                placeholder="Meter Species Correction"
                                className="formControl"
                                onChange={props.handleChange(
                                  "mois_species_cor"
                                )}
                                defaultValue={props.values.mois_species_cor}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">
                            <Form.Item
                              label="Moisture Content Readings (include a range, average, and number of tests performed)"
                              name="mois_content_reading"
                            >
                              <TextArea
                                placeholder="Moisture Content Readings (include a range, average, and number of tests performed)"
                                className="formControl"
                                onChange={props.handleChange(
                                  "mois_content_reading"
                                )}
                                defaultValue={props.values.mois_content_reading}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Installation Method" key="4">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Installation Method"
                              name="car_i2_inst_type"
                            >
                              <Select
                                defaultValue={
                                  props.values.car_i2_inst_type
                                    ? props.values.car_i2_inst_type
                                    : "Installation Method"
                                }
                                onChange={props.handleChange(
                                  "car_i2_inst_type"
                                )}
                              >
                                <Option value="Nail/staple">Nail/staple</Option>
                                <Option value="Glue down">Glue Down</Option>
                                <Option value="Floating">Floating</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>

                        {props.isOpen.stapleField === true ||
                        props.values.car_i2_inst_type === "Nail/staple" ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Fastener Type"
                                  name="inst_method_fastner_type"
                                >
                                  <Input
                                    placeholder="Fastener Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_fastner_type"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_fastner_type
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Length"
                                  name="inst_method_length"
                                >
                                  <Input
                                    placeholder="Length"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_length"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_length
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Gauge"
                                  name="inst_method_gauge"
                                >
                                  <Input
                                    placeholder="Gauge"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_gauge"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_gauge
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Pneumatic Air Pressure Setting"
                                  name="inst_method_pne_air_pres"
                                >
                                  <Input
                                    placeholder="Pneumatic Air Pressure Setting"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_pne_air_pres"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_pne_air_pres
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Nailers Used on the Floor Install"
                                  name="inst_method_nailers_used_floor_install"
                                >
                                  <Input
                                    placeholder=" Number of Nailers Used on the Floor Install"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_nailers_used_floor_install"
                                    )}
                                    defaultValue={
                                      props.values
                                        .inst_method_nailers_used_floor_install
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Type Brand of Nailers"
                                  name="inst_method_type_brand_nailers"
                                >
                                  <Input
                                    placeholder="Type Brand of Nailers"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_type_brand_nailers"
                                    )}
                                    defaultValue={
                                      props.values
                                        .inst_method_type_brand_nailers
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Type of Underlayment Used"
                                  name="inst_method_type_underlayment_used"
                                >
                                  <Input
                                    placeholder="Type of Underlayment Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_type_underlayment_used"
                                    )}
                                    defaultValue={
                                      props.values
                                        .inst_method_type_underlayment_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Floor Measured For Flat"
                                    name="inst_metho_floot_measured_flat"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.inst_metho_floot_measured_flat}
                                    onToggle={props.handleChange('inst_metho_floot_measured_flat')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Method Flatness Measured"
                                  name="inst_metho_flatn_measured"
                                >
                                  <Input
                                    placeholder="Method Flatness Measured"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_metho_flatn_measured"
                                    )}
                                    defaultValue={
                                      props.values.inst_metho_flatn_measured
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Tolerance 1/4' in 10' (+/-)"
                                  name="inst_method_Toler"
                                >
                                  <Input
                                    placeholder="Tolerance 1/4' in 10' (+/-)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_Toler"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_Toler
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Tolerance 3/16' in 6' (+/-)"
                                  name="inst_method_Toler3"
                                >
                                  <Input
                                    placeholder="Tolerance 3/16' in 10' (+/-)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_Toler3"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_Toler3
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Movement within Flooring System"
                                  name="inst_method_type_movement_with_floor_sys"
                                >
                                  <Input
                                    placeholder="Movement within Flooring System"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_type_movement_with_floor_sys"
                                    )}
                                    defaultValue={
                                      props.values
                                        .inst_method_type_movement_with_floor_sys
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Additional Installation Information"
                                  name="inst_method_add_ins"
                                >
                                  <TextArea
                                    placeholder="Additional Installation Information"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "inst_method_add_ins"
                                    )}
                                    defaultValue={
                                      props.values.inst_method_add_ins
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : null}

                        {props.isOpen.installationGlue === true ||
                        props.values.car_i2_inst_type === "Glue down" ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Adhesive System Used (type/brand)"
                                  name="adhesive_sys_used"
                                >
                                  <Input
                                    placeholder="Adhesive System Used (type/brand)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "adhesive_sys_used"
                                    )}
                                    defaultValue={
                                      props.values.adhesive_sys_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Spread Rate"
                                  name="spread_rate"
                                >
                                  <Input
                                    placeholder="Spread Rate"
                                    className="formControl"
                                    onChange={props.handleChange("spread_rate")}
                                    defaultValue={props.values.spread_rate}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Control System Used (type/brand)"
                                  name="mois_cont_sys_used"
                                >
                                  <Input
                                    placeholder="Moisture Control System Used (type/brand)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "mois_cont_sys_used"
                                    )}
                                    defaultValue={
                                      props.values.mois_cont_sys_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Application Method"
                                  name="application_method_glue_down"
                                >
                                  <Input
                                    placeholder="Application Method"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "application_method_glue_down"
                                    )}
                                    defaultValue={
                                      props.values.application_method_glue_down
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Type of Trowel Used"
                                  name="type_trowel_used"
                                >
                                  <Input
                                    placeholder="Type of Trowel Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "type_trowel_used"
                                    )}
                                    defaultValue={props.values.type_trowel_used}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Trowels Used"
                                  name="trowel_used"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.trowel_used
                                        ? props.values.trowel_used
                                        : "Trowels Used"
                                    }
                                    onChange={props.handleChange("trowel_used")}
                                  >
                                    <Option value="New">New</Option>
                                    <Option value="Used">Used</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Trowels Used"
                                  name="num_trowel_used"
                                >
                                  <Input
                                    placeholder="Number of Trowels Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "num_trowel_used"
                                    )}
                                    defaultValue={props.values.num_trowel_used}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Subfloor Measured for Flat"
                                    name="subfloor_measured_flat"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.subfloor_measured_flat}
                                    onToggle={props.handleChange('subfloor_measured_flat')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Method Flatness Measured"
                                  name="meth_flat_meas"
                                >
                                  <Input
                                    placeholder="Method Flatness Measured"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "meth_flat_meas"
                                    )}
                                    defaultValue={props.values.meth_flat_meas}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Tolerance 3/16' in 10' (+/-)"
                                  name="glue_tol"
                                >
                                  <Input
                                    placeholder="Tolerance 3/16' in 10' (+/-)"
                                    className="formControl"
                                    onChange={props.handleChange("glue_tol")}
                                    defaultValue={props.values.glue_tol}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Tolerance 1/8' in 6' (+/-)"
                                  name="glu_tolerance"
                                >
                                  <Input
                                    placeholder="Tolerance 1/8' in 6' (+/-)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "glu_tolerance"
                                    )}
                                    defaultValue={props.values.glu_tolerance}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Age of Concrete"
                                  name="age_con"
                                >
                                  <Input
                                    placeholder="Age of Concrete"
                                    className="formControl"
                                    onChange={props.handleChange("age_con")}
                                    defaultValue={props.values.age_con}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Construction Joints Present"
                                  name="con_joints"
                                >
                                  <Input
                                    placeholder="Construction Joints Present"
                                    className="formControl"
                                    onChange={props.handleChange("con_joints")}
                                    defaultValue={props.values.con_joints}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Location(s)" name="glu_loc">
                                  <Input
                                    placeholder="Location(s)"
                                    className="formControl"
                                    onChange={props.handleChange("glu_loc")}
                                    defaultValue={props.values.glu_loc}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Type" name="glue_type">
                                  <Select
                                    defaultValue={
                                      props.values.glue_type
                                        ? props.values.glue_type
                                        : "Type"
                                    }
                                    onChange={props.handleChange("glue_type")}
                                  >
                                    <Option value="Construction">
                                      Construction
                                    </Option>
                                    <Option value="Control">Control</Option>
                                    <Option value="Isolation">Isolation</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Method Addressed at Installation"
                                  name="glu_met_add"
                                >
                                  <Input
                                    placeholder="Method Addressed at Installation"
                                    className="formControl"
                                    onChange={props.handleChange("glu_met_add")}
                                    defaultValue={props.values.glu_met_add}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Movement Within Flooring System"
                                  name="glu_mov_with_flr_sys"
                                >
                                  <Input
                                    placeholder="Movement Within Flooring System"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "glu_mov_with_flr_sys"
                                    )}
                                    defaultValue={
                                      props.values.glu_mov_with_flr_sys
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={12} xl={12}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Amount of Time Before Traffic Was Allowed on Installed Floor"
                                  name="glu_am_ins_floor"
                                >
                                  <Input
                                    placeholder="Amount of Time Before Traffic Was Allowed on Installed Floor"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "glu_am_ins_floor"
                                    )}
                                    defaultValue={props.values.glu_am_ins_floor}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Additional Installation Information"
                                  name="glu_add_inst_inf"
                                >
                                  <TextArea
                                    placeholder="Additional Installation Information"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "glu_add_inst_inf"
                                    )}
                                    defaultValue={props.values.glu_add_inst_inf}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            
                          </>
                        ) : null}
                        {props.isOpen.installationFloat === true ||
                        props.values.car_i2_inst_type === "Floating" ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Floating Installation Method"
                                  name="float_ins_met"
                                >
                                  <Select
                                    defaultValue={
                                      props.values.float_ins_met
                                        ? props.values.float_ins_met
                                        : "Floating Installation Method"
                                    }
                                    onChange={props.handleChange(
                                      "float_ins_met"
                                    )}
                                  >
                                    <Option value="Locking Mechanism">
                                      Locking Mechanism
                                    </Option>
                                    <Option value="Edge Glued">
                                      Edge Glued
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Adhesive Used"
                                  name="float_adhesive_used"
                                >
                                  <Input
                                    placeholder="Adhesive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_adhesive_used"
                                    )}
                                    defaultValue={
                                      props.values.float_adhesive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Perimeter Expansion"
                                  name="float_per_expa"
                                >
                                  <Input
                                    placeholder="Perimeter Expansion"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_per_expa"
                                    )}
                                    defaultValue={props.values.float_per_expa}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Type of Underlayment Used"
                                  name="float_underlayment_used"
                                >
                                  <Input
                                    placeholder="Type of Underlayment Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_underlayment_used"
                                    )}
                                    defaultValue={
                                      props.values.float_underlayment_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Floor Measured For Flat"
                                    name="float_floor_measured_for_flat"
                                  >
                                    {console.warn('TripleToggle')}
                                    <TripleToggle
                                    value={props.values.float_floor_measured_for_flat}
                                    onToggle={props.handleChange('float_floor_measured_for_flat')}
                                  />
                              </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Method Flatness Measured"
                                  name="float_metho_flatn_measured"
                                >
                                  <Input
                                    placeholder="Method Flatness Measured"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_metho_flatn_measured"
                                    )}
                                    defaultValue={
                                      props.values.float_metho_flatn_measured
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Tolerance 3/16' in 10' (+/-)"
                                  name="flo_Toler"
                                >
                                  <Input
                                    placeholder="Tolerance 3/16' in 10' (+/-)"
                                    className="formControl"
                                    onChange={props.handleChange("flo_Toler")}
                                    defaultValue={props.values.flo_Toler}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Tolerance 1/8' in 6' (+/-)"
                                  name="float_Toler3"
                                >
                                  <Input
                                    placeholder="Tolerance 1/8' in 6' (+/-)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_Toler3"
                                    )}
                                    defaultValue={props.values.float_Toler3}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Movement within Flooring System"
                                  name="float_movement_with_floor_sys"
                                >
                                  <Input
                                    placeholder="Movement within Flooring System"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_movement_with_floor_sys"
                                    )}
                                    defaultValue={
                                      props.values.float_movement_with_floor_sys
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Additional Installation Information"
                                  name="float_add_ins"
                                >
                                  <TextArea
                                    placeholder="Additional Installation Information"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "float_add_ins"
                                    )}
                                    defaultValue={props.values.float_add_ins}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </Panel>
                  </Collapse>
                </div>
                <div className="CollapseSection">
                  <div className="customObservationPanel">
                    <Row gutter={16}>
                      <Col xs={24} md={16} xl={16}>
                        <Collapse
                          defaultActiveKey={
                            props.values.stepTwoTogg2
                              ? props.values.stepTwoTogg2
                              : ["1", "2", "3", "4"]
                          }
                          onChange={props.handleChange("stepTwoTogg2")}
                          className="reportCollapse"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <MinusOutlined className="custom-icon" />
                            ) : (
                              <PlusOutlined className="custom-icon" />
                            )
                          }
                          ghost
                        >
                          <Panel header="Commissioning Party Comments" key="1">
                            <Row gutter={16} >
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                  Note: Please list the source from which standards were applied . Then copy/paste specific areas of content that directly apply to the Statement of Concern.                                   </b> </i> </div>
                              </Col>
                            </Row>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          // "applicable-field-tests",
                                          // "Hardwood"
                                          "float_comm_party_comm",
                                          "nwfa-sand-finish"
                                        );
                                       
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState1}
                                      onChange={onEditorStateChange1}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="End-User Comments" key="2">
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                         getSuggestion(
                                        //   "conclusion",
                                        //   "Hardwood"
                                        "float_end_user_comm",
                                          "nwfa-sand-finish"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState2}
                                      onChange={onEditorStateChange2}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Flooring Contractor Comments" key="3">
                          <Row gutter={16}>
                            <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                         getSuggestion(
                                        //   "industry_standards",
                                        //   "Hardwood"
                                        "float_floor_con_comm",
                                        "nwfa-sand-finish"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState3}
                                      onChange={onEditorStateChange3}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Other Involved Party Comments" key="4">
                          <Row gutter={16}>
                            <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                       getSuggestion(
                                        //   "cause",
                                        //   "Hardwood"
                                        "float_oth_inv_comm",
                                          "nwfa-sand-finish"
                                        );
                                        
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState4}
                                      onChange={onEditorStateChange4}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                        </Collapse>
                      </Col>
                      <Col xs={24} md={8} xl={8} className="sticySidebar">
                        <Card className="custom-sidecard">
                          <h5 className="formGroupTitle1">
                            Applied Industry Standards
                          </h5>
                          <div className="searchWrapper">
                            <Input
                              prefix={<SearchOutlined />}
                              placeholder="Search for names.."
                              className="formControl"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <p>
                              Your citations will start appearing here when you
                              will start writing under observation or industry
                              standards.
                            </p>
                            {loading ? (
                              <div className="spinDiv">
                                <Spin size="large" />
                              </div>
                            ) : (
                              <div className="innerCardWrap">
                                {filterStandards && search
                                  ? filterStandards?.map((item, index) => {
                                      return (
                                        <Card
                                          className="industryCard mb-2 sidebarCard"
                                          key={index}
                                        >
                                          <div className="industryCardBody">
                                            <div className="cardTopSection">
                                              <div className="headerSec">
                                                <h3
                                                  className="industryTitle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                ></h3>
                                                <p
                                                  className="industryDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.excerpt,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="contentSec">
                                              <div className="btnGroup">
                                                <Tooltip title="Copy/Paste">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn viewBtn"
                                                    onClick={() =>
                                                      sendTextToEditor(
                                                        item.description,
                                                        item.label
                                                      )
                                                    }
                                                  >
                                                    <CopyOutlined />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip title="View More">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                    onClick={() =>
                                                      handleClick(item.id)
                                                    }
                                                  >
                                                    <EyeOutlined />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })
                                  : sidebarState?.map((item, index) => {
                                      return (
                                        <Card
                                          className="industryCard mb-2 sidebarCard"
                                          key={index}
                                        >
                                          <div className="industryCardBody">
                                            <div className="cardTopSection">
                                              <div className="headerSec">
                                                <h3
                                                  className="industryTitle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                ></h3>
                                                <p
                                                  className="industryDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.excerpt,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="contentSec">
                                              <div className="btnGroup">
                                                <Tooltip title="Copy/Paste">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn viewBtn"
                                                    onClick={() =>
                                                      sendTextToEditor(
                                                        item.description,
                                                        item.label
                                                      )
                                                    }
                                                  >
                                                    <CopyOutlined />
                                                  </Button>
                                                </Tooltip>
                                                {/* <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip> */}
                                                <Tooltip title="View More">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                    onClick={() =>
                                                      handleClick(item.id)
                                                    }
                                                  >
                                                    <EyeOutlined />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })}
                              </div>
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Form>
      <Modal
        title={industryContent.title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{ __html: industryContent.description }}
            ></p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SecondStep;
