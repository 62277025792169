import Http from "../Http";
import { INSPECTION_URL } from "../Helper";

export function getUpdateClaimAnalystData(values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(INSPECTION_URL + 'update_claimanalyst/', values)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }