import React from 'react';
import { Image } from 'antd';

const SpinReportCustom = () => {
  return (
    <div className="Report-spin-loader">
      <div className="show-loader">
        <div className="ajax-flipper-overlay"></div>
        <div className="ajax-flipper-container">
          <div className="ajax-flipper flip-animation">
            <Image
              src="https://www.floordetective.com/wp-content/uploads/2021/09/logo_tm.png"
              className="ajax-flip-img"
              preview={false}
            />
          </div>
          <div className="ajax-flipper overlap-flip-animation">
            <Image
              src="https://www.floordetective.com/wp-content/uploads/2021/09/logo_tm.png"
              className="ajax-flip-img rotate-180-y"
              preview={false}
            />
          </div>
        </div>
        {/* <div>
        <h2 className="uploading-title">uploading....</h2>
      </div> */}
        <section className="loading-data">
          <div className="loading">
            <div className="loading__letter">R</div>
            <div className="loading__letter">e</div>
            <div className="loading__letter">p</div>
            <div className="loading__letter">o</div>
            <div className="loading__letter">r</div>
            <div className="loading__letter">t</div>
            <div className="loading__letter space-txt"></div>
            <div className="loading__letter">S</div>
            <div className="loading__letter">a</div>
            <div className="loading__letter">v</div>
            <div className="loading__letter">i</div>
            <div className="loading__letter">n</div>
            <div className="loading__letter">g</div>
            <div className="loading__letter">.</div>
            <div className="loading__letter">.</div>
            <div className="loading__letter">.</div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SpinReportCustom;
