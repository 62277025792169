import Http from '../Http';
import { INSPECTION_URL } from "../Helper";

// Industry Standards Get API
export function getStandards() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'industrystandardslist/')
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Industry Standards Create API
export function createStandards(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + 'create_industry_standard/', values)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Industry Standards Update API
export function updateStandards(values) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.put(INSPECTION_URL + 'update_industry_standard/', values)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Industry Standards Delete API
export function deleteStandards(val) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(INSPECTION_URL + 'delete_industry_standard/' + val.pid)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Industry Standards Public Library API
export function publicLibrary() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_publiclibrarylist/')
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Industry Standards Single API
export function singleStandard(val) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(INSPECTION_URL + 'get_industry_standard/' + val.pid)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Industry Standards Copy API
export function copySuggestion(val) {
  console.warn('copysss',val);
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(INSPECTION_URL + 'copy_suggestion/', val)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}