// UserProfileList.js
import React from 'react';
import { Row, Col } from 'antd';
import UserProfileCard from './UserProfileCard';

const UserProfileList = ({ profiles }) => {
  return (
    <Row gutter={16}>
      {profiles.map((profile) => (
        <Col key={profile.Id}  xs={24} md={8} className='mb-3'>
          <UserProfileCard profile={profile} />
        </Col>
      ))}
    </Row>
  );
};

export default UserProfileList;
