import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Image,
  Button,
  Form,
  Skeleton,
} from "antd";
import PageHeader from "../../components/PageHeader";
import {
  IdcardOutlined,
  MailOutlined,
  HomeOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import * as coverLetterService from "../../services/CoverLetter";
import { useNavigate } from "react-router-dom";

function CoverLetter() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);

  const [ coverLetterData, setCoverLetterData ] = useState([]);
  const size = 'large';
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 15000);
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const cover_id = queryParams.get("cover_id");
    
    dispatch(coverLetterService.getCoverData(cover_id))
      .then((res) => {
        console.info("invoicedata", res);
        setCoverLetterData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const getPdf = () => {
    enterLoading(0);
    const queryParams = new URLSearchParams(window.location.search);
    const cover_id = queryParams.get("cover_id");
    //setLoading(true);
    dispatch(coverLetterService.getCoverPdf(cover_id))
      .then((res) => {
        console.info("getCoverPdf", coverLetterData);
        //window.open(res.data, 'download');
        navigate("/send-email/?req_id=" + coverLetterData.req_id)
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  }

  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader 
          title="Cover Letter" 
          type="secondary"
          text="Edit Cover Letter"
          handleClick={() => navigate("/update-cover-letter/?cover_id=" + coverLetterData.cover_id)} 
        />
      </div>
      {loading ? 
        (
          <div className="container-xxl">
          <div className="Skeleton_cover">
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input block={true} active size="large" style={{height:"90px" }}/>
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-5 mt-5" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4 mt-5" justify="center">
                <Col xs={24} md={9} xl={9} className="sklpad">
                  
                </Col>
                <Col xs={24} md={6} xl={6} className="sklpad">
                <Skeleton.Input block={true} active size="large" style={{height:"350px" }}/>
                </Col>
                <Col xs={24} md={9} xl={9} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input block={true} active size={size} />
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input block={true} active size={size} />
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                  
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-4" justify="center">
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                <Col xs={24} md={8} xl={8} className="sklpad">
                <Skeleton.Input block={true} active size="large" />
                </Col>
                
              </Row>
              <Row gutter={16} className="mb-3">
                <Col md={24}>
                  <Skeleton.Input block={true} active size="large" />
                </Col>
              </Row>
              <Row gutter={16} className="mb-5" justify="center">
                <Col xs={24} md={10} xl={10} className="sklpad">
                
                </Col>
                <Col xs={24} md={4} xl={4} className="sklpad">
                <Skeleton.Input active size={size} />
                </Col>
                <Col xs={24} md={10} xl={10} className="sklpad">
                
                </Col>
                
              </Row>
              
            </div>
          </div>
        ) : (
          <div className="container-xxl">
            <Row justify="center">
              <Col xs={24} md={20}>
                <Form
                  name="coverLetter"
                  layout="vertical"
                  autoComplete="off"
                >
                  <div className="srWrapperInner">
                    <Card className="coverCard">
                      <Row gutter={16} justify="center">
                        <Col xs={24} md={6} xl={6}>
                          <div className="inspectorLogo text-center">
                            <Image
                              src={coverLetterData?.cover_logo_url}
                              className="img-fluid"
                              alt="InspectorLogo"
                              preview={false}
                            />
                            {/* <h4 className="inspectorName my-3">{coverLetterData?.inspector_company_name ? coverLetterData?.inspector_company_name : '-'}</h4> */}
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={16} justify="center">
                      <Col xs={24} md={24} xl={24}>
                      <div className="reportHeader coverHaeding1">
                      <h4 className="inspectorName my-3">{coverLetterData?.inspector_company_name ? coverLetterData?.inspector_company_name : '-'}</h4>
                      </div>
                        </Col>
                        </Row>
                        <Row gutter={16} justify="center">
                      <Col xs={24} md={24} xl={24}>
                      <div className="reportHeader coverHaeding1">
                      <h4>{coverLetterData?.coverHeading ? coverLetterData?.coverHeading : '-'}</h4>
                      </div>
                        </Col>
                        </Row>
                      <Row gutter={16} justify="center" className="my-4" wrap={true}>
                        <Col xs={24} md={8}>
                          {/* <div className="reportHeader">
                            <h4>{coverLetterData?.coverHeading ? coverLetterData?.coverHeading : '-'}</h4>
                          </div> */}
                          <Form.Item
                            name="upload"
                            className="text-center uploadLogo"
                          ><Image
                              src={coverLetterData?.cover_image_url || 'https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/08/19024932/elementor-placeholder-image66c2eaf97c28a66c2eaf97c2ed.webp'}
                              className="img-fluid"
                              alt="InspectorLogo"
                              preview={false}
                              
                            />
                          </Form.Item>
                          <Form.Item name="address_on_cover" className="customerNameInput">
                            <p className="formControl">{coverLetterData?.address_on_cover ? coverLetterData?.address_on_cover : '-'}</p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16} justify="center">
                        <div className="inspectorNameSection">
                          <h4 className="nameSectionTitle">Inspector Name</h4>
                          <h4 className="inspectorName">{coverLetterData?.inspector_name ? coverLetterData?.inspector_name : '-'}</h4>
                        </div>
                      </Row>
                      <Row gutter={30} justify="center" className="mb-4">
                        <Col xs={24} md={8} xl={8}>
                          <Form.Item name="inspection_date" label="Inspection Date">
                            <p className="formControl">{coverLetterData?.inspection_date ? coverLetterData?.inspection_date : '-'}</p>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} xl={8} className="colBorder">
                          <Form.Item label="Inspection Certification/CP #" name="inspector_certificationcp">
                            <p className="formControl">{coverLetterData?.inspector_certificationcp ? coverLetterData?.inspector_certificationcp : '-'}</p>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} xl={8}>
                          <Form.Item label="Claim#" name="claim_number">
                          <p className="formControl">{coverLetterData?.claim_number ? coverLetterData?.claim_number : '-'}</p>
                          </Form.Item>
                        </Col>
                      </Row>
                      <div className="letterFooter">
                        <span className="span"><IdcardOutlined/><span className="spanText">{coverLetterData?.inspector_name}</span></span>
                        <span className="divider">{'|'}</span>
                        <span className="span"><HomeOutlined/><span className="spanText">{coverLetterData?.inspector_company_name}</span></span>
                        <span className="divider">{'|'}</span>
                        <span className="span"><MailOutlined /><span className="spanText">{coverLetterData?.cover_email}</span></span>
                        <span className="divider">{'|'}</span>
                        <span className="span"><PhoneOutlined /><span className="spanText">{coverLetterData?.cover_phone}</span></span>
                      </div>
                      <Row gutter={16} className="pt-3">
                        <Col xs={24} md={24} className="text-center">
                          <Button className="btnPrimary" onClick={() => getPdf()} loading={loadings[0]}>Save and Continue to Email</Button>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        )
      }
    </div>
  );
}

export default CoverLetter;
