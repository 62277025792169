import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import ButtonBar from '../../components/ButtonNavigation';
import * as getViewClientDataService from "../../services/ViewClientService";
import {
  Row,
  Col,
  Form,
  Card,
  Divider,
  //Switch,
  Input,
  Button,
  Skeleton,List
  // notification
} from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as UpdateViewClientService from "../../services/viewClientUpdateService";

const UpdateViewClient = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [client, setClient] = useState();
  // const [hideShow1, setHideShow1] = useState(true);
  // const [showHide2, setShowHide2] = useState(true);
  const [value, setValue] = useState('yes');
  const [form] = Form.useForm();

  const queryParams = new URLSearchParams(window.location.search);
  const client_id = queryParams.get("client_id");

  useEffect(() => {
    setLoading(true);
    fetchViewClientData();
    setClient();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(client,loading,setValue);

  const fetchViewClientData = (params = {}) => {
    setLoading(true);
    dispatch(getViewClientDataService.getViewClientData(client_id))
      .then((res) => {
        form.setFieldsValue({
          first_name: res.first_name,
          last_name: res.last_name,
          comp_name: res.company_name,
          city: res.city,
          // company_info: res.company_information === true ? "yes" : "no" ,
          //company_info: res.company_information === "enable" ? "enable" : "disable" ,
          comp_type: res.company_type_,
          country: res.country,
          confirm_password: res.confirm_password,
          comp_email: res.email,
          phone: res.phone,
          phone_personal: res.phone_personal,
          website: res.website,
          po_box: res.po_box,
          street: res.street,
          state: res.states,
          zip_code: res.zip_code,
          user_name: res.user_name,
          email_add: res.email_add,
          pwd: res.confirm_password,
          confirm_pass: res.password,
          send_copy: res.send_copies_of_reports_or_invoice_to,
          status: res.status,
         // shipping_info: res.mailing_and_shipping_address === "yes" ? true : false ,
          //shipping_info: res.mailing_and_shipping_address === "enable" || res.mailing_and_shipping_address === "true" || res.mailing_and_shipping_address === 1 ? "enable" : "disable" ,
          welcome_email : res.welcome_email,
        });
        // if (res?.company_information === true) {
        //   setShowHide2("yes");
        // }
        // if (res?.mailing_and_shipping_address === true) {
        //   setHideShow1(true);
        // }
        // if (res?.company_information === 'enable') {
        //   setShowHide2(true);
        // }
        // if (res?.mailing_and_shipping_address === 'enable') {
        //   setHideShow1(true);
        // }
        setClient(res);
        console.info("fetchclaimdata", res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
    }

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  // const toggle1 = () => {
  //   if (hideShow1 === false) {
  //     setHideShow1(true);
  //   } else {
  //     setHideShow1(false);
  //   }
  // }
  // const toggle2 = () => {
  //   if (showHide2 === false) {
  //     setShowHide2(true);
  //   } else {
  //     setShowHide2(false);
  //   }
  // }
  //  const onChange = (e) => {
  //   console.log('radio checked', e.target.value);
  //   setValue(e.target.value);
  // };
  const onFinish = (values) => {
    console.log("Success:", values);
    const queryParams = new URLSearchParams(window.location.search);
    const client_id = queryParams.get("client_id");
    enterLoading(0);
    values.welcome_email = value;
    values.client_id = client_id;
    // values.company_information = values.company_information === showHide2 === "yes" ? "yes" : "no";
    // values.mailing_and_shipping_address = values.mailing_and_shipping_address === hideShow1 === "yes" ? "yes" : "no";
    // values.company_information = values.company_information === true || values.company_information === "enable" ? true : false;
    // values.mailing_and_shipping_address = values.mailing_and_shipping_address === hideShow1 === true === "1" ? "enable" : "disable";
   
    dispatch(UpdateViewClientService.getUpdateViewClientData(values))
      .then((res) => {
        console.warn("check data ...", res);
        navigate("/single-view-client/?client_id=" + client_id);
        console.warn("Submit",client_id, client);
        // if (res.status === "client added") {
        //   setClient(res);
        //   notification.open({
        //     message: "Client Update successfully created!",
        //     description: 'You have successfully Update a client.',
        //     icon: (
        //       <CheckCircleOutlined 
        //         style={{
        //           color: '#108ee9',
        //         }}
        //       />
        //     ),
        //   });
        //   navigate("/single-view-client/?client_id=" + res.client_id);
        // } else {
        //   setClient(res);
        //   notification.error({
        //     message: `${res.status}`,
        //     description: 'Client not Updated.',
        //     className: 'custom-class',
        //   });
        // }
        //return;
        
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="quickReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Update New Client" type="secondary" />
      </div>
      <div className="buttonSection">
        <ButtonBar />
      </div>
      <div className="container">
        <Row gutter={16} justify="center">
          <Col xs={24} md={24}>
            <div className="reportInner">
              <Form
                name="addClient"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                form={form}
              >
                <div className="srWrapperInner">
                {loading ? (
                <div className="">
             <Row gutter={16} className="mb-1 mt-4 skel_req">
                       <Col xs={24} md={4} xl={4} className="">
                       <Skeleton.Input block={true} active size="small" />
                       </Col>
                       <Col xs={24} md={3} xl={3} className="">
                       </Col>
                       <hr></hr>
                       <Col xs={24} md={17} xl={17} className="">
                        </Col>
                     </Row>
            <br></br>
           <hr/>
           <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
           </Skeleton>
           <br></br>
           <hr/>
           <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
              </Skeleton>
              <br></br>
           <hr/>
           <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
              </Skeleton>
              <br></br>
           <hr/>
           <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
              </Skeleton>
              <br></br>
           <hr/>
           <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
              </Skeleton>
              <br></br>
           <hr/>
           <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
           <Skeleton loading={loading} active >
             <List.Item.Meta
              />
              </Skeleton>
                  </div>
                ) : (
                  <Card className="reportCard">
                    <div className="reportCardBody">
                      <div className="formSection">
                        <div className="inspectionSection">
                          <div className="inspecDetailHead">
                            <h5 className="inspcTitle">Update Client</h5>
                          </div>
                          <Divider />
                          <div className="inspectorDetails">
                            <div className="inovicecard">
                              <div className="inspecContent">
                                <Row gutter={16}>
                                  {/* <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Company Information"
                                        name="company_information"
                                      >
                                        <Switch 
                                        //defaultChecked
                                        defaultChecked={
                                            client?.company_information ===
                                             true
                                              ? "enable"
                                              : "disable"
                                          }
                                           onClick={toggle2} />
                                      </Form.Item>
                                    </div>
                                  </Col> */}
                                </Row>
                                {/* {showHide2 === true ? ( */}
                                  <Row gutter={16}>
                                    <Col xs={24} md={6} xl={6}>
                                      <div className="formGroup">
                                        <Form.Item
                                          label="Company Name"
                                          name="comp_name"
                                        >
                                          <Input
                                            placeholder="Company Name"
                                            className="formControl"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col xs={24} md={6} xl={6}>
                                      <div className="formGroup">
                                        <Form.Item
                                          label="Website"
                                          name="website"
                                        >
                                          <Input
                                            placeholder="Website"
                                            className="formControl"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col xs={24} md={6} xl={6}>
                                      <div className="formGroup">
                                        <Form.Item
                                          label="Email"
                                          name="comp_email"
                                        >
                                          <Input
                                            placeholder="Email"
                                            className="formControl"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col xs={24} md={6} xl={6}>
                                      <div className="formGroup">
                                        <Form.Item
                                          label="Phone"
                                          name="phone"
                                        >
                                          <Input
                                            placeholder="Phone"
                                            className="formControl"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col xs={24} md={6} xl={6}>
                                      <div className="formGroup">
                                        <Form.Item
                                          label="Company Type"
                                          name="comp_type"
                                        >
                                          <Input
                                            placeholder="Company Type"
                                            className="formControl"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Col>
                                  </Row>
                                {/* ) : ('')} */}

                              </div>
                            </div>
                            <div className="inovicecard">
                              <div className="inspecContent">
                                <Row gutter={16}>
                                  {/* <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Mailing and Shipping Address"
                                        name="shipping_info"
                                      >
                                        <Switch 
                                        // defaultChecked 
                                        defaultChecked={
                                          client?.mailing_and_shipping_address ===
                                           true
                                            ? "enable"
                                            : "disable"
                                        }
                                        onClick={toggle1} />
                                      </Form.Item>
                                    </div>
                                  </Col> */}
                                  {/* {hideShow1 === true ? ( */}
                                    <Row gutter={16}>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Street"
                                            name="street"
                                          >
                                            <Input
                                              placeholder="Street"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="PO Box"
                                            name="po_box"
                                          >
                                            <Input
                                              placeholder="PO Box"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="City"
                                            name="city"
                                          >
                                            <Input
                                              placeholder="City"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="State/Province"
                                            name="state"
                                          >
                                            <Input

                                              placeholder="State/Province"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item label="Zip Code/Postal Code" name="zip_code">
                                            <Input
                                              placeholder="Zip Code/Postal Code"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col xs={24} md={6} xl={6}>
                                        <div className="formGroup">
                                          <Form.Item
                                            label="Country"
                                            name="country"
                                          >
                                            <Input
                                              placeholder="Country"
                                              className="formControl"
                                            />
                                          </Form.Item>
                                        </div>
                                      </Col>

                                    </Row>
                                  {/* ) : ('')} */}
                                </Row>
                              </div>
                            </div>
                            <div className="inovicecard">
                              <div className="inspecContent">
                                <Row gutter={16}>
                                  <div className="formGroup">
                                    <Form.Item>Login Information</Form.Item>
                                  </div>
                                </Row>
                                <Row gutter={16}>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Username"
                                        name="user_name"
                                      >
                                        <Input
                                          placeholder="Username"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="First Name"
                                        name="first_name"
                                      >
                                        <Input
                                          placeholder="First Name"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Last Name"
                                        name="last_name"
                                      >
                                        <Input
                                          placeholder="Last Name"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Email Address"
                                        name="comp_email"
                                      >
                                        <Input
                                          placeholder="Email Address"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item label="Password" name="pwd">
                                        <Input.Password
                                          placeholder="Password"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Confirm Password"
                                        name="confirm_pass"
                                      >
                                        <Input.Password
                                          placeholder="Confirm Password"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Phone"
                                        name="phone_personal"
                                      >
                                        <Input
                                          placeholder="Phone"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>{" "}
                                  {/* <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item label="Send Welcome Email">
                                        <Radio.Group name="welcome_email" onChange={onChange} value={value}>
                                          <Radio value="yes"> Yes </Radio>
                                          <Radio value="no"> No </Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    </div>
                                  </Col> */}
                                  <Col xs={24} md={12} xl={12}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Send Copies of Reports or Invoice to"
                                        name="send_copy"
                                      >
                                        <Input
                                          placeholder="Send Copies of Reports or Invoice to"
                                          className="formControl"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  {/* <Col xs={24} md={6} xl={6}>
                                    <div className="formGroup">
                                      <Form.Item label="Status" name="status">
                                        <Switch />
                                      </Form.Item>
                                    </div>
                                  </Col> */}
                                </Row>
                              </div>
                            </div>
                            <div className="custum-btngrp">
                              <Button htmlType="submit" className="submitBtn" loading={loadings[0]}>
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                )}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateViewClient;
