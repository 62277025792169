import React, { useState, useEffect } from "react";
import { Table, Switch, message,Empty,
  Skeleton, Row, Col, Input
} from "antd";
import { IconButton } from "../components/Buttons/buttons";
import {
  DeleteOutlined, EyeFilled, SearchOutlined
} from "@ant-design/icons";
import Swal from "sweetalert2";
import * as Clientsrvices from "../services/AllClients";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function ClientsData() {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchedText, setSearchedText] = useState("");
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportCheck = (event, id) => {
    console.warn("record", event, id);
    let status = event;
    Swal.fire({
      icon: "warning",
      title: "Are You Sure?",
      confirmButtonText: `Yes`,
      showDenyButton: true,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (status === true) {
          setLoading(true);
          let vals = {
            id: id,
            val: status,
          };
          dispatch(Clientsrvices.disable_client(vals))
            .then((res) => {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Enabled!",
                text: "Client has been enabled.",
                showLoaderOnConfirm: true,
              });
            })
            .catch((err) => {
              console.info("Get Error Edit State...");
              setLoading(false);
            });
        } else {
          setLoading(true);
          let vals = {
            id: id,
            val: status,
          };
          dispatch(Clientsrvices.disable_client(vals))
            .then((res) => {
              setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Disabled!",
                text: "Client has been disabled.",
                showLoaderOnConfirm: true,
              });
            })
            .catch((err) => {
              console.info("Get Error Edit State...");
              setLoading(false);
            });
        }
      } else if (result.isDenied) {
        if (event === true) {
          Swal.fire({
            icon: "warning",
            text: "Client Not Disabled!",
            showLoaderOnConfirm: true,
          });
        } else {
          Swal.fire({
            icon: "warning",
            text: "Client Not Enabled.",
            showLoaderOnConfirm: true,
          });
        }
      }
    });
  };


  const onChanges = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const handleBtn = (user_id) => {
    let val = {
      user_id: user_id
    };
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      text: "you want to delete client?",
      showLoaderOnConfirm: true,

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(Clientsrvices.delete_client(val))
        .then((res) => {
          message.success("Client deleted!");
          fetchData();
        })
        .catch((err) => {
          message.error(err);
        });
      } else if (result.isDenied) {
        Swal.fire('Client not deleted!', '', 'info')
      }
    })
    
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(Clientsrvices.getClients())
      .then((res) => {
        console.info(res);
        setClients(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => <>{(page - 1) * pageSize + index + 1}</>,
    },
    {
      title: "Client ID",
      dataIndex: "client_number",
      key: "client_number",
      sorter: {
        compare: (a, b) => a.client_number - b.client_number,
      },
    
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      sorter: (a, b) => {
        a = a.client_name || "";
        b = b.client_name || "";
        return a.localeCompare(b);
      },
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.client_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.client_name)
            .toLowerCase()
            .includes(value.toLowerCase()) 
        );
      },
    },
    {
      title: "Completed Inspections",
      dataIndex: "completed_inspections",
      key: "completed_inspections",
      sorter: {
        compare: (a, b) => a.completed_inspections - b.completed_inspections,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => {
        return (
          <div className="actionBtn">
            <Switch
              checkedChildren="ENABLE"
              unCheckedChildren="DISABLE"
              onChange={(event) => reportCheck(event, e.client_id)}
              defaultChecked={e.user_status === '1' ? false : true}
            />
          </div>
        );
      },
      sorter: (a, b) => {
        a = a.status || "";
        b = b.status || "";
        //return a.localeCompare(b);//

        // sorter: {
        //   compare: (a, b) => a.status - b.status,
        // },
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => {
        // console.log({ row });
        return (
          <div className="actionBtn">
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              tooltip="View"
              text="View"
              handleClick={() =>
                navigate("/single-view-client/?client_id=" + record.client_number)
              }
            />
            <IconButton
              icon={<DeleteOutlined />}
              type="primary"
              handleClick={() => handleBtn(record.client_number)}
              tooltip="Delete"
              text="Delete"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="tableWrapper">
      <div className="filterWrap">
        <Row gutter={16} justify="space-between">
          <Col md={6} xs={24}>
            <div className="searchWrapper">
              <Input
                className="formControl"
                prefix={<SearchOutlined />}
                allowClear
                placeholder="Search..."
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  setSearchedText(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Table
        bordered
        className="inspectionDataTable clientsDataTable"
        tableLayout='auto'
        scroll={{ x: 1000 }}
        columns={columns}
        onChange={onChanges}
        dataSource={clients}
        locale={{
          emptyText: loading ? columns.map(u => <Skeleton.Input height={50} style={{marginTop: '2px',height:'48px',padding:'0px'}} block={true}active={true} />): <Empty />
        }}
        rowKey={(record) => record.clients}
        showSorterTooltip={false}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
          position: ["bottomCenter"],
        }}
      //   loading={{
      //     indicator: <SkeltonTable />,
      //    spinning: loading,
      //  }}
      />
    </div>
  );
}


export default ClientsData;
